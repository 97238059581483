/*==========================================================================

	Main Template Core Stylesheet.
	Contains most important styles of the template.

	Do not change nothing as it will be constantly updated and your work might 
	get overwritten.

========================================================================== */

/* BODY FONT - Applies to everything */
body {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.7;
    color: #535353;
    background-color: #fbfbfb;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: normal;
}

a {
    color: #000;
    -webkit-transition: color 0.25s ease-in-out;
    transition: color 0.25s ease-in-out;
}
a:hover {
    color: #106ee9;
    text-decoration: none;
}
a:focus,
a:active {
    outline: none;
    text-decoration: none;
    color: inherit;
    text-shadow: none;
}
section {
    padding-top: 35px;
    padding-bottom: 35px;
}
.clear {
    clear: both;
}
img {
    max-width: 100%;
    height: auto;
}

h1,
.page-title {
    font-family: Montserrat;
    font-size: 2.2rem;
    line-height: 1.4;
    font-weight: 400;
    font-style: normal;
}
h2 {
    font-family: Montserrat;
    font-size: 1.9rem;
    line-height: 1.4;
    font-weight: 400;
    font-style: normal;
}
h3 {
    font-family: Open Sans;
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 400;
    font-style: normal;
}
h4 {
    font-family: Open Sans;
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 400;
    font-style: normal;
}
h5 {
    font-family: Open Sans;
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 400;
    font-style: normal;
}
h6 {
    font-family: Open Sans;
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 400;
    font-style: normal;
}

body.one-page {
    overflow-x: hidden;
}
.hg_ovhidden {
    overflow: hidden;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

/*dash-list - style for unordered lists */
ul.dash-list,
ul.dash-list ul {
    padding: 0;
    list-style: none;
}
ul.dash-list ul {
    margin-top: 10px;
    margin-bottom: 5px;
}
ul.dash-list li {
    line-height: 1.6;
    padding: 4px 0;
}
ul.dash-list li::before {
    content: '';
    display: inline-block;
    margin-right: 12px;
    width: 8px;
    height: 2px;
    background: #fe6060;
    vertical-align: middle;
}

/* Custom Colors */
.text-white {
    color: #fff;
}
.tcolor {
    color: #106ee9 !important;
}

.white {
    color: #fff !important;
}
.black {
    color: #2d2d2d !important;
}
.gray {
    color: #7c7c7c !important;
}
.gray2 {
    color: #545d60 !important;
}
.light-gray {
    color: #a8a8a8;
}
.light-gray2 {
    color: #dedede;
}
.light-gray3 {
    color: #c8c8c8;
}
.dark-gray {
    color: #343434 !important;
}
.orange {
    color: #e74c3c !important;
}
.blue {
    color: #3498db !important;
}
.b-gray {
    border-bottom-color: #898989 !important;
}

/* Backgrounds Colors */
.bg-white {
    background-color: #fff !important;
}
.tbg {
    background-color: #106ee9 !important;
}
.bg-gray {
    background-color: #6b6b6b !important;
}
.bg-lightgray {
    background-color: #efefef !important;
}
.bg-lightgray2 {
    background-color: #fefefe !important;
}
.bg-dark-gray {
    background-color: #353535 !important;
}
.bg-black-gray {
    background-color: #1b1b1b !important;
}

/* Colored sections */
.colored-section[data-color='darkgray'] {
    background-color: #1a1c25;
    color: #fff;
}
h4[data-color='white'] {
    color: #fff;
}

/* Opacity */
.op-3 {
    opacity: 0.3 !important;
}

.text-shadow {
    text-shadow: 0 2px 1px #000;
}
.vh-100 {
    height: 100vh;
}

/* Icomoon icons */
@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon/icomoon.eot?kyo8m');
    src: url('../fonts/icomoon/icomoon.eot?kyo8m#iefix')
            format('embedded-opentype'),
        url('../fonts/icomoon/icomoon.ttf?kyo8m') format('truetype'),
        url('../fonts/icomoon/icomoon.woff?kyo8m') format('woff'),
        url('../fonts/icomoon/icomoon.svg?kyo8m#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-gi-ico-1:before {
    content: '\e600';
}
.icon-gi-ico-2:before {
    content: '\e601';
}
.icon-gi-ico-3:before {
    content: '\e602';
}
.icon-gi-ico-4:before {
    content: '\e603';
}
.icon-gi-ico-5:before {
    content: '\e604';
}
.icon-gi-ico-6:before {
    content: '\e605';
}
.icon-gi-ico-7:before {
    content: '\e606';
}
.icon-gi-ico-8:before {
    content: '\e607';
}
.icon-gi-ico-9:before {
    content: '\e608';
}
.icon-gi-ico-10:before {
    content: '\e609';
}
.icon-gi-ico-11:before {
    content: '\e60a';
}
.icon-gi-ico-12:before {
    content: '\e60b';
}
.icon-gi-ico-13:before {
    content: '\e60c';
}
.icon-gi-ico-14:before {
    content: '\e60d';
}
.icon-gi-ico-15:before {
    content: '\e60e';
}
.icon-process1:before {
    content: '\e60f';
}
.icon-process2:before {
    content: '\e610';
}
.icon-process3:before {
    content: '\e611';
}
.icon-noun_61152:before {
    content: '\e612';
}
.icon-noun_167805:before {
    content: '\e613';
}
.icon-noun_65754:before {
    content: '\e614';
}
.icon-kallyas:before {
    content: '\e615';
}
.icon-Shape3:before {
    content: '\e616';
}
.icon-calendar2:before {
    content: '\e617';
}
.icon-pricetag:before {
    content: '\e618';
}
.icon-vespa:before {
    content: '\e619';
}
.icon-spinner102:before {
    content: '\e983';
}
/* end Icomoon icons */

/* Page Subheader Colors */
.page-subheader.uh_flat_dark_blue,
.kl-slideshow.uh_flat_dark_blue {
    background-color: #345370;
}
.page-subheader.uh_light_blue_-_flat,
.kl-slideshow.uh_light_blue_-_flat {
    background-color: #26ade4;
}
.page-subheader.uh_neutral_color,
.kl-slideshow.uh_neutral_color {
    background-color: #607d8b;
}
.page-subheader.uh_light_gray,
.kl-slideshow.uh_light_gray {
    background-color: #e0e0e0;
}
.page-subheader.uh_flat_redish,
.kl-slideshow.uh_flat_redish {
    background-color: #db4437;
}
.page-subheader.uh_flat_beige,
.kl-slideshow.uh_flat_beige {
    background-color: #a88c85;
}
.uh_hg_def_header_style,
.hg_def_header_style,
.page-subheader.hg_def_header_style,
.kl-slideshow.hg_def_header_style,
.page-subheader.uh_hg_def_header_style,
.kl-slideshow.uh_hg_def_header_style {
    background-color: #066fd9;
}

/* Min Height */
.min-200 {
    min-height: 200px !important;
}
.min-300 {
    min-height: 300px !important;
}
.min-380 {
    min-height: 380px !important;
}
.min-330 {
    min-height: 330px !important;
}
.min-370 {
    min-height: 370px !important;
}
.min-400 {
    min-height: 400px !important;
}
.min-450 {
    min-height: 450px !important;
}
.min-500 {
    min-height: 500px !important;
}
.min-600 {
    min-height: 600px !important;
}
.min-700 {
    min-height: 700px !important;
}
.min-800 {
    min-height: 800px !important;
}
.min-820 {
    min-height: 820px !important;
}

/* Max Height */
.max-300 {
    max-height: 300px !important;
}

/* Heights */
.h-260 {
    height: 260px !important;
}
.h-300 {
    height: 300px !important;
}
.h-320 {
    height: 320px !important;
}
.h-370 {
    height: 370px !important;
}
.h-520 {
    height: 520px !important;
}
.h-545 {
    height: 545px !important;
}
.h-400 {
    height: 400px !important;
}
.h-420 {
    height: 420px !important;
}
.h-450 {
    height: 450px !important;
}
.h-500 {
    height: 500px !important;
}
.h-600 {
    height: 600px !important;
}
.h-615 {
    height: 615px !important;
}
.h-625 {
    height: 625px !important;
}
.h-700 {
    height: 700px !important;
}
.h-720 {
    height: 720px !important;
}
.h-750 {
    height: 750px !important;
}
.h-760 {
    height: 760px !important;
}
.h-800 {
    height: 800px !important;
}

/* Align Styles */
.aligncenter {
    display: block;
    margin: 0 auto;
}
.alignright {
    float: right;
    margin: 0 0 10px 20px;
}
.alignleft {
    float: left;
    margin: 0 20px 10px 0;
}
.floatleft {
    float: left;
}
.floatright {
    float: right;
}
.textcenter {
    text-align: center !important;
}
.textright {
    text-align: right !important;
}
.textleft {
    text-align: left !important;
}
.vertical-aligned {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.center-block {
    padding-top: calc(100vh - 50%);
}
.bottom-block {
    position: absolute;
    bottom: 50px;
    width: 100%;
}
.absolute {
    position: absolute;
}

.t-underline {
    text-decoration: underline;
}

blockquote {
    border-left: 5px solid #106ee9;
    padding-left: 30px;
}

blockquote.blockquote-reverse,
blockquote.float-right {
    padding-right: 30px;
    padding-left: 0;
    border-right: 5px solid #106ee9;
    border-left: 0;
    text-align: right;
    border-left: none;
}

/* Font Family */
.kl-font-alt {
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

/* Gutter Sizes - Medium */
.row.gutter-md {
    margin-right: 0;
    margin-left: 0;
}
.row.gutter-md > [class*='col-'] {
    padding-right: 15px;
    padding-left: 15px;
}

/* Equalizer classes used for first or last elements in a row to align to fixed-width
containers of the page, from inside of a full-width section and gutter */
/* Gutter Sizes - zero gutter on container fluid */
.row.gutter {
    margin-right: -15px;
    margin-left: -15px;
}
.row.gutter > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}
@media (min-width: 768px) {
    .hg_col_eq_first {
        padding-left: calc((100vw - 750px) / 2);
    }
    .hg_col_eq_last {
        padding-right: calc((100vw - 750px) / 2);
    }

    /* Gutter Sizes - Small */
    .row.gutter-sm {
        margin-right: -15px;
        margin-left: -15px;
    }
    .row.gutter-sm > [class*='col-'] {
        padding-right: 10px;
        padding-left: 10px;
    }
    /* Gutter Sizes - Extra small */
    .row.gutter-xs {
        margin-right: -5px;
        margin-left: -5px;
    }
    .row.gutter-xs > [class*='col-'] {
        padding-right: 20px;
        padding-left: 20px;
    }
    /* Gutter Sizes - zero gutter */
    .row.gutter-0 {
        margin-right: -15px;
        margin-left: -15px;
    }
    .row.gutter-0 > [class*='col-'] {
        padding-right: 15px;
        padding-left: 15px;
    }
}
@media (min-width: 992px) {
    .hg_col_eq_first {
        padding-left: calc((100vw - 970px) / 2);
    }
    .hg_col_eq_last {
        padding-right: calc((100vw - 970px) / 2);
    }

    /* Gutter Sizes - Medium */
    .row.gutter-md {
        margin-right: -25px;
        margin-left: -25px;
    }
    .row.gutter-md > [class*='col-'] {
        padding-right: 25px;
        padding-left: 25px;
    }
}
@media (min-width: 1200px) {
    /* Gutter Sizes - Large */
    .row.gutter-lg {
        margin-right: -45px;
        margin-left: -45px;
    }
    .row.gutter-lg > [class*='col-'] {
        padding-right: 30px;
        padding-left: 30px;
    }

    .hg_col_eq_first {
        padding-left: calc((100vw - 1170px) / 2);
    }
    .hg_col_eq_last {
        padding-right: calc((100vw - 1170px) / 2);
    }

    body.boxed-layout .hg_col_eq_first {
        padding-left: 30px;
    }
}

/* Assure no X overflow is happening in fluid container */
.container-fluid.no-pad-cols .row,
.row.no-pad-cols {
    margin-left: 0;
    margin-right: 0;
}
.container-fluid.no-pad-cols,
.container-fluid.no-pad-cols > .row > div[class*='col-'],
.no-pad-cols > div[class*='col-'] {
    padding-left: 0;
    padding-right: 0;
}

.o-hidden {
    overflow: hidden;
}
.inline-block {
    display: inline-block !important;
}

/* Justified blocks */
.justified-blocks {
    text-align: justify;
}
.justified-blocks .jsf-block {
    display: inline-block;
}
.justified-blocks .jsf-stretch {
    width: 100%;
    display: inline-block;
    font-size: 0;
    line-height: 0;
}

/* PAGE PRELOADER */
.no-js body.preloader {
    display: none;
}

body.preloader:before {
    content: '';
    background-color: #fff;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9998;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

body.preloader:after {
    content: '';
    width: 42px;
    height: 42px;
    border: 2px solid #106ee9;
    border-radius: 30px;
    opacity: 0;
    -webkit-animation: preloader-pulsating-circle 1s ease-out;
    animation: preloader-pulsating-circle 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    top: calc(50% - 21px);
    left: calc(50% - 21px);
    z-index: 9999;
    position: absolute;
}

@-webkit-keyframes preloader-pulsating-circle {
    0% {
        -webkit-transform: scale(0.1);
        transform: scale(0.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 0;
    }
}

@keyframes preloader-pulsating-circle {
    0% {
        -webkit-transform: scale(0.1);
        transform: scale(0.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 0;
    }
}
/* end PAGE PRELOADER */

/* Circled Icon */
.circled-icon {
    display: inline-block;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    line-height: 46px;
    box-sizing: content-box;
    text-align: center;
    position: relative;
    vertical-align: middle;
    border-width: 2px;
    border-style: solid;
}
.circled-icon:before {
    vertical-align: middle;
    display: block;
    line-height: 46px;
}
.circled-icon.ci-xsmall {
    width: 22px;
    height: 22px;
    line-height: 22px;
}
.circled-icon.ci-xsmall:before {
    line-height: 23px;
}
.circled-icon.ci-small {
    width: 36px;
    height: 36px;
    line-height: 36px;
}
.circled-icon.ci-small:before {
    line-height: 36px;
}
.circled-icon.ci-large {
    width: 76px;
    height: 76px;
    line-height: 76px;
}
.circled-icon.ci-large:before {
    line-height: 76px;
}
.circled-icon.ci-faded {
    border-color: rgba(255, 255, 255, 0.5);
}

/* Call to action button in demo */
.buybtn-panel {
    position: fixed;
    z-index: 999;
    bottom: 50px;
    left: 0;
    padding: 20px 50px 20px 20px;
    background: rgba(0, 0, 0, 0.7);
    -webkit-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out;
}
.buybtn-close {
    position: absolute;
    top: 0;
    cursor: pointer;
    display: block;
    height: 80px;
    width: 30px;
    right: 0;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.69);
    line-height: 80px;
    border-left: 1px solid rgba(255, 255, 255, 0.16);
    padding: 0;
    text-align: center;
}
.buybtn-close:before {
    content: '\00d7';
}
.buybtn-close:hover {
    color: rgba(255, 255, 255, 0.9);
}
.buybtn-cta {
    background: #0ca919;
    border-radius: 2px;
    padding: 10px 20px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    width: 160px;
    display: inline-block;
    text-align: center;
}
.buybtn-cta span {
    opacity: 0.5;
    display: inline-block;
    border-left: 1px solid #fff;
    width: 1px;
    margin: 0 7px;
    height: 20px;
    vertical-align: middle;
}
.buybtn-cta:hover,
.buybtn-cta:focus {
    background: #179f07;
    color: #fff;
}
.buybtn-panel.is-hidden {
    left: -200px;
}
.buybtn-panel.is-hidden .buybtn-close:before {
    content: '\203A';
    font-size: 24px;
}

ul.list-style1,
ul.list-style2 {
    margin-left: 0;
    padding-left: 0;
}
ul.list-style1 li,
ul.list-style2 li {
    list-style: none;
    background: url(../images/list-style1.png) no-repeat 2px 8px;
    padding-left: 15px;
    margin-bottom: 4px;
}
ul.list-style2 li {
    background: url(../images/list-style2.png) no-repeat 2px 5px;
    padding-left: 25px;
}

/*fancy-list - style for unordered lists */
ul.fancy-list,
ul.fancy-list ul {
    padding: 0;
    list-style: none;
    color: #888888;
}
ul.fancy-list ul {
    margin-top: 10px;
}
ul.fancy-list li {
    border-top: 1px solid #e6e6e6;
    line-height: 1.4;
    padding: 12px 0;
}
ul.fancy-list li:first-child {
    border-top: 0;
}
ul.fancy-list[data-columns='2'] li:nth-child(2) {
    border-top: 0;
}
ul.fancy-list.pluses li::before {
    content: '+';
    font-size: 16px;
    font-family: 'Open Sans', Arial, sans-serif;
    opacity: 0.8;
    font-weight: 700;
}
ul.fancy-list li a {
    font-size: 13px;
    color: #45474d;
    text-decoration: none;
    line-height: 1.6;
    display: block;
    position: relative;
}
ul.fancy-list li a:hover {
    color: #fe6060;
}
ul.fancy-list.right-icon li a::after {
    font-family: 'icomoon';
    content: '\e007';
    color: #fe6060;
    font-size: 11px;
    line-height: 1;
    margin-right: 0px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -5px;
}
ul.fancy-list.right-icon li::before,
ul.fancy-list.right-icon.pluses li::before {
    display: none;
}

.bottom-border {
    border-bottom: 1px solid #e8e8e8;
}

/* Main Menu Badges */
.main-nav ul .zn-mega-new-item {
    position: absolute;
    line-height: 1;
    padding: 5px;
    text-transform: initial;
    font-size: 10px;
    top: 50%;
    right: 3px;
    background-color: #106ee9;
    color: #fff;
    border-radius: 2px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.main-nav ul ul .zn-mega-new-item {
    left: auto;
    right: 10px;
    text-transform: uppercase;
}
.main-nav ul ul.sub-menu li a:not(:only-child) .zn-mega-new-item {
    right: 30px;
}
.main-nav .zn_mega_container ul .zn-mega-new-item {
    right: 3px;
}

/* Scroll to top button */
#totop {
    height: 9px;
    opacity: 0;
    position: fixed;
    right: -60px;
    width: 49px;
    z-index: 111;
    display: block;
    bottom: 15%;
    background-repeat: no-repeat;
    background-position: center 15px;
    background-color: #404040;
    font-size: 9px;
    font-weight: 900;
    color: #fff;
    text-align: center;
    line-height: 1;
    border-radius: 2px;
    padding: 28px 0 21px 0;
}
#totop:before {
    position: absolute;
    content: '\f077';
    top: 10px;
    left: 50%;
    margin-left: -6px;
    font-size: 13px;
    display: inline-block;
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#totop.show {
    right: 10px;
    opacity: 0.7;
}
#totop:hover {
    opacity: 1;
}

/*  Sparkles */
#sparkles,
.th-sparkles {
    display: none;
}
.th-sparkles-visible .th-sparkles {
    display: block;
}
.th-sparkles > span {
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    background: -moz-radial-gradient(
        center,
        ellipse cover,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 60%
    );
    background: -webkit-gradient(
        radial,
        center center,
        0px,
        center center,
        100%,
        color-stop(0%, rgba(255, 255, 255, 1)),
        color-stop(60%, rgba(255, 255, 255, 0))
    );
    background: -webkit-radial-gradient(
        center,
        ellipse cover,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 60%
    );
    background: -o-radial-gradient(
        center,
        ellipse cover,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 60%
    );
    background: -ms-radial-gradient(
        center,
        ellipse cover,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 60%
    );
    background: radial-gradient(
        ellipse at center,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 60%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
}
.th-sparkles .shiny-spark1 {
    width: 20px;
    height: 20px;
    opacity: 0.4;
}
.th-sparkles .shiny-spark2 {
    width: 10px;
    height: 10px;
    opacity: 0.7;
}
.th-sparkles .shiny-spark3 {
    width: 30px;
    height: 30px;
    opacity: 0.1;
}
.th-sparkles .shiny-spark4 {
    width: 3px;
    height: 3px;
    opacity: 0.9;
}
.th-sparkles .shiny-spark5 {
    width: 15px;
    height: 15px;
    opacity: 0.3;
}
.th-sparkles .shiny-spark6 {
    width: 25px;
    height: 25px;
    opacity: 0.5;
}

/* page title */
.page-title,
.subtitle {
    line-height: 1.4;
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #cbcbcb;
}
.page-title {
    font-size: 36px;
    color: #050505;
    margin-top: 0;
}
.subtitle {
    font-size: 36px;
    color: #595959;
    font-weight: 300;
}

.wgt-title {
    color: #454545;
    font-size: 16px;
    line-height: 1.3;
    border-bottom: 2px solid #ededed;
    font-weight: 700;
    margin-bottom: 35px;
    letter-spacing: 0;
    padding-bottom: 10px;
}
.wgt-title > span {
    border-bottom: 2px solid #106ee9;
    display: inline-block;
    margin-bottom: -12px;
    padding-bottom: 10px;
}

/* support panel */
.panel-checkbox {
    display: none;
}
.support_panel {
    background: #f9f9f9;
    height: auto;
    max-height: 0;
    -webkit-transition: max-height 0.2s ease-out;
    transition: max-height 0.2s ease-out;
    position: relative;
    overflow: hidden;
    z-index: 1;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4) inset;
}
#support_p:checked + .support_panel {
    margin-top: 0;
    max-height: 1000px;
    -webkit-transition: max-height 0.4s ease-out;
    transition: max-height 0.4s ease-out;
}
#sliding_panel.opened {
    margin-top: 0;
}
.support_panel .container {
    padding: 15px 15px 25px;
}
#close_sliding_panel {
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    right: 14px;
    top: 0;
    background: #ccc;
    padding: 40px 10px 10px 10px;
}
#close_sliding_panel:hover {
    background: #ddd;
}
.support-info {
    margin-right: 4px;
    color: #fff;
    opacity: 0.8;
    display: inline-block;
}

.support-info.opened {
    display: none;
}
#support_p:checked .support-info.opened {
    display: none;
}

.topnav-item.spanel-label {
    margin-bottom: 0;
}
.topnav-item.spanel-label span {
    display: inline-block;
}

.support-panel-close {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 28px;
    color: #000;
    font-weight: 400;
    background: rgba(0, 0, 0, 0.08);
    display: block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    -webkit-transform: scale(0.1);
    -ms-transform: scale(0.1);
    transform: scale(0.1);
}
#support_p:checked + .support_panel .support-panel-close {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.support-panel-close:hover {
    background: rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15);
}

.how_to_shop > .row {
    font-size: 15px;
    margin-bottom: 20px;
}
.how_to_shop > p {
    font-size: 1rem;
    margin-top: 15px;
}
.how_to_shop .number {
    display: inline-block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 16px;
    color: #fff;
    background: #106ee9;
    text-align: center;
    margin-right: 10px;
    font-weight: 900;
    border-radius: 50%;
    margin-bottom: 5px;
}

/* Buttons styles */
.btn:focus,
.btn.focus {
    box-shadow: none;
}
/*** Btn Lined (ghost) */
.btn-lined {
    border-width: 2px;
    background: none;
    color: #fff;
    text-shadow: none;
    padding: 7px 20px;
    line-height: 1.6;
    box-shadow: none;
    font-size: 14px;
    font-weight: 700;
    border-style: solid;
    border-radius: 3px;
    border-color: #fff;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}
.btn-lined:hover {
    border-color: rgba(255, 255, 255, 0.7);
    color: #fff;
}
/* Btn Lined - Skewed style */
.btn-lined.btn-skewed {
    border: 0;
    background: none;
    position: relative;
}
.btn-lined.btn-skewed:before {
    content: '';
    border: 2px solid #fff;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: skewX(-8deg);
    -ms-transform: skewX(-8deg);
    transform: skewX(-8deg);
}
.btn-lined.btn-skewed:hover:before {
    border-color: rgba(255, 255, 255, 0.7);
}
/* Btn Lined Dark (ghost) */
.btn-lined.lined-dark {
    color: #242424;
    border-color: #242424;
}
.btn-lined.lined-dark:hover {
    color: #343434;
}
/* Btn Lined DARK - Skewed style */
.btn-lined.lined-dark.btn-skewed {
    border: 0;
    background: none;
    position: relative;
}
.btn-lined.lined-dark.btn-skewed:before {
    content: '';
    border: 2px solid #242424;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: skewX(-8deg);
    -ms-transform: skewX(-8deg);
    transform: skewX(-8deg);
}
.btn-lined.lined-dark.btn-skewed:hover:before {
    border-color: rgba(0, 0, 0, 0.7);
}
/* Btn Lined Dark (ghost) */
.btn-lined.lined-gray {
    color: #8e8e8e;
    border-color: #8e8e8e;
}
.btn-lined.lined-gray:hover {
    color: #454545;
}
/* Btn Lined Gray - Skewed style */
.btn-lined.lined-gray.btn-skewed {
    border: 0;
    background: none;
    position: relative;
}
.btn-lined.lined-gray.btn-skewed:before {
    content: '';
    border: 2px solid #8e8e8e;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: skewX(-8deg);
    -ms-transform: skewX(-8deg);
    transform: skewX(-8deg);
}
.btn-lined.lined-gray.btn-skewed:hover:before {
    border-color: rgba(0, 0, 0, 0.4);
}
/* Button Lined Full Light */
.btn-lined.lined-full-light {
    color: #242424;
    border-color: #fff;
    background: rgba(255, 255, 255, 0.8);
}
.btn-lined.lined-full-light:hover {
    color: #343434;
    background-color: #fff;
}
/* Button Lined Full Dark */
.btn-lined.lined-full-dark {
    color: #e0e0e0;
    border-color: #000;
    background: rgba(0, 0, 0, 0.8);
}
.btn-lined.lined-full-dark:hover {
    color: #f9f9f9;
    background-color: #000;
}
/* Button Lined Custom */
.btn-lined.lined-custom {
    color: #106ee9;
    border-color: #106ee9;
}
.btn-lined.lined-custom:hover {
    border-color: #9c1617;
}

/*** Filled buttons */
.btn-fullwhite,
.btn-fullblack,
.btn-fullcolor {
    border-width: 0;
}
.btn-fullwhite,
.btn-fullblack,
.btn-fullcolor,
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger,
.btn-link {
    text-shadow: none;
    padding: 10px 20px;
    line-height: 1.6;
    box-shadow: none;
    font-size: 13px;
    font-weight: 700;
    border-style: solid;
    border-radius: 3px;
    -webkit-transition: background-color 0.15s ease-out;
    transition: background-color 0.15s ease-out;
}

/*** Btn FullColor */
.btn-fullcolor,
.btn-fullcolor:focus {
    background-color: #106ee9;
    color: #fff;
}
.btn-fullcolor:hover {
    color: #fff;
}
/*** Btn Fullwhite */
.btn-fullwhite {
    background-color: #fff;
    color: #242424;
}
.btn-fullwhite:hover {
    color: #353535;
    background-color: rgba(255, 255, 255, 0.85);
}
/*** Btn Fullblack */
.btn-fullblack {
    background-color: #090909;
    color: #f9f9f9;
}
.btn-fullblack:hover {
    color: #eee;
    background-color: rgba(0, 0, 0, 0.85);
}
.btn-fullcolor:hover,
.btn-fullcolor.btn-skewed:hover:before,
.cart-container .buttons .button.wc-forward:hover {
    background-color: #0a3978;
}
/* Filled buttons - Skewed style */
.btn-fullwhite.btn-skewed,
.btn-fullblack.btn-skewed,
.btn-fullcolor.btn-skewed,
.btn-fullwhite.btn-skewed:hover,
.btn-fullblack.btn-skewed:hover,
.btn-fullcolor.btn-skewed:hover {
    border: 0;
    background: none;
    position: relative;
    z-index: 0;
}
.btn-fullwhite.btn-skewed:before,
.btn-fullblack.btn-skewed:before,
.btn-fullcolor.btn-skewed:before {
    content: '';
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: skewX(-8deg);
    -ms-transform: skewX(-8deg);
    transform: skewX(-8deg);
    -webkit-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
    z-index: -1;
}
/*** Btn FullColor */
.btn-fullcolor.btn-skewed:before {
    background-color: #106ee9;
}
/*** Btn Fullwhite */
.btn-fullwhite.btn-skewed:before {
    background-color: #fff;
}
.btn-fullwhite.btn-skewed:hover:before {
    background-color: rgba(255, 255, 255, 0.85);
}
/*** Btn Fullblack */
.btn-fullblack.btn-skewed:before {
    background-color: #090909;
}
.btn-fullblack.btn-skewed:hover:before {
    background-color: rgba(0, 0, 0, 0.85);
}

/* Btn sizing presets */
.btn-group-lg > .btn,
.btn-lg {
    padding: 20px 30px;
    font-size: 18px;
    line-height: 1.3333333;
}
.btn-group-lg > .btn.btn-lined,
.btn-lg.btn-lined {
    padding: 18px 30px;
}
.btn-group-md > .btn,
.btn-md {
    padding: 16px 25px;
    font-size: 14px;
    line-height: 1.3333333;
}
.btn-group-md > .btn.btn-lined,
.btn-md.btn-lined {
    padding: 14px 25px;
}
.btn-group-sm > .btn,
.btn-sm {
    padding: 5px 10px 4px;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 600;
}
.btn-group-sm > .btn.btn-lined,
.btn-sm.btn-lined {
    padding: 3px 10px 2px;
}
.btn-group-xs > .btn,
.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.6;
    font-weight: 400;
}
.btn-group-xs > .btn.btn-lined,
.btn-xs.btn-lined {
    padding: 1px 5px;
}

/* Button element */
.btn-element > span,
.btn-element > i {
    vertical-align: middle;
    display: inline-block;
}
.btn-element > span + i {
    margin-left: 10px;
}
.btn-element > i + span {
    margin-left: 10px;
}

/* Small hack for icon font */
[data-zniconfam]:before,
[data-zniconfam] {
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.hg_separator {
    margin-top: 15px;
    margin-bottom: 15px;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #e2e2e2;
}
.hg_separator.style2 {
    margin-top: 30px;
    margin-bottom: 45px;
    border-top-width: 4px;
    border-top-color: #ededed;
}
.section-shadow {
    box-shadow: inset 0 1px 0px rgba(0, 0, 0, 0.1),
        inset 0 2px 0px rgba(0, 0, 0, 0.07), inset 0 3px 0px rgba(0, 0, 0, 0.03);
}

body .kl-ioscaption--style4 .more:before {
    border-color: #106ee9;
    background: rgba(16, 110, 233, 0.7);
}
body .kl-ioscaption--style4 .more:hover:before {
    background: rgba(16, 110, 233, 0.9);
}

/*** NEW MENU SYSTEM ***/
.main-nav {
    display: none;
}

@media (min-width: 992px) {
    .main-nav {
        display: block;
    }
    .main-nav ul {
        list-style: none;
        padding: 0;
        margin: 0;
        line-height: 1;
    }
    .main-nav > ul:after,
    .main-nav > ul:before {
        display: table;
        content: ' ';
    }
    .main-nav > ul:after {
        clear: both;
    }
    .main-nav ul li {
        margin-bottom: 0;
    }
    .main-nav ul li a {
        display: block;
        font-weight: 400;
        font-family: 'Montserrat';
    }

    /* FIRST LEVEL */
    .main-nav > ul > li {
        float: left;
        margin-top: 0;
        margin-left: 5px;
    }
    .main-nav > ul > li:not(.menu-item-mega-parent) {
        position: relative;
    }
    .main-nav > ul > li:first-child {
        margin-left: 0;
    }
    .main-nav > ul > li > a {
        position: relative;
        padding: 6px 12px;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 14px;
        color: #fff;
        z-index: 1;
        font-weight: 400;
        -webkit-transition: padding 0.2s ease-in-out,
            -webkit-transform 0.2s ease-in-out;
        transition: padding 0.2s ease-in-out, transform 0.2s ease-in-out;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial,
            sans-serif;
    }

    .main-nav > ul > li.menu-item-has-children > a:after {
        content: '';
        opacity: 1;
        position: absolute;
        height: 1px;
        width: 7px;
        bottom: 3px;
        left: 17px;
        background-color: rgba(255, 255, 255, 0.4);
        -webkit-transition: opacity 0.2s ease-in-out;
        transition: opacity 0.2s ease-in-out;
    }

    .main-nav > ul > li > a:before {
        content: '';
        opacity: 0;
        position: absolute;
        border-radius: 2px;
        height: 50%;
        width: 100%;
        top: 50%;
        left: 0;
        background-color: #106ee9;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-transition: height 0.2s ease-in-out;
        transition: height 0.2s ease-in-out;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        z-index: -1;
    }

    .main-nav > ul > li.active > a,
    .main-nav > ul > li > a:hover,
    .main-nav > ul > li:hover > a {
        text-decoration: none;
        color: #fff;
    }
    .main-nav > ul > li.active > a:before,
    .main-nav > ul > li > a:hover:before,
    .main-nav > ul > li:hover > a:before,
    .main-nav > ul > li > a.active:before {
        height: 100%;
        opacity: 1;
    }
    .main-nav > ul > li.active.menu-item-has-children > a:after,
    .main-nav > ul > li.menu-item-has-children > a:hover:after,
    .main-nav > ul > li.menu-item-has-children:hover > a:after {
        opacity: 0;
    }

    /* FIRST SUB-LEVEL */
    .main-nav ul ul.sub-menu li {
        position: relative;
    }
    .main-nav ul ul.sub-menu ul {
        top: 100%;
        margin-top: 0;
    }
    .main-nav ul ul.sub-menu li a:not(:only-child):after {
        position: absolute;
        top: 50%;
        margin-top: -1px;
        right: 10px;
        content: '';
        opacity: 0.6;
        background-color: rgba(0, 0, 0, 0.3);
        width: 10px;
        height: 2px;
    }
    .main-nav ul ul.sub-menu li a {
        min-width: 235px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: rgba(0, 0, 0, 0.03);
        padding: 10px 20px;
        font-size: 12px;
        color: #757575;
        position: relative;
        text-transform: uppercase;
    }
    .main-nav ul ul.sub-menu li a[title='separator'] {
        text-indent: -2999px;
        padding: 0;
        background-color: rgba(0, 0, 0, 0.05) !important;
        background-clip: padding-box;
        height: 5px;
    }
    .main-nav ul ul.sub-menu li.active > a,
    .main-nav ul ul.sub-menu li a:hover {
        color: #323232;
        background: rgba(0, 0, 0, 0.04);
    }

    /* REST OF SUB-LEVELS */
    .main-nav ul ul.sub-menu,
    .main-nav .hg_mega_container {
        position: absolute;
        border-radius: 2px;
        border: 1px solid rgba(255, 255, 255, 0.8);
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
        background: #f5f5f5;
        padding: 6px 0;
        background: rgba(245, 245, 245, 1);
    }
    .main-nav > ul > li > ul.sub-menu:before {
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        left: 0;
        width: 100%;
        height: 10px;
    }
    /* fix for top hover */
    .main-nav > ul > li > ul.sub-menu:after {
        content: '';
        display: block;
        position: absolute;
        top: -11px;
        left: 14px;
        border: 6px solid transparent;
        border-bottom-width: 5px;
        border-bottom-color: #f5f5f5;
    }
    .main-nav ul ul.sub-menu li:hover > ul {
        left: 100%;
        top: -3px;
        opacity: 1;
    }

    /* MEGAMENU OVERRIDES */
    .main-nav .hg_mega_container a.zn_mega_title[href='#custom-invisible'],
    .main-nav
        .hg_mega_container
        li:last-child
        a.zn_mega_title[href='#custom-invisible'] {
        color: transparent;
    }
    .main-nav .hg_mega_container {
        border: 1px solid rgba(255, 255, 255, 0.8);
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
        background: #f5f5f5;
        padding: 6px 0;
        background: rgba(245, 245, 245, 0.95);
        position: absolute;
        padding: 20px 0;
        margin-top: 10px;
    }
    .main-nav .hg_mega_container:before {
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        left: 0;
        width: 100%;
        height: 10px;
    } /* fix for top hover */
    .main-nav .hg_mega_container li a {
        padding: 10px 0;
        font-size: 13px;
        color: #757575;
        position: relative;
        text-transform: uppercase;
    }
    .main-nav .hg_mega_container li a:not(.zn_mega_title):hover,
    .main-nav .hg_mega_container li.active > a:not(.zn_mega_title) {
        color: #323232;
    }
    .main-nav .hg_mega_container li a:not(.zn_mega_title):before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 2px;
        background-color: #106ee9;
        bottom: 0;
        -webkit-transition: width 0.15s cubic-bezier(0.68, 0, 0.265, 1);
        transition: width 0.15s cubic-bezier(0.68, 0, 0.265, 1);
    }
    .main-nav .hg_mega_container li a:not(.zn_mega_title):hover:before,
    .main-nav .hg_mega_container li.active > a:not(.zn_mega_title):before {
        width: 100%;
    }
    .main-nav .hg_mega_container .zn_mega_title_hide {
        display: none;
    }
    .main-nav .hg_mega_container .zn_mega_title_hide.v-hidden {
        display: block;
        visibility: hidden;
    }
    .main-nav .hg_mega_container li a.zn_mega_title,
    .main-nav div.hg_mega_container ul li:last-child > a.zn_mega_title {
        font-size: 0.9rem;
        padding-left: 0;
        padding-right: 0;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        padding: 12px 0px;
        margin-bottom: 15px;
        font-weight: 600;
        text-transform: uppercase;
        color: #333;
        border-bottom-color: rgba(0, 0, 0, 0.1);
    }
    .main-nav .menu-item-spacer {
        display: inline-block;
        height: 25px;
        width: 100%;
    }

    /* ANIMATIONS */
    .main-nav ul ul.sub-menu,
    .main-nav .hg_mega_container {
        left: -9999px;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: opacity 0.4s ease, top 0.45s ease;
        transition: opacity 0.4s ease, top 0.45s ease;
    }
    .main-nav .hg_mega_container {
        margin-left: 15px;
        margin-right: 15px;
        width: calc(100% - 30px);
    }
    .main-nav ul ul.sub-menu {
        top: 150%;
    }
    .main-nav .hg_mega_container {
        margin-top: 25px;
        -webkit-transition: opacity 0.4s ease, top 0.45s ease,
            margin-top 0.4s ease;
        transition: opacity 0.4s ease, top 0.45s ease, margin-top 0.4s ease;
    }
    .main-nav ul li:hover > ul,
    .main-nav ul li:hover > .hg_mega_container {
        left: 0;
        opacity: 1;
        visibility: visible;
        z-index: 100;
    }
    .main-nav ul li:hover > ul.sub-menu {
        top: 135%;
    }

    .main-nav ul li:hover > .hg_mega_container {
        margin-top: 10px;
    }
    .main-nav ul ul li a {
        -webkit-transition: padding-left 0.35s ease, border-right 0.35s ease;
        transition: padding-left 0.35s ease, border-right 0.35s ease;
    }

    /* MENU BADGES */
    .main-nav ul .hg-mega-new-item {
        position: absolute;
        line-height: 1;
        padding: 5px;
        text-transform: initial;
        font-size: 10px;
        top: 50%;
        right: 3px;
        background-color: #106ee9;
        color: #fff;
        border-radius: 2px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .main-nav ul ul .hg-mega-new-item {
        left: auto;
        right: 10px;
    }
    .main-nav ul ul.sub-menu li a:not(:only-child) .hg-mega-new-item {
        right: 23px;
    }
    .main-nav .hg_mega_container ul .hg-mega-new-item {
        right: 3px;
    }
    #menu-main-menu li > a[href='#'] {
        cursor: default;
    }
}

/* MENU CHASER STYLES */
.chaser {
    display: none;
}
@media (min-width: 992px) {
    .chaser {
        display: block;
        background: rgba(0, 0, 0, 0.85);
        padding: 0;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 111;
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        will-change: transform;
        -webkit-transition-property: opacity, visibility, -webkit-transform;
        transition-property: opacity, visibility, -webkit-transform;
        transition-property: opacity, visibility, transform;
        transition-property: opacity, visibility, transform, -webkit-transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    .chaser.visible {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
    .chaser #wpk-main-menu {
        position: initial;
    }
    .chaser .main-menu,
    .chaser .main-menu ul {
        margin: 0;
        padding: 0;
        position: relative;
    }
    .chaser .main-menu li:not(.menu-item-mega-parent) {
        position: relative;
    }
    .chaser .main-menu > li {
        float: left;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
    }
    .chaser .main-menu > li:last-child {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
    }
    .chaser .main-menu .sub-menu,
    .chaser .main-menu .hg_mega_container {
        left: -2999px;
        position: absolute;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0;
        -webkit-transition: opacity 0.15s ease-in-out,
            -webkit-transform 0.15s ease-in-out;
        transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
        border-radius: 0;
    }
    .chaser .main-menu .hg_mega_container {
        left: -2999px !important;
    }
    .chaser .main-menu .zn_mega_row_start:after,
    .chaser .main-menu .zn_mega_row_start:before {
        display: table;
        content: ' ';
    }
    .chaser .main-menu .zn_mega_row_start:after {
        clear: both;
    }
    .chaser .main-menu li ul {
        background: rgba(0, 0, 0, 0.9);
        min-width: 220px;
        padding: 5px;
    }
    .chaser .main-menu li ul li ul {
        background: none;
    }
    .chaser .main-menu .hg_mega_container > ul {
        padding-top: 0;
        padding-bottom: 20px;
    }
    .chaser .main-menu .hg_mega_container > ul:first-child {
        padding-top: 20px;
    }
    .chaser .main-menu .hg_mega_container > ul:last-child {
        padding-bottom: 30px;
    }
    .chaser .main-menu .hg_mega_container ul ul {
        background: none;
        padding: 10px 0 0;
    }
    .chaser .main-menu .hg_mega_container .zn_mega_title {
        border-bottom: 1px solid #454545;
        background: none;
        padding-left: 20px;
    }
    .chaser .main-menu .hg_mega_container .zn_mega_title_hide {
        display: none;
    }
    .chaser .main-menu .hg_mega_container .zn_mega_title_only_hide {
        display: block;
        visibility: hidden;
    }
    .chaser .main-menu li a {
        display: block;
        color: #ddd;
        font-size: 14px;
        line-height: 1.4;
        padding: 7px 15px;
        -webkit-transition: all 0.15s ease-in-out;
        transition: all 0.15s ease-in-out;
        position: relative;
        text-transform: uppercase;
    }
    .chaser .main-menu li:hover > a:not(:only-child) + .sub-menu,
    .chaser .main-menu li:hover > a:not(:only-child) + .hg_mega_container {
        left: 0;
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    .chaser .main-menu li:hover > a:not(:only-child) + .hg_mega_container {
        left: 15px !important;
    }
    .chaser .main-menu li > a:not(:only-child) {
        padding-right: 25px;
    }
    .chaser .main-menu li > a:not(:only-child):after {
        content: '';
        position: absolute;
        top: 50%;
        right: 9px;
        margin-top: -1px;
        height: 2px;
        width: 6px;
        background: #ccc;
        opacity: 0.5;
    }
    .chaser .main-menu li li:hover > a:not(:only-child) + .sub-menu {
        left: 100%;
        top: -4px;
    }
    .chaser .main-menu li:hover > a,
    .chaser .main-menu li a:hover {
        color: #fff;
        background: #000;
    }
    .chaser .main-menu li.active > a,
    .chaser .main-menu li > a.active {
        background: #106ee9;
        color: #ffffff;
    }
    .chaser .zn-mega-new-item {
        display: none;
    }
    .chaser .spanel-label,
    .chaser .topnav--lang {
        display: none;
    }
    .chaser .kl-header-toptext,
    .chaser .kl-header-toptext a {
        color: #fff;
        float: right;
        margin-left: 5px;
    }
    .chaser .header-leftside-container {
        padding-top: 10px;
    }
    .chaser .header-search {
        display: none;
    }
    .chaser .main-menu > li,
    .chaser .main-menu > li:last-child {
        border: none;
    }
    .chaser .main-menu li a[title='separator'] {
        text-indent: -2999px;
        display: none;
    }
    .chaser .main-menu,
    .chaser .col-sm-10,
    .chaser .col-md-12 {
        position: initial;
    }
    .chaser .row {
        position: relative;
    }
    .chaser .main-menu li li:hover > a:not(:only-child) + .sub-menu {
        background: rgba(0, 0, 0, 0.9);
    }
    .chaser .menu-item-spacer {
        display: inline-block;
        height: 25px;
        width: 100%;
    }
    .chaser #wpk-main-menu {
        padding-left: 0;
    }
    .chaser #_wpk-custom-bar {
        height: 32px;
    }
}

/* Responsive Menu */
#zn-res-menuwrapper {
    display: none;
}
#zn-res-menu ul {
    position: fixed !important;
}
#zn-res-menu,
#zn-res-menu li,
#zn-res-menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
#zn-res-menu * {
    -webkit-backface-visibility: hidden;
}
.zn_res_menu_visible #zn-res-menu,
.zn_res_menu_visible #zn-res-menu li ul {
    display: none;
}
#zn-res-menu,
#zn-res-menu li ul {
    right: 0;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: auto;
    z-index: 11112;
    -webkit-backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-transform: translate(100%, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    -webkit-transition: -webkit-transform 0.5s ease;
    -moz-transition: -moz-transform 0.5s ease;
    -ms-transition: -ms-transform 0.5s ease;
    -o-transition: -o-transform 0.5s ease;
    transition: transform 0.5s ease;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    padding: 0;
    display: none;
}

#zn-res-menu ul.zn-menu-visible,
#zn-res-menu.zn-menu-visible {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
#zn-res-menu li {
    position: relative;
    width: 100%;
    max-width: 100%;
    border-color: #d9d9d9;
}
#zn-res-menu .zn_mega_container {
    width: 100%;
}
#zn-res-menu .zn_mega_container li {
    width: 100%;
    float: none;
}
#zn-res-menu li > a {
    padding: 13px 28px;
    border-bottom: 1px solid;
    border-bottom-color: inherit;
    display: block;
    z-index: 1;
    color: #8c8c8c;
    font-size: 12px;
    text-transform: uppercase;
}
#zn-res-menu li.active > a {
    color: #333;
}
#zn-res-menu a {
    cursor: pointer;
}
#zn-res-menu .zn_res_has_submenu > span,
#zn-res-menu .zn_res_menu_go_back > span {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 44px;
    border-left: 1px solid;
    border-left-color: inherit;
    line-height: 49px;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    z-index: 2;
}
#zn-res-menu .zn_res_menu_go_back > a {
    text-align: center;
    padding-right: 0;
    padding-left: 48px;
    display: inline-block;
    width: calc(100% - 49px);
}
#zn-res-menu .zn_res_menu_go_back > span {
    left: 0;
    right: auto;
    border-left: none;
    border-right: 1px solid;
    border-right-color: inherit;
}
.zn-res-menuwrapper {
    display: block;
}
.zn-res-trigger {
    position: relative;
    display: block;
    width: 25px;
    height: 28px;
    margin: 10px;
}
#zn-res-menu .zn_res_has_submenu > span::before,
#zn-res-menu .zn_res_menu_go_back > span::before {
    display: none;
}
.zn-res-trigger::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 4px;
    top: 50%;
    margin-top: -10px;
    background: #fff;
    border-radius: 4px;
    left: 0;
    box-shadow: 0 8px 0 #fff, 0 16px 0 #fff;
}
#zn-res-menu li > a[title='separator'],
.zn_res_has_submenu .new-item {
    display: none;
}
#zn-res-menu.zn-menu-visible .zn-mega-new-item {
    display: none;
}
/* Menu Overlay */
.zn-res-menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 11111;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease-out, visibility 0.4s ease-out;
}
.zn-menu-visible + .zn-res-menu-overlay {
    opacity: 1;
    visibility: visible;
}

@media (max-width: 992px) {
    #zn-res-menuwrapper {
        display: block;
    }
    .zn_res_menu_visible #zn-res-menu,
    .zn_res_menu_visible #zn-res-menu li ul {
        display: block;
    }
    #main-menu {
        display: none;
    }
    #zn-res-menu li.active {
        background-color: #f3f3f3;
    }
    #zn-res-menu li.active > li {
        background-color: #f3f3f3;
    }
    #main-menu .dropdown-menu {
        margin-left: -20px;
        padding-left: 10px;
    }
    #main-menu .dropdown-menu {
        border: none;
        box-shadow: none;
    }
    #main-menu .dropdown-menu li > a {
        padding: 3px 8px;
        font-size: 12px;
    }
    #main-menu .dropdown-menu li > a:hover {
        background-color: transparent;
    }
    #main-menu .dropdown-menu > .active > a,
    #main-menu .dropdown-menu > .active > a:hover,
    #main-menu .dropdown-menu > .active > a:focus {
        background-color: transparent;
    }
    #main-menu .dropdown-menu li.active > .inner-elem {
        background-color: #106ee9;
    }

    #main-menu > ul {
        -webkit-transition: left 0.2s ease-out;
        -moz-transition: left 0.2s ease-out;
        -o-transition: left 0.2s ease-out;
        -ms-transition: left 0.2s ease-out;
        transition: left 0.2s ease-out;
        -webkit-backface-visibility: hidden;
    }
    #main-menu > ul ul {
        position: static;
        opacity: 1 !important;
        visibility: visible !important;
        background: transparent;
    }
    #main-menu > ul li {
        float: none;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        padding: 5px 0;
    }

    #zn-res-menu .zn-close-menu-button {
        display: inline-block;
        padding: 0 !important;
        position: absolute;
        right: 0;
        top: 0;
        height: 47px;
        width: 49px !important;
        border-left: solid 1px #ccc;
        z-index: 11111;
    }
    #zn-res-menu .zn-close-menu-button span {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate3d(-50%, -50%, 0);
        transform: translate3d(-50%, -50%, 0);
    }
}

/* Call to action Button in Header */
.ctabutton {
    clear: right;
    float: right;
    position: relative;
    font-size: 11px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    padding: 10px 10px 6px;
    border-radius: 2px;
    margin-left: 20px;
    margin-bottom: -5px;
}
.kl-cta-ribbon .trisvg {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 5px;
    margin-top: -0.2px;
    fill: #106ee9;
}
.kl-cta-ribbon .trisvg path {
    fill: #106ee9;
}
.kl-cta-ribbon:hover .trisvg {
    fill: #fff;
}
.kl-cta-ribbon {
    background: #106ee9;
}
.kl-cta-ribbon:focus {
    color: #fff;
}
.kl-cta-ribbon:hover {
    background: #fff;
    color: #000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}
.kl-cta-ribbon strong {
    font-size: 17px;
    font-weight: 900;
    display: block;
    line-height: 24px;
}
.kl-cta-lined {
    margin-top: 5px;
    background-color: transparent;
    box-shadow: none;
    padding: 8px 20px 9px;
}

/* SEARCH PANEL */
.white-icon {
    color: #fff;
}
.header-search {
    position: relative;
    z-index: 100;
    display: inline-block;
}
.header-search .search-container {
    display: none;
    position: absolute;
    right: -10px;
    padding: 30px;
    background: #f4f4f4;
    width: 240px;
    border-bottom: 1px solid #fff;
    z-index: 1;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
}
.header-search .search-container.panel-opened {
    display: block;
}
.header-search .searchBtn {
    line-height: 1;
    display: inline-block;
    background: rgba(0, 0, 0, 0.5);
    position: relative;
    z-index: 1;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    border-radius: 2px;
}
.header-search .searchBtn:hover {
    background: rgba(0, 0, 0, 0.7);
}
.header-search .searchBtn.active {
    background: #f4f4f4 !important;
    border-radius: 2px 2px 0 0;
}
.header-search .searchBtn.active .glyphicon {
    color: #000;
}
.header-searchform {
    border-bottom: 1px solid #cccccc;
    margin: 0;
    position: relative;
}
.header-searchform:hover {
    border-bottom: 1px solid #b5b5b5;
}
.header-searchform input[type='text'] {
    background: none;
    border: none;
    font-size: 12px;
    font-style: italic;
    color: #999;
    padding: 5px 0;
    margin: 0;
    display: inline-block;
    box-shadow: none;
    outline: none;
    font-style: italic;
    width: -webkit-calc(100% - 30px);
    width: calc(100% - 30px);
}
.header-searchform input[type='text']:focus {
    font-style: normal;
    color: #565656;
}
.header-searchform .searchsubmit {
    width: 20px;
    background-color: transparent;
    border: none;
    padding: 0;
    display: inline-block;
    height: 14px;
    line-height: 14px;
    vertical-align: text-top;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -8px;
    outline: none;
    color: rgba(108, 108, 108, 0.4);
}
.screen-reader-text {
    display: none;
}

/* TOP NAVIGATION */
.topnav {
    padding: 0;
    list-style: none;
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    z-index: 111;
}

.topnav > .topnav-item span {
    font-size: 0.8rem;
    font-weight: 700;
    color: #fff;
    line-height: 2.2;
    letter-spacing: 1px;
    display: inline-block;
    padding: 0;
    border-radius: 2px;
    opacity: 0.8;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    -webkit-transition: opacity 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out;
    -ms-transition: opacity 0.15s ease-in-out;
    -o-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out;
}
.topnav > .topnav-item i {
    opacity: 0.8;
    color: #fff;
}
.topnav .topnav-item {
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 2.2rem;
    letter-spacing: 1px;
    display: block;
    padding: 0;
    cursor: pointer;
}
.topnav .topnav-item a,
.topnav .topnav-item span {
    color: #fff;
    opacity: 0.8;
}

.topnav a:hover.topnav-item,
.topnav .topnav-item:hover a,
.topnav a:hover,
.topnav .topnav-item:hover span,
.topnav .spanel-label:hover .support-info,
.topnav > .topnav-item:hover i {
    opacity: 1;
    color: #fff;
}

.topnav.login--panel {
    display: inline-block;
}
.topnav.login--panel span,
.topnav.login--panel .login-icon {
    display: inline-block;
}
.topnav.login--panel .login-icon {
    margin-right: 5px;
}

.mainnav--cart .drop {
    position: relative;
    display: inline-block;
}
.topnav--lang .languages .pPanel,
.mainnav--cart .drop .pPanel {
    position: absolute;
    left: auto;
    width: auto;
    min-width: 120px;
    height: 0;
    opacity: 0;
    visibility: hidden;
    margin-left: -11px;
    right: 0;
}

.topnav--lang .languages .pPanel .inner,
.mainnav--cart .drop .inner {
    margin: 4px;
    list-style: none;
    background: #f9f9f9;
    padding: 10px 0;
    margin-top: 5px;
    position: relative;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    border-radius: 2px;
}

.mainnav--cart .drop .inner:after {
    content: '';
    top: -8px;
    position: absolute;
    left: 9px;
    border: 5px solid transparent;
    border-bottom-width: 4px;
    border-bottom-color: #f5f5f5;
}

.topnav--lang .languages:hover .pPanel,
.mainnav--cart .drop:hover .pPanel {
    opacity: 1;
    height: auto;
    visibility: visible;
    z-index: 11;
}
.mainnav--cart .drop li a {
    font-size: 10px;
    color: #222;
    padding: 0 5px 0 10px;
    display: block;
    text-transform: uppercase;
    margin-top: 1px;
}

.topnav--lang .languages .pPanel .inner .toplang-item.active,
.topnav--lang .languages .pPanel .inner .toplang-item:hover,
.mainnav--cart .drop li.active a,
.mainnav--cart .drop li a:hover {
    background: rgba(80, 80, 80, 0.1);
}

.mainnav--cart .drop li a span {
    float: right;
    opacity: 0.3;
    margin-top: 2px;
}

.topnav .popup-with-form a {
    font-size: 13px;
}

/* Cart details panel */
.site-header .kl-cart-button + .pPanel {
    min-width: 320px;
    left: auto;
    right: 0;
    margin-left: 0;
    margin-right: -10px;
    padding-top: 10px;
}
.site-header .kl-cart-button + .pPanel .inner:after {
    left: auto;
    right: 9px;
}
.mainnav--cart .drop:hover .kl-cart-button .fas {
    opacity: 1;
}
.site-header .kl-cart-button .fas {
    font-size: 16px;
    top: 2px;
    margin-right: 7px;
    color: #fff;
    opacity: 0.8;
}
.site-header .kl-cart-button .fas:after {
    content: attr(data-count);
    font-size: 9px;
    background-color: #106ee9;
    color: #fff;
    width: 15px;
    height: 15px;
    display: block;
    text-align: center;
    line-height: 13px;
    border-radius: 50%;
    position: absolute;
    top: -8px;
    left: -12px;
    font-weight: 600;
    padding-right: 1px;
}
.cart-container > .widget_shopping_cart_content {
    display: block;
    font-size: 12px;
}
.cart-container > .widget_shopping_cart_content .checkout {
    border-left: 1px solid #ccc;
    margin-left: 15px;
    padding-left: 15px;
    display: inline-block;
    color: #106ee9;
    font-size: 12px;
    text-transform: uppercase;
}
.cart-container > .widget_shopping_cart_content .checkout:hover {
    color: #333;
}
.cart-container > .widget_shopping_cart_content .checkout span {
    margin-top: 1px;
    opacity: 0.2;
}

.cart-container {
    margin: 0 !important;
    background: #f9f9f9 !important;
    padding: 25px !important;
    min-width: 320px;
}
.cart-container .cart_list {
    margin: 0 0 15px !important;
    list-style: none;
    border-bottom: 1px solid #ddd;
    padding: 0;
}
.cart-container .cart_list a:hover {
    background-color: transparent !important;
}
.cart-container .cart_list li {
    overflow: hidden;
    margin: 0 0 15px !important;
    text-align: center;
}
.cart-container .cart_list li a:not(.remove) {
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    text-transform: initial;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 0 30px 0 0 !important;
    text-align: left;
    margin-bottom: 3px;
}
.cart-container .cart_list .remove {
    float: right;
    font-size: 16px;
    color: #c3c3c3;
    padding: 0 !important;
    margin-top: 0;
}
.cart-container .cart_list .remove:hover {
    color: #7f7f7f;
}
.cart-container .cart_list .product-title:hover {
    color: #106ee9;
}
.cart-container .cart_list .color-variations,
.cart-container .cart_list .quantity {
    display: block;
    text-align: left;
}
.cart-container .cart_list li img {
    float: left !important;
    margin-right: 15px;
    margin-left: 0 !important;
    width: 75px !important;
}
.cart-container .cart_list li .variation,
.cart-container .cart_list li .variation p {
    margin: 0;
}
.cart-container .cart_list li .variation {
    overflow: hidden !important;
    border: 0 !important;
    padding: 0 !important;
}
.cart-container .cart_list li .variation-Color {
    display: inline-block;
    padding: 0 !important;
    margin: 0 !important;
    clear: none !important;
}
.cart-container .cart_list li .variationr:before,
.cart-container .cart_list li .variation:after {
    content: none;
}
.cart-container .total .amount {
    float: right;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1;
}
.cart-container .total {
    border-bottom: 1px solid #ddd;
    padding: 0 0 15px;
    margin: 0 0 15px;
}
.cart-container .buttons {
    overflow: hidden;
    margin: 0;
}
.cart-container .buttons .button {
    width: 49%;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    text-transform: uppercase;
}
.cart-container .buttons .button.wc-forward {
    background-color: #106ee9;
    color: #fff;
    text-shadow: none;
    font-weight: normal;
    padding: 9px 20px;
    line-height: 1.6;
    box-shadow: none;
    font-size: 14px;
    font-weight: 600;
    border-radius: 3px;
    -webkit-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
}
.cart-container .buttons .button.checkout {
    color: #242424;
    border-color: #242424;
    font-weight: normal;
    border-width: 2px;
    background: none;
    text-shadow: none;
    padding: 8px 20px;
    line-height: 1.6;
    box-shadow: none;
    font-size: 14px;
    border-style: solid;
    border-radius: 3px;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    margin-left: 0;
    background-color: transparent !important;
}

/* Languages */
.topnav.topnav--lang ul.inner {
    padding-left: 0;
    padding-right: 0;
}
.topnav.topnav--lang .languages .pPanel {
    left: 0;
    min-width: 140px;
}

.topnav.topnav--lang .toplang-item {
    margin: 1px 0;
    position: relative;
    text-transform: uppercase;
}
.topnav.topnav--lang .toplang-item a {
    display: inline-block;
    width: 100%;
    padding-right: 10px !important;
    padding: 6px 10px !important;
    font-size: 0.8rem;
    color: inherit;
}
.topnav.topnav--lang .toplang-item .toplang-flag {
    float: left;
    width: 23px;
    margin-right: 9px;
}
.topnav.topnav--lang .toplang-item.active::before {
    content: '\f00c';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    right: 10px;
    top: calc(50% - 12px);
    font-size: 14px;
}

/* Site Header & logo contain in header */
.site-logo {
    display: inline-block;
    margin-bottom: 0;
}
.site-logo img {
    max-height: 60px;
    max-width: 100%;
}

/* Logo with InfoCard on Hover */
.logo-container.hasInfoCard {
    position: relative;
}
.logo-container.hasInfoCard:before {
    content: url(../images/hoverme.png);
    width: 98px;
    height: 35px;
    right: 100%;
    margin-right: 20px;
    top: 20px;
    position: absolute;
}
.boxed .logo-container.hasInfoCard:before {
    display: none;
}
.logo-container .logo-infocard {
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: 20px;
    width: 500px;
    border-radius: 2px;
    font-size: 0.9rem;
    display: block;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
    -webkit-backface-visibility: hidden;
    z-index: 1;
}
.logo-container:hover .logo-infocard {
    visibility: visible;
    opacity: 1;
    margin-top: 10px;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    z-index: 2;
}
.logo-container .logo-infocard .left-side {
    background-color: #106ee9;
    padding: 40px 25px;
}
.logo-container .logo-infocard .right-side {
    background-color: #106ee9;
    padding: 30px 35px;
}
.logo-container .logo-infocard .map-link {
    display: inline-block;
    border: 1px solid #fff;
    color: #fff;
    padding: 5px 9px;
    font-weight: 600;
    border-radius: 3px;
}
.logo-container .logo-infocard .map-link:hover {
    border: 1px solid rgba(255, 255, 255, 0.7);
}

.logo-infocard,
.logo-infocard a {
    color: #fff;
    font-weight: normal;
    line-height: 1.6;
}
.logo-infocard a:hover {
    color: rgba(255, 255, 255, 0.7);
}
.logo-infocard p:last-child {
    margin-bottom: 0;
}
.logo-infocard .social-icons {
    margin-left: 0;
    padding-left: 0;
}
.logo-infocard .social-icons li {
    margin: 0;
}
.logo-infocard .social-icons li.title {
    margin-right: 10px;
    font-size: 15px;
}
.logo-infocard .social-icons li a {
    background-color: transparent;
    box-shadow: none;
}
.logo-infocard .social-icons li a:hover {
    opacity: 0.7;
}
.logo-infocard .contact-details {
    background: url(../images/say_hi.png) no-repeat right top;
}

/* Logo size preset */
.logosize--contain .site-logo .logo-img {
    width: auto;
    height: 100%;
}

/*** Header Height */
.site-header,
.logosize--contain .site-logo a {
    height: 100px;
}
.logosize--contain .site-logo .logo-img,
.logosize--contain .site-logo .logo-img-sticky {
    max-height: 100px;
    vertical-align: middle;
}

/* Site Content */
#page_wrapper {
    position: relative;
    overflow: hidden;
}

/* Site Header */
.site-header {
    position: absolute;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
}
.siteheader-container {
    position: relative;
}

.site-header {
    background: none;
    border: 0;
    box-shadow: none;
    height: auto;
}
.site-header .logosize--contain .site-logo a {
    height: 70px;
}
.site-header .separator {
    background: #fff;
    height: 1px;
    opacity: 0.2;
    margin-bottom: 5px;
}
.site-header .separator.dark {
    background: #9a9a9a;
}
.site-header .kl-header-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 170%;
    opacity: 0.6;
    pointer-events: none;
    background: -moz-linear-gradient(
        top,
        rgba(0, 0, 0, 1) 0%,
        transparent 100%
    );
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(0, 0, 0, 1)),
        color-stop(100%, transparent)
    );
    background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 1) 0%,
        transparent 100%
    );
    background: -o-linear-gradient(top, rgba(0, 0, 0, 1) 0%, transparent 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 1) 0%, transparent 100%);
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 0%,
        transparent 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );
}

.site-header .site-header-top {
    height: 46px;
}
.site-header .site-header-top-wrapper {
    position: relative;
}
.site-header-main-center {
    margin-left: 30px;
}
.site-header .searchBtn.active {
    border-radius: 2px 2px 0 0;
}
.site-header .kl-header-toptext {
    font-size: 0.95rem;
    color: #fff;
    line-height: 2.2;
}
.site-header .kl-header-toptext a {
    font-size: 1rem;
    color: #fff;
}
.site-header .site-header-top-wrapper .topnav {
    z-index: 111;
}
.site-header .site-header-top-wrapper .topnav.topnav--lang {
    z-index: 112;
}
.site-header .social-icons.topnav {
    margin: 0;
    padding: 0;
    position: relative;
}
.site-header .social-icons.topnav li {
    margin: 0;
    margin-left: 10px;
}
.site-header .social-icons.topnav.sc--clean li {
    margin-left: 0;
}
.site-header .social-icons.topnav li:first-child {
    margin-left: 0;
}
.site-header .kl-header-toptext {
    margin-left: 20px;
}
.site-header .social-icons.topnav.sc--clean > li > a {
    color: #fff;
}
.site-header .social-icons.topnav.sc--clean > li > a:hover {
    color: rgba(255, 255, 255, 0.9);
}
.site-header .kl-cta-lined {
    margin-top: 13px;
}

.site-header .site-logo-img {
    width: auto;
    height: 100%;
    padding: 10px 0;
}

/**** Site header style 2 */
.site-header[data-header-style='2'] .site-header-top-wrapper {
    background-color: rgba(0, 0, 0, 0.7);
}
.site-header[data-header-style='2'] .site-header-main-wrapper {
    background: rgba(0, 0, 0, 0.6);
    border-bottom: 3px solid #106ee9;
}
.site-header[data-header-style='2'] .site-header-top {
    height: auto;
    min-height: 80px;
}

.site-header[data-header-style='2'] .site-logo-anch {
    height: 80px;
}
.site-header[data-header-style='2'] .site-logo-img {
    max-height: 60px;
}

.site-header[data-header-style='2']
    .site-header-top-wrapper
    .topnav:last-child {
    margin-right: 0;
}

.site-header[data-header-style='2'] .header-search,
.site-header[data-header-style='3'] .header-search {
    width: 240px;
}
.site-header[data-header-style='2'] .header-search .search-container,
.site-header[data-header-style='3'] .header-search .search-container {
    padding: 0;
    position: static;
    display: block;
    box-shadow: none;
    border: 0;
    background: none;
    display: block !important;
}
.site-header[data-header-style='2'] .header-searchform,
.site-header[data-header-style='3'] .header-searchform {
    border: 0;
    padding: 5px 10px;
    background-color: transparent;
    border-radius: 3px;
}
.site-header[data-header-style='2'] .header-search .searchBtn,
.site-header[data-header-style='3'] .header-search .searchBtn {
    display: none;
}
.site-header[data-header-style='2'] .header-search .inputbox,
.site-header[data-header-style='3'] .header-search .inputbox {
    position: relative;
    z-index: 1;
}
.site-header[data-header-style='2'] .header-search .searchsubmit {
    z-index: 1;
    color: rgba(255, 255, 255, 0.4);
}
.site-header[data-header-style='2'] .header-search .searchsubmit:hover,
.site-header[data-header-style='3'] .header-search .searchsubmit:hover {
    cursor: pointer;
}
.site-header[data-header-style='2'] .header-search .kl-field-bg,
.site-header[data-header-style='3'] .header-search .kl-field-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    -webkit-transition: all 0.15s cubic-bezier(0.68, 0, 0.265, 1);
    transition: all 0.15s cubic-bezier(0.68, 0, 0.265, 1);
}
.site-header[data-header-style='2'] .header-search .inputbox:focus {
    color: #e3e3e3;
}
.site-header[data-header-style='2']
    .header-search
    .inputbox:hover
    ~ .kl-field-bg {
    background-color: rgba(255, 255, 255, 0.15);
}
.site-header[data-header-style='2']
    .header-search
    .inputbox:focus
    ~ .kl-field-bg {
    background-color: rgba(255, 255, 255, 0.2);
    width: 120%;
    left: -10%;
    height: 120%;
    top: -10%;
}

.site-header[data-header-style='2'] .topnav.social-icons li {
    margin: 0;
    margin-left: 10px;
}
.site-header[data-header-style='2'] .topnav.social-icons.sc--clean li {
    margin-left: 0;
}
.site-header[data-header-style='2'] .topnav.social-icons li:first-child {
    margin-left: 0;
}

.site-header[data-header-style='2'] .kl-cart-button + .pPanel {
    left: auto;
    right: 0;
    margin-left: 0;
    margin-right: -10px;
    margin-top: 5px;
}

.site-header[data-header-style='2'] .kl-main-header .kl-cta-ribbon {
    margin-top: 7px;
    margin-bottom: -10px;
}
.site-header[data-header-style='2'] .kl-main-header .kl-cta-lined {
    margin-top: -3px;
    margin-bottom: -8px;
    font-size: 90%;
    padding: 14px 10px;
    color: #fff;
    border: 0;
    background: #000;
}
.site-header[data-header-style='2'] .kl-main-header .kl-cta-lined:hover {
    color: #343434;
    background-color: #fff;
}

.site-header[data-header-style='2'] .main-nav {
    float: left;
    margin-top: 0px;
    clear: none;
}
.site-header[data-header-style='2'] .main-nav > ul {
    margin: 5px 0;
}

.site-header[data-header-style='2'] .main-nav > ul > li.active > a:before,
.site-header[data-header-style='2'] .main-nav > ul > li > a:hover:before,
.site-header[data-header-style='2'] .main-nav > ul > li:hover > a:before {
    height: -webkit-calc(100% + 23px);
    height: calc(100% + 23px);
    margin-bottom: -23px;
    margin-top: 3px;
}
.site-header[data-header-style='2'] .zn-res-menuwrapper {
    float: left;
    margin-top: 0;
    margin-left: 0;
}
.site-header[data-header-style='2'] .zn-res-menuwrapper .zn-res-trigger {
    margin-top: 8px;
    margin-bottom: 6px;
}
.site-header[data-header-style='2'] .zn-res-menuwrapper .zn-res-trigger:after {
    background: #ffffff;
    box-shadow: 0 8px 0 #ffffff, 0 16px 0 #ffffff;
    height: 2px;
}

.site-header[data-header-style='2'] .main-nav ul ul.sub-menu {
    top: calc(100% + 20px);
}
.site-header[data-header-style='2'] .main-nav .hg_mega_container {
    top: 120%;
    margin-top: 0;
}
.site-header[data-header-style='2'] .main-nav ul li:hover > ul {
    top: calc(100% - -12px);
}
.site-header[data-header-style='2'] .main-nav ul li:hover > .hg_mega_container {
    top: 100%;
}
.site-header[data-header-style='2'] .main-nav > ul > li > a {
    padding-top: 9px;
    padding-bottom: 4px;
}
.site-header[data-header-style='2'] .main-nav > ul > li.active > a:before,
.site-header[data-header-style='2'] .main-nav > ul > li:hover > a:before,
.site-header[data-header-style='2'] .main-nav > ul > li > a:hover:before {
    height: calc(100% + 25px);
    margin-bottom: -25px;
    margin-top: 8px;
}
.site-header[data-header-style='2'] .main-nav ul.sub-menu li:hover ul.sub-menu {
    top: -3px;
}

.site-header[data-header-style='2'] .kl-main-header .ctabutton {
    padding: 10px 10px 4px;
}

/**** Site header style 3 */
.site-header[data-header-style='3'] .site-header-row .col {
    padding: 0;
}
.site-header[data-header-style='3'] {
    background: rgba(255, 255, 255, 0.9);
    border-bottom: 3px solid #106ee9;
    box-shadow: none;
    height: auto;
}
.site-header[data-header-style='3'] .site-header-top-wrapper .topnav {
    margin-left: 0;
}

.site-header[data-header-style='3'] .kl-header-toptext {
    color: #494948;
    font-weight: 700;
}

.site-header[data-header-style='3'] .kl-header-toptext a,
.site-header[data-header-style='3'] .spanel-label {
    color: #494948;
}

.site-header[data-header-style='3'] .topnav .topnav-item a,
.site-header[data-header-style='3'] .topnav .topnav-item span,
.site-header[data-header-style='3'] .topnav > .topnav-item i {
    color: #252525;
}

.site-header[data-header-style='3'] .main-nav > ul > li.active > a,
.site-header[data-header-style='3'] .main-nav > ul > li > a:hover,
.site-header[data-header-style='3'] .main-nav > ul > li:hover > a {
    color: #ffffff;
}

.site-header[data-header-style='3'] .topnav:not(.social-icons) > li > a,
.site-header[data-header-style='3'] .topnav.sc--clean > li > a,
.site-header[data-header-style='3'] .topnav > li .white-icon {
    color: #252525;
}
.site-header[data-header-style='3'] .topnav:not(.social-icons) > li > a:hover,
.site-header[data-header-style='3'] .topnav.sc--clean > li > a:hover,
.site-header[data-header-style='3'] .topnav > li a:hover .white-icon {
    color: #676767;
}

.site-header[data-header-style='3'] .site-header-main {
    min-height: 80px;
}
.site-header[data-header-style='3'] .site-header-main-center {
    margin: 0;
}

.site-header[data-header-style='3'] .kl-cart-button .fas {
    color: #494948;
    top: 0;
    margin: 0;
}

.site-header[data-header-style='3'] .header-search .kl-field-bg {
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s cubic-bezier(0.68, 0, 0.265, 1);
    transition: all 0.15s cubic-bezier(0.68, 0, 0.265, 1);
}
.site-header[data-header-style='3']
    .header-search
    .inputbox:focus
    ~ .kl-field-bg {
    background-color: rgba(0, 0, 0, 0.2);
    width: 120%;
    left: -10%;
    height: 120%;
    top: -10%;
}
.site-header[data-header-style='3'] .header-search .inputbox:focus {
    color: #454545;
}
.site-header[data-header-style='3'] .header-search .searchsubmit {
    color: rgba(0, 0, 0, 0.4);
}

.site-header[data-header-style='3'] .separator {
    background: #000;
    height: 1px;
    opacity: 0.1;
}

.site-header[data-header-style='3'] .ctabutton {
    position: absolute;
    right: 15px;
    z-index: 1;
}
.site-header[data-header-style='3'] .kl-cta-ribbon {
    margin-top: 0px;
    margin-bottom: -10px;
}
.site-header[data-header-style='3'] .kl-cta-lined {
    margin-top: -6px;
    margin-bottom: -8px;
    font-size: 90%;
    padding: 17px;
    color: #fff;
    border: 0;
    background: #000;
}
.site-header[data-header-style='3'] .kl-cta-lined:hover {
    color: #f9f9f9;
    background-color: #000;
}
.site-header[data-header-style='3'] .kl-cta-ribbon {
    margin-top: -5px;
}

.site-header[data-header-style='3'] .logo-container .logo-infocard {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 2;
}

.site-header[data-header-style='3']
    .site-header-top-wrapper
    .topnav.topnav--lang {
    margin-right: 0;
}

.site-header[data-header-style='3'] .main-nav {
    text-align: center;
}
.site-header[data-header-style='3'] .main-nav > ul {
    display: inline-block;
    text-align: start;
    margin-top: 8px;
    margin-bottom: 2px;
}
.site-header[data-header-style='3'] .main-nav > ul > li > a {
    color: #656565;
}
.site-header[data-header-style='3']
    .main-nav
    > ul
    > li.menu-item-has-children
    > a:after {
    background-color: rgba(39, 39, 39, 0.4);
}
.site-header[data-header-style='3'] .main-nav > ul {
    margin-bottom: -6px;
}
.site-header[data-header-style='3'] .main-nav ul ul.sub-menu {
    top: calc(100% + 11px);
}
.site-header[data-header-style='3'] .main-nav > ul {
    margin-top: 5px;
}
.site-header[data-header-style='3'] .main-nav ul li:hover > ul {
    top: calc(100% - -3px);
}
.site-header[data-header-style='3'] .main-nav ul li:hover > .hg_mega_container {
    margin-top: 0px;
}

.site-header[data-header-style='3'] .zn-res-trigger:after {
    background: #575757;
    box-shadow: 0 8px 0 #575757, 0 16px 0 #575757;
}
.site-header[data-header-style='3'] .zn-res-trigger {
    margin-top: 9px;
    margin-bottom: 9px;
    margin-left: 0;
}

/* Site header style 4 */
.site-header[data-header-style='4'] {
    background: rgba(0, 0, 0, 0.5);
}

.site-header[data-header-style='4'] .logo-container,
.site-header[data-header-style='6'] .logo-container {
    height: 100%;
    min-width: 175px;
    min-height: 96px;
    background: rgba(0, 0, 0, 0.3);
    border-bottom: 2px solid rgba(0, 0, 0, 0.7);
    border-top-width: 3px;
    border-top-style: solid;
    border-top-color: #106ee9;
}

.site-header[data-header-style='4'] .logo-container.hasInfoCard:before,
.site-header[data-header-style='5'] .logo-container.hasInfoCard:before,
.site-header[data-header-style='6'] .logo-container.hasInfoCard:before {
    top: 40px;
}

.site-header[data-header-style='4'] .site-logo a:after,
.site-header[data-header-style='6'] .site-logo a:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -11px;
    margin-left: -3px;
    border: 5px solid transparent;
    border-top-width: 5px;
    border-top-color: #000;
    border-top-color: rgba(0, 0, 0, 0.7);
}
.site-header[data-header-style='4'] .site-logo {
    padding-left: 25px;
    padding-right: 25px;
    height: 60px;
}

.site-header[data-header-style='4'] .site-header-right-wrapper.col,
.site-header[data-header-style='5'] .site-header-right-wrapper.col,
.site-header[data-header-style='6'] .site-header-right-wrapper.col,
.site-header[data-header-style='7'] .site-header-right-wrapper.col {
    padding-right: 0;
}

.site-header[data-header-style='4'] .header-search {
    margin-left: 20px;
}
.site-header[data-header-style='4'] .main-menu-wrapper {
    margin-left: 10px;
}
.site-header[data-header-style='4'] .kl-cart-button + .pPanel {
    left: 0;
    right: auto;
}
.site-header[data-header-style='4'] .kl-cart-button + .pPanel .inner:after {
    left: 9px;
    right: auto;
}

.site-header[data-header-style='4'] .kl-cta-ribbon {
    margin-top: 13px;
}

/* Site header style 5 */
.site-header[data-header-style='5'] {
    background: rgba(0, 0, 0, 0.5);
    border-top-color: #106ee9;
    border-bottom: 2px solid rgba(0, 0, 0, 0.7);
    border-top: 3px solid #106ee9;
}
.site-header[data-header-style='5'] .logo-container {
    height: 100%;
    min-width: 175px;
    min-height: 94px;
}

/* Site header style 7 */
.site-header[data-header-style='7'] {
    background-color: #fff;
}
.site-header[data-header-style='7'] .logo-container {
    height: 100%;
    min-width: 140px;
}

.site-header[data-header-style='7'] .main-nav > ul > li > a,
.site-header[data-header-style='7'] .topnav .topnav-item a,
.site-header[data-header-style='7'] .topnav .topnav-item span,
.site-header[data-header-style='7'] .topnav > .topnav-item i,
.site-header[data-header-style='7'] .kl-cart-button .fas,
.site-header[data-header-style='7'] .social-icons.topnav.sc--clean > li > a {
    color: #252525;
}

.site-header[data-header-style='7'] .topnav a:hover.topnav-item,
.site-header[data-header-style='7'] .topnav .topnav-item:hover a,
.site-header[data-header-style='7'] .topnav a:hover,
.site-header[data-header-style='7'] .topnav .topnav-item:hover span,
.site-header[data-header-style='7'] .topnav .spanel-label:hover .support-info,
.site-header[data-header-style='7'] .topnav > .topnav-item:hover i,
.site-header[data-header-style='7']
    .social-icons.topnav.sc--clean
    > li
    > a:hover {
    color: #000000;
}

.site-header[data-header-style='7'] .main-nav > ul > li.active > a,
.site-header[data-header-style='7'] .main-nav > ul > li > a:hover,
.site-header[data-header-style='7'] .main-nav > ul > li:hover > a {
    color: #ffffff;
}

.site-header[data-header-style='7'] .zn-res-trigger::after {
    background: #252525;
    box-shadow: 0 8px 0 #252525, 0 16px 0 #252525;
}
.site-header[data-header-style='7'] .kl-cta-ribbon {
    margin-top: 12px;
}

.site-header[data-header-style='7'] .logo-container {
    min-height: 94px;
}

/* Site header style 8 */
.site-header[data-header-style='8'] .siteheader-container {
    margin-top: 17px;
}
.site-header[data-header-style='8'] .logo-container {
    height: 100%;
    min-width: 140px;
}
.site-header[data-header-style='8'] .site-logo-img {
    max-height: 75px;
}

/* Site header style 9 */
.site-header[data-header-style='9'] {
    background-color: #fff;
}
.site-header[data-header-style='9'] .logo-container {
    height: 100%;
    min-width: 140px;
}

.site-header[data-header-style='9'] .kl-header-toptext,
.site-header[data-header-style='9'] .kl-header-toptext a,
.site-header[data-header-style='9'] .main-nav > ul > li > a,
.site-header[data-header-style='9'] .topnav .topnav-item a,
.site-header[data-header-style='9'] .topnav .topnav-item span,
.site-header[data-header-style='9'] .topnav > .topnav-item i,
.site-header[data-header-style='9'] .kl-cart-button .fas,
.site-header[data-header-style='9'] .social-icons.topnav.sc--clean > li > a {
    color: #252525;
}

.site-header[data-header-style='9'] .topnav a:hover.topnav-item,
.site-header[data-header-style='9'] .topnav .topnav-item:hover a,
.site-header[data-header-style='9'] .topnav a:hover,
.site-header[data-header-style='9'] .topnav .topnav-item:hover span,
.site-header[data-header-style='9'] .topnav .spanel-label:hover .support-info,
.site-header[data-header-style='9'] .topnav > .topnav-item:hover i,
.site-header[data-header-style='9']
    .social-icons.topnav.sc--clean
    > li
    > a:hover {
    color: #000000;
}

.site-header[data-header-style='9'] .main-nav > ul > li.active > a,
.site-header[data-header-style='9'] .main-nav > ul > li > a:hover,
.site-header[data-header-style='9'] .main-nav > ul > li:hover > a {
    color: #ffffff;
}

.site-header[data-header-style='9'] .logo-container.hasInfoCard:before {
    content: url(../images/hoverme-dark.png);
}
.site-header[data-header-style='9'] .mainnav--cart {
    margin-left: 15px;
}
.site-header[data-header-style='9'] .header-search {
    margin-left: 20px;
}

.site-header[data-header-style='9'] .zn-res-trigger::after {
    background: #252525;
    box-shadow: 0 8px 0 #252525, 0 16px 0 #252525;
}
.site-header[data-header-style='9'] .logo-container.hasInfoCard:before {
    top: 0;
}

/* Site header style 10 */
.site-header[data-header-style='10'] {
    background: rgba(255, 255, 255, 0.9);
    border-bottom: 3px solid #106ee9;
    box-shadow: none;
    height: auto;
}

.site-header[data-header-style='10'] .site-header-main {
    height: 80px;
}

.site-header[data-header-style='10']
    .site-header-bottom-wrapper
    .siteheader-container {
    padding-left: 0;
}
.site-header[data-header-style='10'] .separator {
    background: #000;
    height: 1px;
    opacity: 0.1;
    display: inline-block;
    width: 100%;
}

.site-header[data-header-style='10'] .logo-container.hasInfoCard:before {
    display: none;
}

.site-header[data-header-style='10'] .main-nav > ul > li.active > a,
.site-header[data-header-style='10'] .main-nav > ul > li > a:hover,
.site-header[data-header-style='10'] .main-nav > ul > li:hover > a {
    color: #ffffff;
}
.site-header[data-header-style='10'] .main-nav {
    text-align: center;
}

.site-header[data-header-style='10'] .quote-ribbon {
    position: absolute;
    top: 5px;
    right: 0;
}

.site-header[data-header-style='10'] .main-menu {
    text-align: left;
}
.site-header[data-header-style='10'] .main-nav ul ul.sub-menu {
    top: calc(100% + 11px);
}
.site-header[data-header-style='10'] .main-nav > ul {
    display: inline-block;
    margin-top: 5px;
    margin-bottom: -6px;
}
.site-header[data-header-style='10'] .kl-cta-ribbon {
    margin-top: -10px;
}
.site-header[data-header-style='10'] .main-nav .hg_mega_container {
    top: calc(100% + 8px);
}
.site-header[data-header-style='10'] .main-nav ul li:hover > ul {
    top: calc(100% - -3px);
}
.site-header[data-header-style='10']
    .main-nav
    ul
    li:hover
    > .hg_mega_container {
    margin-top: 0px;
}
.site-header[data-header-style='10'] .main-nav > ul > li > a {
    color: #252525;
}
.site-header[data-header-style='10'] .logo-container .logo-infocard {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 2;
}

.site-header[data-header-style='10'] .zn-res-trigger::after {
    background: #252525;
    box-shadow: 0 8px 0 #252525, 0 16px 0 #252525;
}

/* Site header style 11 */
.site-header[data-header-style='11'] {
    background: rgba(0, 0, 0, 0.65);
}
.site-header[data-header-style='11'] .logo-container {
    padding: 0 20px;
}

/* Site header style 12 */
.site-header[data-header-style='12'] .site-header-main {
    height: 80px;
}
.site-header[data-header-style='12'] .main-nav {
    text-align: center;
}
.site-header[data-header-style='12'] .logo-container.hasInfoCard:before {
    display: none;
}
.site-header[data-header-style='12'] .logo-container .logo-infocard {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 2;
}
.site-header[data-header-style='12'] .site-header-bottom-wrapper {
    padding: 10px 0;
}

/*** Sticky header ***/
body.sticky-header #header {
    position: fixed !important;
}

/* Header style 1 */
body.sticky-header
    .site-header[data-header-style='1']
    .site-header-top-wrapper {
    height: 57px;
}
body.sticky-header .site-header[data-header-style='1'] .site-header-top-wrapper,
body.sticky-header
    .site-header[data-header-style='1']
    .site-header-main-wrapper {
    opacity: 1;
    -webkit-transition: height 0.2s ease-out, opacity 0.2s ease-out;
    transition: height 0.2s ease-out, opacity 0.2s ease-out;
}

body.sticky-header
    .site-header[data-header-style='1'].header--is-sticked
    .site-header-top-wrapper {
    opacity: 0;
    height: 0 !important;
    overflow: hidden;background: #2f2f2f;
}
body.sticky-header
    .site-header[data-header-style='1'].header--is-sticked
    .site-header-main-wrapper {
    height: 60px;background: #2f2f2f;
	
}
body.sticky-header
    .site-header[data-header-style='1'].header--is-sticked
    .kl-header-bg {
    top: -20px;background: #2f2f2f;
	
}

/* Header style 2 */
body.sticky-header
    .site-header[data-header-style='2']
    .site-header-top-wrapper {
    height: 80px;
}
body.sticky-header
    .site-header[data-header-style='2']
    .site-header-main-wrapper {
    height: 50px;
}
body.sticky-header .site-header[data-header-style='2'] .site-header-top-wrapper,
body.sticky-header
    .site-header[data-header-style='2']
    .site-header-main-wrapper {
    opacity: 1;
    -webkit-transition: height 0.2s ease-out, opacity 0.2s ease-out;
    transition: height 0.2s ease-out, opacity 0.2s ease-out;
}

body.sticky-header
    .site-header[data-header-style='2'].header--is-sticked
    .site-header-top-wrapper {
    height: 0 !important;
    overflow: hidden;
}

body.sticky-header
    .site-header[data-header-style='2'].header--is-sticked
    .kl-main-header
    .kl-cta-ribbon {
    margin-top: 10px;
}

/* Header style 3 */
body.sticky-header
    .site-header[data-header-style='3']
    .site-header-top-wrapper {
    height: 46px;
}
body.sticky-header
    .site-header[data-header-style='3']
    .site-header-main-wrapper {
    height: 81px;
}

body.sticky-header .site-header[data-header-style='3'] .site-header-top-wrapper,
body.sticky-header
    .site-header[data-header-style='3']
    .site-header-main-wrapper,
body.sticky-header
    .site-header[data-header-style='3']
    .site-header-bottom-wrapper {
    opacity: 1;
    -webkit-transition: height 0.2s ease-out, opacity 0.2s ease-out;
    transition: height 0.2s ease-out, opacity 0.2s ease-out;
}

body.sticky-header
    .site-header[data-header-style='3'].header--is-sticked
    .site-header-top-wrapper,
body.sticky-header
    .site-header[data-header-style='3'].header--is-sticked
    .site-header-main-wrapper {
    height: 0 !important;
    overflow: hidden;
}

body.sticky-header
    .site-header[data-header-style='3'].header--is-sticked
    .kl-main-header
    .kl-cta-ribbon {
    margin-top: 5px;
}

/* Header style 4 */
body.sticky-header .site-header[data-header-style='4'] .site-header-top {
    height: 46px;
}

body.sticky-header .site-header[data-header-style='4'] .site-header-top,
body.sticky-header .site-header[data-header-style='4'] .site-header-bottom {
    opacity: 1;
    -webkit-transition: height 0.2s ease-out, opacity 0.2s ease-out;
    transition: height 0.2s ease-out, opacity 0.2s ease-out;
}

body.sticky-header
    .site-header[data-header-style='4'].header--is-sticked
    .site-header-top {
    height: 0 !important;
    overflow: hidden;
}
body.sticky-header
    .site-header[data-header-style='4'].header--is-sticked
    .kl-cta-ribbon {
    margin-top: 10px;
}
body.sticky-header
    .site-header[data-header-style='4'].header--is-sticked
    .logo-container {
    min-height: 60px;
}

/* Header style 5 */
body.sticky-header .site-header[data-header-style='5'] .site-header-top {
    height: 46px;
}

body.sticky-header .site-header[data-header-style='5'] .site-header-top,
body.sticky-header .site-header[data-header-style='5'] .site-header-bottom {
    opacity: 1;
    -webkit-transition: height 0.2s ease-out, opacity 0.2s ease-out;
    transition: height 0.2s ease-out, opacity 0.2s ease-out;
}

body.sticky-header
    .site-header[data-header-style='5'].header--is-sticked
    .site-header-top {
    height: 0 !important;
    overflow: hidden;
}
body.sticky-header
    .site-header[data-header-style='5'].header--is-sticked
    .kl-cta-ribbon {
    margin-top: 2px;
}

body.sticky-header
    .site-header[data-header-style='5'].header--is-sticked
    .logo-container {
    min-height: 60px;
}

/* Header style 6 */
body.sticky-header .site-header[data-header-style='6'] .site-header-top {
    height: 46px;
}

body.sticky-header .site-header[data-header-style='6'] .site-header-top,
body.sticky-header .site-header[data-header-style='6'] .site-header-bottom {
    opacity: 1;
    -webkit-transition: height 0.2s ease-out, opacity 0.2s ease-out;
    transition: height 0.2s ease-out, opacity 0.2s ease-out;
}

body.sticky-header
    .site-header[data-header-style='6'].header--is-sticked
    .site-header-top {
    height: 0 !important;
    overflow: hidden;
}
body.sticky-header
    .site-header[data-header-style='6'].header--is-sticked
    .kl-cta-ribbon {
    margin-top: 10px;
}
body.sticky-header
    .site-header[data-header-style='6'].header--is-sticked
    .logo-container {
    min-height: 60px;
}

/* Header style 7 */
body.sticky-header .site-header[data-header-style='7'].header--is-sticked {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.15);
}
body.sticky-header .site-header[data-header-style='7'] .site-header-top {
    height: 46px;
}

body.sticky-header .site-header[data-header-style='7'] .site-header-top,
body.sticky-header .site-header[data-header-style='7'] .site-header-bottom {
    opacity: 1;
    -webkit-transition: height 0.2s ease-out, opacity 0.2s ease-out;
    transition: height 0.2s ease-out, opacity 0.2s ease-out;
}

body.sticky-header
    .site-header[data-header-style='7'].header--is-sticked
    .site-header-top {
    height: 0 !important;
    overflow: hidden;
}
body.sticky-header
    .site-header[data-header-style='7'].header--is-sticked
    .logo-container {
    min-height: 60px;
}

/* Header style 8 */
body.sticky-header .site-header[data-header-style='8'] .kl-header-bg {
    top: -30px;
}

/* Header style 9 */
body.sticky-header .site-header[data-header-style='9'].header--is-sticked {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.15);
}

body.sticky-header
    .site-header[data-header-style='9']
    .site-header-top-wrapper {
    height: 47px;
}

body.sticky-header .site-header[data-header-style='9'] .site-header-top-wrapper,
body.sticky-header
    .site-header[data-header-style='9']
    .site-header-main-wrapper {
    opacity: 1;
    -webkit-transition: height 0.2s ease-out, opacity 0.2s ease-out;
    transition: height 0.2s ease-out, opacity 0.2s ease-out;
}

body.sticky-header
    .site-header[data-header-style='9'].header--is-sticked
    .site-header-top-wrapper {
    height: 0 !important;
    overflow: hidden;
}

/* Header style 10 */
body.sticky-header
    .site-header[data-header-style='10']
    .site-header-main-wrapper {
    height: 107px;
}

body.sticky-header
    .site-header[data-header-style='10']
    .site-header-main-wrapper,
body.sticky-header
    .site-header[data-header-style='9']
    .site-header-bottom-wrapper {
    opacity: 1;
    -webkit-transition: height 0.2s ease-out, opacity 0.2s ease-out;
    transition: height 0.2s ease-out, opacity 0.2s ease-out;
}

body.sticky-header
    .site-header[data-header-style='10'].header--is-sticked
    .site-header-main-wrapper {
    opacity: 0;
    height: 0 !important;
    overflow: hidden;
}

body.sticky-header
    .site-header[data-header-style='10'].header--is-sticked
    .kl-cta-ribbon {
    margin-top: -3px;
}

/* Header style 11 */
body.sticky-header
    .site-header[data-header-style='11']
    .site-header-top-wrapper {
    height: 47px;
}

body.sticky-header
    .site-header[data-header-style='11']
    .site-header-top-wrapper,
body.sticky-header
    .site-header[data-header-style='11']
    .site-header-main-wrapper {
    opacity: 1;
    -webkit-transition: height 0.2s ease-out, opacity 0.2s ease-out;
    transition: height 0.2s ease-out, opacity 0.2s ease-out;
}

body.sticky-header
    .site-header[data-header-style='11'].header--is-sticked
    .site-header-top-wrapper {
    opacity: 0;
    height: 0 !important;
    overflow: hidden;
}

/* Header style 12 */
body.sticky-header
    .site-header[data-header-style='12']
    .site-header-main-wrapper {
    height: 81px;
}

body.sticky-header
    .site-header[data-header-style='12']
    .site-header-main-wrapper,
body.sticky-header
    .site-header[data-header-style='12']
    .site-header-bottom-wrapper {
    opacity: 1;
    -webkit-transition: height 0.2s ease-out, opacity 0.2s ease-out;
    transition: height 0.2s ease-out, opacity 0.2s ease-out;
}

body.sticky-header
    .site-header[data-header-style='12'].header--is-sticked
    .site-header-main-wrapper {
    opacity: 0;
    height: 0 !important;
    overflow: hidden;
}

/* Social icons */
.social-icons {
    margin-left: 10px;
    margin-bottom: 0;
    z-index: 1;
}
.social-icons li {
    display: inline-block;
    margin: 5px;
    margin-left: 10px;
    margin-right: 0;
    vertical-align: top;
}
.social-icons li a {
    display: inline-block;
    color: #fff;
    width: 35px;
    height: 35px;
    position: relative;
    border-radius: 3px;
    text-align: center;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    font-size: 0.9rem;
    font-weight: normal;
    opacity: 1;
}
.social-icons li a:before {
    line-height: 30px;
}
.social-icons li a:hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
}
.social-icons.sc--normal li a {
    background-color: #111;
}
.social-icons.sc--normal li a:hover {
    background-color: #106ee9;
}
/* Colored social icons */
.social-icons.sc--colored li a:hover {
    background-color: #121212;
}
/* Colored social icons */
.social-icons.sc--colored_hov li a:not(:hover) {
    background-color: #121212;
}
/* Social icons - clean style */
.social-icons.sc--clean li {
    margin-left: 0;
    margin-right: 0;
}
.social-icons.sc--clean li a {
    background: none;
    box-shadow: none;
    font-size: 1rem;
    line-height: 2.2;
}
.social-icons.sc--clean li a:hover {
    background: none;
    box-shadow: none;
}

/* Default social icons in header */
.site-header .social-icons.topnav {
    z-index: 1;
}
.site-header .social-icons.topnav li {
    margin: 0;
    margin-left: 10px;
}
.site-header .social-icons.topnav.sc--clean li {
    margin-left: 0;
}
.site-header .social-icons.topnav li:first-child {
    margin-left: 0;
}
.scheader-icon-nocolor {
    background-color: #121212;
}

/* Social color bg */
.social-icons.sc--colored .fa-facebook-f {
    background-color: #3b579d;
}
.social-icons.sc--colored .fa-twitter {
    background-color: #5ea9dd;
}
.social-icons.sc--colored .fa-google-plus-g {
    background-color: #df4b38;
}
.social-icons.sc--colored .fa-linkedin {
    background-color: #007ab5;
}

/* kl-slideshow div styles */
.kl-slideshow-inner {
    position: relative;
    z-index: 1; /* z-index: 100; */
}
.kl-slideshow-inner .container {
    z-index: 1;
}
.kl-slideshow .bgback {
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}
.kl-slideshow {
    margin-bottom: 0;
}
.kl-ios-selectors-block.bullets2 .item.selected::before,
.kl-ios-selectors-block.bullets2 .item:hover:before {
    background-color: #106ee9;
}
/* used in several sliders */
.zn-shadow-lifted {
    box-shadow: 0 40px 20px -25px rgba(0, 0, 0, 0.25);
}

/* For elements that are positioned right under the header, a safe padding is added */
.kl-slideshow .kl-slideshow-safepadding {
    padding-top: 35px;
    padding-bottom: 35px;
}

/*#sparkles,
.th-sparkles {width: 100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 1; overflow: hidden; display:none;}*/
#sparkles,
.th-sparkles {
    display: none;
}
.th-sparkles-visible .th-sparkles {
    display: block;
}
.th-sparkles > span {
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    background: -moz-radial-gradient(
        center,
        ellipse cover,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 60%
    );
    background: -webkit-gradient(
        radial,
        center center,
        0px,
        center center,
        100%,
        color-stop(0%, rgba(255, 255, 255, 1)),
        color-stop(60%, rgba(255, 255, 255, 0))
    );
    background: -webkit-radial-gradient(
        center,
        ellipse cover,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 60%
    );
    background: -o-radial-gradient(
        center,
        ellipse cover,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 60%
    );
    background: -ms-radial-gradient(
        center,
        ellipse cover,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 60%
    );
    background: radial-gradient(
        ellipse at center,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 60%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
}
.th-sparkles .shiny-spark1 {
    width: 20px;
    height: 20px;
    opacity: 0.4;
}
.th-sparkles .shiny-spark2 {
    width: 10px;
    height: 10px;
    opacity: 0.7;
}
.th-sparkles .shiny-spark3 {
    width: 30px;
    height: 30px;
    opacity: 0.1;
}
.th-sparkles .shiny-spark4 {
    width: 3px;
    height: 3px;
    opacity: 0.9;
}
.th-sparkles .shiny-spark5 {
    width: 15px;
    height: 15px;
    opacity: 0.3;
}
.th-sparkles .shiny-spark6 {
    width: 25px;
    height: 25px;
    opacity: 0.5;
}

/*** Page Subheader's defaults  */
.page-subheader {
    min-height: 300px;
    background-color: #066fd9;
}
.page-subheader[class*='maskcontainer--'] {
    min-height: 330px;
    padding-bottom: 30px;
}
.page-subheader .container {
    padding: 0 15px;
    z-index: 1;
    position: relative;
}
.page-subheader > .shadowUP:after {
    content: '';
    position: absolute;
    bottom: -18px;
    left: 50%;
    border: 6px solid transparent;
    border-top-color: #fff;
    margin-left: -6px;
}
.page-subheader .bgback {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-size: cover;
    background-position: center;
}
#page_header.no-bg {
    background: none;
}

.page-subheader .ph-content-wrap {
    width: 100%;
    height: 400px;
    min-height: 400px;
}

/* Safe padding for Subheader */
.page-subheader .ph-content-wrap {
    padding-top: 100px;
}

/* Page subheader styles */
.page-subheader.gradient,
.kl-slideshow.gradient {
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, transparent),
        color-stop(100%, rgba(0, 0, 0, 0.5))
    );
    background: -webkit-linear-gradient(
        top,
        transparent 0%,
        rgba(0, 0, 0, 0.5) 100%
    );
    background: -webkit-linear-gradient(
        top,
        transparent 0%,
        rgba(0, 0, 0, 0.5) 100%
    );
    background: linear-gradient(
        to bottom,
        transparent 0%,
        rgba(0, 0, 0, 0.5) 100%
    );
    background-color: #345370;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 );
}
.page-subheader,
.kl-slideshow {
    position: relative;
    top: 0;
    z-index: 0;
}
.page-subheader.nobg,
.kl-slideshow.nobg {
    background: none;
}
.page-subheader.hasMargin,
.kl-slideshow.hasMargin {
    margin-bottom: 40px;
}

.page-subheader canvas#target {
    width: 100%;
}

/* HG Section */
.hg_section {
    padding-top: 35px;
    padding-bottom: 35px;
}
.hg_section--relative {
    position: relative;
}
.hg_section--masked {
    padding-bottom: 55px;
}
.hg_section__source {
    position: relative;
}
.kl-bg-source {
    position: relative;
}

/* PORTFOLIO */
.hg_other_images > div {
    margin-top: 15px;
    margin-bottom: 15px;
}
.hg_other_images {
    margin-top: 20px;
}

/* Background source for elements */
.kl-bg-source {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.kl-bg-source:not(:empty),
.kl-bg-source__overlay,
.kl-bg-source__bgimage {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.kl-bg-source__bgimage {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
.kl-bg-source__overlay {
    pointer-events: none;
}
.kl-bg-source__video {
}
.kl-bg-source__iframe {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
}
.kl-bg-source__iframe iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
.kl-bg-source__overlay-gloss {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: -43%;
    background-color: #fff;
    width: 100%;
    -webkit-transform: skewX(-55deg);
    -ms-transform: skewX(-55deg);
    transform: skewX(-55deg);
    height: 100%;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    opacity: 0.08;
}

.kl-bgSource-imageParallax {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    will-change: transform;
}
.kl-bgSource-imageParallax .kl-bg-source__bgimage {
    top: 0;
    left: 0;
    position: relative;
    min-width: 100%;
    min-height: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-attachment: initial;
}
.kl-bgSource-imageParallax.is-fixed {
    left: -100vw;
    position: fixed;
}
.kl-bgSource-imageParallax.is-fixed.is-visible {
    left: 0;
}
.kl-bgSource-imageParallax.is-fixed .kl-bg-source__bgimage {
    height: 100vh;
}

/* Source Mask */
.ls-source__mask {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20vh;
    background: #fff;
    width: 100%;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: skewY(-2deg);
    -ms-transform: skewY(-2deg);
    transform: skewY(-2deg);
    -webkit-backface-visibility: hidden;
}
.ls-source__mask.ls-source__mask-front {
    background: -moz-linear-gradient(
        top,
        rgba(245, 245, 245, 0.6) 0%,
        rgba(251, 251, 251, 1) 60%
    );
    background: -webkit-linear-gradient(
        top,
        rgba(245, 245, 245, 0.6) 0%,
        rgba(251, 251, 251, 1) 60%
    );
    background: -o-linear-gradient(
        top,
        rgba(245, 245, 245, 0.6) 0%,
        rgba(251, 251, 251, 1) 60%
    );
    background: -ms-linear-gradient(
        top,
        rgba(245, 245, 245, 0.6) 0%,
        rgba(251, 251, 251, 1) 60%
    );
    background: linear-gradient(
        to bottom,
        rgba(245, 245, 245, 0.6) 0%,
        rgba(251, 251, 251, 1) 60%
    );
}
.ls-source__mask.ls-source__mask-back {
    background: rgba(255, 255, 255, 0.4);
    -webkit-transform: skewY(-3.1deg);
    -ms-transform: skewY(-3.1deg);
    transform: skewY(-3.1deg);
    -webkit-backface-visibility: hidden;
    bottom: 40px;
}

/*** Special Bottom masks */
.kl-bottommask {
    z-index: 1;
}
.fake-loading ~ .kl-bottommask {
    z-index: 2;
}

/* Shadow up mask Simple */
.maskcontainer--shadow_simple {
    border-bottom: 0 !important;
}
.kl-bottommask--shadow_simple {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: url(../images/shadow-up.png) no-repeat center bottom;
    z-index: 2;
    border-bottom: 0;
    box-sizing: content-box;
    background: -webkit-gradient(
        50% 100%,
        ellipse,
        rgba(0, 0, 0, 0.5),
        transparent 70%
    );
    background: -webkit-radial-gradient(
        50% 100%,
        ellipse,
        rgba(0, 0, 0, 0.3),
        transparent 70%
    );
    background: radial-gradient(
        ellipse at 50% 100%,
        rgba(0, 0, 0, 0.3) 0,
        transparent 70%
    );
    background: -webkit-radial-gradient(
        50% 90%,
        70% 100%,
        rgba(0, 0, 0, 0.25) 0%,
        transparent 70%
    );
}

/* Shadow Down mask Simple */
.maskcontainer--shadow_simple_down {
    border-bottom: 0 !important;
}
.kl-bottommask--shadow_simple_down {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    background: url(../images/shadow-down.png) no-repeat center top;
    opacity: 0.6;
    background: -webkit-gradient(
        50% 0%,
        ellipse,
        rgba(0, 0, 0, 0.5),
        transparent 70%
    );
    background: -webkit-radial-gradient(
        50% 0%,
        ellipse,
        rgba(0, 0, 0, 0.3),
        transparent 70%
    );
    background: radial-gradient(
        ellipse at 50% 0%,
        rgba(0, 0, 0, 0.3) 0,
        transparent 70%
    );
    background: -webkit-radial-gradient(
        50% 0%,
        70% 100%,
        rgba(0, 0, 0, 0.25) 0%,
        transparent 70%
    );
}

/* Shadow up mask - width border and arrow */
.maskcontainer--shadow {
    border-bottom: 0 !important;
}
.kl-bottommask--shadow {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: url(../images/shadow-up.png) no-repeat center bottom;
    z-index: 1;
    border-bottom: 6px solid #ffffff;
    box-sizing: content-box;
    background: -webkit-gradient(
        50% 100%,
        ellipse,
        rgba(0, 0, 0, 0.5),
        transparent 70%
    );
    background: -webkit-radial-gradient(
        50% 100%,
        ellipse,
        rgba(0, 0, 0, 0.3),
        transparent 70%
    );
    background: radial-gradient(
        ellipse at 50% 100%,
        rgba(0, 0, 0, 0.3) 0,
        transparent 70%
    );
    background: -webkit-radial-gradient(
        50% 90%,
        70% 100%,
        rgba(0, 0, 0, 0.25) 0%,
        transparent 70%
    );
}
.kl-bottommask--shadow:after {
    content: '';
    position: absolute;
    bottom: -18px;
    left: 50%;
    border: 6px solid transparent;
    border-top-color: #fff;
    margin-left: -6px;
}

/* Shadow up & down mask */
.maskcontainer--shadow_ud {
    border-bottom: 0 !important;
}
.kl-bottommask--shadow_ud {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 12px;
    background: url(../images/shadow-up.png) no-repeat center bottom;
    z-index: 1;
    border-bottom: 6px solid #ffffff;
    background: -webkit-gradient(
        50% 100%,
        ellipse,
        rgba(0, 0, 0, 0.5),
        transparent 70%
    );
    background: -webkit-radial-gradient(
        50% 100%,
        ellipse,
        rgba(0, 0, 0, 0.3),
        transparent 70%
    );
    background: radial-gradient(
        ellipse at 50% 100%,
        rgba(0, 0, 0, 0.3) 0,
        transparent 70%
    );
    background: -webkit-radial-gradient(
        50% 90%,
        70% 100%,
        rgba(0, 0, 0, 0.25) 0%,
        transparent 70%
    );
}
.kl-bottommask--shadow_ud:after {
    content: '';
    position: absolute;
    bottom: -17px;
    left: 50%;
    border: 6px solid transparent;
    border-top-color: #fff;
    margin-left: -6px;
}
.kl-bottommask--shadow_ud:before {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 0;
    width: 100%;
    height: 10px;
    background: url(../images/shadow-down.png) no-repeat center top;
    opacity: 0.6;
    background: -webkit-gradient(
        50% 0%,
        ellipse,
        rgba(0, 0, 0, 0.5),
        transparent 70%
    );
    background: -webkit-radial-gradient(
        50% 0%,
        ellipse,
        rgba(0, 0, 0, 0.3),
        transparent 70%
    );
    background: radial-gradient(
        ellipse at 50% 0%,
        rgba(0, 0, 0, 0.3) 0,
        transparent 70%
    );
    background: -webkit-radial-gradient(
        50% 0%,
        70% 100%,
        rgba(0, 0, 0, 0.25) 0%,
        transparent 70%
    );
}

/* Mask 1 */
.maskcontainer--mask1 {
    border-bottom: 0 !important;
    padding-bottom: 30px;
}
.kl-bottommask--mask1 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 27px;
    z-index: 99;
    background: url(../images/bottom_mask.png) no-repeat center top;
}

/* Mask 2 */
.maskcontainer--mask2 {
    border-bottom: 0 !important;
    padding-bottom: 40px;
}
.kl-bottommask--mask2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    height: 33px;
    background: url(../images/bottom_mask2.png) no-repeat center top;
}

/* Mask 3 */
.maskcontainer--mask3 {
    border-bottom: 0 !important;
}
.kl-bottommask--mask3 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 57px;
}
.kl-bottommask--mask3 .svgmask {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-bottom: -14px;
}
.kl-bottommask--mask3 .fas {
    position: absolute;
    bottom: 10px;
    left: 50%;
    display: block;
    width: 30px;
    text-align: center;
    font-size: 30px;
    opacity: 0.2;
    height: 15px;
    top: auto;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-left: 1px;
}

/* Mask 4 */
.maskcontainer--mask4 {
    border-bottom: 0 !important;
    padding-bottom: 40px;
}
.kl-bottommask--mask4 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 27px;
}
.kl-bottommask--mask4 .svgmask {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

/* Mask 3 and 4, left or right aligned */
@media (min-width: 1200px) {
    .kl-bottommask--mask3 .svgmask-right,
    .kl-bottommask--mask3 .svgmask-right + .fas,
    .kl-bottommask--mask4 .svgmask-right {
        -webkit-transform: translateX(calc(-50% + 510px));
        -ms-transform: translateX(calc(-50% + 510px));
        transform: translateX(calc(-50% + 510px));
    }
    .kl-bottommask--mask3 .svgmask-left,
    .kl-bottommask--mask3 .svgmask-left + .fas,
    .kl-bottommask--mask4 .svgmask-left {
        -webkit-transform: translateX(calc(-50% - 510px));
        -ms-transform: translateX(calc(-50% - 510px));
        transform: translateX(calc(-50% - 510px));
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .kl-bottommask--mask3 .svgmask-right,
    .kl-bottommask--mask3 .svgmask-right + .fas,
    .kl-bottommask--mask4 .svgmask-right {
        -webkit-transform: translateX(calc(-50% + 410px));
        -ms-transform: translateX(calc(-50% + 410px));
        transform: translateX(calc(-50% + 410px));
    }
    .kl-bottommask--mask3 .svgmask-left,
    .kl-bottommask--mask3 .svgmask-left + .fas,
    .kl-bottommask--mask4 .svgmask-left {
        -webkit-transform: translateX(calc(-50% - 410px));
        -ms-transform: translateX(calc(-50% - 410px));
        transform: translateX(calc(-50% - 410px));
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .kl-bottommask--mask3 .svgmask-right,
    .kl-bottommask--mask3 .svgmask-right + .fas,
    .kl-bottommask--mask4 .svgmask-right {
        -webkit-transform: translateX(calc(-50% + 300px));
        -ms-transform: translateX(calc(-50% + 300px));
        transform: translateX(calc(-50% + 300px));
    }
    .kl-bottommask--mask3 .svgmask-left,
    .kl-bottommask--mask3 .svgmask-left + .fas,
    .kl-bottommask--mask4 .svgmask-left {
        -webkit-transform: translateX(calc(-50% - 300px));
        -ms-transform: translateX(calc(-50% - 300px));
        transform: translateX(calc(-50% - 300px));
    }
}

@media (max-width: 767px) {
    .kl-bottommask--mask3 .svgmask-right,
    .kl-bottommask--mask3 .svgmask-right + .fas,
    .kl-bottommask--mask4 .svgmask-right,
    .kl-bottommask--mask3 .svgmask-left,
    .kl-bottommask--mask3 .svgmask-left + .fas,
    .kl-bottommask--mask4 .svgmask-left {
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

/* Mask 5 */
.maskcontainer--mask5 {
    border-bottom: 0 !important;
    padding-bottom: 75px;
}
.kl-bottommask--mask5 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 64px;
}
.kl-bottommask--mask5 .svgmask {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
.kl-bottommask--mask5 .bmask-customfill {
    -webkit-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    transform: translateY(-1px);
}
/* Hack for safari only to hide shadow */
.is-safari .svgmask .bmask-bgfill,
.is-safari .kl-bottommask--mask3 .bmask-bgfill,
.is-safari .kl-bottommask--mask4 .bmask-bgfill {
    -webkit-filter: none;
    filter: none;
}

.is-safari .kl-bottommask--mask3 .bmask-bgfill,
.is-safari .kl-bottommask--mask4 .bmask-bgfill,
.is-safari .svgmask .bmask-bgfill {
    -webkit-svg-shadow: 0px 0px 7px rgba(0, 0, 0, 0.47);
    transform: translateY(4px);
}

/* Mask 6 */
.maskcontainer--mask6 {
    border-bottom: 0 !important;
    padding-bottom: 70px;
}
.kl-bottommask--mask6 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 57px;
}
.kl-bottommask--mask6 .svgmask {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

/* Mask 7 */
.kl-bottommask--mask7 {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
    left: 0;
}
.kl-bottommask--mask7.mask-over {
    z-index: 2;
}
.kl-bottommask--mask7.screffect {
    -webkit-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    transform-origin: 0 100%;
}

/* Static content with mask, do things a bit differently */
.static-content__slideshow[class*='maskcontainer--'] {
    padding-bottom: 0;
}
.static-content__slideshow[class*='maskcontainer--'] .kl-slideshow-inner {
    padding-bottom: 60px;
}
/* Same goes for portfolio slider */
.portfolio-slider__sideshow[class*='maskcontainer--'] {
    padding-bottom: 0;
}
/* Same goes for fancy slider */
/*.fancyslider__slideshow[class*="maskcontainer--"] .kl-slideshow-inner {padding-bottom: 60px}*/
/* Same goes for revolution slider */
.kl-revolution-slider[class*='maskcontainer--'] {
    padding-bottom: 0;
}

/* 3D Cute slider def. padding */
.cute3dslider .kl-slideshow-inner {
    padding-top: 170px;
    padding-bottom: 50px;
}
/*.br-circle-timer .br-timer-dot {-webkit-transform: translate3d(-50%, -50%, 0); -ms-transform: translate3d(-50%, -50%, 0); transform: translate3d(-50%, -50%, 0);}*/

/* Mod Title */
.m_title {
    margin-top: 0;
    color: #106ee9; /*text-transform: uppercase;*/
} /* uppercase disabled, manually uppercase text */
h3.m_title {
    font-size: 20px;
    line-height: 1.4;
}
.smallm_title {
    color: #106ee9;
    text-shadow: 0 1px 0 #fff;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid #bcbcbc;
    margin: 0 0 20px;
}
.smallm_title span {
    padding-bottom: 9px;
    display: inline-block;
    border-bottom: 1px solid #bcbcbc;
    position: relative;
}
.smallm_title span:before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: -2px;
    border: 3px solid transparent;
    border-bottom-color: #bcbcbc;
}
.smallm_title.centered {
    text-align: center;
}
.smallm_title.bigger {
    font-size: 18px;
}
.circle_title {
    color: #106ee9;
    text-shadow: 0 1px 0 #fff;
    position: relative;
    margin-top: 0;
}
.circle_title:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    border-bottom: 1px dotted #bbb;
}
.mb_title {
    font-size: 20px;
    background: white;
    display: inline-block;
    padding: 0 10px;
    color: #595959;
    line-height: 1.5;
}
.mb_title img {
    margin-right: 5px;
    margin-top: -3px;
}

/* Breadcrumbs */
.breadcrumbs {
    margin: 0 0 10px;
    padding: 7px 15px 6px;
    list-style: none;
    background: rgba(0, 0, 0, 0.8);
    float: left;
    border-radius: 2px;
}
.breadcrumbs li {
    float: left;
    color: #eee;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    text-transform: uppercase;
}
.breadcrumbs li:first-child {
    padding-left: 0;
    background: none;
    margin-left: 0;
}
.breadcrumbs li a {
    color: #fff;
}
.breadcrumbs li:after,
.breadcrumbs2 li:after {
    border: 4px solid transparent;
    content: '';
    height: 0;
    width: 0;
    pointer-events: none;
    display: inline-block;
    border-left-color: #106ee9;
    margin: 0 5px 2px 10px;
}
.breadcrumbs li:last-child:after,
.breadcrumbs2 li:last-child:after {
    display: none;
}

.breadcrumbs2 {
    padding: 0;
    background-color: inherit;
    margin-bottom: 0;
    opacity: 0.4;
    transition: opacity 0.1s ease-out;
    list-style: none;
}
.breadcrumbs2 > li {
    display: inline-block;
    cursor: pointer;
}
.breadcrumbs2:hover {
    opacity: 1;
}
.breadcrumbs2.white a,
.breadcrumbs2.white span {
    color: #fff;
}

/* Social list  */
.social_icons ul,
ul.social_icons {
    list-style: none;
    margin: 0;
    padding: 0;
}
.social_icons li {
    display: inline-block;
    margin-left: 10px;
    line-height: 40px;
}
.social_icons li:first-child {
    margin-left: 0;
}
.social_icons li a {
    color: #cdcdcd;
    text-decoration: none;
    line-height: 1;
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
}
.social_icons li a:hover {
    color: #666;
}
.social_icons a {
    text-decoration: none;
}
.social_icons.socialstyles-extra li {
    margin: 0;
    float: left;
}
.social_icons.socialstyles-extra li a {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    padding: 15px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-left-width: 0;
}
.social_icons.socialstyles-extra li a:hover {
    color: rgba(255, 255, 255, 0.7);
}
.social_icons.socialstyles-extra li:first-child a {
    border-left-width: 1px;
}

/* Header titles */
.subheader-titles {
    text-align: right;
    margin-top: -7px;
}
.subheader-titles.left {
    text-align: left;
}
.subheader-maintitle {
    color: #fff;
    line-height: 1.3;
    margin: 0 0 5px;
}
.subheader-subtitle {
    color: #fff;
    display: inline-block;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.shadowUP {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background: url(../images/shadow-up.png) no-repeat center bottom;
    z-index: 2;
}
.shadowDown {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background: url(../images/shadow-down.png) no-repeat center bottom;
    z-index: 2;
}

.subheader-currentdate {
    color: #383838;
    font: 12px Arial, Helvetica, sans-serif;
    float: left;
    padding: 6px 10px;
    line-height: 1;
    background: rgba(255, 255, 255, 0.5);
    clear: left;
    border-radius: 2px;
}

/* Title Block Element */
.kl-title-block {
    padding-top: 0;
    padding-bottom: 35px;
}
.tbk__title {
    margin-top: 0;
    margin-bottom: 10px;
    font-family: 'Open Sans';
    font-size: 1.6rem;
    line-height: 1.4;
    font-weight: 400;
    font-style: normal;
}
.tbk__subtitle {
    margin-top: 0;
    font-family: 'Open Sans';
    font-size: 1.2rem;
    line-height: 1.6;
    font-weight: 400;
    font-style: normal;
}
.tbk-thinsubtitle {
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 100;
}

/* Symbol */
.tbk__symbol {
    margin-top: 0;
    margin-bottom: 10px;
    display: block;
}

/* Symbol - small line */
.tbk-symbol--line .tbk__symbol span {
    display: inline-block;
    width: 50px;
    height: 2px;
    background-color: #ccc;
}
.tbk-symbol--line.tbk-icon-pos--after-title .tbk__symbol {
    margin-bottom: 20px;
    margin-top: -5px;
    display: inline-block;
}

/* Symbol - border */
.tbk-symbol--border .tbk__symbol,
.tbk-symbol--border2 .tbk__symbol {
    margin-bottom: 20px;
}
.tbk-symbol--border .tbk__symbol span,
.tbk-symbol--border2 .tbk__symbol span {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #ccc;
}
.tbk-symbol--border.tbk-icon-pos--after-text .tbk__symbol {
    margin-top: 30px;
}
.tbk-symbol--border2.tbk-icon-pos--after-title .tbk__border-helper {
    border-bottom: 3px solid #d2d2d2;
    padding-bottom: 15px;
    margin-bottom: -12px;
    display: inline-block;
}

/* Symbol - small line and border */
.tbk-symbol--line_border .tbk__symbol span {
    display: inline-block;
    width: 50px;
    height: 3px;
    background-color: #106ee9;
    margin-right: 25px;
    margin-left: 25px;
    vertical-align: middle;
}
.tbk-symbol--line_border .tbk__symbol:before,
.tbk-symbol--line_border .tbk__symbol:after {
    content: '';
    display: inline-block;
    width: calc(50% - 50px);
    height: 1px;
    background-color: #106ee9;
    vertical-align: middle;
}

.kl-title-block.text-center.tbk-symbol--line_border .tbk__symbol span {
    margin: 0 20px;
}
.kl-title-block.text-center.tbk-symbol--line_border .tbk__symbol:before,
.kl-title-block.text-center.tbk-symbol--line_border .tbk__symbol:after {
    background-color: #bfbfbf;
}

.kl-title-block.text-right.tbk-symbol--line_border .tbk__symbol span {
    margin-right: 0;
}
.kl-title-block.text-right.tbk-symbol--line_border .tbk__symbol:after {
    display: none;
}
.kl-title-block.text-right.tbk-symbol--line_border .tbk__symbol:before {
    width: calc(100% - 80px);
    background-color: #bfbfbf;
}

/* Line Border - aligned left */
.tbk--left.tbk-symbol--line_border .tbk__symbol span {
    margin-left: 0;
}
.tbk--left.tbk-symbol--line_border .tbk__symbol:before {
    display: none;
}
.tbk--left.tbk-symbol--line_border .tbk__symbol:after {
    content: '';
    display: inline-block;
    width: calc(100% - 80px);
    height: 1px;
    background-color: #ccc;
}

/* Line Border - aligned right */
.tbk--right.tbk-symbol--line_border .tbk__symbol span {
    margin-right: 0;
}
.tbk--right.tbk-symbol--line_border .tbk__symbol:before {
    content: '';
    display: inline-block;
    width: calc(100% - 75px);
    height: 1px;
    background-color: #ccc;
}
.tbk--right.tbk-symbol--line_border .tbk__symbol:after {
    display: none;
}

/*Line Border - Positions */
.tbk-symbol--line_border.tbk-icon-pos--after-title .tbk__title {
    margin-bottom: 10px;
}
.tbk-symbol--line_border.tbk-icon-pos--after-subtitle .tbk__title {
    margin-bottom: 10px;
}
.tbk-symbol--line_border.tbk-icon-pos--after-subtitle .tbk__subtitle {
    margin-bottom: 20px;
}
.tbk-symbol--line_border.tbk-icon-pos--after-text .tbk__text {
    margin-bottom: 25px;
}

/* Symbol - icon */
.tbk-symbol--icon.tbk-icon-pos--after-text .tbk__text {
    margin-bottom: 25px;
}

/* Symbol inside title */
.tbk__title .tbk__symbol {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}
.tbk__title .tbk__icon {
    vertical-align: middle;
}

/* Symbol line separator */
.symbol-line {
    font-size: 20px;
    text-align: center;
    color: #e6e6e6;
    vertical-align: middle;
    clear: both;
    line-height: 20px;
    margin-bottom: 20px;
}
.symbol-line .kl-icon {
    font-size: 18px;
}
.symbol-line::before,
.symbol-line::after {
    content: '';
    display: inline-block;
    width: 48%;
    background: #eaeaea;
    height: 2px;
    float: right;
    margin-top: 7px;
}
.symbol-line::before {
    float: left;
}

/* Text colors */
.tbk--text-light {
    color: #f5f5f5;
}
.tbk--text-dark {
    color: #232323;
}

/* Theme Color */
.tbk--color-theme.tbk-symbol--line .tbk__symbol span,
.tbk--color-theme.tbk-symbol--line_border .tbk__symbol span {
    background-color: #106ee9;
}
.tbk--color-theme.tbk-symbol--icon .tbk__icon {
    color: #106ee9;
}

/* Textbox */
.text_box {
    padding-bottom: 20px;
}
.text_box-title--style1 {
    font-size: 1rem;
    color: #106ee9;
    text-transform: uppercase;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
}
.text_box-title--style2 {
    font-size: 1.3rem;
    color: #535353;
    font-weight: normal;
    margin-top: 0;
}

/* VideoBox Play button */
.playVideo {
    display: block;
    width: 80px;
    height: 80px;
    border: 2px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 auto;
    -webkit-transform: scale(0.95) translate3d(0, 0, 0);
    -ms-transform: scale(0.95) translate3d(0, 0, 0);
    transform: scale(0.95) translate3d(0, 0, 0);
    -webkit-transition: -webkit-transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    -webkit-backface-visibility: hidden;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    line-height: 76px;
    font-size: 18px;
}
.playVideo:before {
    content: '\f04b';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}
.playVideo:hover {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    color: #fff;
}
.playVideo ~ h5 {
    margin: 40px auto 0;
}

/* BOXED VERSION */
body.boxed-layout {
    overflow-x: hidden;
}
body.boxed-layout #page_wrapper,
body.boxed-layout .support_panel {
    border-left: 3px solid #fff;
    border-right: 3px solid #fff;
    margin: 0 auto;
    background: #f5f5f5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
body.boxed-layout .kl-slideshow + .hg_section .action_box.style3 {
    margin-top: 0 !important;
}
body.boxed-layout ul.thumbs[data-columns='5'] .project-item {
    width: 25%;
    width: -webkit-calc(100% / 4);
    width: calc(100% / 4);
}
body.boxed-layout .kl-bg-source__bgimage {
    background-size: initial;
}
body.boxed-layout .fancy-list.w-50 {
    width: 100% !important;
}
body.boxed-layout .kl-contentmaps__panel {
    left: 100px;
}
body.boxed-layout .cd-immersive-video .product-preview.video-zoomed-out {
    transform: translateX(812px) translateY(183.5px) !important;
}
body.boxed-layout .works_carousel .caroufredsel:hover .cfs--prev {
    left: -30px;
}
body.boxed-layout .works_carousel .caroufredsel:hover .cfs--next {
    right: -25px;
}

/* Sidebars */
.sidebar {
    background: #fff;
    padding: 20px 25px;
    border-radius: 3px;
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.15);
}
.col-sm-4 .sidebar {
    padding: 25px;
}
.sidebar .widget {
    margin-bottom: 35px;
}
.sidebar .widget .widgettitle {
    color: #050505;
    line-height: 1.4;
    margin: 10px 0 15px;
    padding-bottom: 20px;
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.sidebar .widget .widgettitle:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 2px;
    width: 50px;
    height: 0;
    border-bottom: 1px solid #106ee9;
}
.sidebar .widget .widgettitle:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 2px;
    width: 100%;
    height: 0;
    border-bottom: 1px solid #ececec;
}
.sidebar .widget ul.menu,
.sidebar .widget ul.product-categories,
.sidebar .widget ul.pagenav,
.sidebar .widget_meta > ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.sidebar .widget ul.menu li,
.sidebar .widget ul.product-categories li,
.sidebar .widget ul.pagenav li,
.sidebar .widget_meta > ul li {
    margin-bottom: 0;
}
.sidebar .widget ul.menu li a,
.sidebar .widget ul.product-categories li a,
.sidebar .widget ul.pagenav li a,
.sidebar .widget_meta > ul a {
    color: #747474;
    display: inline-block;
    padding: 0;
    margin-right: 5px;
    position: relative;
    border-radius: 2px;
}
.sidebar .widget ul.menu li a::before,
.sidebar .widget ul.product-categories li a::before,
.sidebar .widget ul.pagenav li a::before,
.sidebar .widget_meta > ul a::before {
    content: '';
    display: inline-block;
    margin-right: 12px;
    width: 8px;
    height: 2px;
    background: #e1e1e1;
    vertical-align: middle;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
.sidebar .widget ul.menu li.active > a,
.sidebar .widget ul.product-categories li.active > a,
.sidebar .widget ul.pagenav li.active a,
.sidebar .widget ul.menu li.current-cat > a,
.sidebar .widget ul.product-categories li.current-cat > a,
.sidebar .widget ul.pagenav li.current-cat > a,
.sidebar .widget ul.menu li > a:hover,
.sidebar .widget ul.product-categories li > a:hover,
.sidebar .widget ul.pagenav li a:hover,
.sidebar .widget_meta > ul a:hover {
    color: #000000;
}
.sidebar .widget ul.menu li a:hover::before,
.sidebar .widget ul.product-categories li a:hover::before,
.sidebar .widget ul.pagenav li a:hover::before,
.sidebar .widget_meta > ul a:hover::before {
    width: 12px;
    margin-right: 8px;
}
div.cloudBlock a {
    padding: 4px;
    float: left;
    display: block;
}

/* Latest Post from sidebar */
.latest_posts.style2 {
    background-color: #fff;
    position: relative;
    padding: 30px 30px 10px 30px;
}
.latest_posts.style2 .m_title {
    margin-bottom: 20px;
}
.latest_posts.style2 .viewall {
    position: absolute;
    top: 28px;
    right: 20px;
    font-size: 10px;
    font-weight: bold;
    color: #757575;
}
.latest_posts.style2 .viewall:hover {
    color: #111;
}
.latest_posts.style2 ul.posts {
    margin: 0;
    padding: 0;
    list-style: none;
}
.latest_posts.style2 ul.posts li {
    margin-top: 25px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 25px;
}
.latest_posts.style2 ul.posts li:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}
.latest_posts.style2 ul.posts .title {
    font-size: 14px;
    font-weight: 700;
    color: #106ee9;
    line-height: 1.6;
    vertical-align: middle;
}
.latest_posts.style2 ul.posts .details {
    float: left;
    width: 110px;
}
.latest_posts.style2 ul.posts .details > span {
    display: inline-block;
    margin-bottom: 2px;
    font-size: 10px;
}
.latest_posts.style2 ul.posts .details span.date {
    background: #106ee9;
    color: #fff;
    padding: 3px 10px;
    margin: 0 0 5px;
    position: relative;
}
.latest_posts.style2 ul.posts .details span.date:after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 10px;
    border: 4px solid transparent;
    border-top-color: #106ee9;
}
.latest_posts.style2 ul.posts .text {
    margin-left: 110px;
}
.latest_posts.style2 ul.posts .details > span.cat {
    display: block;
}

.latest_posts.style3 {
    position: relative;
}
.latest_posts.style3 .m_title {
    margin-bottom: 20px;
}
.latest_posts.style3 .hoverBorder {
    margin-right: 20px;
    margin-top: 5px;
}
.latest_posts.style3 .viewall {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    font-weight: bold;
    color: #757575;
}
.latest_posts.style3 .viewall:hover {
    color: #111;
}
.latest_posts.style3 ul.posts {
    margin: 0;
    padding: 0;
    list-style: none;
}
.latest_posts.style3 ul.posts li {
    font-size: 0.9rem;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e3e3e3;
    overflow: hidden;
}
.latest_posts.style3 ul.posts li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
}
.latest_posts.style3 ul.posts .title {
    font-size: 13px;
    margin: 0 0 5px;
    font-weight: 600;
    text-shadow: 1px 1px 0 white;
    vertical-align: middle;
    line-height: 20px;
    margin: 0 0 5px;
    padding: 0;
    text-shadow: 1px 1px 0 white;
    vertical-align: middle;
}
/*.latest_posts.style3 ul.posts .title a{font-size: 13px; font-weight: normal; font-weight: 600; }*/
.lp-post .hoverBorder ~ .text,
.lp-post .hoverBorder ~ .lp-post-comments-num {
    margin-left: 74px;
}
.lp-post .lp-post-comments-num {
    color: #bababa;
}
.lp-post .hoverBorder + .title {
    overflow: hidden;
}
.widget .latest_posts ul li.lp-post .hoverBorder {
    margin-bottom: 0;
}

.hoverBorder {
    position: relative;
    display: inline-block;
    margin-bottom: 25px;
}
.hoverBorder:hover img {
    border-radius: 2px;
    opacity: 0.8;
}
.hoverBorderWrapper {
    position: relative;
    display: block;
}
.hoverBorderWrapper img {
    max-width: 100%;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    position: relative;
}
.theHoverBorder {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 2px;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.15) inset;
}
.theHoverBorder:hover {
    box-shadow: 0 0 0 3px #106ee9 inset;
}
.plus .theHoverBorder {
    background-position: center center;
}
.plus .theHoverBorder:hover {
    background-image: url(../images/plus01.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-color: rgba(35, 35, 35, 0.5);
}
.hoverBorder:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 3px;
    box-shadow: inset 0 0 0 3px hsla(0, 0%, 100%, 0.15);
    transition: box-shadow 0.2s ease-out, background-color 0.2s ease-out;
}
.hoverBorder:hover:after {
    box-shadow: inset 0 0 0 3px #106ee9;
}

/* Image Boxes */
.image-boxes {
    margin-bottom: 30px;
}
.image-boxes .hoverBorder {
    margin-bottom: 20px;
}
.image-boxes .box {
    margin-bottom: 20px;
}
.image-boxes img {
    width: 100%;
    height: auto;
}
.image-boxes a {
    display: block;
}

/* Style 1 */
.imgboxes_style1 {
    overflow: visible;
}
.imgboxes_style1 .hoverBorder h6 {
    position: absolute;
    left: -10px;
    bottom: 20px;
    color: #fff;
    padding: 11px 15px;
    background: #106ee9;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    margin: 0;
    border-radius: 2px;
}
.imgboxes_style1 .hoverBorder:hover h6 {
    left: 20px;
}
.imgboxes_style1 img {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.22);
}
.imgboxes_style1 .hoverBorderWrapper {
    height: 270px;
}

/* Style 2 */
.image-boxes.ib_style2 .hoverBorder {
    margin-bottom: 0;
}
.imgboxes_style2 .imgboxes-wrapper {
    height: 270px;
}

/* Style 3 */
.slidingDetails {
    position: relative;
    display: block;
    overflow: hidden;
}
.slidingDetails img {
    position: relative;
}
.slidingDetails,
.slidingDetails .details {
    -webkit-transition: all 0.2s cubic-bezier(0.68, 0, 0.265, 1);
    transition: all 0.2s cubic-bezier(0.68, 0, 0.265, 1);
}
.slidingDetails .details {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    padding: 30px;
    color: #fff;
    background: #232323;
    background: rgba(35, 35, 35, 0.8);
}
.slidingDetails:hover .details {
    visibility: visible;
    opacity: 1;
    top: 0;
}
.slidingDetails:hover {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
}
.slidingDetails .details h4 {
    margin-bottom: 20px;
}

/* Image Boxes 1 Style 4 */
.imgboxes_style4 .imgboxes-wrapper {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    height: 270px;
    width: 100%;
}
.imgboxes_style4 .imgboxes-title {
    position: absolute;
    bottom: 0;
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin-bottom: 0;
    padding-bottom: 30px;
    z-index: 0;
}
.imgboxes_style4 .imgboxes-title:before {
    content: '';
    position: absolute;
    top: -100%;
    left: 0;
    height: 200%;
    width: 100%;
    background: -moz-linear-gradient(
        top,
        transparent 0%,
        rgba(0, 0, 0, 1) 100%
    );
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, transparent),
        color-stop(100%, rgba(0, 0, 0, 1))
    );
    background: -webkit-linear-gradient(
        top,
        transparent 0%,
        rgba(0, 0, 0, 1) 100%
    );
    background: -o-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 1) 100%);
    background: -ms-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 1) 100%);
    background: linear-gradient(
        to bottom,
        transparent 0%,
        rgba(0, 0, 0, 1) 100%
    );
    z-index: -1;
    opacity: 0.8;
}
.imgboxes_style4 .imgboxes-title:after {
    content: '';
    height: 2px;
    width: 30px;
    background: #106ee9;
    display: block;
    margin: 0 auto;
    margin-top: 10px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    z-index: 1;
}
.imgboxes_style4:hover .imgboxes-title:after {
    width: 60px;
}
.imgboxes_style4 .imgbox_image {
    -webkit-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
    -webkit-backface-visibility: hidden;
}
.imgboxes_style4:hover .imgbox_image {
    opacity: 0.8;
}
.cover-fit-img {
    object-fit: cover;
    overflow: hidden;
}
.imgbox_image.cover-fit-img {
    height: 100%;
}
.contain-fit-img {
    -o-object-fit: contain;
    object-fit: contain;
    overflow: hidden;
}

/* Style bottom */
.imgboxes_style4.kl-title_style_bottom .imgboxes-title {
    text-align: left;
    padding: 0 20px 30px;
    -webkit-transition: padding-bottom 0.2s ease-out;
    transition: padding-bottom 0.2s ease-out;
}
.imgboxes_style4.kl-title_style_bottom .imgboxes-title:before {
    -webkit-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
}
.imgboxes_style4.kl-title_style_bottom .imgboxes-title:after {
    display: none;
}
.imgboxes_style4.kl-title_style_bottom .imgboxes-border-helper {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    border-bottom: solid 5px #106ee9;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    z-index: 1;
}
.imgboxes_style4.kl-title_style_bottom:hover .imgboxes-border-helper {
    border-bottom: solid 10px #106ee9;
}
.imgboxes_style4.kl-title_style_bottom:hover .imgboxes-title:before {
    opacity: 0.5;
}
.imgboxes_style4.kl-title_style_bottom:hover .imgboxes-title {
    text-align: left;
    padding-bottom: 25px;
}

/* Style left */
.imgboxes_style4.kl-title_style_left .imgboxes-title {
    text-align: left;
    padding: 0 20px 30px;
}
.imgboxes_style4.kl-title_style_left .imgboxes-title:after {
    margin: initial;
    width: 40px;
    margin-top: 10px;
}
.imgboxes_style4.kl-title_style_left:hover .imgboxes-title:after {
    width: 80px;
}

/*-----------------------------------------------------------------------------------*/
/*  Blog Comments
/*-----------------------------------------------------------------------------------*/
.comment-form-wrapper {
    margin-top: 50px;
}

/* recent comments */
#recentcomments {
    list-style: none outside none;
    margin: 0;
    padding: 0;
}
#recentcomments li {
    padding-left: 10px;
    position: relative;
    margin-bottom: 0;
}
#recentcomments li:before {
    content: '\201C';
    width: 3px;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    color: #ddd;
}

/* process boxes */
.process_box {
    position: relative;
    padding: 0;
    margin-bottom: 80px;
    box-shadow: none;
}
.process_box:after {
    border-color: transparent;
    border-top-color: #eee;
    border-width: 10px;
    margin-left: -10px;
    left: 10%;
}
.process_box:before {
    border-color: transparent;
    border-top-color: #ccc;
    border-width: 13px;
    margin-left: -13px;
    left: 10%;
}
.process_box[data-align='right']:after,
.process_box[data-align='right']:before {
    right: 10%;
    left: auto;
    margin-left: 0;
}
.process_box[data-align='right']:before {
    margin-right: -13px;
}
.process_box[data-align='center']:after,
.process_box[data-align='center']:before {
    left: 50%;
}
.process_box[data-align='center']:after {
    border-top-color: #fff;
}
.process_box .number {
    padding: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 240px;
    text-align: center;
    float: left;
}
.process_box .number span {
    font-size: 100px;
    font-weight: 900;
    color: #333;
    vertical-align: middle;
    position: relative;
    top: 50%;
    line-height: 1;
    display: block;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.process_box .content {
    margin-left: 240px;
    padding: 25px 35px 20px 35px;
    float: left;
    font-size: 14px;
    line-height: 30px;
    color: #929292;
    background-color: #fff;
}
.process_box .stp_title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: #333;
}
.process_box[data-align='right'] .number {
    left: auto;
    right: 0;
    border-right: 0;
}
.process_box[data-align='right'] .content {
    margin-left: auto;
    margin-right: 240px;
    padding: 25px 35px 20px 35px;
    color: #929292;
    line-height: 30px;
    font-size: 14px;
}
/* end Process box element */

/* Price list element */
.priceListElement h3.menu-categ {
    font-size: 14px;
    text-align: center;
    color: #ccc;
    letter-spacing: 2px;
}
.priceListElement ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.priceListElement ul li {
    margin-top: 25px;
    margin-bottom: 25px;
}
.priceListElement-itemMain {
    margin-bottom: 10px;
}
.priceListElement .priceListElement-itemMain {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.priceListElement-itemTitle {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-size: 1.1rem;
    line-height: 1.7;
    font-weight: 700;
    margin: 0;
}
.priceListElement .priceListElement-dottedSeparator {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-left: 3px;
    margin-right: 5px;
    margin-bottom: calc((24px - 14px) / 2);
}
.priceListElement .priceListElement-dottedSeparator,
.priceListElement-dash--separator > ul > li {
    background-image: -webkit-radial-gradient(
        circle closest-side,
        rgba(0, 0, 0, 0.2) 99%,
        transparent 1%
    );
    background-image: radial-gradient(
        circle closest-side,
        rgba(0, 0, 0, 0.2) 99%,
        transparent 1%
    );
    background-position: bottom;
    background-size: 5px 3px;
    background-repeat: repeat-x;
}
.priceListElement-itemPrice {
    color: #106ee9;
    text-align: right;
}
.priceListElement .priceListElement-itemTitle,
.priceListElement .priceListElement-itemPrice {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
}
.priceListElement-itemPrice {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
}
/* end Price list element */

/* Counter element */
.fun-fact {
    text-align: center;
}
.fun-fact .fun-number {
    font-size: 80px;
    font-weight: 300;
    color: #23262d;
    line-height: 80px;
    height: 80px;
    display: block;
}
.fun-fact .fun-thing {
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: 700;
    color: #45474d;
    line-height: 1.4;
    display: block;
    position: relative;
    margin-top: 40px;
}
.fun-fact .fun-thing.topline::before {
    content: '';
    position: absolute;
    top: -15px;
    left: 50%;
    width: 80px;
    margin-left: -40px;
    height: 1px;
    background: #dcddde;
}
/* end Counter element */

/* PROGRESS BARS SKILLS ELEMENT */
.skills_wgt ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.skills_wgt ul li {
    margin-top: 15px;
}
.skills_wgt ul li:first-child {
    margin-top: 0;
}
.skills_wgt .skill-title {
    font-size: 13px;
    color: #888888;
    line-height: 1;
    margin-bottom: 13px;
    margin: 0 0 10px;
}
.skills_wgt.stg-dark .skill-title {
    color: #45474d;
}
.skills_wgt.stg-light .skill-title {
    color: #f5f5f5;
}
.skills_wgt .skill-bar {
    display: block;
    border-radius: 2px;
    background: #efefef;
    height: 13px;
}
.skills_wgt.whitebars .skill-bar {
    background: #fff;
}
.skills_wgt .skill-bar > i {
    background: #999;
    border-radius: 2px;
    width: 0;
    height: 13px;
    display: block;
    position: relative;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}
.skills_wgt li:nth-child(1) .skill-bar > i {
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}
.skills_wgt li:nth-child(2) .skill-bar > i {
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
}
.skills_wgt li:nth-child(3) .skill-bar > i {
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
}
.skills_wgt li:nth-child(4) .skill-bar > i {
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
}
.skills_wgt li:nth-child(5) .skill-bar > i {
    -webkit-transition-delay: 0.7s;
    transition-delay: 0.7s;
}
.skills_wgt li:nth-child(6) .skill-bar > i {
    -webkit-transition-delay: 0.8s;
    transition-delay: 0.8s;
}
.skills_wgt li:nth-child(7) .skill-bar > i {
    -webkit-transition-delay: 0.9s;
    transition-delay: 0.9s;
}
.skills_wgt li:nth-child(8) .skill-bar > i {
    -webkit-transition-delay: 1s;
    transition-delay: 1.1s;
}
.skills_wgt li:nth-child(9) .skill-bar > i {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
}
.skills_wgt li:nth-child(10) .skill-bar > i {
    -webkit-transition-delay: 1.2s;
    transition-delay: 1.2s;
}
.skills_wgt.stg-rounded .skill-bar,
.skills_wgt.stg-rounded .skill-bar > i {
    border-radius: 9px;
}
.skills_wgt.started .skill-bar[data-loaded='10'] > i {
    width: 10%;
}
.skills_wgt.started .skill-bar[data-loaded='10'] > i::before {
    content: '10%';
}
.skills_wgt.started .skill-bar[data-loaded='20'] > i {
    width: 20%;
}
.skills_wgt.started .skill-bar[data-loaded='20'] > i::before {
    content: '20%';
}
.skills_wgt.started .skill-bar[data-loaded='30'] > i {
    width: 30%;
}
.skills_wgt.started .skill-bar[data-loaded='30'] > i::before {
    content: '30%';
}
.skills_wgt.started .skill-bar[data-loaded='40'] > i {
    width: 40%;
}
.skills_wgt.started .skill-bar[data-loaded='40'] > i::before {
    content: '40%';
}
.skills_wgt.started .skill-bar[data-loaded='50'] > i {
    width: 50%;
}
.skills_wgt.started .skill-bar[data-loaded='50'] > i::before {
    content: '50%';
}
.skills_wgt.started .skill-bar[data-loaded='60'] > i {
    width: 60%;
}
.skills_wgt.started .skill-bar[data-loaded='60'] > i::before {
    content: '60%';
}
.skills_wgt.started .skill-bar[data-loaded='70'] > i {
    width: 70%;
}
.skills_wgt.started .skill-bar[data-loaded='70'] > i::before {
    content: '70%';
}
.skills_wgt.started .skill-bar[data-loaded='80'] > i {
    width: 80%;
}
.skills_wgt.started .skill-bar[data-loaded='80'] > i::before {
    content: '80%';
}
.skills_wgt.started .skill-bar[data-loaded='90'] > i {
    width: 90%;
}
.skills_wgt.started .skill-bar[data-loaded='90'] > i::before {
    content: '90%';
}
.skills_wgt.started .skill-bar[data-loaded='100'] > i {
    width: 100%;
}
.skills_wgt.started .skill-bar[data-loaded='100'] > i::before {
    content: '100%';
}
.skills_wgt .skill-bar[data-color='t-red'] > i {
    background: #106ee9;
}
.skills_wgt .skill-bar[data-color='green'] > i {
    background: #63ad68;
}
.skills_wgt .skill-bar[data-color='blue'] > i {
    background: #5476a9;
}
.skills_wgt .skill-bar[data-color='red'] > i {
    background: #f46e6e;
}
.skills_wgt .skill-bar[data-color='purple'] > i {
    background: #a872bc;
}
.skills_wgt .skill-bar[data-color='yellow'] > i {
    background: #f4e36e;
}
.skills_wgt .skill-bar[data-color='orange'] > i {
    background: #eea754;
}
.skills_wgt .skill-bar[data-color='turquoise'] > i {
    background: #2bcdc1;
}
.skills_wgt .skill-bar[data-color='lightblue'] > i {
    background: #6cb0df;
}
.skills_wgt .skill-bar[data-color='lightred'] > i {
    background: #df6c8a;
}
.skills_wgt .skill-bar[data-color='pink'] > i {
    background: #f46eb3;
}
.skills_wgt.started .skill-bar i::before {
    background: #1a1c25;
    border-radius: 3px;
    padding: 0;
    height: 22px;
    line-height: 22px;
    width: 35px;
    display: block;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 11px;
    position: absolute;
    bottom: 100%;
    left: 100%;
    margin-left: -25px;
}
.skills_wgt.started .skill-bar i::after {
    content: '';
    position: absolute;
    border: 4px solid transparent;
    border-top-color: #1a1c25;
    bottom: 100%;
    left: 100%;
    margin-left: -12px;
}
.skills_wgt.started .skill-bar i::before,
.skills_wgt.started .skill-bar i::after {
    opacity: 0;
    visibility: hidden;
    margin-bottom: 30px;
}
.skills_wgt.started .skill-bar:hover i::before,
.skills_wgt.started .skill-bar:hover i::after {
    opacity: 1;
    visibility: visible;
    margin-bottom: 15px;
}
.skills_wgt.started .skill-bar:hover i::after {
    margin-bottom: 7px;
}
/* end PROGRESS BARS SKILLS ELEMENT */

/* Accordions elements */
/* Accordion Default Style / Re-Styling / */
.hg_accordion_element.default-style > h3 {
    font-size: 18px;
    line-height: 1.4;
    margin-top: 0;
    text-transform: uppercase;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
}
.hg_accordion_element.default-style .th-accordion {
    border: 1px solid #e3e3e3;
    border-radius: 4px;
}
.hg_accordion_element.default-style .th-accordion .acc-group {
    margin-bottom: 0;
    border-bottom: 1px solid #e3e3e3;
}
.hg_accordion_element.default-style .th-accordion .acc-group:last-child {
    border-bottom: 0;
}
.hg_accordion_element.default-style .th-accordion .acc-group a {
    border: 0;
    padding: 4px 10px;
    color: #106ee9;
    display: block;
    width: 100%;
    text-align: left;
    background-color: #f8f8f8;
    padding: 20px 60px 20px 25px;
    font-size: 0.95rem;
    font-weight: 600;
    position: relative;
    cursor: pointer;
}
.hg_accordion_element.default-style .th-accordion .acc-group a.collapsed {
    color: #505050;
    background-color: #fff;
}
.hg_accordion_element.default-style .th-accordion .acc-group a:hover {
    background: #f8f8f8;
    color: #111;
}
.hg_accordion_element.default-style .th-accordion .acc-group .content {
    background-color: #f8f8f8;
    padding: 20px 25px;
}
.hg_accordion_element.default-style .th-accordion .acc-group a:not(.collapsed) {
    border-bottom: 1px solid #eeeeee;
}
.hg_accordion_element.default-style .th-accordion .acc-group a:before,
.hg_accordion_element.default-style .th-accordion .acc-group a:after {
    position: absolute;
    content: ' ';
    width: 10px;
    height: 2px;
    display: block;
    background: #ddd;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.hg_accordion_element.default-style .th-accordion .acc-group a:before {
    right: 31px;
    top: 50%;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.hg_accordion_element.default-style .th-accordion .acc-group a:after {
    right: 25px;
    top: 50%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.hg_accordion_element.default-style
    .th-accordion
    .acc-group
    a.collapsed:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.hg_accordion_element.default-style .th-accordion .acc-group a.collapsed:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

/* Accordions style2 */
.hg_accordion_element.style2 .th-accordion .acc-group {
    margin-bottom: 2px;
    padding: 0;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
}
.hg_accordion_element.style2 .th-accordion .acc-group a {
    display: block;
    width: 100%;
    text-align: left;
    position: relative;
    margin: 0;
    background: transparent;
    border: 0;
    background-color: #ffffff;
    color: #106ee9;
    padding: 15px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
.hg_accordion_element.style2 .th-accordion .acc-group a.collapsed {
    color: #505050;
    background-color: transparent;
}
.hg_accordion_element.style2 .th-accordion .acc-group .content {
    padding: 10px 15px;
    border-top: 1px solid #e5e5e5;
    background-color: #ffffff;
}
.hg_accordion_element.style2 .th-accordion .acc-group .content p:last-child {
    margin-bottom: 0;
}
.hg_accordion_element.style2 .th-accordion .acc-group a:before,
.hg_accordion_element.style2 .th-accordion .acc-group a:after {
    position: absolute;
    content: ' ';
    width: 8px;
    height: 2px;
    display: block;
    background: #bdbdbd;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.hg_accordion_element.style2 .th-accordion .acc-group a:before {
    right: 20px;
    top: 50%;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.hg_accordion_element.style2 .th-accordion .acc-group a:after {
    right: 15px;
    top: 50%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.hg_accordion_element.style2 .th-accordion .acc-group a.collapsed:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.hg_accordion_element.style2 .th-accordion .acc-group a.collapsed:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

/* Accordions style3 */
.hg_accordion_element.style3 .th-accordion {
    background: #fff;
    border: 1px solid #e3e3e3;
    padding: 15px;
}
.hg_accordion_element.style3 .th-accordion .acc-group {
    margin-bottom: 8px;
    margin-left: 54px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e5e5e5;
}
.hg_accordion_element.style3 .th-accordion .acc-group:last-child {
    border-bottom: 0;
}
.hg_accordion_element.style3 .th-accordion .acc-group a {
    display: block;
    width: 100%;
    color: #106ee9;
    text-align: left;
    position: relative;
    margin: 0;
    padding: 10px 10px 5px 0;
    background: none;
    border: 0;
    font-size: 16px;
    cursor: pointer;
}
.hg_accordion_element.style3 .th-accordion .acc-group a.collapsed {
    color: #505050;
}
.hg_accordion_element.style3 .th-accordion .acc-group a:after {
    width: auto;
    height: auto;
    padding-right: 14px;
}
.hg_accordion_element.style3 .th-accordion .acc-group a:before {
    width: 36px;
    height: 50px;
    background-color: #106ee9;
    position: absolute;
    left: -54px;
    top: 5px;
    content: '\f068';
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    line-height: 40px;
    font-size: 12px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.hg_accordion_element.style3 .th-accordion .acc-group a.collapsed:before {
    content: '\f067';
    background-color: #d3d3d3;
    height: 40px;
}
.hg_accordion_element.style3 .th-accordion .acc-group .content {
    padding: 10px 15px 10px 0;
}
.hg_accordion_element.style3 .th-accordion .acc-group .content p:last-child {
    margin-bottom: 0;
}

/* Accordions - Style 4 */
.hg_accordion_element.style4 .th-accordion {
    border: solid 1px #ce2123;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.hg_accordion_element.style4 .th-accordion .acc-group {
    margin-bottom: 0px;
}
.hg_accordion_element.style4 .th-accordion .acc-group a {
    background: none;
    border: 0;
    padding: 4px 10px;
    display: block;
    width: 100%;
    text-align: left;
    position: relative;
    cursor: pointer;
}
.hg_accordion_element.style4 .th-accordion .acc-group a:hover {
    background: #dcdcdc;
    color: #111;
}
.hg_accordion_element.style4 .th-accordion .acc-group a[aria-expanded='true'] {
    color: #106ee9;
}
.hg_accordion_element.style4 .th-accordion .acc-group .content {
    padding: 10px;
}
.hg_accordion_element.style4 > h3 {
    line-height: 1.4;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-shadow: none;
    padding: 15px 20px;
    margin-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    text-transform: uppercase;
    background-color: #106ee9;
}
.hg_accordion_element.style4 .th-accordion .acc-group {
    overflow: hidden;
}
.hg_accordion_element.style4
    .th-accordion
    .acc-group:last-child
    a[aria-expanded='true'] {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.hg_accordion_element.style4 .th-accordion .acc-group a:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.hg_accordion_element.style4 .th-accordion .acc-group a[aria-expanded='true'] {
    background: #ffffff;
    border-bottom: solid 1px #e6e6e6;
}
.hg_accordion_element.style4 .th-accordion .acc-group a {
    padding: 12px 20px;
    overflow: hidden;
    border-top: solid 1px #e6e6e6;
    background-color: #f9f9f9;
}
.hg_accordion_element.style4 .th-accordion .acc-group:first-child a {
    border-top: none;
}
.hg_accordion_element.style4
    .th-accordion
    .acc-group:first-child
    a[aria-expanded='true'] {
    border-top: none;
}
.hg_accordion_element.style4 .th-accordion .acc-group a:hover {
    background: #f5f5f5;
}
.hg_accordion_element.style4 .th-accordion .acc-group .content {
    border-bottom: none;
    background: #fff;
    padding: 20px;
}
.hg_accordion_element.style4 .th-accordion .acc-group:last-child .content {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.hg_accordion_element.style4
    .th-accordion
    .acc-group
    a[aria-expanded='false']
    span.acc-icon {
    position: absolute;
    right: -31px;
    width: 70px;
    height: 70px;
    border: 7px solid #000;
    border-radius: 50%;
    top: -7px;
}
.hg_accordion_element.style4
    .th-accordion
    .acc-group
    a[aria-expanded='true']
    span.acc-icon:before,
.hg_accordion_element.style4
    .th-accordion
    .acc-group
    a[aria-expanded='true']
    span.acc-icon:after {
    content: '';
    background-color: #000;
    display: inline-block;
    text-align: center;
    line-height: 63px;
    position: absolute;
    left: 50%;
    top: 50%;
}
.hg_accordion_element.style4
    .th-accordion
    .acc-group
    a[aria-expanded='true']
    span.acc-icon:before {
    width: 20px;
    height: 6px;
    margin-top: -3px;
    margin-left: -9px;
}
.hg_accordion_element.style4
    .th-accordion
    .acc-group
    a[aria-expanded='true']
    span.acc-icon:after {
    width: 6px;
    height: 19px;
    margin-left: -2px;
    margin-top: -10px;
}
.hg_accordion_element.style4 .th-accordion .acc-group a span.acc-icon {
    position: absolute;
    right: -20px;
    width: 70px;
    height: 70px;
    opacity: 0.05;
    border: 7px solid #106ee9;
    border-radius: 50%;
    top: -7px;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}
.hg_accordion_element.style4 .th-accordion .acc-group a span.acc-icon:before {
    content: '';
    width: 20px;
    height: 6px;
    background-color: #106ee9;
    display: inline-block;
    text-align: center;
    line-height: 63px;
    position: absolute;
    left: 50%;
    margin-left: -9px;
    top: 50%;
    margin-top: -3px;
}
.hg_accordion_element.style4
    .th-accordion
    .acc-group
    a[aria-expanded='true']:hover
    span.acc-icon {
    opacity: 0.05;
}

/* Accordions - Style 5 */
.hg_accordion_element.style5 .th-accordion {
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;
    border: 1px solid #efefef;
    border-radius: 3px;
}
.hg_accordion_element.style5 .th-accordion .acc-group {
    margin-bottom: 0;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    border-width: 1px 0 0;
}
.hg_accordion_element.style5 .th-accordion .acc-group {
    border-top: 1px solid #efefef;
}
.hg_accordion_element.style5 .th-accordion .acc-group:first-child {
    border: 0;
}
.hg_accordion_element.style5 .th-accordion a {
    background: none;
    border: 0;
    font-size: 1rem;
    color: #474747;
    line-height: 2;
    min-height: 55px;
    display: block;
    width: 100%;
    text-align: left;
    padding: 12px 20px 10px 55px;
    position: relative;
    outline: none;
    cursor: pointer;
}
.hg_accordion_element.style5 .th-accordion a:hover {
    color: #909090;
}
.hg_accordion_element.style5 .th-accordion a::before {
    content: attr(data-number);
    width: 22px;
    height: 22px;
    border-radius: 2px;
    font-weight: 700;
    text-align: center;
    display: block;
    color: #fff;
    background: #106ee9;
    position: absolute;
    left: 17px;
    top: 17px;
    line-height: 22px;
    font-size: 12px;
}
.hg_accordion_element.style5 .th-accordion a.collapsed::before {
    background: #d3d3d3;
}
.hg_accordion_element.style5 .th-accordion .acc-group > div:last-child {
    margin: 0 17px 0 55px;
    color: #888;
    font-size: 0.95rem;
    line-height: 1.7;
}
.hg_accordion_element.style5 .th-accordion ul {
    margin-bottom: 20px;
}
.hg_accordion_element.style5 .th-accordion .inner-acc {
    padding: 15px 0 0;
}
.hg_accordion_element.style5 .th-accordion.filled-bg {
    background-color: #f4f4f4;
}

/* 404 error page */
.error404 .page-subheader {
    height: 200px;
    min-height: 0;
}
.error404-content {
    margin: 20px auto;
    text-align: center;
    line-height: 1;
}
.error404-content h2 {
    font-weight: bold;
    font-size: 260px;
    line-height: 1;
    text-align: center;
    text-shadow: 0 0 28px rgba(35, 35, 35, 0.5);
    text-transform: uppercase;
    margin: 20px 0;
    color: transparent;
}
.error404-content h3 {
    font-size: 30px;
    text-align: center;
    opacity: 0.2;
}
.error404 .search {
    margin: 40px auto;
    text-align: center;
}
.error404 .gensearch__form {
    position: relative;
    display: inline-block;
    width: 300px;
}

/* Historic timeline */
.timeline_bar {
    position: relative;
}
.timeline_bar:before {
    position: absolute;
    content: '';
    width: 1px;
    height: 97%;
    background-color: rgba(0, 0, 0, 0.09);
    display: block;
    top: 26px;
    left: 50%;
    margin-left: -1px;
    z-index: 0;
}
.timeline_bar > .row {
    margin-bottom: 0;
}
.timeline_box {
    background: #fff;
    border-radius: 3px;
    margin: 30px 30px 30px 0;
    padding: 30px;
    position: relative;
}
div[data-align='right'] .timeline_box {
    margin: 30px 0 30px 30px;
}
.timeline_box:after {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    border: 10px solid transparent;
    border-left-color: #fff;
    margin-top: -10px;
}
div[data-align='right'] .timeline_box:after {
    left: -20px;
    right: auto;
    border-left-color: transparent;
    border-right-color: #fff;
}
.timeline_box:before {
    content: '\f073';
    position: absolute;
    font-family: 'Font Awesome 5 Free';
    font-size: 18px;
    top: 50%;
    right: -70px;
    background: #e5e5e5;
    color: #fff;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    box-sizing: content-box;
}
div[data-align='right'] .timeline_box:before {
    right: auto;
    left: -70px;
}
.end_timeline {
    text-align: center;
}
.timeline_box,
.timeline_box .date {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
}
.end_timeline > span {
    background: #fff;
    border-radius: 3px;
    padding: 5px 20px;
    line-height: 2;
    display: inline-block;
}
.timeline_box:hover:before {
    background-color: #106ee9;
}
.timeline_box .date {
    font-size: 13px;
    font-weight: 700;
    background: #fff;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    margin-top: -14px;
    right: -100px;
    color: #787878;
    right: auto;
    white-space: nowrap;
    left: -webkit-calc(100% + 90px);
    left: calc(100% + 90px);
}
.timeline_box .htitle {
    font-weight: bold;
}
div[data-align='right'] .timeline_box .date {
    left: auto;
    right: calc(100% + 90px);
    right: -webkit-calc(100% + 90px);
}

/* BLOG */
.eBlog.itemListView {
    margin-bottom: 35px;
}
.eBlog .itemContainer {
    background: #fff;
    margin-bottom: 35px;
    padding: 30px 30px 15px 30px;
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.15);
    position: relative;
    border-radius: 3px;
}
.eBlog .itemContainer:after {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-width: 5px;
    content: '';
    border-bottom-color: #ccc !important;
}
.eBlog .post_details {
    font-size: 0.8rem;
    color: #9a9a9a;
    margin-bottom: 15px;
    text-transform: uppercase;
    padding: 10px 0 10px;
    position: relative;
    -webkit-transition: opacity 150ms ease-in;
    transition: opacity 150ms ease-in;
}
.eBlog .post_details [class^='icon-'],
.eBlog .itemLinks li [class^='icon-'],
.eBlog .itemTagsBlock [class^='icon-'] {
    margin: 0 5px 0 0;
    padding: 0;
}
.eBlog .itemTitle {
    margin: 0 0 10px;
}
.eBlog .itemBody {
    line-height: 1.8;
}
.eBlog.itemView .itemBody {
    margin-bottom: 50px;
}
.eBlog .post_details:before {
    content: '';
    position: absolute;
    left: 0;
    top: -1px;
    width: 60px;
    height: 1px;
    background-color: #ddd;
}
.eBlog .post_details .infSep {
    display: inline-block;
    margin: 0 5px;
}
.eBlog .itemBottom {
    margin-top: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    padding-bottom: 15px;
}
.eBlog .readMore {
    font-size: 14px;
    font-weight: bold;
}
.eBlog .itemLinks {
    float: left;
    list-style: none !important;
    font-size: 0.8rem;
    color: #aaa;
    text-transform: uppercase;
    margin-top: 0;
    padding-left: 0;
    margin-bottom: 4px;
    margin-left: 0;
    font-weight: 600;
}
.eBlog .itemComments {
    float: right;
}
.eBlog .itemComments a {
    font-size: 0.8rem;
    text-transform: uppercase;
}
.eBlog .itemLinks li {
    float: left;
    margin-left: 0;
    padding-left: 0;
}
.eBlog .itemLinks li:first-child {
    margin-left: 0;
}
.eBlog .itemCategory {
    padding-left: 0;
}
.eBlog .itemDateCreated {
    padding-left: 0;
}
.eBlog .itemTagsBlock,
.eBlog .itemTagsBlock,
.eBlog .userItemTagsBlock {
    padding-left: 0;
    font-size: 0.72rem;
    color: #aaa;
    text-transform: uppercase;
    font-weight: 600;
}
.eBlog .itemCategory span.fa-folder {
    margin-right: 3px;
}
.eBlog .itemTagsBlock,
.eBlog .itemTagsBlock span.fa-tag {
    margin-right: 3px;
}

.eBlog .itemContainer:not(.hg_columns) .itemTagsBlock {
    float: left;
}
.eBlog .itemContainer:not(.hg_columns) .itemTagsBlock ul {
    margin: 0 !important;
    padding: 0 !important;
    list-style: none !important;
    display: inline;
}
.eBlog .itemContainer:not(.hg_columns) .itemTagsBlock ul li {
    display: inline;
}
.itemView .itemTagsBlock {
    margin: 0 0 20px;
}
.itemView .itemTagsBlock > a,
.eBlog .itemContainer:not(.hg_columns) .itemTagsBlock > a,
.tagcloud a {
    padding: 4px 10px;
    background-color: #eaeaea;
    margin: 0 5px 5px 10px;
    color: #9b9b9b;
    display: inline-block;
}
.itemView .itemTagsBlock > a:before,
.eBlog .itemContainer:not(.hg_columns) .itemTagsBlock > a:before,
.tagcloud a:before {
    font-family: 'Font Awesome 5 Free';
    content: '\f02b';
    font-weight: 900;
    margin-right: 5px;
    vertical-align: middle;
    color: #d2d2d2;
}
div.cloudBlock a {
    padding: 4px;
    float: left;
    display: block;
}

.eBlog .itemReadMore {
    float: right;
}

.kl-blog-post-socsharing {
    margin-bottom: 20px;
    margin-top: 10px;
    padding: 0;
    list-style: none;
}
.kl-blog-post-socsharing li {
    float: left;
    margin-right: 20px;
    width: 70px;
}
.blog-item-share {
    display: inline-block;
}
.blog-item-share:before {
    content: attr(data-share-title);
    font-size: 12px;
    font-weight: 600;
    display: block;
    line-height: 1;
    margin-bottom: 10px;
    color: #8a8a8a;
}
.blog-item-share-link {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-top: -5px;
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.25);
    background-color: transparent;
    border-color: #e2e2e2;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    border: 0;
    font-size: 16px;
    text-align: center;
    margin-right: 5px;
    line-height: 30px;
}

.eBlog .itemList .blog-isotope-item {
    padding-left: 10px;
    padding-right: 10px;
}

.itemContainer.hg_columns {
    padding: 0 0 20px;
}
.itemContainer.hg_columns .itemHeader,
.itemContainer.hg_columns .itemBody,
.itemContainer.hg_columns .itemLinks,
.itemContainer.hg_columns .itemTagsBlock {
    padding: 0 15px;
}

.itemContainer.hg_columns .itemIntroText {
    padding: 0 0 15px;
    margin: 0 0 15px;
    border-bottom: 1px solid #f5f5f5;
    font-size: 0.95rem;
}
.itemContainer .theHoverBorder {
    box-shadow: none;
    border-radius: 0;
}
.itemContainer.hg_columns .hoverBorder,
.itemContainer.hg_columns .hoverBorder img {
    width: 100%;
    height: auto;
    border-radius: 0;
}
.itemContainer.hg_columns .readMore {
    font-weight: normal;
}

.itemContainer.hg_columns h3.itemTitle {
    line-height: 1.6;
    font-size: 1.2rem;
    padding: 20px 0 0;
}
.itemContainer.hg_columns.hg_columns4 .readMore,
.itemContainer.hg_columns.hg_columns3 .readMore {
    font-size: 13px;
}

.itemThumbnail {
    position: relative;
}
.itemThumbnail img {
    display: block;
    width: 100%;
    height: auto;
}
.itemThumbnail > a {
    display: block;
}
.itemThumbnail .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    -webkit-transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
}
.itemContainer.hg_columns:hover .overlay {
    opacity: 1;
    visibility: visible;
}
.itemThumbnail .overlay__inner {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.itemThumbnail .overlay__inner a {
    margin: 0 auto;
    width: 48px;
    height: 48px;
    border-radius: 30px;
    display: block;
    border: 2px solid #fff;
    position: relative;
    text-align: center;
    overflow: hidden;
    color: #fff;
    line-height: 46px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.itemThumbnail .overlay__inner a:hover {
    width: 120px;
    background: #106ee9;
    border-color: #106ee9;
}
.itemThumbnail .overlay__inner a:before {
    content: '+';
    position: absolute;
    left: 50%;
    margin-left: -6px;
    font-size: 20px;
    font-weight: bold;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
}
.itemThumbnail .overlay__inner a:hover:before {
    opacity: 0;
}
.itemThumbnail .overlay__inner a:after {
    content: attr(data-readmore);
    opacity: 0;
    font-size: 11px;
    -webkit-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
}
.itemThumbnail .overlay__inner a:hover:after {
    opacity: 1;
}

.eBlog .featured-post {
    position: relative;
    padding: 0;
}
.eBlog .itemContainer.featured-post:after {
    content: none;
}
.eBlog .featured-post .hg_full_image {
    position: relative;
    border-bottom: none;
    min-height: 220px;
}
.eBlog .featured-post .hg_full_image img {
    width: 100%;
}
.eBlog .featured-post .zn_sticky_no_image {
    min-height: 400px;
    background-color: #333;
}
.eBlog .featured-post .itemFeatContent {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px;
}
.eBlog .featured-post .itemFeatContent:before {
    content: '';
    position: absolute;
    top: -50%;
    left: 0;
    width: 100%;
    height: 150%;
    background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.6));
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
    opacity: 0;
    visibility: hidden;
}
.eBlog .featured-post .itemFeatContent-inner {
    position: relative;
}
.eBlog .featured-post .itemHeader {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 15px;
    padding-bottom: 15px;
}
.eBlog .featured-post .itemTitle {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 1.6;
    margin: 0 0 15px;
}
.eBlog .featured-post .post_details {
    color: rgba(255, 255, 255, 0.4);
    padding: 0;
    margin: 0;
}
.eBlog .featured-post .post_details:before {
    content: none;
}
.eBlog .featured-post .itemLinks {
    color: rgba(255, 255, 255, 0.4);
}
.eBlog .featured-post a {
    color: #fff;
}
.eBlog .featured-post a:hover {
    color: rgb(199, 199, 199);
}
.hg_post_image {
    float: left;
    margin-right: 30px;
    margin-bottom: 10px;
}
.hg_post_image,
.hg_full_image {
    border-bottom: 5px solid #106ee9;
}

#th-content-post .page-title {
    padding-bottom: 0;
    border-bottom: 0;
}

/* Post Author */
.eBlog .post-author .author-avatar {
    float: left;
    width: 100px;
    margin-right: 20px;
}
.eBlog .post-author .author-avatar img {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 50%;
}
.eBlog .post-author {
    color: #535353;
    margin-bottom: 50px;
    overflow: hidden;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    padding: 30px 0;
}
.eBlog .author-details > h4 {
    text-transform: uppercase;
}

.masonry-columns {
    height: auto !important;
}

.bigblog-page #footer {
    top: 100%;
}

/* Blockquote */
blockquote {
    margin: 25px 40px;
}

/* Related Articles */
.eBlog .related-articles .rta-title {
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 30px;
}
.eBlog .related-articles .rta-post > a {
    display: block;
    position: relative;
}
.eBlog .related-articles .rta-post > a:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    background-color: #106ee9;
    -webkit-transition: height 0.25s ease-in-out;
    transition: height 0.25s ease-in-out;
}
.eBlog .related-articles .rta-post:hover > a:after {
    height: 8px;
}
.eBlog .related-articles .rta-post h5 {
    text-transform: uppercase;
    margin-top: 10px;
}

/* Pagination */
.pagination-nav {
    text-align: center;
}
.pagination-nav .pagination {
    display: inline-block;
}
.pagination {
    margin: 50px 0 0;
    text-align: center;
    display: block;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 1.6;
    color: #888;
}
/* Do some bootstrap resetting */
.pagination ul,
ul.pagination {
    list-style-type: none;
    padding: 10px 0;
}
.pagination li {
    display: inline-block;
    margin: 0 5px;
}

.pagination a,
.pagination li > a,
.pagination li > a:focus,
.pagination li > a:hover,
.pagination li > span,
.pagination li > span:focus,
.pagination li > span:hover {
    border: 0;
    background: none;
    text-shadow: none;
}
/* Normal state */
.pagination a,
.pagination li a,
.pagination li > span,
.pagination p.counter {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0;
    display: inline-block;
    margin: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    float: none;
    color: rgba(0, 0, 0, 0.85);
    border-radius: 3px;
}
/* Move left and right arrows */
.pagination li:first-child {
    margin-right: 50px;
}
.pagination li:last-child {
    margin-left: 50px;
}
/* hide first or last if no pages */
.pagination li:first-child > span,
.pagination li:last-child > span {
    display: none;
}
/* Add left & right glyphs */
.pagination li:first-child span.zn_icon,
.pagination li:last-child span.zn_icon {
    line-height: 40px;
}
/* Hover state */
.pagination a,
.pagination li a:hover,
.pagination li:first-child > a:hover:before,
.pagination li:last-child > a:hover:before {
    color: rgba(0, 0, 0, 0.5);
}
/* Active */
.pagination .active_page,
.pagination .active > a,
.pagination .active > a:focus,
.pagination .active > a:hover,
.pagination .active > span,
.pagination .active > span:focus,
.pagination .active > span:hover {
    color: rgba(0, 0, 0, 0.5);
    background: none;
    border: 2px solid rgba(0, 0, 0, 0.18);
    height: 40px;
    line-height: 38px;
    width: 40px;
}

.pagination a.next_link {
    margin-left: 20px;
}
.pagination a.next_link:before {
    content: '\f054';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}
.pagination a.previous_link {
    margin-right: 20px;
}
.pagination a.previous_link:before {
    content: '\f053';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

/* grayhover effect */
a.grayHover {
    overflow: hidden;
    position: relative;
    display: inline-block;
}
a.grayHover img {
    position: relative;
}

/* Blog posts for latest news */
.blog-posts {
    margin-left: -15px;
    margin-right: -15px;
    *zoom: 1;
}
.blog-posts::before,
.blog-posts::after {
    display: table;
    line-height: 0;
    content: '';
}
.blog-posts::after {
    clear: both;
}
.blog-posts + hr {
    border-bottom: 2px solid #ededed;
}
.blog-posts .bpost {
    margin-bottom: 60px;
}
.blog-posts .bpost .post-head {
    border-bottom: 1px solid #e8e8e8;
    position: relative;
}
.blog-posts .bpost .post-head > [data-role='title'] {
    font-size: 26px;
    line-height: 1.2;
    font-weight: 600;
    margin: 20px 35px 15px 0;
}
.blog-posts .bpost .post-head > [data-role='title'],
.blog-posts .bpost .post-head > [data-role='title'] a {
    color: #2b2d30;
}
.blog-posts .bpost .post-head > [data-role='title'] a:hover {
    color: #fe6060;
    text-decoration: none;
}
.blog-posts .bpost.col2 .post-head > [data-role='title'] {
    font-size: 24px;
}
.blog-posts .bpost.col3 .post-head > [data-role='title'] {
    font-size: 20px;
}
.blog-posts .bpost.col4 .post-head > [data-role='title'] {
    font-size: 16px;
}
.blog-posts .bpost.col5 .post-head > [data-role='title'] {
    font-size: 14px;
}
.blog-posts .bpost .post-head .date {
    font-size: 15px;
    color: #fe6060;
    float: right;
    width: 30px;
    line-height: 1;
    text-align: center;
    margin-top: 0;
    position: absolute;
    bottom: 15px;
    right: 0;
}
.blog-posts .bpost .post-head .date strong {
    font-size: 25px;
    display: block;
}
.blog-posts .bpost .post-details {
    font-size: 12px;
    color: #a2a2a2;
    font-style: italic;
    line-height: 1.6;
    margin: 15px 0;
}
.blog-posts .bpost .post-details a {
    color: #808080;
}
.blog-posts .bpost .post-content {
    color: #777777;
    margin-bottom: 20px;
}
.blog-posts .bpost .readmore-btn {
    font-weight: normal;
    text-transform: none;
    font-size: 13px;
}

/* Blog Post Widget Style for latest news*/
.bpost.bp-widget .post-head {
    margin-bottom: 20px;
}
.bpost.bp-widget .post-head .post-image {
    margin-bottom: 15px;
}
.bpost.bp-widget [data-role='category'] a {
    color: #c1c1c1;
    text-decoration: none;
}

.bpost.bp-widget [data-role='title'] {
    margin: 10px 0 15px;
}
.bpost.bp-widget [data-role='title'],
.bpost.bp-widget [data-role='title'] a {
    color: #45474d;
}
.bpost.bp-widget [data-role='title'] a:hover {
    color: #fe6060;
    text-decoration: none;
}
.bpost.bp-widget .post-content,
.bpost.bp-widget .post-details,
.bpost.bp-widget .post-details a {
    color: #888888;
}
.bpost.bp-widget .post-details {
    margin-bottom: 20px;
    font-size: 13px;
    font-style: normal;
}
.bpost.bp-widget .post-details a:hover {
    color: #fe6060;
    text-decoration: none;
}

/* EventBox */
.eventbox {
    background: #f4f4f4;
    color: #45474d;
}
.eventbox .vs-icon {
    color: #c8c9ca;
}
.eventbox .eventdate {
    font-size: 14px;
    padding: 20px 0;
    position: relative;
    margin-bottom: 30px;
}
.eventbox .eventdate::before,
.eventbox .eventdate::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 52px;
    height: 1px;
    background: #d1d1d3;
    margin-left: -26px;
}
.eventbox .eventdate::after {
    top: auto;
    bottom: 0;
}
.eventbox .eventdate > .date {
    display: block;
}
.eventbox .eventpic {
    margin-top: 40px;
    position: relative;
}
.eventbox .eventpic:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    border: 12px solid rgba(0, 0, 0, 0);
    border-top-color: #f4f4f4;
    margin-left: -6px;
    z-index: 1;
}
.eventbox .eventpic img {
    position: relative;
    z-index: 0;
}
.eventbox .eventpic .btn {
    position: absolute;
    z-index: 1;
    bottom: 40px;
    left: 20%;
    right: 20%;
}

/* PORTFOLIO */

/* Portfolio Item */
.hg-portfolio-item {
    position: relative;
}
.portfolio-item-title {
    text-transform: uppercase;
    color: #4d4d4d;
    font-size: 32px;
    line-height: 1.3;
    font-weight: 400;
}
.portfolio-item-desc {
    margin-bottom: 30px;
}
.portfolio-item-desc-inner {
    max-height: 150px;
    overflow: hidden;
    position: relative;
}
.portfolio-item-desc-inner:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100px;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(245, 245, 245, 0)),
        color-stop(100%, #fbfbfb)
    );
    background: -webkit-linear-gradient(
        top,
        rgba(245, 245, 245, 0) 0,
        #fbfbfb 100%
    );
    background: linear-gradient(
        to bottom,
        rgba(245, 245, 245, 0) 0,
        #fbfbfb 100%
    );
}
.portfolio-item-more-toggle {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 11px;
}
.portfolio-item-more-toggle .fas {
    font-size: 11px;
    margin-right: 4px;
}
.portfolio-item-more-toggle:after {
    content: attr(data-more-text);
}
/* when opened */
.portfolio-item-desc.is-opened .portfolio-item-desc-inner {
    max-height: none;
    overflow: hidden;
}
.portfolio-item-desc.is-opened .portfolio-item-desc-inner:after {
    display: none;
}
.portfolio-item-desc.is-opened .portfolio-item-more-toggle:after {
    content: attr(data-less-text);
}
.portfolio-item-desc.is-opened .portfolio-item-more-toggle .fas:before {
    content: '\f077';
    font-weight: 900;
}
/* no-toggle */
.portfolio-item-desc.no-toggle .portfolio-item-desc-inner {
    max-height: none;
    overflow: hidden;
}
.portfolio-item-desc.no-toggle .portfolio-item-more-toggle {
    display: none;
}
.portfolio-item-desc.no-toggle .portfolio-item-desc-inner:after {
    display: none;
}
/* when in affix */
.portfolio-item-content.affixcontent
    .portfolio-item-desc.is-opened:not(.no-toggle)
    .portfolio-item-desc-inner {
    max-height: 35vh;
    overflow: auto;
}
/* details */
.portfolio-item-details {
    margin: 0 0 40px;
    list-style: none;
    padding: 0;
}
.portfolio-item-details li {
    margin-bottom: 15px;
}
.portfolio-item-details-label {
    min-width: 100px;
    font-weight: 300;
    color: #ababab;
    float: left;
}
.portfolio-item-details-item {
    float: left;
    overflow: hidden;
}
/* Other details */
.portfolio-item-otherdetails {
    margin-bottom: 30px;
}
.portfolio-item-livelink {
    display: inline-block;
    margin-top: 5px;
}
.portfolio-item-share {
    display: block;
    margin-bottom: 30px;
}
.portfolio-item-share:before {
    content: attr(data-share-title);
    font-size: 12px;
    font-weight: 600;
    display: block;
    line-height: 1;
    margin-bottom: 10px;
    color: #8a8a8a;
}
.portfolio-item-share a {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-top: -5px;
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.25);
    background-color: transparent;
    border-color: #e2e2e2;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    border: 0;
    font-size: 16px;
    text-align: center;
    margin-right: 5px;
    line-height: 30px;
}
.portfolio-item-share a:hover {
    color: #fff;
}
.portfolio-item-share .portfolio-item-share-twitter {
    font-size: 13px;
}
.portfolio-item-share-twitter:hover {
    background: #55acee;
}
.portfolio-item-share-facebook:hover {
    background: #3b5998;
}
.portfolio-item-share-gplus:hover {
    background: #cc3732;
}
.portfolio-item-share-pinterest:hover {
    background: #cc2127;
}
.portfolio-item-share-mail:hover {
    background: #565656;
}
/* Right part */
.hg-portfolio-item .portfolio-item-right {
    margin-bottom: 20px;
}
.portfolio-item {
    margin-bottom: 50px;
}
/* Affixed Portfolio content */
.portfolio-item-content.affixcontent {
    -webkit-transition: top 0.2s ease-out;
    transition: top 0.2s ease-out;
    -webkit-backface-visibility: hidden;
}
/* MQ's */
@media (min-width: 992px) and (max-width: 1199px) {
    .page-title.portfolio-item-title {
        font-size: 24px;
        line-height: 1.3;
    }
}
@media (min-width: 992px) {
    .portfolio-item-content.pinned {
        top: 60px !important;
    }
}
@media (max-width: 991px) {
    .portfolio-item-content.affixcontent {
        position: static !important;
    }
    .page-title.portfolio-item-title {
        font-size: 24px;
        line-height: 1.3;
    }
    .portfolio-item-details-label {
        float: none;
        display: block;
        margin-bottom: 5px;
    }
    .portfolio-item-details-item {
        float: none;
        display: block;
    }
    .portfolio-item-livelink {
        margin-top: 15px;
    }
    .portfolio-item-details {
        margin: 0 0 20px;
    }
}

.portfolio-row {
    margin-bottom: 40px;
}
/* Overlay */
.kl-has-overlay .img-intro {
    position: relative;
    z-index: 0;
}
.kl-has-overlay .img-intro > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}
.kl-has-overlay .img-intro .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(31, 34, 37, 0.25);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.kl-has-overlay .img-intro:hover .overlay {
    opacity: 1;
    visibility: visible;
    box-shadow: inset 0 -8px 0 0 #106ee9;
}
.hg-portfolio-carousel .kl-has-overlay .img-intro:hover .overlay {
    box-shadow: none;
}
.kl-has-overlay .img-intro .overlay-inner {
    position: relative;
    text-align: center;
    top: 50%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
}
.kl-has-overlay .img-intro .overlay-inner > span {
    color: #fff;
    width: 60px;
    height: 60px;
    line-height: 58px;
    border: 2px solid #fff;
    display: inline-block;
    border-radius: 50%;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
}
.kl-has-overlay .img-intro:hover .overlay-inner > span {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.portfolio-entry .title {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.6;
    margin-top: 20px;
}
.portfolio-entry .pt-cat-desc {
    font-size: 0.9rem;
    line-height: 1.8;
}
.hg-portfolio-carousel .portfolio-item:before,
.hg-portfolio-carousel .portfolio-item:after {
    content: ' ';
    display: table;
}
.hg-portfolio-carousel .portfolio-item:after {
    clear: both;
}
.hg-portfolio-carousel .portfolio-item {
    padding-bottom: 70px;
    margin-bottom: 70px;
    border-bottom: 1px solid #ebebeb;
}
.hg-portfolio-carousel .portfolio-item:last-child {
    padding-bottom: 0;
    margin-bottom: 50px;
    border: 0;
}
.hg-portfolio-carousel .pt-content-title {
    margin-bottom: 30px;
    margin-top: 20px;
}
.hg-portfolio-carousel .pt-cat-desc {
    margin-bottom: 30px;
}
.hg-portfolio-carousel .portfolio-item-details {
    margin-bottom: 20px;
    font-size: 0.9rem;
}
.pt-itemlinks {
    margin-bottom: 20px;
}
.pt-itemlinks .btn:first-child {
    margin-right: 10px;
}

/* SORTABLE PORTFOLIO */
.hg-portfolio-sortable h1.title {
    font-weight: bold;
}
.hg-portfolio-sortable #sorting {
    float: right;
    margin: 0;
    font-size: 0.7rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 8px 10px;
    color: #888;
    border-radius: 2px;
    margin-top: 10px;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.hg-portfolio-sortable #sorting ul {
    display: inline-block;
    margin: 0 25px 0 0;
    padding: 0;
    list-style: none;
}
.hg-portfolio-sortable #sort-direction {
    margin-right: 0 !important;
}
.hg-portfolio-sortable #sorting ul li {
    display: inline-block;
    margin-left: 10px;
    line-height: 1;
    margin-bottom: 0;
}
.hg-portfolio-sortable #sorting ul li a {
    color: #b7b7b7;
    line-height: 1;
}
.hg-portfolio-sortable #sorting ul li a.selected {
    color: #131313;
}
.hg-portfolio-sortable #sorting .sortTitle {
    line-height: 1;
}
.hg-portfolio-sortable #portfolio-nav {
    margin: 5px 0 40px;
    padding: 0;
    list-style: none;
    float: left;
}
.hg-portfolio-sortable #portfolio-nav li {
    display: inline-block;
}
.hg-portfolio-sortable #portfolio-nav li a {
    background: #fff;
    color: #000;
    padding: 15px;
    line-height: 1;
    font-size: 10px;
    text-transform: uppercase;
    display: inline-block;
    margin-right: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: bold;
}
.hg-portfolio-sortable #portfolio-nav li a:hover,
.hg-portfolio-sortable #portfolio-nav li.current a {
    background: #106ee9;
    color: #fff;
}
.hg-portfolio-sortable li.kl-has-overlay .img-intro:hover .overlay {
    box-shadow: none;
}
.portfolio-item .img-intro img,
.hg-portfolio-sortable .item .img-intro img {
    width: 100%;
}
.hg-portfolio-sortable ul#thumbs {
    padding: 0;
    list-style: none;
    overflow: hidden;
    margin-left: -10px;
    margin-right: -10px;
}
.hg-portfolio-sortable ul#thumbs li {
    width: 100%;
    float: left;
    padding: 0 10px;
    margin-bottom: 20px;
}
.hg-portfolio-sortable ul#thumbs[data-columns='2'] li {
    width: 50%;
}
.hg-portfolio-sortable ul#thumbs[data-columns='3'] li {
    width: 33.333%;
}
.hg-portfolio-sortable ul#thumbs[data-columns='4'] li {
    width: 25%;
}
.hg-portfolio-sortable ul#thumbs[data-columns='5'] li {
    width: 20%;
}
.hg-portfolio-sortable ul#thumbs li .inner-item {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.15);
    background: #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    opacity: 0.9;
}
.hg-portfolio-sortable ul#thumbs li .inner-item:hover {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
    opacity: 1;
}
.hg-portfolio-sortable ul#thumbs li .inner-item h4 {
    display: block;
    line-height: 1.6;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.17);
    font-size: 0.9rem;
    text-transform: uppercase;
    margin: 0;
    padding: 15px;
}
.hg-portfolio-sortable ul#thumbs li .inner-item .moduleDesc {
    font-size: 0.85rem;
    color: #777;
    padding: 0 15px 15px;
    margin: 15px 0 0;
    display: block;
}
.hg-portfolio-sortable ul#thumbs li .inner-item .moduleDesc p {
    margin-bottom: 10px;
}
.hg-portfolio-sortable ul#thumbs li.isotope-item {
    z-index: 2;
}
.hg-portfolio-sortable ul#thumbs li.isotope-hidden {
    z-index: 1;
}
.hg-portfolio-sortable .inner-item iframe {
    display: block;
}

/* portfolio Category */
.zn_portfolio_category.ptfcat-one-col > .row {
    margin-bottom: 50px;
}

/* Services Boxes */
.services_box--classic .services_box__inner {
    margin-bottom: 30px;
}
.services_box--classic .services_box__content {
    overflow: hidden;
}
.services_box--classic .services_box__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.6;
    color: #106ee9;
    margin: 15px 0;
    vertical-align: middle;
}
.services_box--classic .services_box__icon {
    float: left;
    width: 80px;
    line-height: 80px;
    color: #fff;
    background: #646464;
    text-align: center;
    margin-right: 20px;
    vertical-align: middle;
    border-radius: 50%;
    height: 80px;
    -webkit-transition: background 0.15s ease-out;
    transition: background 0.15s ease-out;
    position: relative;
}
.services_box--classic:hover .services_box__icon {
    background: #106ee9;
}
.services_box--classic .services_box__list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.services_box--classic .services_box__list li::before {
    content: '\f0da';
    font-family: 'Font Awesome 5 Free';
    font-size: 15px;
    font-weight: 900;
    margin-right: 10px;
    color: #868686;
    top: 0;
    vertical-align: middle;
    -webkit-transition: color 0.15s;
    transition: color 0.15s;
}
.services_box--classic .services_box__list li:hover::before {
    color: #454545;
}
.services_box--classic .services_box__list-text {
    vertical-align: middle;
}
/* image */
.services_box--classic .services_box__iconimg {
    max-width: 70px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -webkit-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
}
.services_box--classic .services_box__iconimg-hover {
    opacity: 0;
}
.services_box--classic:hover .sb--hashover .services_box__iconimg-main {
    opacity: 0;
}
.services_box--classic:hover .services_box__iconimg-hover {
    opacity: 1;
}
/* icon */
.services_box--classic .services_box__fonticon {
    color: #fff;
    font-size: 30px;
    line-height: 82px;
    -webkit-transition: color 0.2s ease-out 0.1s;
    transition: color 0.2s ease-out 0.1s;
}
.services_box--classic:hover .services_box__fonticon {
    color: #fff;
}

/* Modern Services Boxes */
.services_box--modern .services_box__inner {
    margin-bottom: 30px;
    position: relative;
}
.services_box--modern .services_box__title {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.4;
    color: #424242;
    margin: 15px 0;
    vertical-align: middle;
}
.services_box--modern .services_box__icon {
    float: left;
    width: 80px;
    line-height: 80px;
    box-shadow: inset 0 0 0 2px #106ee9;
    text-align: center;
    margin-right: 20px;
    vertical-align: middle;
    border-radius: 50%;
    height: 80px;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
.services_box--modern:hover .services_box__icon {
    box-shadow: inset 0 0 0 40px #106ee9;
}
.services_box--modern .services_box__icon-inner {
    position: relative;
    height: 100%;
}
.services_box--modern .services_box__icon:after {
    content: '';
    position: absolute;
    top: 80px;
    left: 40px;
    height: calc(100% - 60px);
    width: 1px;
    background-color: rgba(0, 0, 0, 0.07);
}
/* image */
.services_box--modern .services_box__iconimg {
    max-width: 70px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    transition: opacity 0.15s ease-out;
}
.services_box--modern .services_box__iconimg-hover {
    opacity: 0;
    -webkit-transform: translateX(-50%) translateY(-50%) scale(0);
    transform: translateX(-50%) translateY(-50%) scale(0);
    transition: all 0.15s ease-out;
}
.services_box--modern:hover .sb--hashover .services_box__iconimg-main {
    opacity: 0;
}
.services_box--modern:hover .services_box__iconimg-hover {
    opacity: 1;
    -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
    transform: translateX(-50%) translateY(-50%) scale(1);
}
/* icon */
.services_box--modern .services_box__fonticon {
    color: #106ee9;
    font-size: 40px;
    line-height: 83px;
    -webkit-transition: color 0.2s ease-out 0.1s;
    transition: color 0.2s ease-out 0.1s;
}
.services_box--modern:hover .services_box__fonticon {
    color: #fff;
}
/* list */
.services_box--modern .services_box__list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.services_box--modern .services_box__list li {
    position: relative;
}
.services_box--modern .services_box__list li:before {
    content: '';
    width: 5px;
    height: 5px;
    position: absolute;
    left: 38px;
    top: 9px;
    border-radius: 50%;
    display: block;
    background-color: #fff;
    box-shadow: 0 0 0 2px #106ee9;
    -webkit-transition: all 0.15s;
    transition: all 0.15s;
}
.services_box--modern .services_box__list li:hover:before {
    box-shadow: 0 0 0 3px #106ee9;
}
.services_box--modern .services_box__list-text {
    vertical-align: middle;
}
.services_box--modern.sb--hasicon .services_box__title {
    margin-left: 100px;
}
.services_box--modern.sb--hasicon .services_box__desc {
    margin-left: 100px;
}
.services_box--modern.sb--hasicon .services_box__list li {
    padding-left: 100px;
    padding-bottom: 15px;
}

/* Boxed Services Boxes */
.services_box--boxed {
    padding: 30px;
    background: #eee;
    margin: 0;
    position: relative;
    margin-bottom: 30px;
    border-radius: 5px;
    z-index: 1;
}
.services_box--boxed:hover {
    z-index: 2;
}
.services_box--boxed .services_box__inner {
    position: relative;
}
.services_box--boxed .services_box__title {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.3;
    color: #424242;
    margin: 0 0 15px;
    vertical-align: middle;
    z-index: 2;
    position: relative;
}
.services_box--boxed .services_box__desc {
    z-index: 2;
    position: relative;
}
.services_box--boxed .services_box__icon {
    max-height: 80px;
    position: relative;
    z-index: 2;
    min-height: 50px;
}
/* image */
.services_box--boxed .services_box__iconimg {
    transition: opacity 0.2s ease-out;
}
.services_box--boxed .services_box__iconimg-hover {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
.services_box--boxed:hover .sb--hashover .services_box__iconimg-main {
    opacity: 0;
}
.services_box--boxed:hover .services_box__iconimg-hover {
    opacity: 1;
}
/* icon */
.services_box--boxed .services_box__fonticon {
    color: #106ee9;
    font-size: 50px;
    -webkit-transition: color 0.2s ease-out 0.1s;
    transition: color 0.2s ease-out 0.1s;
}
.services_box--boxed:hover .services_box__fonticon {
    color: #454545;
}
/* list */
.services_box--boxed .services_box__list-wrapper {
    position: absolute;
    width: 100%;
    top: -30px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-out;
}
.services_box--boxed:hover .services_box__list-wrapper {
    opacity: 1;
    visibility: visible;
}
.services_box--boxed .services_box__list-bg {
    position: absolute;
    width: calc(100% + 60px);
    height: calc(100% + 30px);
    background: #fff;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    z-index: 1;
    opacity: 1;
    left: -30px;
    border-radius: 5px;
}
.services_box--boxed .services_box__list {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 250px;
    z-index: 1;
    position: relative;
}
.services_box--boxed .services_box__list li {
    position: relative;
    line-height: 26px;
    padding: 10px 0;
    border-top: 1px solid #eee;
    color: #106ee9;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease-out;
}
.services_box--boxed .services_box__list li:nth-child(1) {
    transform: translateY(10px);
    transition-delay: 0.025s;
}
.services_box--boxed .services_box__list li:nth-child(2) {
    transform: translateY(20px);
    transition-delay: 0.05s;
}
.services_box--boxed .services_box__list li:nth-child(3) {
    transform: translateY(30px);
    transition-delay: 0.075s;
}
.services_box--boxed .services_box__list li:nth-child(4) {
    transform: translateY(40px);
    transition-delay: 0.1s;
}
.services_box--boxed .services_box__list li:nth-child(5) {
    transform: translateY(50px);
    transition-delay: 0.125s;
}
.services_box--boxed .services_box__list li:nth-child(6) {
    transform: translateY(60px);
    transition-delay: 0.15s;
}
.services_box--boxed .services_box__list li:nth-child(7) {
    transform: translateY(70px);
    transition-delay: 0.175s;
}
.services_box--boxed .services_box__list li:nth-child(8) {
    transform: translateY(80px);
    transition-delay: 0.2s;
}
.services_box--boxed .services_box__list li:nth-child(9) {
    transform: translateY(90px);
    transition-delay: 0.225s;
}
.services_box--boxed .services_box__list li:nth-child(10) {
    transform: translateY(100px);
    transition-delay: 0.25s;
}
.services_box--boxed:hover .services_box__list li {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

/* CIRCLE HOVER */
.circlehover {
    width: 130px;
    height: 130px;
    text-align: center;
    color: white;
    font-size: 18px;
    padding: 10px;
    display: table;
    line-height: 1.4;
    background-color: #106ee9;
    position: relative;
    font-weight: 300;
    border-radius: 50%;
    box-shadow: 3px 3px 1px rgba(0, 0, 0, 0.2);
}
.circlehover[data-size='small'] {
    width: 80px;
    height: 80px;
    font-size: 14px;
    padding: 8px;
}
.circlehover[data-size='medium'] {
    width: 95px;
    height: 95px;
    font-size: 16px;
    padding: 10px;
}
.circlehover > span {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    z-index: 1;
}
.circlehover:before {
    content: '';
    position: absolute;
    top: 7px;
    left: 7px;
    background: #106ee9;
    width: 30px;
    height: 30px;
    border-radius: 3px;
}
.circlehover[data-position='top-left']:before {
    top: 7px;
    left: 7px;
}
.circlehover[data-position='top-right']:before {
    top: 7px;
    right: 7px;
    left: auto;
}
.circlehover[data-position='bottom-left']:before {
    bottom: 7px;
    left: 7px;
    top: auto;
}
.circlehover[data-position='bottom-right']:before {
    bottom: 7px;
    right: 7px;
    left: auto;
    top: auto;
}
.circlehover[data-position='top']:before,
.circlehover[data-position='right']:before,
.circlehover[data-position='left']:before,
.circlehover[data-position='bottom']:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.circlehover[data-position='top']:before {
    top: -10px;
    left: 50%;
    margin-left: -15px;
}
.circlehover[data-position='right']:before {
    top: 50%;
    right: -10px;
    left: auto;
    margin-top: -15px;
}
.circlehover[data-position='left']:before {
    top: 50%;
    left: -10px;
    margin-top: -15px;
}
.circlehover[data-position='bottom']:before {
    bottom: -10px;
    left: 50%;
    margin-left: -15px;
    top: auto;
}
.circlehover[data-position='bottom-right'] {
    box-shadow: -3px -3px 1px rgba(0, 0, 0, 0.5);
}
.circlehover:hover {
    color: #fff;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    box-shadow: 5px 5px 1px rgba(0, 0, 0, 0.2);
}
.circlehover[data-position='bottom-right']:hover {
    box-shadow: -5px -5px 1px rgba(0, 0, 0, 0.5);
}
.circlehover[data-align='left'] {
    float: left;
}
.circlehover[data-align='right'] {
    float: right;
}
.circlehover {
    -webkit-transition: all 400ms cubic-bezier(0.68, 0, 0.265, 1);
    -webkit-transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -webkit-transition-timing-function: cubic-bezier(0.68, 0, 0.265, 1);
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.circlehover.with-symbol > span.symbol {
    opacity: 0;
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    line-height: 130px;
}
.circlehover.with-symbol:hover > span.text {
    opacity: 0;
}
.circlehover.with-symbol:hover > span.symbol {
    opacity: 1;
}
.call-out-banner .m_title {
    font-size: 28px;
    position: relative;
    margin-top: 20px;
    text-transform: none;
}
.call-out-banner .m_title:before {
    position: absolute;
    content: '';
    width: 80px;
    height: 3px;
    background-color: #ddd;
    top: -20px;
    left: 0;
}

/* Callout Banner */
.callout-banner {
    margin-bottom: 40px;
    margin-top: 20px;
}
.callout-banner .m_title {
    font-weight: 300;
    font-size: 24px;
    line-height: 1.6;
    position: relative;
    padding-top: 20px;
    margin-top: 0;
}
.callout-banner .m_title strong {
    font-weight: 600;
}
.callout-banner .m_title:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 3px;
    background: #000;
    opacity: 0.1;
}

/* Callout Banner style 2 */
.circlehover.style2 {
    width: 100%;
    height: 75px;
    text-align: center;
    color: white;
    font-size: 18px;
    padding: 10px 20px;
    display: table;
    line-height: 1.3;
    background-color: #106ee9;
    position: relative;
    font-weight: 300;
    text-shadow: none;
    border-radius: 4px;
    box-shadow: none;
}
.circlehover.style2:before {
    content: '';
    position: absolute;
    background-color: #106ee9;
    width: 100%;
    height: 100%;
    top: initial;
    bottom: -4px;
    left: 0;
    border-radius: 3px;
    -webkit-transform: skewY(3deg);
    -ms-transform: skewY(3deg);
    transform: skewY(3deg);
    z-index: 1;
}
.circlehover.style2:after {
    content: '';
    position: absolute;
    background-color: #dcdcdc;
    width: 90%;
    height: 16px;
    top: initial;
    bottom: -9px;
    left: 11px;
    border-radius: 3px;
    -webkit-transform: skewY(-3deg);
    -ms-transform: skewY(-3deg);
    transform: skewY(-3deg);
}
.circlehover.style2.with-symbol > span.symbol {
    line-height: 73px;
}
.circlehover.style2.with-symbol > span.symbol img {
    max-height: 48px;
}

/* Callout Banner style 3 */
.circlehover.style3 {
    width: 150px;
    height: 150px;
    box-shadow: none;
}
.circlehover.style3:before {
    left: 3px;
    top: inherit;
    bottom: 25px;
    -webkit-transform: skewY(-28deg) rotate(27deg);
    -ms-transform: skewY(-28deg) rotate(27deg);
    transform: skewY(-28deg) rotate(27deg);
}
.circlehover.style3 .text {
    background: #000;
    color: #fff;
    display: block;
    position: absolute;
    bottom: 15px;
    right: -23px;
    min-width: 100px;
    padding: 3px 10px;
    font-style: italic;
    border-radius: 4px;
    font-size: 13px;
    line-height: 20px;
}
.circlehover.style3.with-symbol > span.symbol {
    line-height: 150px;
}

.triangle {
    display: none;
}
.circlehover.style3 .triangle {
    display: block;
    position: absolute;
    left: 72px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    top: 65px;
}
.circlehover.style3 .play-icon {
    display: block;
    background-color: #fff;
    -webkit-transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
    -ms-transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
    position: relative;
    text-align: left;
}
.circlehover.style3 .play-icon:before,
.circlehover.style3 .play-icon:after {
    content: '';
    position: absolute;
    background-color: inherit;
}
.circlehover.style3 .play-icon,
.circlehover.style3 .play-icon:before,
.circlehover.style3 .play-icon:after {
    width: 16px;
    height: 16px;
    border-top-right-radius: 30%;
}
.circlehover.style3 .play-icon {
    -webkit-transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
    -ms-transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
}
.circlehover.style3 .play-icon:before {
    -webkit-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
        translate(0, -50%);
    -ms-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
        translate(0, -50%);
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
        translate(0, -50%);
}
.circlehover.style3 .play-icon:after {
    -webkit-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414)
        translate(50%);
    -ms-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414)
        translate(50%);
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}
.circlehover.style3:hover .triangle {
    display: none;
}

/* team members */
.team_member {
    background: #fff;
    padding: 0 0 5px;
    position: relative;
}
.team_member:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}
.team_member .details {
    padding: 0 20px 20px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    background: #fff;
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    font-size: 0.9rem;
}
.team_member:hover .details {
    opacity: 1;
    visibility: visible;
}
.team_member:hover {
    z-index: 2;
}
.team_member img {
    width: 100%;
}
.team_member > h4,
.team_member > h6 {
    padding: 10px 20px 0 20px;
}
.team_member .details .social-icons {
    padding: 0;
    margin-left: -4px;
    margin-right: -4px;
}
.team_member .details .social-icons li {
    margin: 0 4px;
}
.team_member .details .social-icons li:first-child {
    margin-left: 0;
}
.team_member .details {
    width: 100%;
}
.sctb-icon-nocolor {
    background-color: #121212;
}

/* Latest Posts Default */
.latest_posts.default-style .hoverBorder {
    margin-bottom: 10px;
}
.latest_posts.default-style .hoverBorder h6 {
    position: absolute;
    left: -10px;
    bottom: 16px;
    color: #fff;
    padding: 11px 14px;
    background: #106ee9;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1;
    margin: 0;
    border-radius: 2px;
}
.latest_posts.default-style .hoverBorder:hover h6 {
    left: 15px;
    background: #fff;
    color: #106ee9;
}
.latest_posts.default-style a + em {
    font-size: 0.9rem;
    color: #7d7d7d;
}
.latest_posts.default-style .post {
    margin-bottom: 30px;
}
.latest_posts.default-style .post .m_title {
    font-style: italic;
    font-size: 1.2rem;
    line-height: 1.4;
    margin-top: 10px;
}
.latest_posts.default-style .post em {
    display: block;
}
.latest_posts.default-style img {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.22);
}

/* latest posts default style - style 2 */
.latest_posts.default-style.kl-style-2 .lp-title {
    background-color: #106ee9;
    height: 120px;
    width: 100%;
    text-align: center;
    padding: 20px 40px;
    margin: 0 0 20px;
}
.latest_posts.default-style.kl-style-2 .lp-title .m_title {
    position: relative;
    color: #fff;
    text-shadow: none;
    font-weight: bold;
    font-size: 3rem;
    line-height: 1;
    top: 50%;
    margin: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.latest_posts.default-style.kl-style-2 .post {
    position: relative;
    margin-bottom: 20px;
}
.latest_posts.default-style.kl-style-2 .post-details {
    position: absolute;
    bottom: 0;
    padding: 20px 15px;
}
.latest_posts.default-style.kl-style-2 .m_title a {
    color: #fff;
    text-shadow: none;
    font-style: normal;
    margin-bottom: 5px;
}
.latest_posts.default-style.kl-style-2 .post-details em {
    font-style: normal;
    color: #d6d6d6;
    font-size: 0.7rem;
}
.latest_posts.default-style.kl-style-2 .post-details em a {
    color: #fff;
}
.latest_posts.default-style.kl-style-2 .m_title {
    position: relative;
    margin: 0 0 20px;
}
.latest_posts.default-style.kl-style-2 .post:not(.lp-title) .m_title:after {
    position: absolute;
    content: '';
    width: 88px;
    height: 3px;
    background-color: #106ee9;
    left: 0;
    bottom: -10px;
    -webkit-transition: width 0.25s ease-in-out;
    transition: width 0.25s ease-in-out;
}
.latest_posts.default-style.kl-style-2
    .post:not(.lp-title):hover
    .m_title:after {
    width: 160px;
}
.latest_posts.default-style.kl-style-2 .theHoverBorder {
    background: -webkit-linear-gradient(
        transparent 50%,
        rgba(0, 0, 0, 0.8) 100%
    );
    background: -o-linear-gradient(transparent 50%, rgba(0, 0, 0, 0.8) 100%);
    background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.8) 100%);
    box-shadow: none;
    border-radius: 0;
    -webkit-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
}
.latest_posts.default-style.kl-style-2 .hoverBorderWrapper img {
    border-radius: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%;
}
.latest_posts.default-style.kl-style-2 .hoverBorder {
    margin: 0;
}
.latest_posts.default-style.kl-style-2 .hoverBorder:hover img {
    opacity: 1;
}
.latest_posts.default-style.kl-style-2 .post:hover .theHoverBorder {
    opacity: 0;
}

.latest_posts.style2 {
    background-color: #fff;
    position: relative;
    padding: 30px 30px 10px 30px;
}
.latest_posts.style2 .m_title {
    margin-bottom: 20px;
}
.latest_posts.style2 .viewall {
    position: absolute;
    top: 28px;
    right: 20px;
    font-size: 0.8rem;
    font-weight: bold;
    color: #757575;
}
.latest_posts.style2 .viewall:hover {
    color: #111;
}
.latest_posts.style2 ul.posts {
    margin: 0;
    padding: 0;
    list-style: none;
}
.latest_posts.style2 ul.posts li {
    margin-top: 25px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 25px;
}
.latest_posts.style2 ul.posts li:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}
.latest_posts.style2 ul.posts .title {
    font-size: 1rem;
    font-weight: 700;
    color: #106ee9;
    line-height: 1.6;
    vertical-align: middle;
}
.latest_posts.style2 ul.posts .details {
    float: left;
    width: 110px;
}
.latest_posts.style2 ul.posts .details > span {
    display: inline-block;
    margin-bottom: 2px;
    font-size: 0.8rem;
}
.latest_posts.style2 ul.posts .details span.date {
    background: #106ee9;
    color: #fff;
    font-weight: bold;
    padding: 3px 10px;
    margin: 0 0 5px;
    position: relative;
}
.latest_posts.style2 ul.posts .details span.date:after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 10px;
    border: 4px solid transparent;
    border-top-color: #106ee9;
}
.latest_posts.style2 ul.posts .text {
    margin-left: 110px;
}
.latest_posts.style2 ul.posts .details > span.cat {
    display: block;
}

.latest_posts.style3 {
    position: relative;
}
.latest_posts.style3 .m_title {
    margin-bottom: 20px;
}
.latest_posts.style3 .hoverBorder {
    margin-right: 20px;
    margin-top: 5px;
    float: left;
    margin-bottom: 50px;
}
.latest_posts.style3 .viewall {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    font-weight: bold;
    color: #757575;
}
.latest_posts.style3 .viewall:hover {
    color: #111;
}
.latest_posts.style3 ul.posts {
    margin: 0;
    padding: 0;
    list-style: none;
}
.latest_posts.style3 ul.posts li {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e3e3e3;
    overflow: hidden;
}
.latest_posts.style3 ul.posts li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
}
.latest_posts.style3 ul.posts .title {
    font-size: 0.95rem;
    margin: 0 0 5px;
    font-weight: 600;
    text-shadow: 1px 1px 0 white;
    vertical-align: middle;
    line-height: 1.6;
    margin: 0 0 5px;
    padding: 0;
    text-shadow: 1px 1px 0 white;
    vertical-align: middle;
}

.lp-post .hoverBorder ~ .text,
.lp-post .hoverBorder ~ .lp-post-comments-num {
    margin-left: 74px;
}
.lp-post .lp-post-comments-num {
    color: #bababa;
}
.lp-post .hoverBorder + .title {
    overflow: hidden;
}
.widget .latest_posts ul li.lp-post .hoverBorder {
    margin-bottom: 0;
}

/* Latest Posts - Accordion Style */
.latest_posts-acc {
    position: relative;
}
.latest_posts-acc-elm-title {
    position: absolute;
    top: 14px;
    z-index: 1;
    margin: 0;
}
.latest_posts-acc-viewall {
    position: absolute;
    top: 12px;
    right: 0;
    font-size: 11px;
    font-weight: bold;
    color: #757575;
    z-index: 1;
}
.latest_posts-acc-item,
.latest_posts-acc-inner,
.latest_posts-acc-link {
    -webkit-transition: width 0.2s ease-in-out 0.2s;
    transition: width 0.2s ease-in-out 0.2s;
}
.latest_posts-acc-wrapper {
    width: 100%;
    margin: 0 auto 20px;
    overflow: hidden;
    position: relative;
}
.latest_posts-acc-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}
.latest_posts-acc-item {
    width: calc(25% - 20px);
    padding-top: 50px;
    position: relative;
    float: left;
    overflow: hidden;
    margin-right: 20px;
    border-top: 4px solid #e7e7e7;
}
.latestposts--dark .latest_posts-acc-item {
    border-top-color: #323232;
}
.latest_posts-acc-inner,
.latest_posts-acc-item:hover
    ~ .latest_posts-acc-item
    > .latest_posts-acc-inner {
    width: calc(200% + 40px);
}
.latest_posts-acc-item:hover > .latest_posts-acc-inner,
.latest_posts-acc-item.last > .latest_posts-acc-inner {
    width: 100%;
}
.latest_posts-acc-link {
    width: calc(50% - 20px);
    float: left;
    margin-right: 20px;
    opacity: 0.8;
}
.latest_posts-acc-item.last,
.latest_posts-acc-item:hover:not(.last) {
    width: 50%;
}
.latest_posts-acc-item:hover ~ .latest_posts-acc-item {
    width: calc(25% - 20px);
}
.latest_posts-acc-item:hover .latest_posts-acc-link {
    opacity: 1;
}
.latest_posts-acc-item.last {
    margin-right: 0;
}
.latest_posts-acc-details {
    font-size: 0.8rem;
}
.latest_posts-acc-title {
    font-size: 1rem;
    line-height: 1.5;
    margin: 10px 0;
}
.latest_posts-acc-text {
    font-size: 0.85rem;
    line-height: 1.6;
}
.latest_posts-acc-more {
    font-size: 0.7rem;
    font-weight: bold;
    color: #000;
    display: inline-block;
    margin-top: 5px;
}

/** Devices Images Element */
.el-devimages--ltr {
    direction: ltr;
}
.el-devimages__laptop {
    position: relative;
    width: 1160px;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
}
.el-devimages__laptop-img {
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 3px;
}
.el-devimages--type-img .el-devimages__laptop {
    height: 670px;
    background-image: url(../images/devices/macbook.png);
}
.el-devimages--type-img .el-devimages__laptop-img {
    left: 14%;
    top: 7.6%;
    bottom: 13.3%;
    right: 13.9%;
}
.el-devimages--type-vector .el-devimages__laptop {
    height: 600px;
    background-image: url(../images/devices/macbook-air.svg);
}
.el-devimages--type-vector .el-devimages__laptop-img {
    left: 16.6%;
    top: 6.3%;
    bottom: 11.8%;
    right: 16.6%;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.24);
}
.el-devimages__smartphone {
    position: absolute;
    width: 220px;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -20px;
    left: 70px;
}
.el-devimages__smartphone-img {
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 3px;
}
.el-devimages--type-img .el-devimages__smartphone {
    height: 403px;
    width: 245px;
    background-image: url(../images/devices/iphone6.png);
}
.el-devimages--type-img .el-devimages__smartphone-img {
    left: 15.9%;
    top: 11.6%;
    bottom: 13.8%;
    right: 14.8%;
}
.el-devimages--type-vector .el-devimages__smartphone {
    height: 400px;
    width: 200px;
    left: 110px;
    background-image: url(../images/devices/iphone6.svg);
}
.el-devimages--type-vector .el-devimages__smartphone-img {
    left: 7.2%;
    top: 14%;
    bottom: 11.7%;
    right: 7.3%;
}

/* Frame */
.el-devimages__frame {
    position: relative;
    width: 1160px;
    height: 520px;
    padding-top: 30px;
    padding-bottom: 5px;
    border-radius: 4px 4px 0 0;
    background-color: #dcdee7;
    border: 2px solid #fff;
    box-shadow: 0 10px 13px -10px rgba(0, 0, 0, 0.3);
}
.el-devimages__frame:before {
    content: '';
    position: absolute;
    top: 12px;
    margin-top: 0;
    left: 25px;
    display: block;
    background: #ababab;
    border-radius: 30px;
    width: 7px;
    height: 7px;
    box-shadow: -12px 0 0 #ababab, 12px 0 0 #ababab;
}
.el-devimages__frame-img {
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 30px;
    bottom: 5px;
    right: 0;
}

/* Right to left */
.el-devimages--rtl {
    direction: rtl;
}
.el-devimages--rtl.el-devimages--type-img .el-devimages__smartphone {
    left: auto;
    right: 70px;
}
.el-devimages--rtl.el-devimages--type-vector .el-devimages__smartphone {
    left: auto;
    right: 110px;
}
.el-devimages--rtl .el-devimages__frame:before {
    left: auto;
    right: 25px;
}

/* Center */
.el-devimages--center .el-devimages__frame {
    width: 100%;
    height: 0;
    padding-bottom: 45%;
}

/* Tablets devices Animation */
.tablet-device {
    display: block;
    position: relative;
    margin: 0 -10.8%;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-out;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}
.tablet-device::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background: #636363;
    border-radius: 8px;
    left: 50%;
    top: 6%;
    margin-left: -4px;
    border: 2px solid #fff;
}
.tablet-device.has-glare::after {
    content: '';
    position: absolute;
    width: 424px;
    height: 463px;
    background-image: url(../images/glare-effect.png);
    left: 50%;
    top: 0;
    margin-left: -30px;
}
.tablet-device.black-tablet::before {
    background: #3f3f3f;
    border: 2px solid #686868;
}
.tablet-device img {
    padding: 14.4% 12% 0;
    border-radius: 20px 20px 0 0;
    background: #f2f2f2;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.22),
        inset 0 1px 1px rgba(255, 255, 255, 0.78); /*border: 1px solid #EBEBEB; border-bottom: 0;*/
}
.tablet-device.black-tablet img {
    background: #000000;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.22),
        inset 0 1px 1px rgba(255, 255, 255, 0.78); /*border: 1px solid #525252;*/
}
.tablet-device.tb-left {
    -webkit-transform: scale(0.9) translateX(20px) translateY(200px);
    -ms-transform: scale(0.9) translateX(20px) translateY(200px);
    transform: scale(0.9) translateX(20px) translateY(200px);
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}
.tablet-device.tb-right {
    -webkit-transform: scale(0.9) translateX(-20px) translateY(200px);
    -ms-transform: scale(0.9) translateX(-20px) translateY(200px);
    transform: scale(0.9) translateX(-20px) translateY(200px);
    -webkit-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}
.tablet-device.tb-center {
    margin: -8.9% -14.9% 0;
    z-index: 1;
    -webkit-transform: translateY(200px);
    -ms-transform: translateY(200px);
    transform: translateY(200px);
}
.started .tablet-device.tb-left,
.touch .tablet-device.tb-left {
    -webkit-transform: scale(0.9) translateX(20px) translateY(0);
    -ms-transform: scale(0.9) translateX(20px) translateY(0);
    transform: scale(0.9) translateX(20px) translateY(0);
    opacity: 1;
}
.started .tablet-device.tb-right,
.touch .tablet-device.tb-right {
    -webkit-transform: scale(0.9) translateX(-20px) translateY(0);
    -ms-transform: scale(0.9) translateX(-20px) translateY(0);
    transform: scale(0.9) translateX(-20px) translateY(0);
    opacity: 1;
}
.started .tablet-device.tb-center,
.touch .tablet-device.tb-center {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

/*** SearcBox Element */
.elm-searchbox__form {
    border-radius: 4px;
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
    position: relative;
}
.elm-searchbox__input,
.elm-searchbox__submit {
    height: 50px;
    float: left;
    border: 0;
    vertical-align: middle;
    outline: none;
    margin: 0;
    top: 0;
}
.elm-searchbox__input {
    width: calc(100% - 130px);
    background: #fff;
    border-radius: 4px 0 0 4px;
    padding-left: 20px;
    -webkit-transition: padding-left 0.15s ease-out;
    transition: padding-left 0.15s ease-out;
}
.elm-searchbox__input:focus {
    padding-left: 30px;
}
.elm-searchbox__submit {
    width: 130px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
}
.elm-searchbox__submit:not(.glyphicon) {
    font-size: 14px;
    font-weight: 600;
}

/* Normal Style */
.elm-searchbox--normal .elm-searchbox__submit {
    color: #fff;
    background-color: #106ee9;
}
.elm-searchbox--normal .elm-searchbox__submit:hover {
    background-color: #000;
}

/* Normal with transparent button */
.elm-searchbox--normal2 .elm-searchbox__submit {
    color: #999;
    background-color: #fff;
    position: relative;
}
.elm-searchbox--normal2 .elm-searchbox__submit:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 86%;
    top: 7%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
}
.elm-searchbox--normal2 .elm-searchbox__submit:hover {
    color: #767676;
}
/* Transparent */
.elm-searchbox--transparent .elm-searchbox__input {
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-right: 0;
    background: rgba(255, 255, 255, 0.3);
    color: #fff;
}
.elm-searchbox--transparent .elm-searchbox__input::-webkit-input-placeholder {
    color: #fff;
}
.elm-searchbox--transparent .elm-searchbox__input:-moz-placeholder {
    color: #fff;
}
.elm-searchbox--transparent .elm-searchbox__input::-moz-placeholder {
    color: #fff;
}
.elm-searchbox--transparent .elm-searchbox__input:-ms-input-placeholder {
    color: #fff;
}
.elm-searchbox--transparent .elm-searchbox__submit {
    color: #fff;
    background-color: #106ee9;
}
.elm-searchbox--transparent .elm-searchbox__submit:hover {
    background-color: #000;
}
/* Transparent with transparent button */
.elm-searchbox--transparent2 .elm-searchbox__input {
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-right: 0;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
}
.elm-searchbox--transparent2 .elm-searchbox__input::-webkit-input-placeholder {
    color: #fff;
}
.elm-searchbox--transparent2 .elm-searchbox__input:-moz-placeholder {
    color: #fff;
}
.elm-searchbox--transparent2 .elm-searchbox__input::-moz-placeholder {
    color: #fff;
}
.elm-searchbox--transparent2 .elm-searchbox__input:-ms-input-placeholder {
    color: #fff;
}
.elm-searchbox--transparent2 .elm-searchbox__submit {
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-left: 0;
    position: relative;
    cursor: pointer;
}
.elm-searchbox--transparent2 .elm-searchbox__submit:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 86%;
    top: 7%;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
}
.elm-searchbox--transparent2 .elm-searchbox__submit:hover {
    color: rgba(255, 255, 255, 0.8);
}
/* Typing effect */
.elm-searchbox__input-text {
    max-width: 400px;
    position: absolute;
    display: block;
    top: 50%;
    left: 0;
    padding-left: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 14px;
    line-height: 1.2;
    color: #595959;
    overflow: hidden;
    white-space: nowrap;
    -webkit-animation: elm-searchbox__inputtext 4s steps(50, end);
    animation: elm-searchbox__inputtext 4s steps(50, end);
}
.elm-searchbox__input-text:after {
    content: ' |';
    -webkit-animation: blink 1s infinite;
    animation: blink 1s infinite;
}
.elm-searchbox__input:focus + .elm-searchbox__input-text {
    opacity: 0;
    visibility: hidden;
}
@-webkit-keyframes elm-searchbox__inputtext {
    from {
        max-width: 0;
    }
}
@keyframes elm-searchbox__inputtext {
    from {
        max-width: 0;
    }
}
@-webkit-keyframes blink {
    to {
        opacity: 0;
    }
}
@keyframes blink {
    to {
        opacity: 0;
    }
}
.elm-searchbox--transparent .elm-searchbox__input-text,
.elm-searchbox--transparent2 .elm-searchbox__input-text {
    color: #fff;
}

/** Action Box **/
.action_box {
    padding: 40px 30px;
    color: #fff;
    border-top: 2px solid #fff;
    border-bottom: 1px solid #fff;
    position: relative;
    background-color: #106ee9;
    clear: both;
}
.action_box h4.text {
    font-size: 1.7rem;
    line-height: 1.4;
    font-weight: 200;
    margin-bottom: 0;
}
.action_box .ac-subtitle {
    color: rgba(255, 255, 255, 0.6);
    line-height: 1.6;
}
.action_box:after,
.action_box:before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -14px;
    margin-left: -25%;
    border-radius: 3px;
    border: 8px solid transparent;
    border-top-width: 7px;
    border-top-color: #106ee9;
    z-index: 1;
}
.action_box[data-arrowpos='center']:after,
.action_box[data-arrowpos='center']:before {
    margin-left: -7px;
}
.action_box[data-arrowpos='right']:after,
.action_box[data-arrowpos='right']:before {
    margin-left: 25%;
}

/* Style 2 */
.action_box.style2 .action_box_content {
    display: block;
}
.action_box.style2 .ac-content-text,
.action_box.style2 .ac-buttons {
    display: block;
    text-align: center;
    padding-left: 0;
}
.action_box.style2 .ac-content-text {
    margin-bottom: 25px;
}
.action_box.style2 .ac-btn,
.action_box.style2 .ac-btn:last-child {
    display: inline-block;
}

/* Action Box style 3 */
.style3 .action_box_inner {
    background-color: #106ee9;
    position: relative;
    padding: 35px;
    border-radius: 6px;
    z-index: 1;
}
.style3 .action_box_inner:before {
    position: absolute;
    content: '';
    background-color: #106ee9;
    top: -6px;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    -webkit-transform: skewY(1.5deg);
    -ms-transform: skewY(1.5deg);
    transform: skewY(1.5deg);
    border-radius: 6px;
    transform-origin: right top;
}

.style3 .action_box_inner:after {
    position: absolute;
    content: '';
    background-color: rgba(0, 0, 0, 0.2);
    width: 90%;
    height: 30%;
    display: block;
    border-radius: 4px;
    -webkit-transform: skewY(-2deg);
    -ms-transform: skewY(-2deg);
    transform: skewY(-2deg);
    right: 5%;
    top: -20px;
    z-index: -1;
}
.action_box.style3 {
    padding: 0;
    border-radius: 6px;
}

.action_box.style3:before {
    position: absolute;
    content: '';
    background-color: #106ee9;
    width: 30px;
    height: 32px;
    display: block;
    bottom: -6px;
    left: 20%;
    border-radius: 4px;
    -webkit-transform: skewY(35deg);
    -ms-transform: skewY(35deg);
    transform: skewY(35deg);
}

.action_box.style3:after {
    display: none;
}
.action_box .ac-btn {
    margin: 0;
    display: inline;
}
.action_box .ac-btn:last-child {
    margin-left: 15px;
}

/*** Big Social Block Element */
.bigsocialblock {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}
.bigsocialblock__item {
    position: relative;
    overflow: hidden;
    height: 150px;
    padding: 25px;
    text-align: right;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-backface-visibility: hidden;
}
.bigsocialblock__link {
    display: block;
    width: 100%;
    height: 100%;
}
.bigsocialblock__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.bigsocialblock__bg a {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
}
.bigsocialblock__title {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    margin: 0;
    position: absolute;
    z-index: 1;
    right: 25px;
    bottom: 20px;
}
.bigsocialblock__count {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    right: 25px;
    bottom: 40px;
}
.bigsocialblock__follow {
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    position: absolute;
    right: 25px;
    bottom: 20px;
}
.bigsocialblock__count,
.bigsocialblock__follow {
    display: block;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.2s ease-out, visibility 0.2s ease-out,
        -webkit-transform 0.2s ease-out;
    transition: opacity 0.2s ease-out, visibility 0.2s ease-out,
        transform 0.2s ease-out;
    -webkit-backface-visibility: hidden;
}
.bigsocialblock--style1 .bigsocialblock__item:hover .bigsocialblock__count,
.bigsocialblock--style1 .bigsocialblock__item:hover .bigsocialblock__follow {
    -webkit-transform: scale(0.6);
    -ms-transform: scale(0.6);
    transform: scale(0.6);
}
.bigsocialblock__social-icon {
    position: absolute;
    color: #fff;
    z-index: 1;
    font-size: 80px;
    border: solid 5px #fff;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    line-height: 140px;
    left: -21px;
    bottom: -30px;
    text-align: center;
    opacity: 0.5;
    -webkit-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
}
/* White */
.bsb--theme-light .bigsocialblock__title,
.bigsocialblock__item:hover .bigsocialblock__title,
.bsb--theme-light .bigsocialblock__count,
.bigsocialblock__item:hover .bigsocialblock__count,
.bsb--theme-light .bigsocialblock__follow,
.bigsocialblock__item:hover .bigsocialblock__follow {
    color: #fff;
}
.bsb--theme-light .bigsocialblock__social-icon,
.bigsocialblock__item:hover .bigsocialblock__social-icon {
    color: #fff;
    border-color: #fff;
}
/* Dark */
.bsb--theme-dark .bigsocialblock__title,
.bsb--theme-dark .bigsocialblock__count,
.bsb--theme-dark .bigsocialblock__follow {
    color: #8a8a8a;
}
.bsb--theme-dark .bigsocialblock__social-icon {
    color: #8a8a8a;
    border-color: #8a8a8a;
}
/* Style 1 */
.bigsocialblock__item:hover {
    box-shadow: 0 6px 22px 4px rgba(0, 0, 0, 0.4);
    z-index: 1;
}
.bigsocialblock--style1 .bigsocialblock__item:hover .bigsocialblock__title {
    bottom: 70px;
}
.bigsocialblock--style1 .bigsocialblock__item:hover .bigsocialblock__count,
.bigsocialblock--style1 .bigsocialblock__item:hover .bigsocialblock__follow {
    opacity: 1;
    visibility: visible;
    z-index: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.bigsocialblock__item:hover .bigsocialblock__social-icon {
    opacity: 1;
}
.bigsocialblock.type-chover .bigsocialblock__bg {
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
}
.bigsocialblock.type-chover .bigsocialblock__item:hover .bigsocialblock__bg {
    opacity: 1;
}

/* Style 2 */
.bigsocialblock--style2 .bigsocialblock__social-icon {
    font-size: 200px;
    width: 400px;
    height: 400px;
    line-height: 400px;
    left: -130px;
    bottom: -140px;
    border: solid 15px #fff;
}
.bigsocialblock--style2 .bigsocialblock__item:hover {
    box-shadow: none;
}
.bigsocialblock--style2 .bigsocialblock__count {
    font-size: 30px;
    line-height: 30px;
    display: block;
    position: relative;
    text-align: right;
    margin-bottom: 10px;
    z-index: 1;
    bottom: auto;
    right: auto;
}
.bigsocialblock--style2 .bigsocialblock__follow {
    color: #fff;
    font-weight: 400;
    display: block;
    position: relative;
    text-align: right;
    bottom: auto;
    right: auto;
}
.bigsocialblock--style2 .bigsocialblock__item:hover .bigsocialblock__count,
.bigsocialblock--style2 .bigsocialblock__item:hover .bigsocialblock__follow {
    opacity: 1;
    visibility: visible;
}

/* NO FLEXBOX */
.no-flexbox .bigsocialblock {
    display: table;
    table-layout: fixed;
    width: 100%;
}
.no-flexbox .bigsocialblock__item {
    display: table-cell;
    position: relative;
    overflow: hidden;
    height: 150px;
    padding: 25px;
    text-align: right;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
/* End big social element */

/* Circle Title text box */
.circle-text-box .wpk-circle-span {
    font-size: 12px;
    font-weight: 300;
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    position: relative;
    color: #ffffff;
    -webkit-transition: all 0.2s cubic-bezier(0.68, 0, 0.265, 1);
    transition: all 0.2s cubic-bezier(0.68, 0, 0.265, 1);
}
.circle-text-box .wpk-circle-span > span {
    position: relative;
    z-index: 1;
}
.circle-text-box .wpk-circle-span:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    background-color: #106ee9;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    -webkit-transition: all 0.2s cubic-bezier(0.68, 0, 0.265, 1);
    transition: all 0.2s cubic-bezier(0.68, 0, 0.265, 1);
}
.circle-text-box .wpk-circle-span {
    float: left;
    margin-right: 20px;
}
.circle-text-box .wpk-circle-title {
    height: 55px;
    margin: 0 0 30px;
    font-size: 20px;
    font-weight: 600;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.3);
    color: #106ee9;
}
.circle-text-box .wpk-circle-span + .wpk-circle-title {
    overflow: hidden;
    line-height: 60px;
}
.circle-text-box.style1:hover .wpk-circle-span:after {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}
/* Circle Title text box - Style 2 */
.circle-text-box.style2 .wpk-circle-span::before {
    content: '';
    position: absolute;
    right: 5px;
    bottom: 5px;
    background: #106ee9;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    -webkit-transition: all 0.2s cubic-bezier(0.68, 0, 0.265, 1);
    transition: all 0.2s cubic-bezier(0.68, 0, 0.265, 1);
}
.circle-text-box.style2:hover .wpk-circle-span {
}
.circle-text-box.style2:hover .wpk-circle-span::before {
    -webkit-transform: translate3d(5px, 5px, 0);
    transform: translate3d(5px, 5px, 0);
}
.circle-text-box.style2:hover .wpk-circle-span::after {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}
/* Circle Title text box - Style 3 */
.circle-text-box.style3 .wpk-circle-span {
    font-size: 12px;
    font-weight: 300;
    display: inline-block;
    background: #106ee9;
    color: #ffffff;
    width: auto;
    height: 45px;
    line-height: 45px;
    text-align: right;
    z-index: 1;
    border-radius: 3px;
    padding-left: 25px;
    margin-left: -25px;
    padding-right: 10px;
}
.circle-text-box .wpk-circle-span > span {
    position: relative;
    z-index: 1;
}
.circle-text-box.style3 .wpk-circle-span:after {
    display: none;
}
.circle-text-box.style3 .wpk-circle-title {
    height: 42px;
    margin: 0 0 35px;
}
.circle-text-box.style3 .wpk-circle-span + .wpk-circle-title {
    line-height: 45px;
}
.circle-text-box.style3:hover .wpk-circle-span {
    padding-left: 35px;
    margin-left: -35px;
}

/* Hover Box Style 1 */
.hover-box {
    display: block;
    padding: 15px;
    background: #969696;
    border: 1px solid rgba(0, 0, 0, 0.4);
    color: #fff;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    margin-bottom: 20px;
    overflow: hidden;
}
.hover-box:hover {
    background-color: #106ee9 !important;
    color: #fff;
}
.hover-box.centered {
    text-align: center;
}
.hover-box > img {
    float: left;
    margin: 15px 15px 8px 10px;
}
.hover-box.centered > img {
    float: none;
}
.hover-box > h3 {
    line-height: 1.1;
    margin-top: 0;
}
.hover-box > h4 {
    line-height: 1.1;
    margin-top: 0;
}
.hover-box > p {
    margin-bottom: 0;
    line-height: 1.1;
}

/* Hover Box Style 2 & 3 */
.hover-box.hover-box-2,
.hover-box.hover-box-3 {
    text-shadow: none;
    border: none;
    padding: 25px;
    margin-bottom: 0;
    min-height: 180px;
    position: relative;
}
.hover-box.hover-box-2:hover,
.hover-box.hover-box-3:hover {
    box-shadow: 0 10px 2px -6px rgba(0, 0, 0, 0.8);
    -webkit-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    transform: translateY(-4px);
}
.hover-box.hover-box-3 img {
    display: none;
}
.hover-box.hover-box-2 > h3,
.hover-box.hover-box-3 > h3 {
    margin-bottom: 20px;
}
.hover-box.hover-box-2 > h4,
.hover-box.hover-box-3 > h4 {
    font-weight: bold;
}
.hover-box.hover-box-2 .hb-img {
    width: 110px;
    margin: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
    float: none;
    max-width: none;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.hover-box.hover-box-2 .hb-img.rb-right {
    right: 0;
}
.hover-box.hover-box-2 > p,
.hover-box.hover-box-3 > p {
    line-height: 1.4;
}
.hover-box.hover-box-2 > p:after,
.hover-box.hover-box-3 > p:after {
    content: '';
    height: 3px;
    width: 50px;
    display: block;
    background: #fff;
    margin-top: 22px;
}
.hover-box.hover-box-2:hover .hb-img {
    opacity: 0;
    right: -100%;
}
.hover-box.hover-box-2 .hb-circle {
    background: url(../images/circle-arr-hover.svg) no-repeat left top;
    width: 187px;
    height: 187px;
    display: block;
    position: absolute;
    bottom: -40px;
    right: -40px;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.hover-box.hover-box-2:hover .hb-circle {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}

/*** Icon Box - Default element */
.kl-iconbox__title {
    font-family: Open Sans;
    font-size: 1.4rem;
    line-height: 1.7;
    font-weight: 600;
    font-style: normal;
    color: #106ee9;
}
.kl-iconbox {
    margin-bottom: 30px;
}
.kl-iconbox__inner {
}
.kl-iconbox__icon-wrapper,
.kl-iconbox__title-wrapper,
.kl-iconbox__desc-wrapper,
.kl-iconbox__cta-wrapper {
    margin-top: 0;
    margin-bottom: 15px;
}
.kl-iconbox__content-wrapper {
    overflow: hidden;
}
.kl-iconbox__link {
}
.kl-iconbox__title,
.kl-iconbox__desc {
    margin: 0;
}
.kl-iconbox__desc {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-size: 0.95rem;
    line-height: 1.7;
    font-weight: 400;
    font-style: normal;
    color: #3e3f3d;
}
.kl-iconbox__icon {
    line-height: 1;
    font-size: 42px;
    -webkit-transition: color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.kl-iconbox__icon.custom-size {
    font-size: 14px !important;
}
.kl-iconbox--theme-light,
.kl-iconbox--theme-light a {
    color: #fff;
}
.kl-iconbox--theme-dark,
.kl-iconbox--theme-dark a {
    color: #323232;
}
/* Floated Icons  */
.kl-iconbox--fleft .kl-iconbox__icon-wrapper {
    float: left;
    margin-right: 20px;
}
.kl-iconbox--fright .kl-iconbox__icon-wrapper {
    float: right;
    margin-left: 20px;
}
/* Shaped Icons - Resized */
.kl-iconbox--sh .kl-iconbox__icon-wrapper {
    position: relative;
}
.kl-iconbox--align-left.kl-iconbox--sh span.kl-iconbox__icon,
.kl-iconbox--align-right.kl-iconbox--sh span.kl-iconbox__icon {
    text-align: center;
}
/* Shaped icons - Square */
.kl-iconbox--sh-square span.kl-iconbox__icon {
    display: inline-block;
    border-radius: 3px;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    padding: 22px;
    background-color: #3498db;
    color: #fff;
}
.kl-iconbox--sh-square span.kl-iconbox__icon:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    z-index: -1;
    border-radius: 3px;
    -webkit-transition: width 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
        left 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
        right 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: width 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
        left 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
        right 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.kl-iconbox--sh-square:hover span.kl-iconbox__icon:after {
    width: 120%;
    left: -10%;
    background-color: #2980b9;
}
.kl-iconbox--align-left.kl-iconbox--sh-square:hover span.kl-iconbox__icon:after,
.kl-iconbox--fleft.kl-iconbox--sh-square:hover span.kl-iconbox__icon:after {
    width: 120%;
    left: -20%;
}
.kl-iconbox--align-right.kl-iconbox--sh-square span.kl-iconbox__icon:after,
.kl-iconbox--fright.kl-iconbox--sh-square span.kl-iconbox__icon:after {
    left: auto;
    right: 0;
}
.kl-iconbox--align-right.kl-iconbox--sh-square:hover
    span.kl-iconbox__icon:after,
.kl-iconbox--fright.kl-iconbox--sh-square:hover span.kl-iconbox__icon:after {
    width: 120%;
    left: auto;
    right: -20%;
}
/* Shaped icons - Circle */
.kl-iconbox--sh-circle span.kl-iconbox__icon {
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    padding: 22px;
    background-color: #e74c3c;
    color: #fff;
    font-size: 46px;
}
.kl-iconbox--sh-circle span.kl-iconbox__icon:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 50%;
    opacity: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: -webkit-transform 0.2s
            cubic-bezier(0.215, 0.61, 0.355, 1),
        opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
        opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.kl-iconbox--sh-circle:hover span.kl-iconbox__icon:after {
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);
    opacity: 1;
    background-color: #c0392b;
}

.kl-iconbox.stage .kl-iconbox__icon {
    color: #d1d1d1;
}
.kl-iconbox.stage:hover .kl-iconbox__icon {
    color: #106ee9;
}

img.kl-iconbox__icon.agency-icons {
    max-width: 150px;
    width: 100%;
}

.default-icon-style .kl-iconbox__icon {
    font-size: 16px;
}
.default-icon-style .kl-iconbox__title {
    font-size: 17px;
    line-height: 25px;
}

/*** GRID ICON BOXES */
.grid-ibx__item {
    float: left;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    -webkit-backface-visibility: hidden;
    min-height: 280px;
    background-color: #fff;
}
.grid-ibx__item[onclick] {
    cursor: pointer;
}
.grid-ibx--cols-1 .grid-ibx__item {
    width: 100%;
}
.grid-ibx--cols-2 .grid-ibx__item {
    width: 50%;
}
.grid-ibx--cols-3 .grid-ibx__item {
    width: 33.3333%;
}
.grid-ibx--cols-4 .grid-ibx__item {
    width: 25%;
}
.grid-ibx--cols-5 .grid-ibx__item {
    width: 20%;
}
.grid-ibx__item-inner {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    padding: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.grid-ibx__link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.grid-ibx__title-wrp,
.grid-ibx__icon-wrp,
.grid-ibx__desc-wrp {
    margin-top: 0;
    margin-bottom: 20px;
    backface-visibility: hidden;
    -webkit-filter: blur(0);
}
.grid-ibx__title,
.grid-ibx__desc {
    margin: 0;
}
.grid-ibx__icon {
    font-size: 24px;
    line-height: 1;
    -webkit-transition: color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    color: #e2e2e2;
}
.grid-ibx__item:hover .grid-ibx__icon {
    color: #106ee9;
}

/* GridIconBox Styles - Lined Full */
.grid-ibx--style-lined-full {
    border-width: 1px 0 0 1px;
}
.grid-ibx--style-lined-full .grid-ibx__item {
    border-width: 0 1px 1px 0;
}
.grid-ibx--style-lined-full,
.grid-ibx--style-lined-full .grid-ibx__item {
    border-color: rgba(0, 0, 0, 0.1);
    border-style: solid;
}

/* GridIconBox Styles - Lined Center */
.grid-ibx--style-lined-center,
.grid-ibx--style-lined-center .grid-ibx__item {
    border-color: rgba(0, 0, 0, 0.1);
    border-style: solid;
}
.grid-ibx--style-lined-center .grid-ibx__item {
    border-width: 0 1px 1px 0;
}
.grid-ibx--style-lined-center.grid-ibx--cols-1 .grid-ibx__item:nth-child(1) {
    border-right-width: 0;
}
.grid-ibx--style-lined-center.grid-ibx--cols-2 .grid-ibx__item:nth-child(2) {
    border-right-width: 0;
}
.grid-ibx--style-lined-center.grid-ibx--cols-3 .grid-ibx__item:nth-child(3) {
    border-right-width: 0;
}
.grid-ibx--style-lined-center.grid-ibx--cols-4 .grid-ibx__item:nth-child(4) {
    border-right-width: 0;
}
.grid-ibx--style-lined-center.grid-ibx--cols-5 .grid-ibx__item:nth-child(5) {
    border-right-width: 0;
}
.grid-ibx--style-lined-center .grid-ibx__row:last-child .grid-ibx__item {
    border-bottom-width: 0;
}
.grid-ibx--style-lined-center {
    border: 0;
}

/* GridIconBox Styles - Gradient */
.grid-ibx--style-lined-gradient .grid-ibx__item {
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;
    position: relative;
}
.grid-ibx--style-lined-gradient.grid-ibx--cols-1 .grid-ibx__item {
    width: -webkit-calc(100% - 30px);
    width: calc(100% - 30px);
}
.grid-ibx--style-lined-gradient.grid-ibx--cols-2 .grid-ibx__item {
    width: -webkit-calc(50% - 30px);
    width: calc(50% - 30px);
}
.grid-ibx--style-lined-gradient.grid-ibx--cols-3 .grid-ibx__item {
    width: -webkit-calc(33.3333% - 30px);
    width: calc(33.3333% - 30px);
}
.grid-ibx--style-lined-gradient.grid-ibx--cols-4 .grid-ibx__item {
    width: -webkit-calc(25% - 30px);
    width: calc(25% - 30px);
}
.grid-ibx--style-lined-gradient.grid-ibx--cols-5 .grid-ibx__item {
    width: -webkit-calc(20% - 30px);
    width: calc(20% - 30px);
}
.grid-ibx--style-lined-gradient .grid-ibx__ghelper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 0;
    border-radius: 4px 4px 0 0;
}
.grid-ibx--style-lined-gradient .grid-ibx__item:before,
.grid-ibx--style-lined-gradient .grid-ibx__item:after {
    content: '';
    position: absolute;
    top: 4px;
    width: 1px;
    height: 100%;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(60%, rgba(0, 0, 0, 0.2)),
        color-stop(100%, transparent)
    );
    background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0.2) 60%,
        transparent 100%
    );
    background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0.2) 60%,
        transparent 100%
    );
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.2) 60%,
        transparent 100%
    );
}
.grid-ibx--style-lined-gradient .grid-ibx__item:before {
    left: 0;
}
.grid-ibx--style-lined-gradient .grid-ibx__item:after {
    right: 0;
}
.grid-ibx--style-lined-gradient .grid-ibx__item:hover .grid-ibx__ghelper {
    border-color: #106ee9;
    border-bottom: 0;
}
.grid-ibx--style-lined-gradient .grid-ibx__item:hover:before,
.grid-ibx--style-lined-gradient .grid-ibx__item:hover:after {
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, #106ee9),
        color-stop(100%, transparent)
    );
    background: -webkit-linear-gradient(top, #106ee9 0%, transparent 100%);
    background: -webkit-linear-gradient(top, #106ee9 0%, transparent 100%);
    background: linear-gradient(to bottom, #106ee9 0%, transparent 100%);
}

/* Hover Effects - Shadow */
.grid-ibx--hover-shadow .grid-ibx__item {
    -webkit-transition: box-shadow 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: box-shadow 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.grid-ibx--hover-shadow .grid-ibx__item:hover {
    box-shadow: 0 40px 120px -20px rgba(0, 0, 0, 0.6);
    z-index: 1;
}
.grid-ibx--hover-shadow.grid-ibx--style-lined-gradient .grid-ibx__item:hover {
    box-shadow: 0 -10px 30px -10px rgba(0, 0, 0, 0.29);
}

/* Hover Effects - Scale */
.grid-ibx--hover-scale .grid-ibx__item {
    -webkit-transition: -webkit-transform 0.25s
            cubic-bezier(0.215, 0.61, 0.355, 1),
        background 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1),
        background 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.grid-ibx--hover-scale .grid-ibx__item:hover {
    -webkit-transform: scale3d(1.05, 1.05, 1);
    -ms-transform: scale3d(1.05, 1.05, 1);
    transform: scale3d(1.05, 1.05, 1);
    z-index: 1;
    border-width: 1px !important;
    background: #fff;
    border-radius: 3px;
}
.grid-ibx--hover-scale.grid-ibx--style-lined-gradient .grid-ibx__item:hover {
    background: none;
}

/* Hover Effects - Shadow & Scale */
.grid-ibx--hover-shadowscale .grid-ibx__item {
    -webkit-transition: -webkit-transform 0.25s
            cubic-bezier(0.215, 0.61, 0.355, 1),
        background 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
        box-shadow 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1),
        background 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
        box-shadow 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.grid-ibx--hover-shadowscale .grid-ibx__item:hover {
    -webkit-transform: scale3d(1.06, 1.06, 1);
    -ms-transform: scale3d(1.06, 1.06, 1);
    transform: scale3d(1.06, 1.06, 1);
    z-index: 1;
    border-width: 1px !important;
    background: #fff;
    box-shadow: 0 40px 120px -20px rgba(0, 0, 0, 0.6);
    border-radius: 2px;
}
.grid-ibx--hover-shadowscale.grid-ibx--style-lined-gradient
    .grid-ibx__item:hover {
    box-shadow: 0 -10px 30px -10px rgba(0, 0, 0, 0.29);
    background: none;
}
/*** end GRID ICON BOXES */

/* Process steps */
.process_steps {
    overflow: hidden;
    border-radius: 2px;
    margin-bottom: 35px;
    width: 100%;
    min-height: 0;
}
/* Process steps style1 */

.process_steps--style1 {
    margin-right: 0;
}
.process_steps--style1 .process_steps__step {
    padding: 20px 10px;
    color: #fff;
    position: relative;
    text-align: center;
    min-height: 240px;
}
.process_steps--style1 .process_steps__step:first-child {
    border-radius: 3px 0 0 3px;
}
.process_steps--style1 .process_steps__step:last-child {
    border-radius: 0 3px 3px 0;
}
.process_steps--style1 .process_steps__step:after {
    content: '';
    position: absolute;
    top: 15px;
    right: -14px;
    border: 7px solid transparent;
    z-index: 1;
}
.process_steps--style1 .process_steps__intro {
    background: #106ee9;
    text-align: left;
    padding: 20px;
}
.process_steps--style1 .process_steps__intro:after {
    border-left-color: #106ee9;
}
.process_steps--style1 .process_steps__intro-title {
    margin-top: 0;
    line-height: 1.2;
    font-weight: 500;
    font-size: 24px;
}
.process_steps--style1 .process_steps__intro-title strong {
    font-size: 120%;
    display: block;
}
.process_steps--style1 .process_steps__intro-link {
    font-size: 11px;
    font-weight: 700;
    color: #eee;
}
.process_steps--style1 .process_steps__intro-link:hover {
    color: #fff;
}
.process_steps--style1 .process_steps__step-desc {
    margin-bottom: 0;
}
.process_steps--style1 .process_steps__step-icon {
    margin: 20px 0 15px;
    min-height: 50px;
    line-height: 50px;
    font-size: 56px;
}
.process_steps--style1 .process_steps__step-typeimg,
.process_steps--style1 .process_steps__step-typeimg img {
    max-height: 90px;
    display: inline-block;
}
.process_steps--style1 [class*='col-']:first-child {
    overflow: visible;
}
.process_steps--style1 [class*='col-'] {
    overflow: hidden;
}
.process_steps--style1 [class*='col-']:nth-child(2) {
    background-color: #5a5a5a;
}
.process_steps--style1 [class*='col-']:nth-child(2):after {
    border-left-color: #5a5a5a;
}
.process_steps--style1 [class*='col-']:nth-child(3) {
    background-color: #4a4a4a;
}
.process_steps--style1 [class*='col-']:nth-child(3):after {
    border-left-color: #4a4a4a;
}
.process_steps--style1 [class*='col-']:nth-child(4) {
    background-color: #3e3e3e;
}
.process_steps--style1 [class*='col-']:nth-child(4):after {
    border-left-color: #3e3e3e;
}
.process_steps--style1 [class*='col-']:nth-child(5) {
    background-color: #383838;
}
.process_steps--style1 [class*='col-']:nth-child(5):after {
    border-left-color: #383838;
}
.process_steps--style1 [class*='col-']:nth-child(6) {
    background-color: #303030;
}
.process_steps--style1 [class*='col-']:nth-child(6):after {
    border-left-color: #303030;
}
.process_steps--style1 [class*='col-']:nth-child(7) {
    background-color: #252525;
}
.process_steps--style1 [class*='col-']:nth-child(7):after {
    border-left-color: #252525;
}
.process_steps--style1 [class*='col-']:last-child:after {
    border: 0;
}

/* Process steps style2 */
.process_steps--style2 {
    overflow: hidden;
    padding: 11px 0;
}
.container .process_steps--style2 {
    overflow: visible;
}
.process_steps--style2 .process_steps__container,
.process_steps--style2 .process_steps__height {
    min-height: 235px;
}
.process_steps--style2 .process_steps__container {
    float: left;
    width: 66%;
    border-radius: 5px;
    position: relative;
    min-height: 310px;
    padding-right: calc(((100vw - 1170px) / 2) + 15px);
}
.container .process_steps--style2 .process_steps__container {
    padding-right: 0;
}
.process_steps--style2 .process_steps__container:before {
    content: '';
    width: 102%;
    height: 108%;
    position: absolute;
    left: -30px;
    -webkit-transform: skewX(-10deg) translate3d(0, 0, 0);
    -ms-transform: skewX(-10deg) translate3d(0, 0, 0);
    transform: skewX(-10deg) translate3d(0, 0, 0);
    border: solid 1px #e0e0e0;
    top: -4%;
    padding-right: calc(((100vw - 1170px) / 2) + 60px);
    box-sizing: content-box;
    border-radius: 5px;
}
.process_steps--style2 .process_steps__container:after {
    content: '';
    width: 10px;
    height: 106%;
    position: absolute;
    left: -40px;
    -webkit-transform: skewX(-10deg) translate3d(0, 0, 0);
    -ms-transform: skewX(-10deg) translate3d(0, 0, 0);
    transform: skewX(-10deg) translate3d(0, 0, 0);
    z-index: 1;
    top: -3%;
    background: -webkit-gradient(
        100% 50%,
        ellipse,
        rgba(0, 0, 0, 0.5),
        transparent 70%
    );
    background: -webkit-radial-gradient(
        100% 50%,
        ellipse,
        rgba(0, 0, 0, 0.5),
        transparent 70%
    );
    background: radial-gradient(
        ellipse at 100% 50%,
        rgba(0, 0, 0, 0.5),
        transparent 70%
    );
    background: -webkit-radial-gradient(
        100% 50%,
        100% 70%,
        rgba(0, 0, 0, 0.5) 0%,
        transparent 70%
    );
}
.process_steps--style2.kl-bgc-light .process_steps__container:before {
    background: #fff;
}
.process_steps--style2.kl-bgc-gray .process_steps__container:before {
    background: #f1f1f1;
    border: solid 1px #f1f1f1;
}
.process_steps--style2 .process_steps__step {
    padding: 20px;
    color: #fff;
    position: relative;
    text-align: center;
    overflow: hidden;
}
.process_steps--style2 .process_steps__container .process_steps__step:after {
    content: '';
    width: 10px;
    height: 70%;
    background: url(../images/step-separator.svg) no-repeat left top;
    left: 100%;
    top: 15%;
    -webkit-transform: skew(-10deg);
    -ms-transform: skew(-10deg);
    transform: skew(-10deg);
    opacity: 0.05;
    position: absolute;
}
.process_steps--style2
    .process_steps__container
    .process_steps__step:last-child:after {
    display: none;
}

.process_steps--style2 .process_steps__step-title {
    color: #333333;
    font-weight: bold;
    font-size: 20px;
}
.process_steps--style2 .process_steps__step-desc {
    color: #888888;
    margin-bottom: 0;
}
.process_steps--style2 .process_steps__step-icon {
    margin: 20px 0 15px;
    min-height: 50px;
    line-height: 50px;
    font-size: 48px;
}
.process_steps--style2 .process_steps__step-typeimg,
.process_steps--style2
    .process_steps__step-typeimg
    .process_steps__step-icon-src {
    max-height: 90px;
    display: inline-block;
    margin: 0;
}
.process_steps--style2 .process_steps__intro {
    background: #106ee9;
    text-align: left;
    float: left;
    width: 34%;
    padding: 30px 40px 30px 30px;
    padding-left: calc(((100vw - 1170px) / 2) + 15px);
}
.container .process_steps--style2 .process_steps__intro {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-left: 30px;
}
.process_steps--style2 .process_steps__intro:after {
    border-left-color: #106ee9;
}
.process_steps--style2 .process_steps__intro-title {
    margin-top: 0;
    line-height: 1.2;
    font-weight: 500;
    position: relative;
    padding-bottom: 10px;
}
.process_steps--style2 .process_steps__intro-title::before {
    content: '';
    height: 3px;
    width: 30px;
    background: #fff;
    position: absolute;
    display: inline-block;
    bottom: 0;
}
.process_steps--style2 .process_steps__intro-title strong {
    font-size: 120%;
    display: block;
}
.process_steps--style2 .process_steps__intro-link {
    font-size: 11px;
    font-weight: 700;
    color: #eee;
    display: inline-block;
}
.process_steps--style2 .process_steps__intro-link:hover {
    color: #fff;
}

/* steps boxes #5 style 1 */
.process-list {
    position: relative;
}
.process-list .processitems {
    padding: 0;
    list-style: none;
    position: relative;
}
.process-list .processitems li {
    text-align: center;
    position: relative;
    z-index: 1;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    float: left;
    width: -webkit-calc(20% - 20px);
    width: 20%;
}
.process-list .processitems li .ico-wrapper {
    margin-bottom: 30px;
}
.process-list .processitems li .ico-wrapper > span {
    font-size: 55px;
    color: #45474d;
    display: inline-block;
    width: 120px;
    height: 120px;
    text-align: center;
    position: relative;
    vertical-align: middle;
    background: #f2f3f4;
    border-radius: 50%;
    line-height: 120px;
}
.process-list .processitems li .ico-wrapper > span::after {
    opacity: 0;
    visibility: hidden;
    content: '';
    position: absolute;
    width: 100%;
    top: 60px;
    height: 2px;
    background: #f2f3f4;
    left: 100%;
}
.process-list .processitems li.lined .ico-wrapper > span::after {
    opacity: 1;
    visibility: visible;
}
.process-list .processitems li.last .ico-wrapper > span::after {
    display: none;
}
.process-list .processitems li h4 {
    font-size: 20px;
}
.process-list .processitems li p {
    color: #888;
}
.process-list .processitems li:hover .ico-wrapper > span {
    color: #fff;
    background: #106ee9;
}

/* Animations for Process Icons */
.process_steps__step:hover .process_steps__step-icon-src[data-animation] {
    display: inline-block;
}

.process_steps__step:hover
    .process_steps__step-icon-src[data-animation='tada'] {
    -webkit-animation: tada ease-in-out 1 normal 800ms;
    animation: tada ease-in-out 1 normal 500ms;
}
.process_steps__step:hover
    .process_steps__step-icon-src[data-animation='pulse'] {
    -webkit-animation: pulse ease-in-out 1 normal 500ms;
    animation: pulse ease-in-out 1 normal 500ms;
}
.process_steps__step:hover
    .process_steps__step-icon-src[data-animation='fadeOutRightBig'] {
    -webkit-animation: fadeOutRightBig ease-out 1 normal 1s;
    animation: fadeOutRightBig ease-out 1 normal 1s;
}
/* Pulse */
.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
}
@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
    }
    33% {
        -webkit-transform: scale(0.9);
    }
    66% {
        -webkit-transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
    }
}
@keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    33% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    66% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
/* Tada */
.tada {
    -webkit-animation-name: tada;
    animation-name: tada;
}
@-webkit-keyframes tada {
    0% {
        -webkit-transform: scale(1);
    }
    10%,
    20% {
        -webkit-transform: scale(0.9) rotate(-3deg);
    }
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale(1.1) rotate(3deg);
    }
    40%,
    60%,
    80% {
        -webkit-transform: scale(1.1) rotate(-3deg);
    }
    100% {
        -webkit-transform: scale(1) rotate(0);
    }
}
@keyframes tada {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    10%,
    20% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg);
    }
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }
    40%,
    60%,
    80% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }
    100% {
        -webkit-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
    }
}
/* Fadeout right big */
.fadeOutRightBig {
    -webkit-animation-name: fadeOutRightBig;
    animation-name: fadeOutRightBig;
}
@-webkit-keyframes fadeOutRightBig {
    0% {
        -webkit-transform: translate(2px, 1px);
    }
    3% {
        -webkit-transform: translate(-1px, -2px);
    }
    6% {
        -webkit-transform: translate(-3px, 0);
    }
    8% {
        -webkit-transform: translate(0, 2px);
    }
    10% {
        -webkit-transform: translate(1px, -1px);
    }
    20% {
        -webkit-transform: translate(20px, -20px);
    }
    100% {
        -webkit-transform: translate(120px, -120px);
    }
}
@keyframes fadeOutRightBig {
    0% {
        -webkit-transform: translate(2px, 1px);
        transform: translate(2px, 1px);
    }
    3% {
        -webkit-transform: translate(-1px, -2px);
        transform: translate(-1px, -2px);
    }
    6% {
        -webkit-transform: translate(-3px, 0);
        transform: translate(-3px, 0);
    }
    8% {
        -webkit-transform: translate(0, 2px);
        transform: translate(0, 2px);
    }
    10% {
        -webkit-transform: translate(1px, -1px);
        transform: translate(1px, -1px);
    }
    20% {
        -webkit-transform: translate(20px, -20px);
        transform: translate(20px, -20px);
    }
    100% {
        -webkit-transform: translate(120px, -120px);
        transform: translate(120px, -120px);
    }
}

/* Process Steps 4 */
.step-boxes-4 {
    display: table;
    table-layout: fixed;
    width: 100%;
}
.process_box4 {
    display: table-cell;
    text-align: center;
    position: relative;
}

.process_box4 .stp_title {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
}
.process_box4 .number {
    display: block;
    width: 80px;
    height: 80px;
    position: absolute;
    top: -40px;
    left: 50%;
    margin-left: -40px;
}
.process_box4 .number:before {
    content: '';
    background: #106ee9;
    border-radius: 50%;
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 50%;
    margin-left: -10px;
    transition: all 0.2s ease-out;
}
.process_box4:hover .number:before {
    width: 80px;
    height: 80px;
    margin-left: -40px;
    margin-top: -40px;
}
.process_box4 .number span {
    visibility: hidden;
    opacity: 0;
    line-height: 80px;
    position: relative;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    transition: opacity 0.2s ease-out 0.1s, font-size 0.2s ease-out 0.1s,
        visibility 0.2s ease-out 0.1s;
    height: 80px;
    width: 80px;
    display: block;
}
.process_box4:hover .number span {
    visibility: visible;
    opacity: 1;
    font-size: 30px;
}

.pb__line {
    display: block;
    height: 1px;
    width: 100%;
    margin-left: -10px;
    background: #d8d8d8;
    margin: 50px 0;
    position: relative;
}

.process_box4 .content {
    color: #a3a3a3;
    padding: 0 10px;
}

a.step_link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

/* Goboxes (stepsbox2) */
.gobox {
    color: #535353;
    padding: 25px;
    min-height: 100px;
    position: relative;
    line-height: 1.6;
    margin-bottom: 25px;
    border-radius: 5px;
    background-color: #ededed;
}
.gobox-content {
    z-index: 1;
    position: relative;
}
.gobox-content p {
    font-size: 0.9rem;
}
.gobox-content p:last-child {
    margin-bottom: 0;
}
.gobox h4 {
    margin-top: 0;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
}
.gobox:before,
.gobox:after {
    content: '';
    position: absolute;
    top: 50%;
}
.gobox:before {
    margin-top: 0;
    top: 0;
    left: -5px;
    background-color: #ededed;
    width: 40%;
    height: 100%;
    border-radius: 5px;
    -webkit-transform: skewX(-12deg);
    -ms-transform: skewX(-12deg);
    transform: skewX(-12deg);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
}
.gobox:after {
    margin-top: 0;
    top: 0;
    right: -5px;
    background-color: #ededed;
    width: 40%;
    height: 100%;
    border-radius: 5px;
    -webkit-transform: skewX(-12deg);
    -ms-transform: skewX(-12deg);
    transform: skewX(-12deg);
    -webkit-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    transform-origin: bottom right;
}
.gobox.gobox-first:before,
.gobox.gobox-last:after {
    display: none;
}
.gobox.ok:before,
.gobox.ok:after,
.gobox.ok {
    background-color: #106ee9;
    font-size: 1rem;
    color: #fff;
}
.gobox.ok h4 {
    color: #fff;
}
.gobox.ok > .far {
    font-size: 42px;
    color: rgba(255, 255, 255, 0.6);
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 0px;
}
.gobox.ok > .far + .gobox-content {
    overflow: hidden;
    margin-left: 30px;
}

/* info text box */
.info-text {
    background: #767676;
    border-radius: 2px;
    color: #fff;
    font-size: 1.2rem;
    line-height: 1.4;
    font-weight: 300;
    padding: 25px 30px;
    position: relative;
    box-shadow: 3px 3px 0 white;
    overflow: hidden;
}
.info-text p:only-child {
    margin-bottom: 0;
}
.info-text:after {
    content: 'i';
    width: 90px;
    height: 90px;
    position: absolute;
    right: -15px;
    bottom: -15px;
    font-family: Georgia;
    font-style: italic;
    border: 4px solid rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    line-height: 82px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 44px;
}

.infobox1 {
    text-align: center;
    background: #ececec;
    border-bottom: 1px solid rgba(255, 255, 255, 0.9);
    padding: 50px 30px 30px 30px;
    border-radius: 3px;
    margin-bottom: 30px;
}
.infobox1 .m_title {
    margin: 0 0 10px;
    font-weight: 300;
    font-size: 24px;
    line-height: 1.6;
}
.infobox1 .m_title strong {
    font-weight: 600;
}
.infobox1:hover {
    background: #e9e9e9;
}

.infobox2 {
    background: #ececec;
    border-bottom: 1px solid rgba(255, 255, 255, 0.9);
    padding: 40px 40px 30px;
    border-radius: 2px;
    margin-bottom: 30px;
}
.infobox2 .m_title {
    margin: 0 0 10px;
    font-weight: 300;
    font-size: 24px;
    line-height: 1.6;
}
.infobox2 .m_title strong {
    font-weight: 600;
}

.infobox2 .ib-button {
    float: right;
    margin-top: 5px;
    text-align: right;
}
.infobox2 .ib-button .btn {
    font-size: 14px;
}
.infobox2:hover {
    background: #e9e9e9;
}

.infobox3 {
    text-align: center;
    padding: 100px 30px;
    margin-bottom: 20px;
}
.infobox3 .m_title {
    margin: 0 0 10px;
    font-weight: 300;
    font-size: 24px;
    line-height: 1.6;
    text-shadow: none;
}
.infobox3 .m_title strong {
    font-weight: 600;
}
.infobox3 .ib-button.ib-button-1,
.infobox3 .ib-button.ib-button-2 {
    margin-top: 30px;
    display: inline-block;
    text-transform: uppercase;
}
.infobox3 .ib-button.ib-button-1 {
    margin-right: 20px;
}
.infobox3--dark,
.infobox3--dark .m_title {
    color: #343434;
}
.infobox3--light,
.infobox3--light .m_title {
    color: #fff;
}

/* info box style 2 */
.ib2-custom .ib2-inner {
    padding: 40px;
    position: relative;
    z-index: 1;
}
.ib2-text-color-light-theme .ib2-inner {
    border-left: 4px solid #106ee9;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.ib2-info-message {
    font-size: 1rem;
    font-weight: bold;
    margin: 0 0 20px 42px;
    padding: 0 20px 5px 0;
    position: relative;
}
.ib2-info-message:before {
    content: 'i';
    position: absolute;
    left: -42px;
    top: -5px;
    width: 32px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    border-width: 2px;
    border-style: solid;
    color: #106ee9;
    border-radius: 50%;
    font-size: 17px;
    font-weight: 900;
}
.ib2-text-color-light-theme .ib2-info-message {
    border-bottom: 1px solid #e4e4e4;
}
.ib2-text-color-dark-theme .ib2-info-message {
    color: #fff;
}
.ib2-style2.ib2-text-color-dark-theme .ib2-info-message {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.ib2-style3.ib2-text-color-dark-theme .ib2-info-message {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.ib2-style2.ib2-text-color-dark-theme .ib2-info-message:before {
    border-color: rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.2);
}
.ib2-style3.ib2-text-color-dark-theme .ib2-info-message:before {
    border-color: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.2);
}
.ib2-content--title {
    font-size: 22px;
    font-weight: 100;
    line-height: 1.4;
    margin: 0 0 10px;
}
.ib2-content--text {
    line-height: 1.8;
}
.ib2-content--text p:last-child {
    margin-bottom: 0;
}
.ib2-text-color-light-theme .ib2-content--title {
    color: #828282;
}
.ib2-text-color-light-theme .ib2-content--text {
    color: #535353;
}
.ib2-style2.ib2-text-color-dark-theme .ib2-content--title {
    color: #fff;
}
.ib2-style2.ib2-text-color-dark-theme .ib2-content--text {
    color: rgba(255, 255, 255, 0.6);
}
.ib2-style3.ib2-text-color-dark-theme .ib2-content--title {
    color: #fff;
}
.ib2-style3.ib2-text-color-dark-theme .ib2-content--text {
    color: rgba(255, 255, 255, 0.6);
}
.ib2-style3 {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.ib2-style3:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.ib2-style3.ib2-text-color-dark-theme:after {
    background-color: rgba(37, 41, 43, 0.7);
}
.ib2-style3.ib2-text-color-light-theme:after {
    background-color: rgba(255, 255, 255, 0.85);
}

/* Statistics element */
/* General */
.statistic-box__title {
    font-size: 40px;
    color: #fff;
    font-weight: 300;
    line-height: 1;
    margin: 0 0 10px;
}
.statistic-box__content {
    color: #fff;
    font-size: 14px;
}
.statistic-box__details {
    margin: 0;
    position: relative;
    width: 100%;
}
.statistic-box__icon-holder {
    width: 100%;
    margin: 0;
    vertical-align: middle;
}
.statistic-box__icon {
    color: #fff;
    opacity: 0.8;
    line-height: 60px;
    margin: 0;
    -webkit-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
}
span.statistic-box__icon {
    font-size: 80px;
}
.statistic-box__icon-holder--img {
    line-height: 60px;
}
.statistic-box__iconimg {
    max-height: 80px;
    width: auto;
}
.statistic-box:hover .statistic-box__icon {
    opacity: 1;
}
.statistic-box__line {
    position: relative;
    width: 100%;
    height: 1px;
    border-bottom: dotted 1px #fff;
    margin: 0;
}
.statistic-box__line::before {
    content: '';
    position: absolute;
    height: 3px;
    background-color: #fff;
    width: 3px;
    display: block;
    left: 50%;
    border-radius: 3px;
    top: -1px;
    box-shadow: 0px 5px 0 -1px #fff, 0px 10px 0 -1px #fff, 0px 15px 0 -1px #fff,
        0px 20px 0 -1px #fff;
}
/* Style 1 - horiontal odd/even */
.statistic-box--stl-style1.statistic-box__container {
    width: 100%;
    display: table;
    table-layout: fixed;
    margin-top: 120px;
    margin-bottom: 120px;
}
.statistic-box--stl-style1 .statistic-box {
    position: relative;
    display: table-cell;
    text-align: center;
}
.statistic-box--stl-style1 .statistic-box__icon-holder {
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    padding-top: 0;
    padding-bottom: 35px;
}
.statistic-box--stl-style1 .statistic-box__details {
    position: absolute;
    top: 0;
    min-height: 90px;
    padding-top: 35px;
    padding-bottom: 0;
}
.statistic-box--odd .statistic-box__icon-holder {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    padding-top: 35px;
    padding-bottom: 0;
}
.statistic-box--odd .statistic-box__details {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    padding-bottom: 35px;
    padding-top: 0;
}
.statistic-box--odd .statistic-box__line::before {
    bottom: -2px;
    box-shadow: 0px -5px 0 -1px #fff, 0px -10px 0 -1px #fff,
        0px -15px 0 -1px #fff, 0px -20px 0 -1px #fff;
}
/* Style 1 - horiontal normal */
.statistic-box--stl-style2.statistic-box__container {
    width: 100%;
    display: table;
    table-layout: fixed;
    margin-top: 120px;
    margin-bottom: 120px;
}
.statistic-box--stl-style2 .statistic-box {
    position: relative;
    display: table-cell;
    text-align: center;
}
.statistic-box--stl-style2 .statistic-box__icon-holder {
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    padding-top: 0;
    padding-bottom: 35px;
}
.statistic-box--stl-style2 .statistic-box__details {
    position: absolute;
    top: 0;
    min-height: 90px;
    padding-top: 35px;
    padding-bottom: 0;
}
/* Dark */
.statistic-box--dark .statistic-box__title {
    color: #121212;
}
.statistic-box--dark .statistic-box__content {
    color: #121212;
}
.statistic-box--dark .statistic-box__icon {
    color: #121212;
}
.statistic-box--dark .statistic-box__line {
    border-bottom-color: #d6d6d6;
}
.statistic-box--dark .statistic-box__line::before {
    background-color: #121212;
    box-shadow: 0px 5px 0 -1px #121212, 0px 10px 0 -1px #121212,
        0px 15px 0 -1px #121212, 0px 20px 0 -1px #121212;
}
.statistic-box--dark .statistic-box--odd .statistic-box__line::before {
    box-shadow: 0px -5px 0 -1px #121212, 0px -10px 0 -1px #121212,
        0px -15px 0 -1px #121212, 0px -20px 0 -1px #121212;
}
/* end Statistics element */

/*** STAGE Image Box */
.stage-ibx__stage {
    position: relative;
}
.stage-ibx__point {
    display: none;
}

/* tabs style 1 */
.tabs_style1 {
    background: #fff;
    border: 1px solid #ececec;
}
.tabs_style1 > ul.nav {
    margin-bottom: 0;
    background: rgb(255, 255, 255);
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(255, 255, 255, 1)),
        color-stop(100%, rgba(249, 249, 249, 1))
    );
    background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 1) 0%,
        rgba(249, 249, 249, 1) 100%
    );
    background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 1) 0%,
        rgba(249, 249, 249, 1) 100%
    );
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 0%,
        rgba(249, 249, 249, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f9f9f9',GradientType=0 );
    border-bottom: 1px solid #ececec;
}
.tabs_style1 > ul.nav > li {
    display: inherit;
    margin-bottom: -3px;
}
.tabs_style1 > ul.nav > li > a {
    padding: 14px 20px 15px;
    line-height: 1.4;
    border-right: 1px solid #ececec;
    color: #343434;
    margin-bottom: 2px;
}
.tabs_style1 > ul.nav > li > a.active,
.tabs_style1 > ul.nav > li > a.focus {
    background-color: #fff !important;
    box-shadow: inset 0 3px 0 #106ee9;
}
.tabs_style1 > ul.nav > li > a:hover {
    background-color: #fff !important;
}
.tabs_style1 > ul.nav > li > a.active {
    border-top: none;
    border-left: 0;
    font-weight: 700;
    padding: 17px 20px 15px;
    margin-top: -2px;
}
.tabs_style1 .tab-content {
    width: 100%;
    padding: 0 15px 15px;
    width: auto;
    margin-top: 10px;
}
.tabs_style1 .tab-content > .active {
    padding-top: 10px;
}

/* tabs style 2 */
.tabs_style2 {
    margin: 10px 0;
}
.tabs_style2 > ul.nav {
    margin-bottom: 10px;
}
.tabs_style2 > ul.nav > li {
    float: left;
    border-left: 1px dotted #ccc;
    line-height: 18px;
}
.tabs_style2 > ul.nav > li:first-child {
    border-left: 0;
}
.tabs_style2 > ul.nav > li:first-child a {
    border-left: 0;
    padding-left: 0;
}
.tabs_style2 > ul.nav > li > a {
    padding: 4px 20px;
    line-height: 18px;
    color: #555;
}
.tabs_style2 > ul.nav > li > a.active {
    font-weight: bold;
}
.tabs_style2 > ul.nav > li > a.active,
.tabs_style2 > ul.nav > li > a:hover {
    background: none;
    color: #222;
}
.tabs_style2 .tab-content {
    width: 100%;
    padding: 0;
    margin-top: 15px;
}

/* tabs style 3 */
.tabs_style3 {
    background: #fff;
    border: 1px solid #ececec;
    border-radius: 5px;
    position: static;
    overflow: hidden;
}
.tabs_style3 > ul.nav {
    margin-bottom: 0;
    background: rgb(255, 255, 255);
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(255, 255, 255, 1)),
        color-stop(100%, rgba(249, 249, 249, 1))
    );
    background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 1) 0%,
        rgba(249, 249, 249, 1) 100%
    );
    background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 1) 0%,
        rgba(249, 249, 249, 1) 100%
    );
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 0%,
        rgba(249, 249, 249, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f9f9f9',GradientType=0 );
}
.tabs_style3 > ul.nav > li {
    float: left;
    margin-bottom: -1px;
}
.tabs_style3 > ul.nav > li > a {
    display: inline-block;
    padding: 15px 20px;
    line-height: 18px;
    border: 1px solid transparent;
    border-left: 1px solid #fff;
    border-right: 1px solid #ececec;
    color: #343434;
}
.tabs_style3 > ul.nav > li > a.active,
.tabs_style3 > ul.nav > li > a:hover,
.tabs_style3 > ul.nav > li > a:focus {
    background-color: #fff !important;
}
.tabs_style3 > ul.nav > li > a.active {
    border-left: 0;
    font-weight: 700;
}
.tabs_style3 .tab-content {
    width: 100%;
    padding: 10px 15px 15px;
    margin-top: 10px;
}

/* tabs style 4 */
.tabs_style4 > ul.nav {
    margin-bottom: 0;
}
.tabs_style4 > ul.nav > li {
    float: left;
    margin-bottom: -1px;
}
.tabs_style4 > ul.nav > li > a {
    padding: 13px 20px;
    line-height: 18px;
    color: #343434;
}
.tabs_style4 > ul.nav > li > a.active,
.tabs_style4 > ul.nav > li > a:hover {
    color: #222;
}
.tabs_style4 > ul.nav > li > a.active,
.tabs_style4 > ul.nav > li > a:focus {
    background: #fff !important;
    border: 1px solid #ececec;
    border-bottom: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.tabs_style4 .tab-content {
    width: 100%;
    padding: 10px 15px 15px;
    border: 1px solid #ececec;
    background: #fff;
    margin-top: 10px;
}

/* tabs style 5 */
.tabs_style5 > ul.nav {
    border-bottom: 2px solid #dfdfdf;
}
.tabs_style5 > ul.nav > li {
    float: left;
    margin-bottom: -2px;
}
.tabs_style5 > ul.nav li > a {
    color: #909090;
}
.tabs_style5 > ul.nav li > a:hover {
    color: #535353;
}
.tabs_style5 > ul.nav > li > a.active {
    color: #535353;
    border-bottom: 2px solid #106ee9;
    background: none;
}

.tabs_style5 > ul.nav > li:first-child > a {
    padding: 8px 20px 8px 0;
}
.tabs_style5 > ul.nav > li > a {
    padding: 8px 20px;
    line-height: 1.7;
    display: inline-block;
}
.tabs_style5 > ul.nav > li > a:hover {
    background: none;
}

.tabs_style5 .tab-content h5 {
    color: #4d4d4d;
}
.tabs_style5 .tab-content {
    width: 100%;
    padding: 10px 0 15px;
    margin-top: 10px;
}

/* Pricing Tables element (new style) */

.pricing-table-element {
    *zoom: 1;
    background: #fff;
    border: 1px solid #e6e7e8;
    border-radius: 4px;
    font-size: 13px;
    color: #999999;
}
.pricing-table-element::before,
.pricing-table-element::after {
    display: table;
    line-height: 0;
    content: '';
}
.pricing-table-element::after {
    clear: both;
}
.pricing-table-element ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: table;
    width: 100%;
}
.pricing-table-element .features-column {
    background: #eee;
    border-radius: 4px 0 0 4px;
    text-align: right;
    width: 28%;
}
.pricing-table-element .features-column ul li {
    border-bottom: 1px solid #e6e7e8;
    padding: 15px 40px 15px 0;
    height: 44px;
    line-height: 1;
}
.pricing-table-element .plan-column,
.pricing-table-element .features-column {
    float: left;
}
.pricing-table-element[data-columns='2'] .plan-column {
    width: 50%;
}
.pricing-table-element[data-columns='3'] .plan-column {
    width: 33.3333%;
}
.pricing-table-element[data-columns='4'] .plan-column {
    width: 25%;
}
.pricing-table-element[data-columns='5'] .plan-column {
    width: 20%;
}
.pricing-table-element[data-columns='1'] .features-column ~ .plan-column {
    width: 72%;
} /* with features-col */
.pricing-table-element[data-columns='2'] .features-column ~ .plan-column {
    width: -webkit-calc(72% / 2);
    width: calc(72% / 2);
} /* with features-col */
.pricing-table-element[data-columns='3'] .features-column ~ .plan-column {
    width: -webkit-calc(72% / 3);
    width: calc(72% / 3);
} /* with features-col */
.pricing-table-element[data-columns='4'] .features-column ~ .plan-column {
    width: -webkit-calc(72% / 4);
    width: calc(72% / 4);
} /* with features-col */
.pricing-table-element[data-columns='5'] .features-column ~ .plan-column {
    width: -webkit-calc(72% / 5);
    width: calc(72% / 5);
} /* with features-col */
.pricing-table-element .plan-column {
    border-left: 1px solid #e6e7e8;
}
.pricing-table-element .plan-column:first-child {
    border-left: 0;
}
.pricing-table-element .plan-column ul {
    display: table;
}
.pricing-table-element .plan-column ul li {
    display: table-row;
}
.pricing-table-element .plan-column .inner-cell {
    border-bottom: 1px solid #e6e7e8;
    padding: 0;
    text-align: center;
    height: 50px;
    line-height: 1;
    display: table-cell;
    vertical-align: middle;
}
.pricing-table-element .plan-column ul li:last-child .inner-cell {
    height: 100px;
}
.pricing-table-element .plan-column ul li:nth-child(odd):not(:first-child) {
    background: #fafbfb;
}
.pricing-table-element .plan-column .plan-title {
    color: #106ee9;
    font-size: 25px;
    height: auto;
    padding: 0;
}
.pricing-table-element .plan-title .inner-cell {
    height: 75px;
}
.pricing-table-element .plan-column .subscription-price {
    font-size: 13px;
    color: #999999;
    height: auto;
    padding: 0;
}
.pricing-table-element .subscription-price .inner-cell {
    height: 120px;
}
.pricing-table-element .plan-column .subscription-price .currency {
    font-size: 18px;
    color: #434343;
    font-weight: 700;
    vertical-align: bottom;
    display: inline-block;
    line-height: 1.5;
    margin-right: -2px;
}
.pricing-table-element .plan-column .subscription-price .price {
    font-size: 45px;
    color: #434343;
    font-weight: 700;
    line-height: 1;
}
.pricing-table-element .plan-column .subscription-price .price::after {
    content: '\a';
    white-space: pre;
}
.pricing-table-element .plan-column.featured {
    border-radius: 4px;
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.06);
    position: relative;
    margin-top: -20px;
    background: #fff;
    margin: -20px 0;
}

.pricing-table-element .plan-column.featured + .plan-column {
    border-left-color: transparent;
}
.pricing-table-element .plan-column.featured .plan-title {
    padding: 0;
    color: #45474d;
}
.pricing-table-element .plan-column.featured .plan-title .inner-cell {
    height: 95px;
}
.pricing-table-element .plan-column.featured .plan-title .inner-cell::after {
    content: 'MOST POPULAR';
    opacity: 0.4;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    display: block;
}
.pricing-table-element .plan-column.featured .subscription-price .inner-cell {
    background: #106ee9;
    color: #fff;
}
.pricing-table-element .plan-column.featured .subscription-price span {
    color: #fff;
}
.pricing-table-element .plan-column.featured ul li:last-child .inner-cell {
    height: 120px;
}

/* Blue Color */
.pricing-table-element.blue .btn-fullcolor,
.pricing-table-element.blue
    .plan-column.featured
    .subscription-price
    .inner-cell {
    background-color: #1e73be;
}
.pricing-table-element.blue .btn-fullcolor:hover {
    background-color: #185c98;
}
.pricing-table-element.blue .plan-column .plan-title {
    color: #1e73be;
}
.pricing-table-element.blue .plan-column.featured .plan-title .inner-cell {
    color: #45474d;
}
.pricing-table-element.blue {
    color: #999999 !important;
}
/* Green Color */
.pricing-table-element.green .btn-fullcolor,
.pricing-table-element.green
    .plan-column.featured
    .subscription-price
    .inner-cell {
    background-color: #4ca518;
}
.pricing-table-element.green .btn-fullcolor:hover {
    background-color: #3d8413;
}
.pricing-table-element.green .plan-column .plan-title {
    color: #4ca518;
}
.pricing-table-element.green .plan-column.featured .plan-title .inner-cell {
    color: #45474d;
}
.pricing-table-element.green {
    color: #999999 !important;
}
/* Dark Blue Color */
.pricing-table-element.dark-blue .btn-fullcolor,
.pricing-table-element.dark-blue
    .plan-column.featured
    .subscription-price
    .inner-cell {
    background-color: #2c3e50;
}
.pricing-table-element.dark-blue .btn-fullcolor:hover {
    background-color: #1f2933;
}
.pricing-table-element.dark-blue .plan-column .plan-title {
    color: #2c3e50;
}
.pricing-table-element.dark-blue .plan-column.featured .plan-title .inner-cell {
    color: #45474d;
}
.pricing-table-element.dark-blue {
    color: #999999 !important;
}

/* vertical tabs */
.vertical_tabs .tabbable .nav > li {
    display: inherit;
    position: relative;
    width: 100%;
}
.vertical_tabs {
    overflow: hidden;
}
.vertical_tabs .tabbable .nav > li > a,
.vertical_tabs .tabbable .nav > li > a > span {
    -webkit-transition: color 0.2s ease-out;
    transition: color 0.2s ease-out;
}
.vertical_tabs .tabbable .nav > li > a > span span {
    margin: 0;
}
.vertical_tabs .tabbable .nav > li > a:focus,
.vertical_tabs .tabbable .nav > li > a:hover {
    background-color: transparent;
    border-right: 0;
}
.vertical_tabs .tabbable .nav > li > a.active,
.vertical_tabs .tabbable .nav > li > a.active > span,
.vertical_tabs .tabbable .nav > li > a:hover,
.vertical_tabs .tabbable .nav > li > a:hover > span {
    color: #106ee9 !important;
}
.vertical_tabs .tabbable .nav > li:first-child {
    border-top: 0;
}
.vertical_tabs .tabbable .tab-content > .tab-pane > h4 {
    margin: 0 0 25px;
}

/* Style1 specific */
.vertical_tabs.kl-style-1 .tabbable .nav > li > a.active {
    box-shadow: inset -3px 0 0 0 #106ee9;
}
.vertical_tabs.kl-style-1 .tabbable .nav > li > a {
    line-height: 22px;
    padding: 10px 20px 10px 0;
    margin-right: -1px;
    font-weight: 400;
    color: #8f8f8f;
    font-size: 16px;
    width: 100%;
}
.vertical_tabs.kl-style-1 .tabbable .nav > li > a > span {
    padding: 6px 0 7px;
    color: #c7c7c7;
    margin-right: 8px;
    line-height: 1;
    display: inline-block;
    margin-top: -1px;
    border-radius: 50%;
    font-size: 18px;
    vertical-align: middle;
    margin-right: 10px;
}
.vertical_tabs.kl-style-1 .tabbable .nav > li > a.active > span,
.vertical_tabs.kl-style-1 .tabbable .nav > li > a:hover > span {
    color: #767676;
}

/* Style 2 */
.vertical_tabs.kl-style-2 .tabbable .nav {
    margin-right: -1px;
    overflow: hidden;
    border-bottom: 0;
    border-left: 0;
}
.vertical_tabs.kl-style-2 .tabbable .nav > li > a {
    width: 100%;
    line-height: 22px;
    color: #858585;
    font-weight: normal;
    background-color: #fff;
    padding: 20px 45px 20px 25px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-transform: none;
    margin-right: 0;
    position: relative;
    border-left: 1px solid #ddd;
    background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0%, #fff),
        color-stop(100%, rgba(239, 239, 239, 0.5))
    );
    background-image: -webkit-linear-gradient(
        left,
        #fff 0%,
        rgba(239, 239, 239, 0.5) 100%
    );
    background-image: linear-gradient(
        to right,
        #fff 70%,
        rgba(239, 239, 239, 0.5) 100%
    );
}
.vertical_tabs.kl-style-2 .tabbable .nav > li:first-child > a {
    border-radius: 3px 0 0 0;
    border-top: 1px solid #ddd;
}
.vertical_tabs.kl-style-2 .tabbable .nav > li:last-child > a {
    border-radius: 0 0 0 3px;
}
.vertical_tabs.kl-style-2 .tabbable .nav > li.active > a {
    color: #131313;
    font-weight: 600;
    box-shadow: none;
}
.vertical_tabs.kl-style-2 .tabbable .nav > li > a > span {
    color: #999;
    line-height: 1;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-weight: normal;
    margin-right: 0;
    padding: 0;
    font-size: 20px;
}
.vertical_tabs.kl-style-2 .tabbable .tab-content {
    background-color: #fff;
    border: 1px solid #ddd;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 40px 25px;
}

/* Keywords box */
.keywordbox {
    border-top: 4px solid #ddd;
    position: relative;
    min-height: 100px;
}
.keywordbox:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -3px;
    border: 6px solid transparent;
    border-top-color: #ddd;
}
.keywordbox .title-text {
    font-size: 1.5rem;
    font-style: italic;
    color: #8d8d8d;
    line-height: 1.5;
}

/* style2 */
.keywordbox.keywordbox-2 h4,
.keywordbox.keywordbox-3 h4,
.keywordbox.keywordbox-4 h4 {
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 0;
}

.keywordbox.keywordbox-2,
.keywordbox.keywordbox-3 {
    padding: 0 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 150px;
    border-top: none;
    border-bottom: solid 5px #106ee9;
    position: relative;
    z-index: 2;
}
.keywordbox.keywordbox-3 {
    border-bottom: solid 10px #106ee9;
    text-transform: uppercase;
    font-style: normal;
}

.keywordbox.keywordbox-2::after,
.keywordbox.keywordbox-3::after {
    content: none;
}

.keywordbox.keywordbox-2::after,
.keywordbox.keywordbox-3::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    border: 0;
    background: -moz-linear-gradient(
        top,
        rgba(22, 22, 22, 0.3) 0%,
        rgba(1, 1, 1, 0.8) 94%,
        rgba(0, 0, 0, 0.8) 100%
    );
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(22, 22, 22, 0.3)),
        color-stop(94%, rgba(1, 1, 1, 0.8)),
        color-stop(100%, rgba(0, 0, 0, 0.8))
    );
    background: -webkit-linear-gradient(
        top,
        rgba(22, 22, 22, 0.3) 0%,
        rgba(1, 1, 1, 0.8) 94%,
        rgba(0, 0, 0, 0.8) 100%
    );
    background: -o-linear-gradient(
        top,
        rgba(22, 22, 22, 0.3) 0%,
        rgba(1, 1, 1, 0.8) 94%,
        rgba(0, 0, 0, 0.8) 100%
    );
    background: -ms-linear-gradient(
        top,
        rgba(22, 22, 22, 0.3) 0%,
        rgba(1, 1, 1, 0.8) 94%,
        rgba(0, 0, 0, 0.8) 100%
    );
    background: linear-gradient(
        to bottom,
        rgba(22, 22, 22, 0.3) 0%,
        rgba(1, 1, 1, 0.8) 94%,
        rgba(0, 0, 0, 0.8) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d161616', endColorstr='#cc000000',GradientType=0 );
    z-index: -1;
}
.keywordbox.keywordbox-4 {
    padding: 60px 0;
    color: #fff;
    text-transform: uppercase;
    font-style: normal;
    border-top: none;
}
.keywordbox.keywordbox-4::after {
    margin-left: -22px;
    border: 22px solid transparent;
    border-top-color: #fff;
}

/* Search form */
.search-form-wrapper {
    margin: 0 0 30px;
}
.gensearch__form {
    position: relative;
}
.gensearch__input {
    height: 42px;
    width: 98%;
    padding: 0 20px;
    outline: none;
    border: 1px solid #ddd;
    font-size: 0.8rem;
}
.gensearch__submit {
    position: absolute;
    right: 0;
    border: 0;
    color: #bbb;
    background-color: transparent;
    outline: none;
    height: 100%;
    padding: 0 20px;
    font-size: 12px;
    -webkit-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
    cursor: pointer;
}
.gensearch__submit:focus {
    outline: none;
}
.gensearch__submit:hover {
    color: #131313;
}
.gensearch__submit:after {
    content: '';
    width: 1px;
    height: 24px;
    background-color: #ddd;
    display: block;
    left: 0;
    position: absolute;
    top: 50%;
    margin-top: -12px;
}

/* Offline/Comming soon page */
.offline-page #background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #ffffff;
    background: -webkit-gradient(
        radial,
        center center,
        0,
        center center,
        100%,
        color-stop(0%, #ffffff),
        color-stop(100%, #e5e5e5)
    );
    background: -webkit-radial-gradient(
        center,
        ellipse cover,
        #ffffff 0%,
        #e5e5e5 100%
    );
    background: -webkit-radial-gradient(
        center,
        ellipse,
        #ffffff 0%,
        #e5e5e5 100%
    );
    background: radial-gradient(ellipse at center, #ffffff 0%, #e5e5e5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5',GradientType=1 );
}
.offline-page .containerbox {
    width: 700px;
    margin: 100px auto 0;
    background: rgba(0, 0, 0, 0.7);
    position: relative;
    z-index: 1;
    padding: 25px;
    box-shadow: 0 10px 20px #ccc;
    border-bottom: 5px solid #106ee9;
    color: #eee;
    margin-bottom: 100px;
}
.offline-page .containerbox__logo {
    text-align: center;
    width: 175px;
    position: absolute;
    top: 0;
    left: 25px;
    height: 100%;
}
.offline-page .site-logo {
    position: relative;
    top: 50%;
    width: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.offline-page .site-logo .logo-img {
    max-width: 175px !important;
}
.offline-page .logo-img-sticky {
    display: none;
}
.offline-page .content {
    margin-left: 200px;
    border-left: 1px solid #777;
    padding-left: 20px;
}
.offline-page .containerbox:after {
    content: '';
    position: absolute;
    bottom: -25px;
    left: 100px;
    border-top: 20px solid #106ee9;
    border-right: 20px solid transparent;
    z-index: 2;
}
.offline-page .ud_counter {
    margin: 40px 0;
}
.offline-page .ud_counter ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
}
.offline-page .ud_counter ul li {
    display: inline-block;
    margin: 0 0 0 12px;
    font-size: 22px;
    font-weight: 700;
    padding: 15px 10px;
    width: 60px;
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 2px;
}
.offline-page .ud_counter ul li:first-child {
    margin-left: 0;
}
.offline-page .ud_counter ul li span {
    display: block;
    font-size: 22px;
    line-height: 1;
    margin-bottom: 15px;
}
.offline-page .ud_counter ul li.till_lauch {
    height: 80px;
    width: 80px;
    padding: 0;
    vertical-align: top;
}
.offline-page .mail_when_ready {
    float: left;
    margin: 0;
}
.offline-page .mail_when_ready input[type='submit'] {
    vertical-align: top;
}
.offline-page ul.social-icons {
    padding-left: 0;
    margin-left: 0;
}
.offline-page ul.social-icons li:first-child {
    margin-left: 0;
}
.sccsoon-icon-nocolor {
    background-color: #121212;
}
.till_lauch {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 80px;
    height: 80px;
    margin: 0 20px 18px 22px;
}
.offline-page .ud_counter ul li p {
    margin-bottom: 0;
}
.offline-page .till_lauch {
    height: 80px;
}
.mail_when_ready .newsletter-signup input[type='text'] {
    width: calc(60% - 130px);
}

/* Footer Styles*/
/* Footer Default style */
#footer {
    background: #2f2f2f;
    padding: 60px 0 20px;
    color: #d5d5d5;
    border-top: 4px solid #fff;
    position: relative;
}
#footer a {
    color: #fff;
}
#footer a:hover {
    color: #d5d5d5;
}
#footer .m_title {
    font-size: 1.1rem;
    font-weight: bold;
    color: #fff;
    text-shadow: none;
}
#footer .menu {
    margin: 0;
    padding: 0;
    list-style: none;
}
#footer .menu a {
    padding-right: 10px;
}
#footer .sbs .menu li {
    float: left;
    width: 50%;
}
.scfooter-icon-nocolor {
    background-color: #121212;
}

#footer .payments-links li {
    display: inline-block;
}
#footer .payments-links a {
    color: #adb5bd;
    font-size: 30px;
    margin-left: 3px;
    position: relative;
    -webkit-transform: skewX(-10deg);
    -ms-transform: skewX(-10deg);
    transform: skewX(-10deg);
}
#footer .payments-links a:hover {
    color: #fff;
}
/* END Footer Default style */

/* Footer style 2 */
#footer[data-footer-style='2'] .footer_nav li {
    margin-left: 20px;
}
#footer[data-footer-style='2'] .footer_nav {
    margin-right: 0;
}

/* Footer style 3 */
#footer[data-footer-style='3'] .container-fluid,
#footer[data-footer-style='3f'] .container-fluid {
    padding: 0 30px;
}
#footer[data-footer-style='3'],
#footer[data-footer-style='3f'] {
    padding: 30px 0;
    width: 100%;
    z-index: 10;
}

#footer[data-footer-style='3f'] {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10;
}
#footer[data-footer-style='3f'] #content {
    margin-bottom: 81px;
}
#footer .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
}
/* END Footer style 3 */

/* Footer style 4 */
#footer[data-footer-style='4'] {
    background-color: #1f1f1f;
}
#footer[data-footer-style='4'] .copyright {
    text-align: center;
    float: none !important;
}
#footer[data-footer-style='4'] .footer-logo {
    display: inline-block;
}
#footer[data-footer-style='4'] .footer-links {
    font-size: 17px;
}
#footer[data-footer-style='4'] .elm-social-icons {
    margin-top: 50px;
    margin-bottom: 30px;
    display: block !important;
}
#footer[data-footer-style='4'] .footer_nav {
    margin: 0;
}
#footer[data-footer-style='4'] .footer_nav li {
    margin-left: 20px;
}
/* END Footer style 4 */

/* Footer style 5 */
#footer[data-footer-style='5'] {
    background-color: #1a1c25;
    padding: 0;
    color: rgba(255, 255, 255, 0.6);
}
#footer[data-footer-style='5'] .bottom-footer {
    background-color: #15161e;
}
#footer[data-footer-style='5'] a {
    color: rgba(195, 195, 195, 0.6);
}
#footer[data-footer-style='5'] a:hover {
    color: rgba(255, 255, 255, 0.6);
}

#footer[data-footer-style='5'] .social-icons {
    float: left;
    margin-left: 0;
}
#footer[data-footer-style='5'] h4.simple-title {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 25px;
}

#footer[data-footer-style='5'] .main-footer ul.menu li {
    border-top: 1px solid #555;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
#footer[data-footer-style='5'] .main-footer ul.menu li:first-child {
    border-top: 0;
}
#footer[data-footer-style='5'] .main-footer ul.menu li a {
    line-height: 0.9rem;
    line-height: 1.5;
    display: inline-block;
    padding: 10px 0;
}
#footer[data-footer-style='5'] .bottom-footer ul.menu li {
    line-height: 1.6;
    display: inline-block;
    margin: 0 10px;
}
#footer[data-footer-style='5'] .bottom-footer ul.menu li:first-child {
    border-top: 0;
}
/* END Footer style 5 */

/* Footer style 6 */
#footer[data-footer-style='6'] {
    font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
#footer[data-footer-style='6'] {
    background-color: #f1f1f1;
    padding: 0;
    color: #535353;
}
#footer[data-footer-style='6'] .bottom-footer {
    background-color: #f1f1f1;
}
#footer[data-footer-style='6'] a {
    color: #535353;
}
#footer[data-footer-style='6'] .menu li a {
    font-size: 0.9rem;
    font-weight: 400;
    color: #313131;
}
#footer[data-footer-style='6'] .simple-title {
    color: #0a0a0a;
    font-weight: 700;
    font-size: 1rem;
    margin-top: 10px;
}
#footer[data-footer-style='6'] .social-icons {
    float: left;
}
/* END Footer style 6 */

.newsletter-signup form {
    margin-bottom: 0;
}
.newsletter-signup input {
    vertical-align: top;
    margin-bottom: 10px;
    width: calc(90% - 130px);
    display: inline-block;
    height: 40px;
}
.newsletter-signup input[type='text'] {
    color: #4d4d4d;
    background: #efefef;
    border: 1px solid #a3a3a3;
    box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1) inset;
    width: 190px;
    display: inline-block;
    height: 40px;
    width: calc(90% - 130px);
}
.newsletter-signup input[type='submit'] {
    color: #fff;
    background: #106ee9;
    border: 0;
    font-weight: 700;
    font-size: 13px;
    height: 40px;
    padding: 0 15px;
    margin-left: 10px;
    border-radius: 3px;
    width: 100px;
    cursor: pointer;
    border: none !important;
}
.newsletter-signup input[type='submit']:hover {
    background: #000;
}
.newsletter-signup #result {
    display: inline-block;
    margin-bottom: 10px;
}
.newsletter-signup #result:empty {
    display: inline;
}

#sidebar .newsletter-signup input[type='text'],
.zn_sidebar .newsletter-signup input[type='text'],
[id*='sidebar-widget-'] .newsletter-signup input[type='text'] {
    width: 139px;
}
.newsletter-signup.cd-news .nl-email {
    width: calc(70% - 130px);
}

.map-link > span {
    margin-top: 2px;
}

#footer .bottom {
    padding-top: 30px;
    border-top: 5px solid #484848;
    color: #8b8c8d;
}
#footer .footer-area--bottom {
    padding-top: 30px;
    border-top: 5px solid #484848;
    color: #8b8c8d;
}
#footer .social-icons {
    float: right;
}
#footer .copyright img {
    float: left;
    margin-right: 20px;
}

#footer .social-icons li.title {
    line-height: 30px;
}
#footer .social-icons li {
    margin: 0 0 0 10px;
}
#footer .social-icons li:first-child {
    margin-left: 0;
}

#footer .contact-details {
    min-height: 64px;
    background: url(../images/say_hi.png) no-repeat right top;
}

/* contact form errors */
.cf_response {
    display: none;
    padding-left: 15px;
    padding-right: 15px;
}
.ui-state-error {
    border: 1px solid #eb3034 !important;
    position: relative;
}
.ui-state-valid {
    border: none !important;
    position: relative;
}
.ui-state-error + span::after,
.ui-state-valid + span::after {
    font-family: 'icomoon';
    font-size: 15px;
    position: absolute;
    right: 12px;
    top: 10px;
    display: block;
    z-index: 1;
}
.ui-state-error + span::after {
    content: '\e01b';
    color: #eb3034;
}
.ui-state-valid + span::after {
    content: '\e013';
    color: #0eab55;
}

#notification_container .alert {
    display: inline-block;
    position: relative;
    padding: 10px 20px;
}
#notification_container .close::before {
    content: '\00d7';
    color: #000;
    outline: none;
}
#notification_container .close {
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 15px;
    opacity: 1;
}

/* css transitions - reducing transitions lines */
.topnav .topnav-item a,
.topnav .topnav-item span,
.topnav .topnav-item i,
.topnav--lang .languages .pPanel,
.mainnav--cart .drop .pPanel,
.kl-cart-button .fas,
.login--panel .login-icon,
#totop,
.playVideo span,
.theHoverBorder,
.hoverBorder h6,
.partners_carousel li a,
.hoverBorder:hover img,
.feature_box.style3 .box,
.services_box .icon,
.gobox,
.hover-box,
.circlehover span,
.process_box,
#sidebar ul.menu li a,
.zn_sidebar ul.menu li a,
[id*='sidebar-widget-'] ul.menu li a,
.timeline_box,
.timeline_box:hover .date,
.services_box.style2 .box,
.team_member,
.ptcarousel .controls > a,
.shop-features .shop-feature,
.timeline_box:before,
.support_panel {
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

/* Kallyas Videos */
.kl-video-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
}
.kl-video {
    position: absolute;
    z-index: 1;
}
.kl-video video {
    display: block;
    max-width: none;
}
.kl-video img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    left: 0 !important;
    top: 0;
    bottom: 0;
    right: 0;
}

.video-container {
    position: relative;
    padding-bottom: 55.25%;
    height: 0;
    overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.csstransforms .kl-video.valign video {
    top: 50% !important;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.csstransforms .kl-video.halign video {
    left: 50% !important;
    -webkit-transform: translateX(-50%) scale(1.002);
    -ms-transform: translateX(-50%) scale(1.002);
    transform: translateX(-50%) scale(1.002);
}
.csstransforms .kl-video.halign.valign video,
.csstransforms .kl-video.halign.valign iframe {
    top: 50% !important;
    left: 50% !important;
    -webkit-transform: translateY(-50%) translateX(-50%) scale(1.002);
    -ms-transform: translateY(-50%) translateX(-50%) scale(1.002);
    transform: translateY(-50%) translateX(-50%) scale(1.002);
}
/* Video Grid Overlay */
.video-grid-overlay:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: url(../images/video-pattern-1.png);
    opacity: 0.7;
    pointer-events: none;
}
.video-grid-overlay.ovrl2:after {
    background-image: url(../images/video-pattern-2.png);
}
/* Video with subtle top gradient */
.video-subtle-gradient:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 270px;
    z-index: 2;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(0, 0, 0, 0.3)),
        color-stop(100%, transparent)
    );
    background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0.3) 0%,
        transparent 100%
    );
    background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0.3) 0%,
        transparent 100%
    );
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3) 0%,
        transparent 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d000000', endColorstr='#00000000',GradientType=0 );
}
/* Video Controls */
.kl-video--controls {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 30px;
    right: 50px;
    z-index: 3;
    width: 50px;
    opacity: 0.1;
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
}
.kl-video--controls[data-position='top-left'] {
    top: 120px;
    right: auto;
    left: 50px;
}
.kl-video--controls[data-position='top-right'] {
    top: 120px;
    left: auto;
    right: 50px;
}
.kl-video--controls[data-position='top-center'] {
    right: auto;
    left: 50%;
    margin-left: -25px;
}
.kl-video--controls[data-position='bottom-right'] {
    top: auto;
    right: 20px;
    bottom: 5px;
}
.kl-video--controls[data-position='bottom-left'] {
    top: auto;
    right: auto;
    left: 50px;
    bottom: 5px;
}
.kl-video--controls[data-position='bottom-center'] {
    top: auto;
    right: auto;
    left: 50%;
    bottom: 5px;
    margin-left: -25px;
}
div[class*='maskcontainer--'] .kl-video--controls[data-position='bottom-right'],
div[class*='maskcontainer--'] .kl-video--controls[data-position='bottom-left'],
div[class*='maskcontainer--']
    .kl-video--controls[data-position='bottom-center'] {
    bottom: 80px;
}
.kl-video--controls[data-position='middle-right'] {
    top: 50%;
    margin-top: -25px;
}
.kl-video--controls[data-position='middle-left'] {
    left: 50px;
    right: auto;
    top: 50%;
    margin-top: -25px;
}
.kl-video--controls[data-position='middle-center'] {
    left: 50%;
    right: auto;
    top: 50%;
    margin-top: -25px;
    margin-left: -25px;
}
.kl-video-container:hover .kl-video--controls,
#page-header:hover .kl-video--controls {
    opacity: 1;
}
.kl-video-container .kl-video--controls a {
    width: 50px;
    height: 50px;
    display: block;
    cursor: pointer;
    color: #fff;
    text-align: center;
    line-height: 50px;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    opacity: 0.5;
}
.kl-video--controls .btn-audio {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
}
.kl-video--controls:hover .btn-audio {
    opacity: 0.7;
    visibility: visible;
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
}
.kl-video--controls .btn-toggleplay {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}
.kl-video--controls .btn-toggleplay .paused:before {
    content: '\f04c';
}
.kl-video--controls:hover .btn-toggleplay {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
}
.kl-video--controls a:hover {
    opacity: 1;
}
.kl-video--controls .btn-audio .kl-icon {
    width: 28px;
    height: 28px;
    line-height: 28px;
}
.kl-video--controls .btn-audio .kl-icon:before {
    line-height: 29px;
}
.kl-video--controls .btn-audio .mute:before {
    content: '\f026';
}

.csstransforms .kl-video.halign.valign video {
    -webkit-transform: translateY(-50%) translateX(-50%) scale(1);
    -ms-transform: translateY(-50%) translateX(-50%) scale(1);
    transform: translateY(-50%) translateX(-50%) scale(1);
    -o-object-fit: cover;
    object-fit: cover;
}

.kl-video video {
    display: block;
    max-width: none;
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
}

/* FLICKR FEED */
.flickr_feeds {
    margin: 0 0 0 -20px;
    padding: 0;
    list-style: none;
    position: relative;
}
.flickrfeed.loading:after {
    content: 'Loading..';
}
.flickr_feeds li {
    float: left;
    margin: 0 0 20px 20px;
}
.flickr_feeds li a {
    display: block;
    width: 73px;
    position: relative;
}
.flickr_feeds[data-size='small'] li a {
    width: 60px;
}
.flickr_feeds li a .hov1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

/*** Testimonials Partners */
.testimonials-partners__separator {
    background: #fff;
    opacity: 0.2;
    height: 1px;
    width: 100%;
    margin-bottom: 70px;
}
.ts-pt-testimonials {
    margin-left: -50px;
}
/* Testimonial Item */
.ts-pt-testimonials__item {
    float: left;
    margin-left: 50px;
    margin-bottom: 50px;
    color: #fff;
}
.ts-pt-testimonials__item--size-1 {
    width: -webkit-calc(25% - 50px);
    width: calc(25% - 50px);
}
.ts-pt-testimonials__item--size-2 {
    width: -webkit-calc(50% - 50px);
    width: calc(50% - 50px);
}
.ts-pt-testimonials__item--size-3 {
    width: -webkit-calc(75% - 50px);
    width: calc(75% - 50px);
}
.ts-pt-testimonials__item--size-4 {
    width: -webkit-calc(100% - 50px);
    width: calc(100% - 50px);
}
/* Testimonial content */
.ts-pt-testimonials__text {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 40px 25px 40px;
    font-size: 1.1rem;
    font-weight: 400;
    font-style: italic;
    line-height: 1.7;
    position: relative;
}
.ts-pt-testimonials__text:after {
    content: '';
    position: absolute;
    left: 55px;
    border: 13px solid transparent;
}
.ts-pt-testimonials__item--normal .ts-pt-testimonials__text:after {
    top: 100%;
    border-top-color: rgba(0, 0, 0, 0.2);
}
.ts-pt-testimonials__item--reversed .ts-pt-testimonials__text:after {
    bottom: 100%;
    border-bottom-color: rgba(0, 0, 0, 0.2);
}
.ts-pt-testimonials__item--normal .ts-pt-testimonials__infos {
    margin-top: 15px;
}
.ts-pt-testimonials__item--reversed .ts-pt-testimonials__infos {
    margin-bottom: 15px;
}
.ts-pt-testimonials__item--normal .ts-pt-testimonials__infos--noimg {
    margin-top: 25px;
    margin-left: 20px;
}
.ts-pt-testimonials__item--reversed .ts-pt-testimonials__infos--noimg {
    margin-bottom: 25px;
    margin-left: 20px;
}
.ts-pt-testimonials__img {
    position: relative;
    width: 110px;
    height: 110px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    overflow: hidden;
    float: left;
    margin-right: 20px;
    margin-left: 25px;
    border: 5px solid rgba(255, 255, 255, 0.2);
    background-clip: content-box;
}
.ts-pt-testimonials__item--normal .ts-pt-testimonials__img {
    margin-top: -35px;
}
.ts-pt-testimonials__item--reversed .ts-pt-testimonials__img {
    margin-top: -15px;
}
.ts-pt-testimonials__item--size-1 .ts-pt-testimonials__img {
    width: 60px;
    height: 60px;
    margin-left: 5px;
    margin-top: 0;
}
.ts-pt-testimonials__name {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 0;
}
.ts-pt-testimonials__item--size-1 .ts-pt-testimonials__name {
    font-size: 14px;
}
.ts-pt-testimonials__position {
    font-size: 13px;
    line-height: 1.4;
    font-weight: 300;
    opacity: 0.5;
    margin-bottom: 5px;
}
.ts-pt-testimonials__stars span {
    opacity: 0.6;
    font-size: 11px;
}
.ts-pt-testimonials__stars--1 span:nth-child(1) ~ span,
.ts-pt-testimonials__stars--2 span:nth-child(2) ~ span,
.ts-pt-testimonials__stars--3 span:nth-child(3) ~ span,
.ts-pt-testimonials__stars--4 span:nth-child(4) ~ span {
    opacity: 0.3;
}

/* Video AdBox */
.adbox.video {
    position: relative;
}
.adbox.video:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(23, 23, 25, 0.5);
}
.adbox.video > img {
    display: block;
    width: 100%;
    height: auto;
}
.adbox.video .video_trigger_wrapper {
    position: absolute;
    z-index: 1;
    top: 0;
    left: auto;
    margin-left: 0;
    margin-top: 0;
    width: 100%;
    height: 100%;
}
.adbox_container {
    position: relative;
    width: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.adbox_container > h5 {
    color: white;
    font-size: 11px;
    min-width: 150px;
    margin: 0 auto;
    display: block;
    text-align: center;
}

.fluid-width-video-wrapper {
    width: 100%;
    position: relative;
    padding: 0;
}

.fluid-width-video-wrapper iframe,
.fluid-width-video-wrapper object,
.fluid-width-video-wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* To Next Button */
.tonext-btn {
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -12px;
    color: #fff;
    display: block;
    z-index: 101;
    text-decoration: none;
}
.tonext-btn.has-nav {
    bottom: 70px;
}
.mouse-anim-icon {
    width: 24px;
    height: 40px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.1);
    transition: background-color 0.2s ease-out;
    position: relative;
    display: block;
}
.mouse-anim-icon:after {
    position: absolute;
    content: '';
    width: 2px;
    height: 9px;
    background-color: rgba(0, 0, 0, 0.25);
    left: 50%;
    margin-left: -1px;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-name: animScroll;
    animation-name: animScroll;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}
.mouse-anim-icon:hover {
    background-color: rgba(255, 255, 255, 0.4);
}
.mouse-anim-icon:hover:after {
    top: 24px;
}
@-webkit-keyframes animScroll {
    from {
        top: 8px;
    }
    to {
        top: 24px;
    }
}
@keyframes animScroll {
    from {
        top: 8px;
    }
    to {
        top: 24px;
    }
}

/*** Media Container */
.media-container {
    position: relative;
}
.media-container__link--wrap {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.media-container__link--btn {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    outline: none !important;
    -webkit-backface-visibility: hidden;
}
.media-container__link .kl-icon {
    color: #fff;
}
.media-container__link--btn .kl-icon:not(.circled-icon) {
    margin-right: 5px;
    -webkit-transition: margin-right 0.2s ease-out;
    transition: margin-right 0.2s ease-out;
}
.media-container__link--btn:hover .kl-icon:not(.circled-icon) {
    margin-right: 15px;
}
.media-container .kl-title-block {
    z-index: 1;
}

/* Bodered animated 1 */
.media-container__link--style-borderanim1 {
    padding: 10px 30px;
    font-size: 42px;
    letter-spacing: 2px;
    font-weight: 600;
    line-height: 1.2;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    -webkit-transition: color 0.2s ease-out;
    transition: color 0.2s ease-out;
}
.media-container__link--style-borderanim1 > i {
    opacity: 0.8;
    background-color: #fff;
    display: block;
    position: absolute;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.media-container__border-tt {
    width: 30%;
    height: 7px;
    top: 0;
    left: 0;
}
.media-container__border-tl {
    height: 80%;
    width: 7px;
    top: 7px;
    left: 0;
}
.media-container__border-bb {
    width: 30%;
    height: 7px;
    bottom: 0;
    right: 0;
}
.media-container__border-br {
    height: 80%;
    width: 7px;
    bottom: 7px;
    right: 0;
}
.media-container:hover > i {
    opacity: 1;
}
.media-container:hover .media-container__border-tt {
    width: 100%;
    height: 3px;
    top: 0;
    left: 0;
}
.media-container:hover .media-container__border-tl {
    height: 100%;
    width: 3px;
    top: 0;
    left: 0;
}
.media-container:hover .media-container__border-bb {
    width: 100%;
    height: 3px;
    bottom: 0;
    right: 0;
}
.media-container:hover .media-container__border-br {
    height: 100%;
    width: 3px;
    bottom: 0;
    right: 0;
}
/* Alternative animated border */
.media-container__link--style-borderanim2 .media-container__text {
    text-align: center;
    font-size: 30px;
    letter-spacing: 4px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.borderanim2-svg {
    position: relative;
}
.borderanim2-svg__shape {
    stroke-width: 8px;
    fill: transparent;
    stroke: #fff;
    -webkit-transition: stroke-width 1s, stroke-dashoffset 1s,
        stroke-dasharray 1s;
    transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
}
.media-container:hover .borderanim2-svg__shape {
    stroke-width: 3px;
    stroke-dashoffset: 0;
}
.media-container
    .media-container__link--style-borderanim2
    .media-container__text:hover {
    color: #106ee9;
}

.borderanim2-svg {
    width: 400px;
}
.borderanim2-svg .media-container__text {
    line-height: 70px;
}
.borderanim2-svg__shape {
    stroke-dasharray: 100px 1000px;
    stroke-dashoffset: -620px;
    stroke: #106ee9;
}
.style2:hover .borderanim2-svg__shape {
    stroke-dasharray: 940px;
}

/* Circle animation */
.circleanim-svg {
    width: 108px;
    height: 108px;
}
.circleanim-svg__circle-front,
.circleanim-svg__circle-back {
    fill: none;
    stroke: #fff;
}
.circleanim-svg__circle-front {
    stroke-dasharray: 60px 1000px;
    stroke-width: 5px;
    stroke-opacity: 0.5;
    stroke-dashoffset: 0;
    -webkit-transition: stroke-width 1s, stroke-dashoffset 1s,
        stroke-dasharray 1s, stroke-opacity 1s;
    transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s,
        stroke-opacity 1s;
}
.media-container:hover .circleanim-svg__circle-front {
    stroke-dasharray: 340px;
    stroke-width: 2px;
    stroke-opacity: 1;
}

/* Media container with custom content */
.media-container.media-container--type-pb {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.media-container-pb {
    width: calc(100% - 50px);
    margin: 10px 25px;
}

.media-container-pb.media-container-pb--alg-top {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
}
.media-container-pb.media-container-pb--alg-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
}
.media-container-pb.media-container-pb--alg-bottom {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.prt-hover-slidein,
.prt-hover-slideout {
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.prt-hover-slidein {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
}
.media-container:hover .prt-hover-slidein {
    opacity: 1;
    max-height: 200px;
}
.prt-hover-slidein .tbk__subtitle {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
    line-height: 30px;
}
/*** End Media Container Element */

/* Custom container */
.custom_container.p-5 {
    padding: 0 !important;
    padding-top: 5% !important;
}

/* photo gallery */
ul.photo_gallery {
    clear: both;
    list-style: none outside none;
    margin: 8px auto;
    overflow: hidden;
    padding: 8px 0;
}
ul.photo_gallery li {
    background: none !important;
    float: left;
    margin: 5px;
    padding: 0;
    list-style: none;
}

/*** Grid Photo Gallery */
.gridPhotoGallery {
    margin-left: -5px;
    margin-right: -5px;
}
.gridPhotoGallery__item {
    float: left;
    position: relative;
    padding: 0;
}
.gridPhotoGallery__link {
    width: 100%;
    height: 0;
    display: block;
    padding: 0;
    padding-bottom: 100%;
    margin-bottom: 0;
}
.gridPhotoGallery__img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    left: 5px;
    bottom: 5px;
}

/* Icons */
.gridPhotoGallery .gridPhotoGallery__item .kl-icon {
    opacity: 0;
    position: absolute;
    top: 50%;
    margin-top: 15px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    border-color: #fff;
    color: #fff;
}
.gridPhotoGallery .gridPhotoGallery__link:hover .kl-icon {
    display: block;
    opacity: 1;
    margin-top: 0;
}

/* Title */
.gridPhotoGallery__link {
    position: relative;
}
.gridPhotoGallery__link:after {
    content: attr(title);
    position: absolute;
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    left: 15%;
    right: 15%;
    top: 50%;
    margin-top: -65px;
    opacity: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.gridPhotoGallery__link:hover:after {
    opacity: 1;
}
/* BG Overlay */
.gridPhotoGallery__img:after {
    content: '';
    position: absolute;
    background-color: rgba(28, 31, 32, 0.25);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
}
.gridPhotoGallery__link:hover .gridPhotoGallery__img:after {
    opacity: 1;
}
/* Cols Width */
.gridPhotoGallery--cols-1 .gridPhotoGallery__item {
    width: 100%;
}
.gridPhotoGallery--cols-2 .gridPhotoGallery__item {
    width: 50%;
}
.gridPhotoGallery--cols-3 .gridPhotoGallery__item {
    width: 33.3333%;
}
.gridPhotoGallery--cols-4 .gridPhotoGallery__item {
    width: 25%;
}
.gridPhotoGallery--cols-5 .gridPhotoGallery__item {
    width: 20%;
}
.gridPhotoGallery--cols-6 .gridPhotoGallery__item {
    width: 16.6666%;
}
/* 2 x width */
.gridPhotoGallery--cols-2 .gridPhotoGalleryItem--w2 {
    width: 100%;
}
.gridPhotoGallery--cols-3 .gridPhotoGalleryItem--w2 {
    width: 66.6666%;
}
.gridPhotoGallery--cols-4 .gridPhotoGalleryItem--w2 {
    width: 50%;
}
.gridPhotoGallery--cols-5 .gridPhotoGalleryItem--w2 {
    width: 40%;
}
.gridPhotoGallery--cols-6 .gridPhotoGalleryItem--w2 {
    width: 33.3333%;
}
/* height rations w1 + h1 & w2 + h2 */
.gridPhotoGallery--ratio-short
    .gridPhotoGalleryItem--w1
    .gridPhotoGalleryItem--h1,
.gridPhotoGallery--ratio-short
    .gridPhotoGalleryItem--w2
    .gridPhotoGalleryItem--h2 {
    padding-bottom: 75%;
}
.gridPhotoGallery--ratio-square
    .gridPhotoGalleryItem--w1
    .gridPhotoGalleryItem--h1,
.gridPhotoGallery--ratio-square
    .gridPhotoGalleryItem--w2
    .gridPhotoGalleryItem--h2 {
    padding-bottom: 100%;
}
.gridPhotoGallery--ratio-tall
    .gridPhotoGalleryItem--w1
    .gridPhotoGalleryItem--h1,
.gridPhotoGallery--ratio-tall
    .gridPhotoGalleryItem--w2
    .gridPhotoGalleryItem--h2 {
    padding-bottom: 125%;
}
/* height rations w2 + h1 */
.gridPhotoGallery--ratio-short
    .gridPhotoGalleryItem--w2
    .gridPhotoGalleryItem--h1 {
    padding-bottom: 37.5%;
}
.gridPhotoGallery--ratio-square
    .gridPhotoGalleryItem--w2
    .gridPhotoGalleryItem--h1 {
    padding-bottom: 50%;
}
.gridPhotoGallery--ratio-tall
    .gridPhotoGalleryItem--w2
    .gridPhotoGalleryItem--h1 {
    padding-bottom: 62.5%;
}
/* height rations w1 + h2 */
.gridPhotoGallery--ratio-short
    .gridPhotoGalleryItem--w1
    .gridPhotoGalleryItem--h2 {
    padding-bottom: 150%;
}
.gridPhotoGallery--ratio-square
    .gridPhotoGalleryItem--w1
    .gridPhotoGalleryItem--h2 {
    padding-bottom: 200%;
}
.gridPhotoGallery--ratio-tall
    .gridPhotoGalleryItem--w1
    .gridPhotoGalleryItem--h2 {
    padding-bottom: 250%;
}

/* Photo Gallery alternative element */
.photogallery-widget .photogallery-wrapper {
    overflow: hidden;
    position: relative;
}
.photogallery-widget .caroufredsel {
    width: 74.35%;
}
.photogallery-widget ul.slides {
    list-style: none;
    padding: 0;
    margin: 0;
}
.photogallery-widget .caroufredsel .slides li {
    position: relative;
}
.photogallery-widget .caroufredsel .slides li img {
    width: 100%;
}
.photogallery-widget .cfs--navigation {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    width: 100%;
}
.photogallery-widget .cfs--navigation a {
    color: #fff;
    top: auto;
    margin-top: 0;
}
.photogallery-widget .cfs--navigation a::before {
    font-size: 35px;
}
.photogallery-widget .cfs--prev {
    left: 20px;
}
.photogallery-widget .cfs--next {
    right: 20px;
}
.photogallery-widget .caroufredsel:hover .cfs--prev {
    left: 30px;
}
.photogallery-widget .caroufredsel:hover .cfs--next {
    right: 30px;
}
.photogallery-widget .caroufredsel .pg-caption,
.photogallery-widget .caroufredsel .pg-caption a {
    color: #fff;
    line-height: 1.8;
    font-size: 16px;
}
.photogallery-widget .caroufredsel .pg-caption {
    position: absolute;
    bottom: 50px;
    left: 0;
    text-align: center;
    padding: 0;
    width: 100%;
    margin: 0;
    text-transform: uppercase;
}
.photogallery-widget .caroufredsel .pg-caption a {
    text-transform: none;
    font-style: italic;
}
.photogallery-widget .caroufredsel .pg-caption.blacklined {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: inline;
    border-radius: 2px;
    padding: 2px 7px;
}
.photogallery-widget .cfs-counter {
    position: absolute;
    bottom: 30px;
    left: 50px;
    z-index: 2;
    color: rgba(255, 255, 255, 0.3);
    font-weight: 700;
    font-style: italic;
}
.photogallery-widget .cfs--pagination {
    height: 100%;
    overflow: hidden;
    width: 25.65%;
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    bottom: auto;
}
.photogallery-widget .pg-thumb {
    width: 50%;
    float: left;
    cursor: pointer;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    -webkit-backface-visibility: hidden;
    position: relative;
    background-color: #1a1c25;
}
.photogallery-widget .pg-thumb > a {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.8;
}
.photogallery-widget .pg-thumb:hover > a,
.photogallery-widget .pg-thumb.selected > a {
    opacity: 1;
}
.photogallery-widget .pg-thumb:hover {
    z-index: 2;
}
.photogallery-widget .pg-thumb .pg-caption {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background: #106ee9;
    padding: 20px 5px 0 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    text-align: left;
    margin: 0;
    opacity: 0;
    visibility: hidden;
}
.photogallery-widget .pg-thumb:nth-child(even) .pg-caption {
    right: 0;
}
.photogallery-widget .pg-thumb:nth-child(odd) .pg-caption {
    left: 0;
}
.photogallery-widget .pg-thumb:nth-child(even):hover .pg-caption {
    opacity: 1;
    visibility: visible;
    right: 100%;
}
.photogallery-widget .pg-thumb:nth-child(odd):hover .pg-caption {
    opacity: 1;
    visibility: visible;
    left: 100%;
}
.photogallery-widget .pg-thumb .pg-caption::before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -6px;
    border: 6px solid transparent;
}
.photogallery-widget .pg-thumb:nth-child(even) .pg-caption::before {
    right: -12px;
    border-left-color: #106ee9;
}
.photogallery-widget .pg-thumb:nth-child(odd) .pg-caption::before {
    left: -12px;
    border-right-color: #106ee9;
}
.photogallery-widget .pg-thumb .pg-caption a {
    color: #fff;
    font-size: 12px;
    opacity: 0.8;
}
.photogallery-widget .pg-thumb .pg-caption a:hover {
    opacity: 1;
}
.photogallery-widget .pg-thumb:nth-child(9):last-child {
    width: 100%;
}
.photogallery-widget .pg-thumb:nth-child(9):last-child .pg-caption {
    left: 0;
    right: auto;
}
.photogallery-widget .pg-thumb .pg-caption {
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

/* bottom-thumbs */
.photogallery-widget.bottom-thumbs .caroufredsel {
    width: 100%;
    position: relative;
}
.photogallery-widget.bottom-thumbs .cfs--navigation {
    width: 100%;
}
.photogallery-widget.bottom-thumbs .cfs--pagination {
    width: 100%;
    width: -webkit-calc(100% + 2px);
    width: calc(100% + 2px);
    position: static;
    height: auto;
    margin-top: 2px;
    margin-left: -2px;
}
.photogallery-widget.bottom-thumbs .pg-thumb {
    width: 20%;
    width: -webkit-calc(20% - 2px);
    width: calc(20% - 2px);
    height: auto;
    margin-bottom: 2px;
    margin-left: 2px;
}
/* end Photo Gallery alternative element */

/* Counter */
.ud_counter {
    margin: 0 0 50px;
}
.ud_counter:hover img[data-animation='fadeOutRightBig'] {
    -webkit-animation: fadeOutRightBig ease-out 1 normal 1s;
    animation: fadeOutRightBig ease-out 1 normal 1s;
}
.ud_counter ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
}
.ud_counter ul li {
    display: inline-block;
    margin: 0 20px 15px 0;
    font-size: 28px;
    font-weight: 700;
    padding: 2px 0;
    width: 70px;
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 2px;
}
.ud_counter ul li p {
    display: block;
    font-size: 13px;
    margin-top: 5px;
    line-height: 1;
    margin-bottom: 15px;
}
.ud_counter ul li.till_lauch {
    height: 80px;
    width: 80px;
    padding: 0;
    vertical-align: top;
}
.till_lauch {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 80px;
    height: 84px;
    margin: 0 20px 14px 22px;
    overflow: hidden;
}
.mail_when_ready {
    float: left;
    margin: 0 0 15px 0;
    width: 100%;
}

/* skills diagram (shortcode) */
#skills_diagram {
    float: right;
    width: 600px;
    position: relative;
}
#skills_diagram .legend {
    position: absolute;
    right: 0;
    top: 40px;
    text-align: right;
}
#skills_diagram #thediagram {
    width: 600px;
    height: 500px;
    margin-top: -50px;
    float: right;
}
#skills_diagram .skills {
    margin: 0;
    padding: 0;
    list-style: none;
}
#skills_diagram .skills li {
    float: right;
    clear: both;
    padding: 0 15px;
    height: 25px;
    line-height: 25px;
    color: white;
    margin-bottom: 1px;
    font-size: 14px;
}

/* Skills diagram element */
.kl-skills-diagram {
    position: relative;
}
.skills-responsive-diagram {
    padding-bottom: 100%;
    height: 0;
    position: relative;
}
.kl-diagram {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.kl-diagram svg {
    overflow: visible !important;
    width: 100%;
    height: 100%;
}

.kl-skills-legend {
    position: absolute;
}
.kl-skills-legend.legend-topright {
    left: 100%;
    top: 40px;
    text-align: left;
}
.kl-skills-legend.legend-bottomright {
    left: 100%;
    bottom: 40px;
    text-align: left;
}
.kl-skills-legend.legend-topleft {
    right: 100%;
    top: 40px;
    text-align: right;
}
.kl-skills-legend.legend-bottomleft {
    right: 100%;
    bottom: 40px;
    text-align: right;
}
.kl-skills-list {
    margin: 0;
    padding: 0;
    list-style: none;
    direction: ltr;
}
.kl-skills-list li {
    padding: 0 13px;
    height: 25px;
    line-height: 25px;
    color: white;
    margin-bottom: 2px;
    font-size: 12px;
    font-weight: 300;
    display: inline-block;
}
.kl-skills-legend > h4 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 0;
}
/* end Skills diagram element */

/* Custom height for carousels */
.carousel-item--height190 {
    height: 190px;
}
.carousel-item--height200 {
    height: 200px;
}
.carousel-item--height240 {
    height: 240px;
}
.carousel-item--height280 {
    height: 280px;
}
.carousel-item--height300 {
    height: 300px;
}
.carousel-item--height500 {
    height: 500px;
}

/* Recent Work Carousel 1 - Style 1 */
.recentwork_carousel__img {
    height: 100% !important;
    width: 100% !important;
}

.recentwork_carousel__crsl {
    margin: 0;
    padding: 0;
    list-style: none;
    height: auto;
}
.recentwork_carousel__crsl .recent-work_carousel-item {
    float: left;
    text-align: center;
    margin: 0;
    padding: 0 20px 0 0;
}
.recentwork_carousel__link {
    display: block;
    padding: 0;
    margin: 0;
    line-height: 0;
}
.recentwork_carousel__hover {
    display: block;
    position: relative;
    overflow: hidden;
}
.recentwork_carousel__hov {
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.recentwork_carousel--1 .recentwork_carousel__hov {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 100%;
    background-color: rgba(35, 35, 35, 0.8);
    display: block;
}
.recentwork_carousel--1 .recentwork_carousel__hov:after {
    content: '\2b';
    position: relative;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -10px;
    opacity: 0;
}
.recentwork_carousel__img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.recentwork_carousel__details {
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.recentwork_carousel--1 .recentwork_carousel__details {
    display: block;
    position: relative;
}
.recentwork_carousel--1 .recentwork_carousel__bg {
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.recentwork_carousel--1 .recentwork_carousel__bg {
    position: absolute;
    top: 0;
    height: 0;
    background: #106ee9;
    width: 100%;
    left: 0;
}
.recentwork_carousel__crsl-title {
    font-size: 1rem;
    line-height: 1.4;
    color: #106ee9;
    padding: 10px 0;
    margin: 0;
    position: relative;
    z-index: 1;
}
.recentwork_carousel__cat {
    font-size: 0.8rem;
    display: block;
    color: #343434;
    line-height: 1.4;
    padding-bottom: 15px;
    position: relative;
    z-index: 1;
}
.recentwork_carousel__link:hover .recentwork_carousel__hov {
    top: 0;
}
.recentwork_carousel__link:hover .recentwork_carousel__hov:after {
    opacity: 1;
}
.recentwork_carousel--1
    .recentwork_carousel__link:hover
    .recentwork_carousel__bg {
    height: 100%;
}
.recentwork_carousel__link:hover .recentwork_carousel__crsl-title,
.recentwork_carousel__link:hover .recentwork_carousel__cat {
    color: #fff;
}
.recentwork_carousel__controls .complete {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    margin: 0 10px;
}
.recentwork_carousel__controls .complete:before {
    content: '';
    border-radius: 2px;
    width: 4px;
    height: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    box-shadow: 6px 0 0 rgba(0, 0, 0, 0.5), 12px 0 0 rgba(0, 0, 0, 0.5),
        0 6px 0 rgba(0, 0, 0, 0.5), 6px 6px 0 rgba(0, 0, 0, 0.5),
        12px 6px 0 rgba(0, 0, 0, 0.5), 0 12px 0 rgba(0, 0, 0, 0.5),
        6px 12px 0 rgba(0, 0, 0, 0.5), 12px 12px 0 rgba(0, 0, 0, 0.5);
}
.recentwork_carousel__controls {
    margin-bottom: 20px;
}
.recentwork_carousel__controls > a {
    opacity: 0.5;
}
.recentwork_carousel__controls > a:hover {
    opacity: 1;
}
.recentwork_carousel__details.nopointer {
    pointer-events: none;
}

/* Recent Work Carousel 1 - Style 2 */
.recentwork_carousel--2 li {
    text-align: left;
    padding: 0 0 0 3px;
}
.recentwork_carousel--2 .recentwork_carousel__link {
    position: relative;
}
.recentwork_carousel--2 .recentwork_carousel__img {
    height: 190px;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-transition: -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
}
.recentwork_carousel--2
    .recentwork_carousel__link:hover
    .recentwork_carousel__img {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.recentwork_carousel--2 .recentwork_carousel__hov {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    -webkit-backface-visibility: hidden;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(0, 0, 0, 0)),
        color-stop(100%, rgba(0, 0, 0, 0.4))
    );
    background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.4) 100%
    );
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.4) 100%
    );
}
.recentwork_carousel--2
    .recentwork_carousel__link:hover
    .recentwork_carousel__hov {
    opacity: 1;
    visibility: visible;
}
.recentwork_carousel--2 .recentwork_carousel__details {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15px;
    opacity: 0;
    visibility: hidden;
    padding: 20px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: bottom 0.25s ease, opacity 0.25s ease,
        visibility 0.25s ease;
    transition: bottom 0.25s ease, opacity 0.25s ease, visibility 0.25s ease;
}
.recentwork_carousel--2 .recentwork_carousel__link:hover .details {
    opacity: 1;
    visibility: visible;
    bottom: 0;
}
.recentwork_carousel--2 .recentwork_carousel__cat {
    background-color: #106ee9;
    display: inline-block;
    padding: 6px;
    text-transform: uppercase;
    border-radius: 2px;
    font-size: 11px;
    line-height: 1;
    z-index: 1;
}
.recentwork_carousel--2 .recentwork_carousel__crsl-title {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 0;
}
.recentwork_carousel--2 .recentwork_carousel__crsl-title,
.recentwork_carousel--2 .recentwork_carousel__cat {
    color: #fff;
}
.recentwork_carousel--2 .recentwork_carousel__title {
    color: #595959;
    font-weight: bold;
    position: relative;
    padding: 0 0 15px;
    margin: 0 0 30px;
}
.recentwork_carousel--2 .recentwork_carousel__title:after {
    position: absolute;
    content: '';
    display: block;
    width: 48px;
    height: 3px;
    background-color: #106ee9;
    bottom: 0;
    left: 0;
}
.recentwork_carousel--2 .recentwork_carousel__desc {
    margin-bottom: 30px;
}
.recentwork_carousel--2 .btn {
    font-size: 11px;
}
.recentwork_carousel--2 .recentwork_carousel__controls {
    float: right;
    margin-top: 6px;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}
.recentwork_carousel--2:hover .recentwork_carousel__controls {
    opacity: 1;
}
.recentwork_carousel--2 .recentwork_carousel__controls .glyphicon {
    display: none;
}
.recentwork_carousel--2 .hgSlickNav-arr {
    position: relative;
    width: 24px;
    height: 24px;
    display: inline-block !important;
}
.recentwork_carousel--2 .recentwork_carousel__next:before,
.recentwork_carousel--2 .recentwork_carousel__prev:after,
.recentwork_carousel--2 .recentwork_carousel__next:after,
.recentwork_carousel--2 .recentwork_carousel__prev:before {
    position: absolute;
    content: '';
    left: 7px;
    width: 10px;
    height: 2px;
    background-color: #2c2c2c;
}
.recentwork_carousel--2 .recentwork_carousel__next:before,
.recentwork_carousel--2 .recentwork_carousel__prev:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.recentwork_carousel--2 .recentwork_carousel__next:after,
.recentwork_carousel--2 .recentwork_carousel__prev:before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.recentwork_carousel--2 .recentwork_carousel__next:after,
.recentwork_carousel--2 .recentwork_carousel__prev:after {
    top: 14px;
}
.recentwork_carousel--2 .recentwork_carousel__next:before,
.recentwork_carousel--2 .recentwork_carousel__prev:before {
    top: 8px;
}

/* Recent work 1 - Style 3 */
.rwc--3 .recentwork_carousel__title {
    font-size: 28px;
    letter-spacing: -1px;
}
.container-fluid .recentwork_carousel--2 {
    margin-bottom: 0;
}
.container-fluid .recentwork_carousel--2 .recentwork_carousel__crsl-wrapper {
    padding-right: 15px;
}

/* Recent work carousel 2 - style1 */
.recentwork_carousel_v2 {
    position: relative;
    margin-bottom: 40px;
}
.recentwork_carousel_v2 .recentwork_carousel__controls {
    position: absolute;
    right: 0;
    top: 5px;
}
.recentwork_carousel_v2 .recentwork_carousel__crsl-wrapper {
    margin-left: -10px;
    margin-right: -10px;
}
.recentwork_carousel_v2 li {
    padding: 0 10px;
}
.recentwork_carousel_v2 .recentwork_carousel__link {
    position: relative;
    overflow: hidden;
}
.recentwork_carousel_v2 .recentwork_carousel__details {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 0;
    background: -webkit-gradient(
        radial,
        center center,
        0,
        center center,
        100%,
        color-stop(0%, rgba(0, 0, 0, 0.25)),
        color-stop(1%, rgba(0, 0, 0, 0.25)),
        color-stop(100%, rgba(0, 0, 0, 0.65))
    );
    background: -webkit-radial-gradient(
        center,
        ellipse cover,
        rgba(0, 0, 0, 0.25) 0%,
        rgba(0, 0, 0, 0.25) 1%,
        rgba(0, 0, 0, 0.65) 100%
    );
    background: -webkit-radial-gradient(
        center,
        ellipse,
        rgba(0, 0, 0, 0.25) 0%,
        rgba(0, 0, 0, 0.25) 1%,
        rgba(0, 0, 0, 0.65) 100%
    );
    background: radial-gradient(
        ellipse at center,
        rgba(0, 0, 0, 0.25) 0%,
        rgba(0, 0, 0, 0.25) 1%,
        rgba(0, 0, 0, 0.65) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#a6000000',GradientType=1 );
}
.recentwork_carousel_v2
    .recentwork_carousel__link:hover
    .recentwork_carousel__details {
    opacity: 1;
}
.recentwork_carousel_v2 .recentwork_carousel__plus {
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    background: #106ee9;
    font-size: 16px;
    color: #fff;
    top: 50%;
    margin-top: -12px;
    left: 50%;
    margin-left: -12px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}
.recentwork_carousel_v2 .recentwork_carousel__crsl-title {
    position: absolute;
    font-size: 1rem;
    top: 65%;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0; /*text-shadow: -1px -1px 0 rgba(0,0,0,0.3);*/
    color: #fff;
}
.recentwork_carousel_v2 .recentwork_carousel__cat {
    position: absolute;
    font-size: 0.8rem;
    top: 80%;
    left: 0;
    right: 0;
    padding: 0; /*text-shadow: -1px -1px 0 rgba(0,0,0,0.3);*/
    color: #fff;
}

.recent_works3 .carousel-item--height {
    height: 165px;
}

/* Recent work carousel 3 */
.recentwork_carousel_v3 {
    position: relative;
    margin-bottom: 40px;
}
.recentwork_carousel_v3 .recentwork_carousel__crsl-wrapper {
    margin-left: -5px;
    margin-right: -5px;
}
.recentwork_carousel_v3 li {
    padding: 0 5px;
}
.recentwork_carousel_v3 .recentwork_carousel__link {
    position: relative;
    overflow: hidden;
}
.recentwork_carousel_v3 .recentwork_carousel__details {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15px;
    opacity: 0;
    padding: 20px;
    -webkit-transition: bottom 0.25s ease, opacity 0.25s ease,
        visibility 0.25s ease;
    transition: bottom 0.25s ease, opacity 0.25s ease, visibility 0.25s ease;
    -webkit-backface-visibility: hidden;
    text-align: start;
    padding-top: 80px;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(0, 0, 0, 0)),
        color-stop(100%, rgba(0, 0, 0, 0.4))
    );
    background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.4) 100%
    );
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.4) 100%
    );
}

.recentwork_carousel_v3 .recentwork_carousel__cat {
    background-color: #106ee9;
    display: inline-block;
    padding: 6px;
    text-transform: uppercase;
    border-radius: 2px;
    font-size: 11px;
    line-height: 1.6;
    z-index: 1;
}
.recentwork_carousel_v3 .recentwork_carousel__crsl-title {
    font-size: 1.2rem;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 0;
    color: #595959;
    font-weight: 600;
    position: relative;
    padding: 0;
    margin: 15px 0 0;
}
.recentwork_carousel_v3 .recentwork_carousel__crsl-title,
.recentwork_carousel_v3 .recentwork_carousel__cat {
    color: #fff;
}
.recentwork_carousel_v3 .recentwork_carousel__top-container {
    padding-top: 25px;
    padding-bottom: 35px;
}
.recentwork_carousel_v3 .recentwork_carousel__title {
    font-size: 32px;
    font-weight: 700;
    color: #343434;
    line-height: 1;
}
.recentwork_carousel_v3 .btn {
    color: #585858;
    line-height: 1;
    padding: 0;
    text-decoration: none;
}
.recentwork_carousel_v3 .btn:hover {
    color: #343434;
}
.recentwork_carousel_v3 .btn::before {
    content: '';
    background-color: #106ee9;
    margin-right: 5px;
    width: 10px;
    height: 3px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px;
}
.recentwork_carousel_v3
    .recentwork_carousel__link:hover
    .recentwork_carousel__details {
    opacity: 1;
    visibility: visible;
    bottom: 0;
}
.recentwork_carousel_v3 .recentwork_carousel__img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-transition: -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
}
.recentwork_carousel_v3
    .recentwork_carousel__link:hover
    .recentwork_carousel__img {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.recentwork_carousel_v3 .recentwork_carousel__controls {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 0;
}
.recentwork_carousel_v3:hover .recentwork_carousel__controls {
    opacity: 1;
}
.recentwork_carousel_v3 .recentwork_carousel__controls .glyphicon {
    display: none;
}
.recentwork_carousel_v3 .recentwork_carousel__next,
.recentwork_carousel_v3 .recentwork_carousel__prev {
    position: relative;
    width: 24px;
    height: 24px;
    display: inline-block !important;
}
.recentwork_carousel_v3 .recentwork_carousel__next:before,
.recentwork_carousel_v3 .recentwork_carousel__prev:after,
.recentwork_carousel_v3 .recentwork_carousel__next:after,
.recentwork_carousel_v3 .recentwork_carousel__prev:before {
    position: absolute;
    content: '';
    left: 7px;
    width: 10px;
    height: 2px;
    background-color: #2c2c2c;
}
.recentwork_carousel_v3 .recentwork_carousel__next:before,
.recentwork_carousel_v3 .recentwork_carousel__prev:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.recentwork_carousel_v3 .recentwork_carousel__next:after,
.recentwork_carousel_v3 .recentwork_carousel__prev:before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.recentwork_carousel_v3 .recentwork_carousel__next:after,
.recentwork_carousel_v3 .recentwork_carousel__prev:after {
    top: 14px;
}
.recentwork_carousel_v3 .recentwork_carousel__next:before,
.recentwork_carousel_v3 .recentwork_carousel__prev:before {
    top: 8px;
}
/* Full-Width V3 */
.container-fluid .recentwork_carousel_v3 .recentwork_carousel__crsl-wrapper {
    margin-left: -10%;
    margin-right: -10%;
}

/*cfs-nav*/
.works_carousel .cfs--navigation a {
    display: block;
    width: 43px;
    height: 43px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    line-height: 40px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    color: #eee;
    color: rgba(255, 255, 255, 0.15);
}
.works_carousel .cfs--prev {
    text-align: left;
    left: 10px;
}
.works_carousel .cfs--next {
    text-align: right;
    right: 10px;
}
.works_carousel .bottom-nav .cfs--prev {
    text-align: center;
    left: auto;
    right: 30px;
    bottom: 0;
    top: auto;
}
.works_carousel .bottom-nav .cfs--next {
    text-align: center;
    right: 0;
    bottom: 0;
    top: auto;
    margin: 0;
}

/*cfs--pagination*/
.works_carousel .cfs--pagination {
    width: 100%;
    position: absolute;
    text-align: center;
    z-index: 2;
    left: 0;
    bottom: 0;
}
.works_carousel .cfs--pagination > a {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin: 2px 3px;
    background: #d9d9d9;
    box-shadow: none;
    border-radius: 50%;
    text-decoration: none;
}
.works_carousel .cfs--pagination > a:hover {
    background: #333;
    background: rgba(0, 0, 0, 0.5);
}
.works_carousel .cfs--pagination > a.selected {
    background: #f4f4f4;
    box-shadow: 0 0 0 3px #fe6060;
    width: 8px;
    height: 8px;
    margin: 3px 4px;
}

.works_carousel .cfs--pagination {
    bottom: -50px;
}
.works_carousel .cfs--pagination a {
    margin: 0 2px;
    width: 6px;
    height: 6px;
    box-shadow: none;
    background: #fff;
    background: rgba(255, 255, 255, 0.5);
}
.works_carousel .cfs--pagination a.selected {
    background: #afafaf;
    background: rgba(255, 255, 255, 0.96);
    cursor: default;
    box-shadow: none;
    width: 6px;
    height: 6px;
    margin: 0 2px;
}
.works_carousel .cfs--navigation a {
    opacity: 0;
    cursor: pointer;
    margin: -40px 0 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
.works_carousel .cfs--navigation a::before {
    font-family: 'Glyphicons Halflings';
    color: rgba(255, 255, 255, 0.2);
    font-size: 50px;
}

.works_carousel .cfs--prev {
    left: -70px;
    padding-right: 40px;
}
.works_carousel .cfs--next {
    right: -70px;
    padding-left: 40px;
    text-align: center;
    width: 40px;
}
.works_carousel .caroufredsel:hover .cfs--prev {
    opacity: 1;
    left: -80px;
}
.works_carousel .caroufredsel:hover .cfs--next {
    opacity: 1;
    right: -80px;
}
.works_carousel .cfs--navigation a:hover::before {
    color: #106ee9;
}
.works_carousel .cfs--prev::before {
    content: '\e257';
}
.works_carousel .cfs--next::before {
    content: '\e258';
}
.works_carousel .fancy-title {
    color: #fff;
    margin-bottom: 0;
}
.works_carousel .details [data-role='title'] {
    color: #fff;
    font-weight: 700;
    font-size: 15px;
}
/* Hoverlay style */
.works_carousel.stl-hoverlay .caroufredsel_wrapper {
    margin: 0;
}
.works_carousel.stl-hoverlay .inner-slide {
    position: relative;
    margin: 0;
    color: #fff;
}
.works_carousel.stl-hoverlay .inner-slide .imgprev {
    margin: 0;
}
.works_carousel.stl-hoverlay .inner-slide .imgprev img {
    width: 100%;
    display: block;
}
.works_carousel.stl-hoverlay .inner-slide .imgprev::after {
    display: none;
}
.works_carousel.stl-hoverlay .inner-slide .details {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fe6060;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
}
.works_carousel.stl-hoverlay .inner-slide:hover .details {
    opacity: 1;
    visibility: visible;
}
.works_carousel.stl-hoverlay .dt-inner {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
}
.works_carousel.stl-hoverlay .inner-slide [data-role='title'] {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}
/* simple style */
.works_carousel.stl-simple .caroufredsel_wrapper {
    margin: 0;
}
.works_carousel.stl-simple .inner-slide {
    position: relative;
    margin: 0;
}
.works_carousel.stl-simple .inner-slide .imgprev {
    margin: 0;
    background: #242731;
}
/*.works_carousel.stl-simple .inner-slide .imgprev img {width: 100%; display: block;}*/
.works_carousel.stl-simple .inner-slide:hover .imgprev img {
    opacity: 0.6;
}
.works_carousel.stl-simple .inner-slide .imgprev::after {
    display: none;
}
.works_carousel.stl-simple .inner-slide .details {
    display: block;
    padding: 30px 0 0;
    border-left: 1px solid #eeeeee;
    min-height: 105px;
}
.works_carousel.stl-simple .inner-slide [data-role='title'] {
    font-size: 16px;
    margin: 0;
    font-weight: 600;
}
.works_carousel.stl-simple .inner-slide [data-role='title'] a {
    color: #242731;
    text-decoration: none;
}
.works_carousel.stl-simple .inner-slide:hover [data-role='title'] a,
.works_carousel.stl-simple .inner-slide [data-role='title'] a:hover {
    color: #106ee9;
}
.works_carousel.stl-simple .inner-slide [data-role='category'] {
    font-size: 13px;
    margin-top: 0;
    margin-bottom: 0;
    color: #ceced0;
}
.stg-textcenter.caroufredsel .inner-slide .details {
    text-align: center;
}
/* Navigation style for full-width style */
.works_carousel.stl-fullwidth .cfs--navigation a {
    opacity: 0.2;
    cursor: pointer;
    position: absolute;
    top: 0;
    margin: 0;
    padding: 0;
    width: 35px;
    height: 100%;
    background: url(../images/transparent_png/white40.png);
    background: rgba(255, 255, 255, 0.4);
}
.works_carousel.stl-fullwidth .cfs--navigation a:hover {
    background: url(../images/transparent_png/white70.png);
    background: rgba(255, 255, 255, 0.7);
    width: 50px;
}
.works_carousel.stl-fullwidth .cfs--navigation a::before {
    color: #333;
    color: rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
}
.works_carousel.stl-fullwidth .cfs--navigation a:hover::before {
    color: #fe6060;
}
.works_carousel.stl-fullwidth .cfs--prev,
.works_carousel.stl-fullwidth .cfs--prev:hover {
    left: 0;
    text-align: center;
}
.works_carousel.stl-fullwidth .cfs--next,
.works_carousel.stl-fullwidth .cfs--next:hover {
    right: 0;
    text-align: center;
}
/* end Image boxes carousel element */

/*contact form */
form .row {
    margin-left: -5px;
    margin-right: -5px;
}
/*   form .row [class*="col-"] { padding-left: 5px; padding-right: 5px; }   */
.inline-form {
    font-size: 0;
}
.contact_form textarea {
    height: 100px;
}
.inline-form .form-group,
.contact_form .form-group {
    padding: 0 8px;
}
.contact_form .form-control {
    font-size: 13px;
    padding: 14px;
    height: auto;
    outline: none;
    box-shadow: inset 1px 2px 0 rgba(0, 0, 0, 0.06);
    border: 1px solid #d9d9d9;
    overflow-x: hidden;
}

.form-control:focus {
    border: none;
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
}
.kl-fancy-form {
    position: relative;
}
.inline-form .kl-fancy-form label,
.contact_form .kl-fancy-form label {
    left: 25px;
}
.kl-fw-form .form-control,
.kl-fw-form.form-control {
    width: 100%;
}
.kl-hw-field {
    width: 50%;
    display: inline-block;
}
.kl-fancy-form label {
    color: #969696;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    top: 6px;
    left: 16px;
    cursor: text;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
    font-weight: 700;
}
.kl-fancy-form input:focus + label,
.kl-fancy-form textarea:focus + label {
    color: #c0c0c0;
}

/* Selectbox fix for Chrome/Safari on OSX */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .kl-fancy-form select.form-control {
        height: 52px;
        -webkit-appearance: none;
        box-shadow: inset 1px 2px 0 rgba(0, 0, 0, 0.06);
        background-image: url(../images/select-arrow.svg);
        background-repeat: no-repeat;
        background-size: 6px 12px;
        background-position-y: center;
        background-position-x: calc(100% - 10px);
    }
}

.kl-fancy-form input::-webkit-input-placeholder {
    color: #bababa;
}
.kl-fancy-form input:focus::-webkit-input-placeholder {
    color: #d7d7d7;
}
/* Firefox < 19 */
.kl-fancy-form input:-moz-placeholder {
    color: #bababa;
}
.kl-fancy-form input:focus:-moz-placeholder {
    color: #d7d7d7;
}
/* Firefox > 19 */
.kl-fancy-form input::-moz-placeholder {
    color: #bababa;
}
.kl-fancy-form input:focus::-moz-placeholder {
    color: #d7d7d7;
}
/* Internet Explorer 10 */
.kl-fancy-form input:-ms-input-placeholder {
    color: #bababa;
}
.kl-fancy-form input:focus:-ms-input-placeholder {
    color: #d7d7d7;
}
textarea.form-control {
    width: 100%;
    max-width: 100%;
}

/* TWITTER FEED */
#twitterFeed {
    background: url(../images/twitter-bird.png) no-repeat 15px center #444;
    font-size: 12px;
    color: #fff;
    padding: 15px 75px 15px 60px;
    position: relative;
    margin-bottom: 15px;
    font-style: italic;
    border-radius: 3px;
}
#twitterFeed:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 15px;
    border: 5px solid transparent;
    border-top-color: #444;
}
#twitterFeed ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 1rem;
}
#twitterFeed .twTime {
    display: inline-block;
    color: #9d9d9d;
    padding-left: 10px;
    border-left: 1px solid #707070;
    font-size: 11px;
    position: absolute;
    right: -65px;
    top: 0;
    bottom: 0;
    width: 45px;
    vertical-align: middle;
    line-height: 1;
}
#twitterFeed > div:not(:only-child) {
    margin-bottom: 10px;
}
.twitterFeed {
    margin-bottom: 30px;
}
.twitter-feed > iframe {
    max-height: 20px;
}
#twitterFeed .timePosted {
    display: inline-block;
    color: #9d9d9d;
    padding-left: 10px;
    border-left: 1px solid #707070;
    font-size: 0.9rem;
    position: absolute;
    right: 15px;
    top: 15px;
    bottom: 15px;
    width: 60px;
    vertical-align: middle;
    line-height: 1;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}
#twitterFeed .timePosted a {
    -ms-flex-item-align: center !important;
    align-self: center !important;
}
#twitterFeed p {
    margin-bottom: 0;
}
#twitterFeed p.tweet a {
    text-decoration: underline;
}

/* SOCIAL SHARING */
.social-share {
    margin: 10px 0 0;
    padding: 0;
    list-style: none;
}
.social-share li {
    display: inline-block;
    margin-left: 10px;
    width: 90px;
    max-height: 20px;
    vertical-align: top;
}
.social-share li.sc-gplus {
    width: 70px;
}
.social-share li.sc-pinterest > a {
    width: 40px !important;
    margin-left: -40px !important;
    vertical-align: top !important;
}
.social-share.sc--colored {
    margin-top: 15px;
}
.social-share.sc--colored li {
    padding: 10px;
    border-radius: 3px;
}
.social-share.sc--colored li.sc-facebook {
    background: #3b5998;
}
.social-share.sc--colored li.sc-twitter {
    background: #3cf;
}
.social-share.sc--colored li.sc-gplus {
    background: #d34836;
    width: 70px;
}
.social-share.sc--colored li.sc-pinterest {
    background: #cb2027;
}
.social-share div {
    margin-right: 10px;
}
.social-share div:last-child {
    margin-right: 0;
}
.social-share2 {
    margin: 0px 0 30px;
    padding: 0;
    list-style: none;
    text-align: left;
}
#fb-root {
    margin: 0;
}

/* TESTIMONIALS BOX */
.testimonial_box {
    padding: 0 0 0 25px;
    margin: 15px 0;
    width: 100%;
    padding-left: 0;
    position: relative;
}
.testimonial_box[data-size='small'] {
    width: 30%;
}
.testimonial_box[data-size='medium'] {
    width: 50%;
}
.testimonial_box[data-size='large'] {
    width: 70%;
}
.testimonial_box[data-size='full'] {
    width: 100%;
}
.testimonial_box[data-size='onethird'] {
    width: 33%;
}
.testimonial_box[data-size='twothirds'] {
    width: 66%;
}
.testimonial_box[data-theme='light'] blockquote {
    background: #ddd;
    color: #343434;
}
.testimonial_box[data-theme='dark'] blockquote {
    background: #707070;
    color: #f5f5f5;
}

.testimonial_box[data-theme='light'][data-align='left'] blockquote:after {
    border-right-color: #fff;
}
.testimonial_box[data-theme='dark'][data-align='left'] blockquote:after {
    border-right-color: #707070;
}
.testimonial_box[data-theme='light'][data-align='right'] blockquote:after {
    border-left-color: #ddd;
}
.testimonial_box[data-theme='dark'][data-align='right'] blockquote:after {
    border-left-color: #707070;
}
.testimonial_box[data-theme='light'][data-align='top'] blockquote:after {
    border-bottom-color: #ddd;
}
.testimonial_box[data-theme='dark'][data-align='top'] blockquote:after {
    border-bottom-color: #707070;
}
.testimonial_box[data-theme='light'][data-align='bottom'] blockquote:after {
    border-top-color: #ddd;
}
.testimonial_box[data-theme='dark'][data-align='bottom'] blockquote:after {
    border-top-color: #707070;
}

.testimonial_box[data-align='left'] .details {
    float: left;
    margin-right: 10px;
    width: 90px;
}
.testimonial_box[data-align='left'] blockquote {
    margin-left: 90px;
}
.testimonial_box[data-align='left'] blockquote:after {
    left: -14px;
    top: 50%;
}

.testimonial_box[data-align='right'] .details {
    float: right;
    margin-left: 10px;
    width: 90px;
    text-align: right;
}
.testimonial_box[data-align='right'] blockquote {
    margin-right: 90px;
}
.testimonial_box[data-align='right'] blockquote:after {
    right: -14px;
    top: 50%;
}

.testimonial_box[data-align='top'] .details {
    float: none;
    margin-left: 10px;
    width: 100%;
    position: relative;
    z-index: 1;
}
.testimonial_box[data-align='top'] .details img {
    position: absolute;
    top: -5px;
    right: -5px;
}
.testimonial_box[data-align='top'] .details h6 strong {
    display: block;
}
.testimonial_box[data-align='top'] .details h6 {
    margin-top: 0;
}
.testimonial_box[data-align='top'] blockquote {
    margin-top: 14px;
    clear: both;
}
.testimonial_box[data-align='top'] blockquote:after {
    left: 30px;
    top: -14px;
}

.testimonial_box[data-align='bottom'] {
    margin-bottom: 35px;
}
.testimonial_box[data-align='bottom'] .details {
    position: absolute;
    left: 25px;
    right: 5px;
    top: 100%;
    z-index: 1;
}
.testimonial_box[data-align='bottom'] .details img {
    float: right;
    margin-top: -20px;
}
.testimonial_box[data-align='bottom'] .details h6 strong {
    display: block;
}
.testimonial_box[data-align='bottom'] blockquote {
    margin-bottom: 0;
    float: none;
    position: relative;
    z-index: 0;
}
.testimonial_box[data-align='bottom'] blockquote:after {
    left: 30px;
    bottom: -14px;
}

.testimonial_box .details h6 {
    font-size: 0.8rem;
    font-weight: normal;
    margin: 10px 0 0;
    line-height: 1.4;
}
.testimonial_box .details h6 strong {
    display: block;
}
.testimonial_box blockquote {
    margin: 0;
    padding: 20px;
    border: 0;
    font-size: 1rem;
    font-style: italic;
    position: relative;
    border-radius: 3px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}
.testimonial_box blockquote:after {
    content: '';
    position: absolute;
    border-width: 7px;
    border-style: solid;
    border-color: transparent;
}

.testimonial_box:hover blockquote {
    box-shadow: 0 2px 13px rgba(0, 0, 0, 0.4);
}

.testimonial_box .details img {
    border-radius: 50%;
}

/* Stats boxes */
.stats_box .mb_title {
    background: none;
}
.statbox {
    background: #fff;
    padding: 15px;
    margin-bottom: 25px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.statbox img {
    margin-right: 15px;
}
.statbox__fonticon {
    margin-right: 15px;
    font-size: 46px;
    color: #8f8f8f;
    vertical-align: top;
    height: 48px;
    line-height: 51px;
}
.statbox h4 {
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: uppercase;
    color: #106ee9;
    line-height: 1.4;
    margin-top: 0;
}
.statbox h6 {
    color: #969696;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 0.8rem;
    line-height: 1.4;
}

/* Services boxes widget */
.services_boxes_wgt {
    position: relative;
    z-index: 1;
}
.services_boxes_wgt ul[class*='sbox-'] {
    float: left;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
}
.services_boxes_wgt ul[class*='sbox-'] > li {
    float: left;
}
.services_boxes_wgt .intro-col + ul[class*='sbox-'] {
    width: 75%;
}
.services_boxes_wgt .sbox-1 > li {
    width: 100%;
}
.services_boxes_wgt .sbox-2 > li {
    width: 50%;
}
.services_boxes_wgt .sbox-3 > li {
    width: 33.33%;
}
.services_boxes_wgt .sbox-4 > li {
    width: 25%;
}
.services_boxes_wgt .sbox-5 > li {
    width: 20%;
}
.services_boxes_wgt .intro-col {
    float: left;
    width: 25%;
    color: #888888;
}
.services_boxes_wgt .inner-box {
    background: #fff;
    padding: 35px 30px;
    margin: 0 0 0 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    position: relative;
}
.services_boxes_wgt li [data-role='title'] {
    font-size: 15px;
    font-weight: 600;
    color: #4d4d4d;
    vertical-align: top;
    line-height: 1.5;
    margin: 0;
    padding: 0;
}
.services_boxes_wgt li [data-role='title'] .kl-icon {
    color: #c5c5c5;
    font-size: 40px;
    line-height: 1;
    float: left;
    margin-right: 20px;
    vertical-align: middle;
    display: block;
    -webkit-transition: color 0.3s ease-out;
    transition: color 0.3s ease-out;
}
.services_boxes_wgt .hover-details {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    width: 100%;
    background: #fff;
    padding: 35px 30px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    left: 0;
    margin-top: 0;
    z-index: 9;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.colored-area .services_boxes_wgt .hover-details,
.colored-area .services_boxes_wgt .inner-box {
    background: #fff;
}
.services_boxes_wgt .hover-details::after {
    background: #e8e8e8;
    position: absolute;
    top: 15px;
    left: 0;
    content: '';
    height: 1px;
    margin-left: 35px;
    margin-right: 35px;
    width: -webkit-calc(100% - 70px);
    width: calc(100% - 70px);
}
.services_boxes_wgt li:hover .hover-details {
    opacity: 1;
    visibility: visible;
    margin-top: -30px;
}
.services_boxes_wgt li:hover [data-role='title'] .kl-icon {
    color: #fe6060;
}
.read-more-link {
    color: #a3a4a4;
    font-size: 12px;
    line-height: 1.6;
    border-bottom: 1px solid #d1d1d1;
    padding-bottom: 3px;
    text-decoration: none;
}
.read-more-link:hover {
    color: #888;
    text-decoration: none;
    border-bottom-color: #fe6060;
}

/* Logos / clients list */
.logos-bar {
    bottom: 100%;
    width: 100%;
}
.logos-bar--relative {
    position: relative;
    width: 100%;
}
.logolist {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.logolist li {
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    zoom: 1;
}
.logolist li img {
    opacity: 0.8;
}
.logolist li:hover img {
    opacity: 1;
}
.no-flexbox .logolist {
    text-align: justify;
}
.no-flexbox .logolist li {
    min-height: 75px;
}
.no-flexbox .logolist:after {
    content: '';
    width: 100%;
    display: inline-block;
    font-size: 0;
    line-height: 0;
    min-height: 0;
}

/* Fluid Container with normal width support */
/* Does not work with nesting columns, so basically can be used only for first level of grid columns */
.col-normal {
    position: relative;
    min-height: 1px;
    margin-left: -15px;
    margin-right: -15px;
    -webkit-transition: padding 0.2s ease-out;
    transition: padding 0.2s ease-out;
}
html:not(.js) .col-normal {
    margin: 0;
}

/* Animated Charts */
.chart {
    position: relative;
    display: inline-block;
    width: 110px;
    height: 110px;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
}
.chart canvas {
    position: absolute;
    top: 0;
    left: 0;
}
.percent {
    display: inline-block;
    line-height: 110px;
    z-index: 2;
}
.percent::after {
    content: '%';
    margin-left: 0.1em;
    font-size: 0.8em;
}

/* funding percentage */
.fundedperc {
    background-image: url(../images/bgfunded.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.fundedperc .chart {
    width: auto;
    height: auto;
    margin: 64px auto;
    min-height: 300px;
}
.fundedperc .chart canvas {
    position: static;
}
.fundedperc .percent {
    position: absolute;
    font-size: 25px;
    font-weight: 600;
    display: block;
    line-height: 1;
    text-align: center;
    width: 100%;
    margin-top: 81px;
    letter-spacing: 11px;
    color: rgba(255, 255, 255, 0.7);
}
.fundedperc .percent::after {
    content: '';
    display: none;
}
.fundedperc .percent span {
    font-size: 80px;
    font-weight: 700;
    display: block;
    line-height: 1.2;
    letter-spacing: 0;
    color: #fff;
}
.fundedperc .percent span::after {
    content: '%';
    margin-left: 0.1em;
    font-weight: 300;
}

/* World Map Offices */
.world-map-offices {
    position: relative;
}
.world-map-offices > img {
    opacity: 0.2;
}
.world-map-offices .world-office {
    position: absolute;
    padding-bottom: 20px;
    min-width: 7px;
    min-height: 7px;
    z-index: 1;
}
.world-map-offices .world-office:hover {
    z-index: 2;
}
.world-map-offices .world-office::before {
    content: '';
    width: 7px;
    height: 7px;
    background: #f0d228;
    border-radius: 50%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-top: 0;
    cursor: pointer;
    z-index: 1;
}
.world-map-offices .world-office:hover::before,
.world-map-offices .world-office.selected::before {
    box-shadow: 0 0 0 4px #fe6060;
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
}
.world-map-offices .office-details {
    color: #7b7b7b;
    font-size: 12px;
    font-weight: 600;
    min-width: 310px;
    opacity: 0;
    visibility: hidden;
    position: relative;
    left: -3999px;
    -webkit-transition: opacity 0.3s ease-in, visibility 0.3s ease-in;
    transition: opacity 0.3s ease-in, visibility 0.3s ease-in;
    position: absolute;
    top: -140px;
    padding-bottom: 30px;
}
.world-map-offices .world-office:hover .office-details,
.world-map-offices .world-office.selected .office-details {
    opacity: 1;
    visibility: visible;
    left: -118px;
}
.world-map-offices .office-details::before {
    content: '';
    position: absolute;
    left: 120px;
    bottom: 18px;
    border-top: 12px solid #fff;
    border-right: 12px solid rgba(0, 0, 0, 0);
}
.world-map-offices .office-content {
    padding: 7px 25px 0;
    position: relative;
    overflow: hidden;
    min-height: 130px;
    max-height: 130px;
    background: #fff;
    border-radius: 0 2px 2px 0;
    overflow-y: visible;
}
.world-map-offices .office-content h5 {
    color: #1a1c25;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    margin: 5px 0 7px;
}
.world-map-offices .office-content p {
    font-size: 0.9rem;
}
.world-map-offices .office-content p:last-child {
    margin-bottom: 0;
}
.world-map-offices .office-details > a {
    float: left;
}
.world-map-offices .office-details > a img {
    border-radius: 2px 0 0 2px;
}
/* World Map Trigger Links */
.world-map-triggers {
    max-width: 400px;
    margin-bottom: 150px;
    overflow: hidden;
}
ul.world-map-triggers[data-columns] li {
    display: inline-block;
    float: left;
    margin-left: 20px;
}
ul.world-map-triggers[data-columns='3'] li {
    width: -webkit-calc(33.3333% - 20px);
    width: calc(33.3333% - 20px);
}
.world-map-triggers .office-name {
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    line-height: 1.4;
    padding: 5px 15px;
    margin-bottom: 5px;
    display: inline-block;
    border: 1px solid transparent;
    border-radius: 3px;
}
.world-map-triggers .office-name.selected,
.world-map-triggers .office-name:hover {
    border-color: #f0d228;
}

/* Fake Loading */
.fake-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 1;
    pointer-events: none;
    -webkit-animation: fake-loading 0.4s ease-in 2s 1 normal forwards;
    animation: fake-loading 0.4s ease-in 2s 1 normal forwards;
}
.fake-loading.fl--nobg {
    background: none;
}
.fake-loading.loading-1s {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}
.fake-loading.loading-2s {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.fake-loading.loading-4s {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}
.fake-loading.loading-8s {
    -webkit-animation-delay: 8s;
    animation-delay: 8s;
}
.fake-loading.loading-10s {
    -webkit-animation-delay: 10s;
    animation-delay: 10s;
}
.fake-loading:after {
    content: '';
    border: 2px solid rgba(205, 33, 34, 0.16);
    border-top-color: #106ee9;
    border-right-color: #106ee9;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
    -webkit-animation: fake-loading-circle 0.5s infinite linear;
    animation: fake-loading-circle 0.5s infinite linear;
}
@-webkit-keyframes fake-loading {
    0% {
        visibility: visible;
        opacity: 1;
    }
    100% {
        visibility: hidden;
        opacity: 0;
    }
}
@keyframes fake-loading {
    0% {
        visibility: visible;
        opacity: 1;
    }
    100% {
        visibility: hidden;
        opacity: 0;
    }
}
@-webkit-keyframes fake-loading-circle {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes fake-loading-circle {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* Bootstrap code examples
-------------------------------------------------- */

/* Base class */
.bs-docs-example {
    position: relative;
    margin: 15px 0;
    padding: 39px 19px 14px;
    *padding-top: 19px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
}
/* Echo out a label for the example */
.bs-docs-example:after {
    content: 'Example';
    position: absolute;
    top: -1px;
    left: -1px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: bold;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    color: #9da0a4;
    border-radius: 4px 0 4px 0;
}
/* Remove spacing between an example and it's code */
.bs-docs-example + .prettyprint {
    margin-top: -20px;
    padding: 0;
    padding-top: 15px;
}
/* Tweak examples  */
.bs-docs-example > p:last-child {
    margin-bottom: 0;
}
/* Special grid styles  */
.show-grid {
    margin-top: 10px;
    margin-bottom: 20px;
}
.show-grid [class*='span'] {
    background-color: #eee;
    text-align: center;
    border-radius: 3px;
    min-height: 40px;
    line-height: 40px;
}
.show-grid:hover [class*='span'] {
    background: #ddd;
}
.show-grid .show-grid {
    margin-top: 0;
    margin-bottom: 0;
}
.show-grid .show-grid [class*='span'] {
    background-color: #ccc;
}

/* Icon list (for demo purposes) */
.kl-icon-list {
    margin: 0 0 30px;
    padding: 0px;
    list-style: none;
}
.kl-icon-list li {
    width: 13%;
    display: inline-block;
    font-size: 32px;
    margin: 0 6px 10px;
    text-align: center;
    float: left;
    padding: 30px 0;
    border-radius: 3px;
    border: 1px solid transparent;
    color: #999;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
    position: relative;
    cursor: pointer;
}
.kl-icon-list li:hover {
    border-color: #ddd;
    color: #535353;
}
.kl-icon-list li:after {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translateX(-50%);
    content: 'Click to copy "' attr(class) '"';
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    font-size: 10px;
    font-family: sans-serif;
    font-weight: bold;
    color: #fff;
    min-width: 270px;
    line-height: 1;
    text-align: center;
    border-radius: 3px;
    display: none;
    transition: background-color 0.2s ease-out, min-width 0.2s ease-out;
    z-index: 10;
}
.kl-icon-list li:hover:after {
    display: block;
}
.kl-icon-list li.is-copied:after {
    background-color: #4caf50;
    content: 'Copied!';
    min-width: 0;
}

/* Caroufredsel Default */
/* Caroufredsel default styles */
.caroufredsel {
    margin: 0;
    position: relative;
    zoom: 1;
}
.caroufredsel ul.slides {
    margin: 0;
    padding: 0;
    list-style: none;
}

.cfs--default {
    margin: 0;
    position: relative;
    zoom: 1;
    padding-left: 0;
}
.cfs--default > ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.cfs--default .cfs--item,
.cfs--default > ul > li {
    display: block;
    float: left;
    position: relative;
}
/* CFS SVG navigation */
.cfs-svg--next,
.cfs-svg--prev {
    width: 40px;
    height: 60px;
    display: block !important;
    cursor: pointer;
}
.cfs-svg--next svg,
.cfs-svg--prev svg {
    display: inline-block;
}
.cfs-svg--next polyline,
.cfs-svg--prev polyline {
    -webkit-transition: stroke-width 250ms ease-in-out;
    transition: stroke-width 250ms ease-in-out;
}
.cfs-svg--prev:hover polyline,
.cfs-svg--prev:focus polyline {
    stroke-width: 3px;
}
.cfs-svg--prev:active polyline {
    stroke-width: 6px;
    -webkit-transition: stroke-width 100ms ease-in-out;
    transition: stroke-width 100ms ease-in-out;
}
.cfs-svg--next:hover polyline,
.cfs-svg--next:focus polyline {
    stroke-width: 3px;
}
.cfs-svg--next:active polyline {
    stroke-width: 10px;
    -webkit-transition: stroke-width 100ms ease-in-out;
    transition: stroke-width 100ms ease-in-out;
}

/*cfs-nav*/
.cfs--navigation a {
    display: block;
    width: 40px;
    height: 40px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    line-height: 40px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    color: #eee;
    color: rgba(255, 255, 255, 0.15);
}
.cfs--prev {
    text-align: left;
    left: 10px;
}
.cfs--next {
    text-align: right;
    right: 10px;
}
.stg-slimmer-arrows .cfs--prev::before,
.stg-slimmer-arrows ~ .cfs--navigation .cfs--prev::before {
    content: '\f053';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}
.stg-slimmer-arrows .cfs--next::before,
.stg-slimmer-arrows ~ .cfs--navigation .cfs--next::before {
    content: '\f054';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}
.stg-slim-arrows .cfs--prev::before,
.stg-slim-arrows ~ .cfs--navigation .cfs--prev::before {
    content: '\f053';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}
.stg-slim-arrows .cfs--next::before,
.stg-slim-arrows ~ .cfs--navigation .cfs--next::before {
    content: '\f054';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}
.caroufredsel:hover .cfs--prev {
    opacity: 0.7;
}
.caroufredsel:hover .cfs--next,
.caroufredsel:hover ~ .cfs--navigation .cfs--next {
    opacity: 0.7;
}
.caroufredsel:hover .cfs--next:hover,
.caroufredsel:hover .cfs--prev:hover,
.caroufredsel:hover ~ .cfs--navigation .cfs--next:hover,
.caroufredsel:hover ~ .cfs--navigation .cfs--prev:hover {
    opacity: 1;
}

/* caroufredsel hoverstyle */
.stl-hoverstyle .cfs--navigation a {
    width: 76px;
    height: 70px;
    color: #fff;
    line-height: 70px;
    text-align: center;
    opacity: 0.7;
    margin: -35px 0 0;
}
.stl-hoverstyle:hover .cfs--navigation a {
    width: 76px;
    height: 70px;
    color: #fff;
    line-height: 70px;
    text-align: center;
    opacity: 1;
}
.stl-hoverstyle .cfs--navigation a:before {
    font-size: 32px;
}
.stl-hoverstyle .cfs--prev {
    left: 0;
    border-radius: 0 3px 3px 0;
}
.stl-hoverstyle .cfs--next {
    right: 0;
    border-radius: 3px 0 0 3px;
}
.stl-hoverstyle .cfs--navigation a:hover {
    background-color: #106ee9;
}

.caroufredsel_wrapper .slides li {
    position: relative;
    display: block;
    float: left;
}
.caroufredsel_wrapper .playVideo,
.js-slick .playVideo {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
}

/*** Static content */
.static-content__wrapper {
    width: 100%;
    overflow: hidden;
    min-height: 450px;
}
/* Source Styles */
.static-content__source + .static-content__inner {
    position: relative;
}
.static-content__source {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Safe padding */
.kl-slideshow .kl-slideshow-safepadding {
    padding-top: 170px;
    padding-bottom: 50px;
}

.static-content--fullscreen {
    height: 100vh;
}
.static-content--fullscreen .th-google_map {
    height: 100vh !important;
}

/* Static Content - default */
.static-content {
    color: #fff;
    padding-top: 20px;
}
.static-content__title {
    font-size: 4rem;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
    line-height: 1.3;
}
.static-content__subtitle {
    font-size: 2rem;
    font-weight: 400;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
    line-height: 1.4;
}
.static-content__subtitle .stronger {
    font-weight: 600;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.5);
    padding-bottom: 2px;
    cursor: help;
}

.static-content.video-style .playVideo {
    margin-bottom: 20px;
}
/* Infopop balloon */
.static-content__infopop {
    width: 350px;
    margin: 50px auto 0;
    padding: 20px;
    position: relative;
    color: #343434;
    border-radius: 8px;
    background-clip: padding-box;
    background: rgba(254, 254, 254, 0.8);
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(254, 254, 254, 0.8)),
        color-stop(100%, rgba(209, 209, 209, 0.8))
    );
    background: -webkit-linear-gradient(
        top,
        rgba(254, 254, 254, 0.8) 0%,
        rgba(209, 209, 209, 0.8) 100%
    );
    background: -webkit-linear-gradient(
        top,
        rgba(254, 254, 254, 0.8) 0%,
        rgba(209, 209, 209, 0.8) 100%
    );
    background: linear-gradient(
        to bottom,
        rgba(254, 254, 254, 0.8) 0%,
        rgba(209, 209, 209, 0.8) 100%
    );
    box-shadow: 0 10px 11px -10px rgba(0, 0, 0, 0.8),
        inset 0 -1px 0 rgba(255, 255, 255, 0.8),
        inset 0 0 6px rgba(255, 255, 255, 0.8);
}
.static-content__infopop:after {
    content: '';
    position: absolute;
    border: 7px solid transparent;
}
.static-content__infopop[data-arrow='top']:after {
    top: -13px;
    left: 50%;
    margin-left: -7px;
    border-bottom-width: 7px;
    border-bottom-color: rgba(254, 254, 254, 0.8);
}
.static-content__infopop[data-arrow='bottom']:after {
    bottom: -14px;
    left: 50%;
    margin-left: -7px;
    border-top-width: 7px;
    border-top-color: rgba(254, 254, 254, 0.8);
}
.static-content__infopop[data-arrow='left']:after {
    left: -14px;
    top: 50%;
    margin-top: -7px;
    border-right-width: 7px;
    border-right-color: rgba(254, 254, 254, 0.8);
}
.static-content__infopop[data-arrow='right']:after {
    right: -14px;
    top: 50%;
    margin-top: -7px;
    border-left-width: 7px;
    border-left-color: rgba(254, 254, 254, 0.8);
}
.sc-infopop__text {
    color: rgba(37, 37, 37, 0.7);
    font-size: 24px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
    font-weight: 500;
    line-height: 1.2;
    margin: 5px 0;
}
.static-content__infopop .sc-infopop__btn {
    float: right;
    text-align: center;
    line-height: 20px;
    font-weight: 600;
    color: #106ee9;
    padding: 10px 15px 10px;
    border: 0;
    margin: 13px -20px 0 0;
    font-size: 18px;
    background: rgba(0, 0, 0, 0.1);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.static-content__infopop:hover .sc-infopop__btn {
    padding: 10px 30px 10px 15px;
}
.static-content__infopop .sc-infopop__btn:hover {
    color: #222;
    background: rgba(0, 0, 0, 0.2);
}
.static-content__infopop.sc-infopop--left {
    margin-left: 0;
}
.static-content__infopop.sc-infopop--left[data-arrow='top']:after {
    left: 0;
    margin-left: 25px;
}
.static-content__infopop.sc-infopop--right {
    margin-right: 0;
}
.static-content__infopop.sc-infopop--right[data-arrow='top']:after {
    right: 0;
    margin-right: 25px;
}

@-webkit-keyframes fadeBoxIn {
    0% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
    50% {
        -webkit-transform: scale(1.05);
        opacity: 0.9;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
}
@keyframes fadeBoxIn {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
    50% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        opacity: 0.9;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
.fadeBoxIn {
    -webkit-animation-name: fadeBoxIn;
    animation-name: fadeBoxIn;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s;
    -o-animation-delay: 1.5s;
}

/* Static Content - boxes */
.sc__boxes {
    margin-top: 70px;
}
.sc__boxes .static-content__infopop {
    width: auto;
    margin: 0;
    border-radius: 4px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.3),
        inset 0 -1px 0 rgba(255, 255, 255, 0.8),
        inset 0 0 6px rgba(255, 255, 255, 0.8);
    -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    -webkit-backface-visibility: hidden;
}
.sc__boxes:hover .static-content__infopop {
    opacity: 0.4;
}
.sc__boxes:hover .static-content__infopop:hover {
    opacity: 1;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2),
        inset 0 -1px 0 rgba(255, 255, 255, 0.8),
        inset 0 0 6px rgba(255, 255, 255, 0.8);
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
}
.sc__boxes .sc__box-title {
    margin-bottom: 10px;
}
.sc__boxes .static-content__infopop .sc__img {
    margin-right: 15px;
    float: left;
    margin-bottom: 15px;
    margin-top: 10px;
    max-width: 36px;
}
.sc__boxes .static-content__infopop .sc__img--flex {
    width: 36px;
}
.sc__boxes .static-content__infopop .sc__img ~ p {
    margin-left: 52px;
}

/* Static content - Video Background */
.sc__video-line {
    margin-top: 1px;
    display: inline-block;
    padding: 5px 10px;
    background: rgba(0, 0, 0, 0.3);
    font-size: 36px;
    color: #fff;
    line-height: 1.4;
    font-weight: 300;
}
.sc__iframe-video {
    width: 100% !important;
}
.static-content__wrapper .sc--captpos-bottom {
    top: auto;
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    bottom: 10px;
}
.static-content__wrapper .sc--captpos-top {
    top: auto;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

/* Static Content - textpop */
.textpop-style {
    padding-bottom: 20px;
    padding-top: 40px;
}
.textpop__texts > span {
    display: block;
    color: #eee;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    padding: 7px 0;
    cursor: default;
    position: relative;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.textpop__line1 {
    font-size: 20px;
    letter-spacing: 10px;
    word-spacing: 5px;
}
.textpop__line2 {
    font-size: 60px;
    font-weight: 700;
    letter-spacing: 5px;
    word-spacing: 5px;
}
.textpop__line3 {
    font-size: 40px;
}
.textpop__line4 {
    font-size: 30px;
    letter-spacing: 3px;
    word-spacing: 5px;
    margin-top: 5px;
}
.textpop__texts .stronger {
    font-weight: 900;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.5);
    padding-bottom: 2px;
}
.textpop__texts > span:hover {
    color: #fff;
    text-shadow: 0 4px 1px rgba(0, 0, 0, 0.6);
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);
}

/* Static Content - event style */
.static-content.event-style .static-content__subtitle {
    text-align: left;
    font-size: 28px;
    margin-bottom: 50px;
}
.static-content .or {
    line-height: 30px;
    margin: 0 20px 15px 20px;
}
.static-content.event-style .or {
    float: left;
}
.static-content.event-style .social-icons li {
    margin: 0 0 15px 10px;
    vertical-align: top;
}
.black_border {
    border: 10px solid rgba(0, 0, 0, 0.32);
    border-radius: 3px;
    box-shadow: 0 20px 10px -10px rgba(0, 0, 0, 0.5);
}
.white_border {
    border: 10px solid white;
    border-radius: 3px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}
.scev-icon-nocolor {
    background-color: #121212;
}

/* Static Content - product zoom */
/*.productzoom-style {padding-top:0;}*/
.productzoom-style .static-content__subtitle {
    font-size: 28px;
    margin-top: 0;
}
.sc__screenshot {
    position: relative;
    padding: 24px 0 7px;
    width: 100%;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
    background-color: #c8d0d2;
    border-radius: 3px;
}
.sc__screenshot:before {
    content: '';
    position: absolute;
    top: 8px;
    margin-top: 0;
    left: 25px;
    display: block;
    background: #eaae2a;
    border-radius: 30px;
    width: 8px;
    height: 8px;
    box-shadow: -12px 0 0 #ed4529, 12px 0 0 #59c626;
}
.sc__screenshot .image {
    position: relative;
}
.sc__screenshot .image img {
    width: 100%;
}

/* Loupe */
.sc__loupe {
    position: absolute;
    display: none;
    border: 1px solid #000;
    background-color: transparent;
    z-index: 1000;
    margin: 0px;
    padding: 0px;
    background-repeat: no-repeat;
    background-position: center;
}
.overlay_white {
    background-color: #fff;
}
.shadow {
    box-shadow: 5px 5px 10px #000;
}
.glossy {
    display: block;
    margin: 0px;
    background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0.6) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(255, 255, 255, 0.6)),
        color-stop(100%, rgba(255, 255, 255, 0))
    );
}

.sc__loupe {
    cursor: url(../addons/jquery_loupe/blank.png),
        url(../addons/jquery_loupe/blank.cur), none;
    border: 2px solid white;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #fff;
    position: absolute;
    display: none;
    width: 150px;
    height: 150px;
    box-shadow: 0 0 5px #777, 0 0 10px #aaa inset;
    border-radius: 50%;
    z-index: 1000;
    margin: 0px;
    padding: 0px;
}
.sc__loupe .glossy {
    display: block;
    margin: 0px;
    background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0.6) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(255, 255, 255, 0.6)),
        color-stop(100%, rgba(255, 255, 255, 0))
    );
}
.static-content__slideshow .static-content .sc__features {
    margin: 30px 0;
    list-style: none;
    padding-left: 0;
}
.static-content .sc__features li {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.4);
}
.static-content .sc__features li > span {
    margin: 7px 10px 0 0;
}
.static-content .btn .button-icon {
    margin-right: 5px;
}
.static-content .btn span {
    vertical-align: middle;
}

/* Static Content - text with register form */
.static-content--with-login .static-content__title {
}
.fancy_register_form {
    padding: 30px 25px;
    background: rgba(255, 255, 255, 0.3);
    color: #272727;
    position: relative;
}
.fancy_register_form form {
    margin: 0;
}
.fancy_register_form > h4 {
    margin: 10px 0 45px;
    font-size: 32px;
    font-weight: 500;
    color: #464444;
}
.fancy_register_form label {
    display: inline-block;
    color: #fff;
    font-size: 14px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
    text-align: left !important;
    font-weight: normal;
    align-self: flex-end;
}
.fancy_register_form .form-control {
    padding: 10px 15px;
    height: 38px;
}
.fancy_register_form div#login_error {
    color: #000;
    text-align: center;
    text-shadow: none !important;
}
.fancy_register_form:after {
    content: '';
    position: absolute;
    bottom: -25px;
    left: 100px;
    border-top: 25px solid #fff;
    border-right: 25px solid transparent;
    opacity: 0.3;
}

/* Static Content - simple */
.static-content--simple {
    color: #8b8b8b; /*text-shadow: 0 1px 0 rgba(255,255,255,.4); */
    box-shadow: none;
}
.static-content--simple h2,
.static-content--simple .static-content__subtitle {
    text-shadow: none;
}
.static-content--simple .can_be_used {
    color: #b7b7b7;
    font-weight: 500;
    text-shadow: none;
}
.sc__simpleaction {
    margin-top: 30px;
}
.static-content--simple .btn {
    display: table-cell;
}
.static-content--simple .sc__line {
    width: 100%;
    overflow: hidden;
    display: table-cell;
    position: relative;
}
.static-content--simple .sc__line:after {
    content: '';
    display: block;
    position: absolute;
    left: 20px;
    top: 50%;
    width: 100%;
    height: 1px;
    background: #c6c6c6;
}

/* Faded Bottom Mask */
.sc__fade-boxed-mask {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 110px;
}
.sc__fade-mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(245, 245, 245, 0)),
        color-stop(100%, #fbfbfb)
    );
    background: -webkit-linear-gradient(
        top,
        rgba(245, 245, 245, 0) 0%,
        #fbfbfb 100%
    );
    background: -webkit-linear-gradient(
        top,
        rgba(245, 245, 245, 0) 0%,
        #fbfbfb 100%
    );
    background: linear-gradient(
        to bottom,
        rgba(245, 245, 245, 0) 0%,
        #fbfbfb 100%
    );
}
.sc__boxed-mask {
    position: absolute;
    top: 0;
    bottom: auto;
    left: 50%;
    width: 1200px;
    height: 105%;
    border-radius: 4px;
    background: #f5f5f5;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.35);
}
.sc--has-fade-mask .static-content__inner {
    padding-bottom: 140px;
}
.sc--has-fade-mask {
    margin-bottom: -100px;
    overflow: hidden;
    min-height: auto;
}

/* Text color theme */
.scs--theme-light {
    color: #fff;
}
.scs--theme-light h2,
.scs--theme-light h3,
.scs--theme-light .can_be_used {
    color: #fff;
}

/* dark */
.scs--theme-dark {
    color: #232323;
}
.scs--theme-dark h2,
.scs--theme-dark h3,
.scs--theme-dark .can_be_used {
    color: #232323;
}
.scs--theme-dark .sc__line:after {
    background: #343434;
}

/* Static Content - Weather box */
.sc--wb-style .static-content__title {
    text-shadow: none;
    letter-spacing: -1px;
}
.sc--wb-style .static-content__subtitle {
    text-shadow: none;
    font-weight: 300;
}
.sc--wb-style .sc__actionarea {
    text-align: center;
    margin-top: 40px;
}
.sc--wb-style .sc-infopop__text {
    font-size: 16px;
    color: #fff;
    text-shadow: none;
    font-weight: 300;
    margin-top: 10px;
}
.sc__weather {
    min-height: 120px;
    position: relative;
}
/* Weather list */
ul.scw_list {
    margin: 30px 80px 0;
    list-style: none;
}
.scw_list li {
    width: 17%;
    float: left;
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
}
.scw_list .wt-icon {
    font-size: 140px;
    height: 110px;
    line-height: 1;
    display: block;
    margin-bottom: 15px;
}
.scw__degs {
    font-size: 28px;
}
.scw__high {
    font-weight: 700;
}
.scw__low {
    opacity: 0.7;
    margin-left: 15px;
}
.scw__day {
    font-size: 22px;
    font-weight: 300;
    display: block;
}
.scw__alt {
    font-size: 16px;
}
.scw_list li:first-child {
    width: 32%;
    margin-top: -60px;
}
.scw_list li:first-child .wt-icon {
    font-size: 230px;
    height: 180px;
    margin-bottom: 20px;
}
.scw_list li:first-child .scw__degs {
    font-size: 34px;
}
.scw_list li:first-child .scw__high {
    font-weight: 700;
}
.scw_list li:first-child .scw__day {
    font-size: 28px;
}
.scw_list li:first-child .scw__alt {
    font-size: 18px;
}

/* Static Content - Reservation Form */
.sc--resform-style .static-content__title {
    text-shadow: none;
    letter-spacing: -1px;
}
.sc--resform-style .static-content__subtitle {
    text-shadow: none;
    font-weight: 300;
}
.sc--resform-style .sc__actionarea {
    text-align: center;
    margin-top: 40px;
}
.sc--resform-style .sc-infopop__text {
    font-size: 16px;
    color: #fff;
    text-shadow: none;
    font-weight: 300;
    margin-top: 10px;
}
/* Reservation form */
.sc__res-form {
    width: 540px;
    position: relative;
    margin: 70px auto 0;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
}
.sc__form-reservation {
}
.rf__block {
    float: left;
    width: 70%;
}
.rfblock--submit {
    width: 30%;
}
.rfblock--fields {
    padding: 30px 20px;
    background-color: #345370;
    border-radius: 3px 0 0 3px;
}
.rfblock--fields .rf__field {
    width: calc(25% - 10px);
}
.rf__field {
    border-left: 1px solid rgba(255, 255, 255, 0.06);
    margin-left: 20px;
    padding-left: 20px;
    float: left;
}
.rfblock--fields .rf__field:first-child {
    border: 0;
    margin-left: 0;
    padding-left: 0;
    width: calc(50% - 20px);
}
.rf__label {
    display: block;
    font-weight: 300;
    font-size: 13px;
    margin-bottom: 10px;
}
.rf__select {
    width: 100%;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-size: 5px 8px;
    background-position: 86% 50%;
    background-color: #2a425a;
    border: 0;
    height: 35px;
    padding: 0 0 0 15px;
    color: #fff;
    outline: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAQCAYAAAAvf+5AAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RUY4QjAxMDMyMDEzMTFFNTlCOTRBMEJGNDg4OEVBOTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RUY4QjAxMDQyMDEzMTFFNTlCOTRBMEJGNDg4OEVBOTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpFRjhCMDEwMTIwMTMxMUU1OUI5NEEwQkY0ODg4RUE5OSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFRjhCMDEwMjIwMTMxMUU1OUI5NEEwQkY0ODg4RUE5OSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgLLZzQAAAA7SURBVHjaYvz//z8DGkiD0rOQBZkYiASMSCam4VAzi2QT04hRSJYbqWMi9RWy4Ak/hkEUM9RNPQABBgBHYhbX6Sf7oQAAAABJRU5ErkJggg==);
}
.rf__select:hover,
.rf__select:focus {
    background-color: #263645;
    outline: none;
}
.rffield--bigger .rf__select {
    width: -webkit-calc(50% - 5px);
    width: calc(50% - 5px);
    float: left;
    margin-left: 10px;
}
.rf__select.rf__checkinmonth {
    margin-left: 0;
}
.rf__submit {
    background-color: #518ac0;
    position: absolute;
    right: 0;
    width: 30%;
    height: 100%;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    border-radius: 0 3px 3px 0;
    -webkit-transition: background-color 0.2s ease-out,
        padding-left 0.2s ease-out;
    transition: background-color 0.2s ease-out, padding-left 0.2s ease-out;
    padding: 0 20px;
    text-align: left;
    line-height: 1.6;
    outline: none;
    color: #fff;
    cursor: pointer;
}
.rf__submit:active,
.rf__submit:focus {
    outline: none;
}
.rf__submit:hover {
    background-color: #30689d;
    padding-left: 15px;
}
.rf__submit:after {
    content: '\f105';
    display: inline-block;
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 75px;
    color: rgba(255, 255, 255, 0.15);
    position: absolute;
    right: 22px;
    top: 50%;
    margin-top: -30px;
    -webkit-transition: right 0.2s ease-out;
    transition: right 0.2s ease-out;
}
.rf__submit:hover:after {
    right: 15px;
}
.rf__submit span {
    position: relative;
}

/* Showroom Carousel */
.sc--showroomcrs-style .sc__textcontent h2 {
    font-size: 4rem;
    line-height: 1;
    letter-spacing: -2px;
    font-weight: 700;
}
.sc--showroomcrs-style .sc__textcontent h3 {
    font-size: 2rem;
    font-weight: 700;
}
.sc--showroomcrs-style {
    max-width: 75%;
}
.sc__textcontent {
    margin-bottom: 40px;
}
.sc__actionarea {
    margin-bottom: 70px;
}
.sc__secbtn {
    margin-left: 20px;
}
.sc__shcar-wrapper {
    overflow: hidden;
    position: relative;
}
.sc__shcar-wrapper .caroufredsel_wrapper {
    margin-left: -20px !important;
    width: calc(100% - 10px) !important;
}
.sc__shcar-item {
    padding-top: 13px;
    padding-left: 10px;
    margin-left: 10px;
    border-left: 2px solid rgba(255, 255, 255, 0.1);
}
.shc__item-link {
    display: block;
}
.shc__item-link[data-lightbox='iframe'] {
    position: relative;
}
.shc__item-link[data-lightbox='iframe']:after {
    display: block;
    content: '\f04b';
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
    line-height: 56px;
    text-align: center;
    font-size: 18px;
    padding-left: 3px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.8);
    color: rgba(255, 255, 255, 0.8);
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    transition: all 0.2s ease-out;
    -webkit-backface-visibility: hidden;
}
.shc__item-link[data-lightbox='iframe']:hover:after {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    border: 2px solid #fff;
    color: #fff;
}
.shc__item-title {
    font-size: 14px;
    font-weight: 700;
}

/* Huge Arrow */
.sc-huge-arrow {
    position: absolute;
    top: 60%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 0;
    display: block;
    left: calc(45% - 200px);
    -webkit-animation-name: arrow_entry;
    animation-name: arrow_entry;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s;
    -o-animation-delay: 1.5s;
}
.sc-huge-arrow:before,
.sc-huge-arrow:after {
    width: 900px;
    height: 200px;
    display: block;
    content: '';
    background-color: rgba(255, 255, 255, 0.17);
    -webkit-transform: rotate(-45deg) skew(45deg);
    -ms-transform: rotate(-45deg) skew(45deg);
    transform: rotate(-45deg) skew(45deg);
    top: -200px;
    left: 0;
    position: absolute;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}
.sc-huge-arrow:before {
    -webkit-transform: rotate(45deg) skew(-45deg);
    -ms-transform: rotate(45deg) skew(-45deg);
    transform: rotate(45deg) skew(-45deg);
    top: 0%;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
}
.no-csstransforms .sc-huge-arrow {
    display: none;
}
@-webkit-keyframes arrow_entry {
    0% {
        -webkit-transform: scale(0.8);
        opacity: 0;
        margin-left: -50;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 1;
        margin-left: 0;
    }
}
@keyframes arrow_entry {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.9);
        opacity: 0;
        margin-left: -50px;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
        margin-left: 0px;
    }
}

/* Text play */
#textplay {
    text-align: center;
}
.textplay-line {
    font-size: 40px;
    line-height: 1;
    margin-bottom: 20px;
    color: #fff;
    font-weight: 800;
}
.textplay-sep {
    font-weight: 300;
    opacity: 0.2;
}
.textplay-word {
    opacity: 0.4;
    transition: opacity 0.3s ease-out;
    -webkit-backface-visibility: hidden;
}
.textplay-word.active {
    opacity: 1;
}

/*** Content Maps */
.scontent__maps {
    position: relative;
}
.th-google_map {
    width: 100%;
}
.th-google_map .gmap_marker p {
    font-size: 1rem !important;
}
/* Start with the defualt 700px in height but if the viewport
height is lower than 700, make it 90vh (90% of the viewport) */
.scontent__maps:not(.static-content--fullscreen) {
    height: 750px;
}
@media only screen and (max-height: 700px) {
    .scontent__maps:not(.static-content--fullscreen) {
        height: 90vh;
    }
    #map {
        width: 100%;
        height: 700px;
    }
}
.scontent__maps.static-content--fullscreen {
    height: 100vh;
}
.scontent__maps .static-content__infopop {
    position: absolute;
    z-index: 1;
    right: 15px;
    bottom: 50px;
}
.kl-contentmaps__panel {
    position: absolute;
    width: 350px;
    left: calc((100vw - 1170px) / 2);
    top: 10%;
    z-index: 1;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}
.kl-slideshow .kl-contentmaps__panel {
    top: 180px;
}
.kl-contentmaps__panel-img {
    width: 100%;
    display: block;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-transition: opacity 0.2s ease-out, height 0.3s ease-out;
    transition: opacity 0.2s ease-out, height 0.3s ease-out;
    opacity: 1;
}
.kl-contentmaps__panel-img:hover {
    opacity: 0.7;
}
.kl-contentmaps__panel-info {
    padding: 30px;
    min-height: 50px;
    max-height: 350px;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    color: #343434;
}
.kl-contentmaps__panel-info-text {
    max-height: 350px;
    padding-top: 20px;
    -webkit-transition: max-height 0.3s ease-out, padding-top 0.3s ease-out;
    transition: max-height 0.3s ease-out, padding-top 0.3s ease-out;
}
.kl-contentmaps__panel-title {
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
}
.kl-contentmaps__linkapp {
    margin: 20px;
    display: block;
}
.kl-contentmaps__panel-tgg {
    display: block;
    width: 30px;
    height: 30px;
    border: 2px solid #fff;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    right: 20px;
    top: 20px;
    margin: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0.8;
    -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.4s ease-out;
    transition: opacity 0.2s ease-out, transform 0.4s ease-out;
    background-color: rgba(0, 0, 0, 0.3);
}
.kl-contentmaps__panel-tgg:hover {
    opacity: 1;
}
.kl-contentmaps__panel-tgg:before,
.kl-contentmaps__panel-tgg:after {
    content: '';
    width: 2px;
    height: 14px;
    background: #fff;
    display: block;
    transform-origin: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -7px;
    margin-left: -1px;
}
.kl-contentmaps__panel-tgg:after {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
/* Hide panel if checked */
.is-closed.kl-contentmaps__panel {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
}
.is-closed .kl-contentmaps__panel-info-text {
    max-height: 0;
    overflow: hidden;
    padding-top: 0;
}
.is-closed .kl-contentmaps__panel-img {
    height: 80px;
}
.is-closed .kl-contentmaps__panel-tgg {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}

.scontent__maps.maskcontainer--mask3 .kl-bottommask--mask3 {
    bottom: -8px;
}

/* Static content - Heat distortion */
.wrap-heat {
    position: relative;
    height: 100vh;
}
.Background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.Background canvas {
    height: 100vh !important;
}

/* Static content - Isometric grids */
body.isometric #page_wrapper {
    background: linear-gradient(90deg, #58a8fb, #465683);
}

.pseudo-scroller {
    pointer-events: none;
}
.isolayer {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
}
.js .grid,
.js .grid__item,
.js .grid__link {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.grid {
    position: relative;
    margin: 0 auto;
    padding: 0;
    list-style: none;
}
.grid__item {
    width: 300px;
}
.js .grid__item {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.grid__link {
    position: relative;
    z-index: 1;
    display: block;
}
.grid__img {
    display: block;
    max-width: 100%;
}
.grid__title {
    font-size: 0.65em;
    font-weight: 600;
    position: absolute;
    z-index: -1;
    bottom: 0;
    width: 100%;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    opacity: 0;
    color: #fff;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
}
.grid__item:hover .grid__title {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.layer {
    position: relative;
    display: block;
}
.layer:not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
}
.section--intro {
    overflow: hidden;
    position: relative;
    height: 100vh;
    min-height: 800px;
    max-height: 1200px;
}

/* Shadow effect */
.isolayer--shadow .grid__link::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    opacity: 0.6;
    background: rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.8);
    -webkit-transform: translateZ(-1px) scale(0.95);
    transform: translateZ(-1px) scale(0.95);
    -webkit-transition: transform 0.3s, opacity 0.3s, box-shadow 0.3s;
    transition: transform 0.3s, opacity 0.3s, box-shadow 0.3s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.isolayer--shadow .grid__item:hover .grid__link::before {
    opacity: 0.2;
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.8);
    -webkit-transform: translateZ(-1px) scale(1);
    transform: translateZ(-1px) scale(1);
}

/* All individual isometric grid layouts (static and scrollable) */
.isolayer--scroll1 {
    width: 70vw;
    max-width: 1200px;
    height: calc(100vh - 280px);
}
.isolayer--scroll1 .grid__item {
    width: 300px;
    padding: 15px;
}
.isolayer--deco1 {
    width: 1200px;
    height: 900px;
}
.isolayer--deco1 .grid__link .layer:first-child:not(img) {
    background: #ac5cf5;
}
.isolayer--deco1 .grid__link .layer:nth-child(2):not(img) {
    background: #5db4eb;
}
.isolayer--deco1 .grid__link .layer:nth-child(3):not(img) {
    background: #5debb4;
}
.isolayer--deco1 .grid__link div.layer {
    width: 370px;
    height: 270px;
    opacity: 0.4;
}
.isolayer--deco1 .grid__item {
    width: 400px;
    height: 300px;
    padding: 15px;
}
.isolayer--deco1 .grid__item:nth-child(2) {
    margin-top: 150px;
}
.isolayer--deco2 {
    width: 1200px;
    height: 100vh;
}
.isolayer--deco2 .grid__item {
    padding: 20px;
}
.isolayer--deco2 .grid__link .layer {
    border: 20px solid #fff;
    border-width: 40px 20px;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.08);
}
.isolayer--deco3 {
    width: 960px;
    height: 500px;
}
.isolayer--deco3 .grid__item {
    width: 240px;
    height: 320px;
    padding: 0;
}
.isolayer--deco3 .grid__item:nth-child(2),
.isolayer--deco3 .grid__item:nth-child(4) {
    margin-top: -160px;
}
.isolayer--deco3 .grid__link,
.isolayer--deco3 .layer {
    width: 100%;
    height: 100%;
}
.isolayer--deco3 .layer {
    text-align: center;
    background: #fff;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
}
.isolayer--deco3 .grid__link:hover .layer:not(:first-child) {
    box-shadow: 2px 0 2px rgba(0, 0, 0, 0.1);
}
.isolayer--deco3 .grid__item:nth-child(2n) .layer {
    background: #f7d2dc;
}
.isolayer--deco3 .grid__item:nth-child(3n) .layer {
    background: #fff0f3;
}
.isolayer--deco3 .grid__item:nth-child(4n) .layer {
    background: #e9c5cc;
}
.isolayer--deco3 .grid__item:nth-child(5n) .layer {
    background: #d6cbcd;
}
.isolayer--deco3 .grid__item:nth-child(7n) .layer {
    background: #ffc9d8;
}
.isolayer--deco3 .layer {
    width: 100%;
    height: 100%;
}
.isolayer--deco3 .decoletter {
    font-size: 6em;
    font-weight: 900;
    line-height: 1;
    display: block;
    padding: 70px 0 0 0;
    color: #000;
}
.isolayer--deco3 .deconumber {
    font-size: 3em;
    display: block;
    color: #000;
}
.isolayer--deco4 {
    width: 1000px;
    height: 900px;
}
.isolayer--deco4 .grid__item {
    padding: 15px;
}
.isolayer--deco4 .grid__link .layer:first-child:not(img) {
    background: #ac5cf5;
}
.isolayer--deco4 .grid__link .layer:nth-child(2):not(img) {
    background: #5db4eb;
}
.isolayer--deco4 .grid__link .layer:nth-child(3):not(img) {
    background: #5debb4;
}
.isolayer--deco4 .grid__link .layer {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
}
.isolayer--deco4 .grid__link div.layer {
    width: 270px;
    height: 203px;
    opacity: 0.4;
}
.isolayer--deco4 .grid__item {
    width: 300px;
    height: 225px;
    padding: 15px;
}

/* Flip effect for the scrollable grid */
.js .grid--effect-flip .grid__item {
    opacity: 0;
}
.js .grid--effect-flip .grid__item.shown {
    opacity: 1;
}
.grid--effect-flip .grid__item.animate {
    -webkit-transform: translateY(800px);
    transform: translateY(800px);
    -webkit-animation: moveUp 1s cubic-bezier(0.2, 1, 0.3, 1) forwards;
    animation: moveUp 1s cubic-bezier(0.2, 1, 0.3, 1) forwards;
}
@-webkit-keyframes moveUp {
    100% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}
@keyframes moveUp {
    100% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

/* offer-banners */
.offer-banners .offer-banners-link {
    margin-bottom: 30px;
    display: block;
}
.offer-banners.ob--resize-cover .offer-banners-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

/* SHOP LATEST // BEST SELLING TABS - CAROUSEL */
.shop-latest .tabbable .nav {
    border-bottom: 3px solid #e7e7e7;
    padding-bottom: 15px;
    margin-bottom: 20px; /*text-align: center;*/
}
.shop-latest .tabbable .nav li {
    float: left;
    margin-left: 20px;
    padding-left: 20px;
    float: none;
    display: inline-block;
}
.shop-latest .tabbable .nav li:first-child {
    margin-left: 0;
    padding-left: 0;
    border-left: 0;
}
.shop-latest .tabbable .nav li a {
    font-size: 18px;
    text-transform: uppercase;
    color: #595959;
    position: relative;
    padding: 0;
}
.shop-latest .tabbable .nav li a:hover {
    color: #595959;
    background-color: transparent;
}
.shop-latest .tabbable .nav li a.active {
    color: #106ee9;
    background-color: transparent;
}
.shop-latest .tabbable .nav li a.active:after {
    content: '';
    position: absolute;
    bottom: -18px;
    left: 50%;
    margin-left: -4px;
    border: 4px solid transparent;
    border-bottom-color: #106ee9;
}
.shop-latest .tabbable .nav li a.active:before {
    position: absolute;
    content: '';
    bottom: -21px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #106ee9;
}
.shop-latest .tab-content {
    overflow: visible;
}
.shop-latest .tab-content > .tab-pane {
    display: block;
    visibility: hidden;
    height: 0;
    overflow: hidden;
}
.shop-latest .tab-content > .active {
    visibility: visible;
    height: auto;
}
.shop-latest .tabbable .spp-carousel.sppCrs--c .hgSlickNav {
    top: 0;
    right: 0;
}
.shop-latest .tabbable .spp-carousel.sppCrs--c .hgSlickNav-arr {
    background: none;
}
.shop-latest .tabbable .spp-carousel.sppCrs--c .hgSlickNav-arr:hover,
.shop-latest .tabbable .spp-carousel.sppCrs--c:hover .hgSlickNav-arr:hover {
    background: none;
}

/* Shop Latest - Content */
.spp-list .product-list-item {
    margin: 20px 30px 40px;
}
.spp-carousel.sppCrs--c .hgSlickNav {
    position: absolute;
    margin: 0;
    top: -50px;
    right: 43px;
    height: 40px;
    width: 90px;
    z-index: 0;
    opacity: 0;
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
}
.spp-carousel.sppCrs--c .slick-initialized ~ .hgSlickNav {
    opacity: 1;
}
.spp-carousel.sppCrs--c .hgSlickNav-arr {
    top: 0;
    right: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    background: #f1f1f1;
    display: inline-block !important;
    position: relative;
    -webkit-transition: background 0.2s ease-out;
    transition: background 0.2s ease-out;
}
.spp-carousel.sppCrs--c:hover .hgSlickNav-arr {
    background: #eee;
}

.spp-carousel.sppCrs--c .hgSlickNav-arr:hover,
.spp-carousel.sppCrs--c:hover .hgSlickNav-arr:hover {
    background: #dedede;
}

.spp-carousel.sppCrs--c .hgSlickNav-arr svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -7px 0 0 -7px;
    width: 14px;
    height: 14px;
}
.spp-carousel.sppCrs--c .hgSlickNav-arr polyline {
    stroke-width: 36px;
}
.spp-carousel.sppCrs--c .hgSlickNav-arr:hover polyline {
    stroke-width: 46px;
}
.spp-carousel.sppCrs--c .hgSlickNav-arr:active polyline {
    stroke-width: 60px;
}
.spp-carousel.sppCrs--c .hgSlickNav-prev {
    top: 50%;
    margin-right: 10px;
}

.spp-carousel.sppCrs--m .hgSlickNav {
    opacity: 0;
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
}
.spp-carousel.sppCrs--m .slick-initialized ~ .znSlickNav {
    opacity: 1;
}
.spp-carousel.sppCrs--m .hgSlickNav-arr {
    opacity: 0.5;
    -webkit-transition: opacity 0.25s ease-out;
    transition: opacity 0.25s ease-out;
}
.spp-carousel.sppCrs--m .hgnSlickNav-arr:hover {
    opacity: 1;
}

.spp-carousel.sppCrs--m .hgSlickNav-arr,
.spp-carousel.sppCrs--m .hgSlickNav-arr svg {
    width: 24px;
    height: 24px;
}

.spp-carousel.sppCrs--arrCenter .hgSlickNav-arr {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.spp-carousel.sppCrs--arrCenter .hgSlickNav-prev {
    left: -40px;
}
.spp-carousel.sppCrs--arrCenter .hgSlickNav-next {
    right: -40px;
}
.spp-carousel.sppCrs--arrTop .hgSlickNav {
    position: absolute;
    top: -40px;
    right: 0;
}
.spp-el-nav.text-right
    + .spp-el-tab-content
    .spp-carousel.sppCrs--arrTop
    .hgSlickNav {
    left: 0;
    right: auto;
}

.tab-pane .spp-carousel.sppCrs--arrTop .hgSlickNav {
    top: -50px;
}

.spp-carousel.sppCrs--arrTop .hgSlickNav-arr,
.spp-carousel.sppCrs--arrTop .hgSlickNav-arr svg {
    width: 18px;
    height: 18px;
}
.spp-carousel.sppCrs--arrTop .hgSlickNav-arr {
    opacity: 0.4;
}
.spp-carousel.sppCrs--arrTop .hgSlickNav-arr:hover {
    opacity: 1;
}
.spp-carousel.sppCrs--arrTop .hgSlickNav-arr polyline {
    stroke-width: 30px;
}
.spp-carousel.sppCrs--arrTop .hgSlickNav-next {
    margin-left: 12px;
}

.spp-products-rows > .row {
    margin-bottom: 35px;
}
.spp-products-rows .spp-title {
    margin-bottom: 20px;
    font-size: 26px;
}

/* Limited offers carousel */
.limited-offers-carousel {
    position: relative;
    margin-bottom: 40px;
}
.hg_limited_offers {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-right: 60px;
}
.hg_limited_offers li {
    margin: 20px 20px 35px;
    min-height: 240px;
    position: relative;
    text-align: center;
    background: #ffffff;
}
.hg_limited_offers li:after {
    content: attr(data-discount);
    font-size: 11px;
    color: #fff;
    font-weight: bold;
    background: #c51212;
    line-height: 1;
    padding: 4px 6px;
    position: absolute;
    left: 10px;
    top: 10px;
}
.hg_limited_offers li:before,
.hg_limited_offers .hg_badge_container {
    display: none;
}
.hg_limited_offers .product-list-item .kw-prodimage {
    max-height: 120px;
    line-height: 120px;
    margin: 10px 10px 15px 10px;
    overflow: hidden;
}
.hg_limited_offers .product-list-item .kw-details {
    padding: 0;
}
.hg_limited_offers .product-list-item:hover .kw-details {
    background-color: #fff;
}
.hg_limited_offers .product-list-item .kw-details-title {
    padding: 3px 0 8px;
    margin: 0 5px;
    font-size: 12px;
    line-height: 1.6;
    font-weight: 400;
}
.hg_limited_offers .product-list-item .price {
    margin-top: 0;
}
.hg_limited_offers .product-list-item {
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.hg_limited_offers.products .product-list-item .Price-amount {
    color: #77a464;
    display: block;
    font-weight: 400;
    margin-bottom: 0.5em;
    font-size: 13px;
}
.hg_limited_offers.products .product-list-item .price del {
    color: inherit;
    opacity: 0.5;
    display: block;
    margin-bottom: 5px;
}
.hg_limited_offers.products .product-list-item .price ins {
    background: 0 0;
    font-weight: 700;
}
.hg_limited_offers.products
    .product-list-item
    .price
    del
    .Price-currencySymbol {
    margin: 0;
    color: inherit;
}
.hg_limited_offers.products .product-list-item ins .Price-amount {
    font-weight: 600;
}
.hg_limited_offers.products .product-list-item .price .from {
    font-size: 0.67em;
    margin: -2px 0 0;
    text-transform: uppercase;
    color: rgba(132, 132, 132, 0.5);
}

.lt-offers .hgSlickNav {
    width: 58px;
    background: #fff;
    position: absolute;
    border: 2px solid #e8e8e8;
    text-align: center;
    right: 0;
    top: 0;
    height: 100%;
}
.lt-offers .hgSlickNav:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 70%;
    margin-left: 17%;
    left: 0;
    border-top: 1px solid #e8e8e8;
}
.lt-offers .hgSlickNav-arr {
    position: absolute;
    width: 54px;
    height: 50%;
    display: block;
    opacity: 0.5;
}
.lt-offers .hgSlickNav-arr:hover {
    opacity: 1;
}
.lt-offers .hgSlickNav-arr.hgSlickNav-prev {
    top: 50%;
}
.lt-offers .hgSlickNav-arr svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -7px 0 0 -7px;
}

/* kl-store styles */
.kl-store #content div.product .kl-store-tabs ul.tabs:after,
.kl-store #content div.product .kl-store-tabs ul.tabs:before,
.kl-store #content div.product div.thumbnails:after,
.kl-store #content div.product div.thumbnails:before,
.kl-store .col2-set:after,
.kl-store .col2-set:before,
.kl-store div.product .kl-store-tabs ul.tabs:after,
.kl-store div.product .kl-store-tabs ul.tabs:before,
.kl-store div.product div.thumbnails:after,
.kl-store div.product div.thumbnails:before,
.kl-store-page #content div.product .kl-store-tabs ul.tabs:after,
.kl-store-page #content div.product .kl-store-tabs ul.tabs:before,
.kl-store-page #content div.product div.thumbnails:after,
.kl-store-page #content div.product div.thumbnails:before,
.kl-store-page .col2-set:after,
.kl-store-page .col2-set:before,
.kl-store-page div.product .kl-store-tabs ul.tabs:after,
.kl-store-page div.product .kl-store-tabs ul.tabs:before,
.kl-store-page div.product div.thumbnails:after,
.kl-store-page div.product div.thumbnails:before {
    content: ' ';
    display: table;
}

.kl-store-page ul.products {
    list-style: none;
    padding: 0;
    margin: 0;
}
.kl-store #content div.product .kl-store-tabs,
.kl-store #content div.product .kl-store-tabs ul.tabs:after,
.kl-store #content div.product div.thumbnails a.first,
.kl-store #content div.product div.thumbnails:after,
.kl-store .cart-collaterals:after,
.kl-store .col2-set:after,
.kl-store .kl-store-pagination ul.page-numbers:after,
.kl-store div.product .kl-store-tabs,
.kl-store div.product .kl-store-tabs ul.tabs:after,
.kl-store div.product div.thumbnails a.first,
.kl-store div.product div.thumbnails:after,
.kl-store ul.products,
.kl-store ul.products li.first,
.kl-store ul.products:after,
.kl-store-page #content div.product .kl-store-tabs,
.kl-store-page #content div.product .kl-store-tabs ul.tabs:after,
.kl-store-page #content div.product div.thumbnails a.first,
.kl-store-page #content div.product div.thumbnails:after,
.kl-store-page .cart-collaterals:after,
.kl-store-page .col2-set:after,
.kl-store-page .kl-store-pagination ul.page-numbers:after,
.kl-store-page div.product .kl-store-tabs,
.kl-store-page div.product .kl-store-tabs ul.tabs:after,
.kl-store-page div.product div.thumbnails a.first,
.kl-store-page div.product div.thumbnails:after,
.kl-store-page ul.products,
.kl-store-page ul.products li.first,
.kl-store-page ul.products:after {
    clear: both;
}

.kl-store .col2-set,
.kl-store-page .col2-set {
    width: 100%;
}
.kl-store .col2-set .col-1,
.kl-store-page .col2-set .col-1 {
    float: left;
    width: 48%;
}
.kl-store .col2-set .col-2,
.kl-store-page .col2-set .col-2 {
    float: right;
    width: 48%;
}
.kl-store img,
.kl-store-page img {
    height: auto;
    max-width: 100%;
}
.kl-store #content div.product div.thumbnails a,
.kl-store div.product div.thumbnails a,
.kl-store-page #content div.product div.thumbnails a,
.kl-store-page div.product div.thumbnails a {
    float: left;
    width: 30.75%;
    margin-right: 3.8%;
    margin-bottom: 1em;
}
.kl-store #content div.product div.thumbnails a.last,
.kl-store div.product div.thumbnails a.last,
.kl-store-page #content div.product div.thumbnails a.last,
.kl-store-page div.product div.thumbnails a.last {
    margin-right: 0;
}
.kl-store #content div.product div.thumbnails.columns-1 a,
.kl-store div.product div.thumbnails.columns-1 a,
.kl-store-page #content div.product div.thumbnails.columns-1 a,
.kl-store-page div.product div.thumbnails.columns-1 a {
    width: 100%;
    margin-right: 0;
    float: none;
}

.kl-store #content div.product div.thumbnails.columns-2 a,
.kl-store div.product div.thumbnails.columns-2 a,
.kl-store-page #content div.product div.thumbnails.columns-2 a,
.kl-store-page div.product div.thumbnails.columns-2 a {
    width: 46%;
    clear: right;
}

.kl-store #content div.product div.thumbnails.columns-3 a,
.kl-store div.product div.thumbnails.columns-3 a,
.kl-store-page #content div.product div.thumbnails.columns-3 a,
.kl-store-page div.product div.thumbnails.columns-3 a {
    width: 29.5%;
    clear: right;
}

.kl-store #content div.product div.thumbnails.columns-4 a,
.kl-store div.product div.thumbnails.columns-4 a,
.kl-store-page #content div.product div.thumbnails.columns-4 a,
.kl-store-page div.product div.thumbnails.columns-4 a {
    width: 21.2%;
    clear: right;
}

.kl-store #content div.product div.thumbnails.columns-5 a,
.kl-store div.product div.thumbnails.columns-5 a,
.kl-store-page #content div.product div.thumbnails.columns-5 a,
.kl-store-page div.product div.thumbnails.columns-5 a {
    width: 16.9%;
    clear: right;
}

.kl-store #content div.product div.summary,
.kl-store div.product div.summary,
.kl-store-page #content div.product div.summary,
.kl-store-page div.product div.summary {
    float: right;
    width: 48%;
}

.kl-store #content div.product .kl-store-tabs ul.tabs li,
.kl-store div.product .kl-store-tabs ul.tabs li,
.kl-store-page #content div.product .kl-store-tabs ul.tabs li,
.kl-store-page div.product .kl-store-tabs ul.tabs li {
    display: inline-block;
}

.kl-store #content div.product #reviews .comment:after,
.kl-store #content div.product #reviews .comment:before,
.kl-store .kl-store-pagination ul.page-numbers:after,
.kl-store .kl-store-pagination ul.page-numbers:before,
.kl-store div.product #reviews .comment:after,
.kl-store div.product #reviews .comment:before,
.kl-store ul.products:after,
.kl-store ul.products:before,
.kl-store-page #content div.product #reviews .comment:after,
.kl-store-page #content div.product #reviews .comment:before,
.kl-store-page .kl-store-pagination ul.page-numbers:after,
.kl-store-page .kl-store-pagination ul.page-numbers:before,
.kl-store-page div.product #reviews .comment:after,
.kl-store-page div.product #reviews .comment:before,
.kl-store-page ul.products:after,
.kl-store-page ul.products:before {
    content: ' ';
    display: table;
}

.kl-store #content div.product #reviews .comment:after,
.kl-store div.product #reviews .comment:after,
.kl-store-page #content div.product #reviews .comment:after,
.kl-store-page div.product #reviews .comment:after {
    clear: both;
}

.kl-store #content div.product #reviews .comment img,
.kl-store div.product #reviews .comment img,
.kl-store-page #content div.product #reviews .comment img,
.kl-store-page div.product #reviews .comment img {
    float: right;
    height: auto;
}

.kl-store ul.products li.product,
.kl-store-page ul.products li.product {
    float: left;
    margin: 0 3.8% 2.992em 0;
    padding: 0;
    position: relative;
    width: 22.05%;
}
.kl-store ul.products li.last,
.kl-store-page ul.products li.last {
    margin-right: 0;
}
.kl-store-page.columns-1 ul.products li.product,
.kl-store.columns-1 ul.products li.product {
    width: 100%;
    margin-right: 0;
}
.kl-store-page.columns-2 ul.products li.product,
.kl-store.columns-2 ul.products li.product {
    width: 48%;
}
.kl-store-page.columns-3 ul.products li.product,
.kl-store.columns-3 ul.products li.product {
    width: 30.75%;
}
.kl-store-page.columns-5 ul.products li.product,
.kl-store.columns-5 ul.products li.product {
    width: 16.95%;
}
.kl-store-page.columns-6 ul.products li.product,
.kl-store.columns-6 ul.products li.product {
    width: 13.5%;
}
.kl-store .kl-store-result-count,
.kl-store-page .kl-store-result-count {
    float: left;
}
.kl-store .kl-store-ordering,
.kl-store-page .kl-store-ordering {
    float: right;
}
.kl-store .kl-store-pagination ul.page-numbers li,
.kl-store-page .kl-store-pagination ul.page-numbers li {
    display: inline-block;
}
.kl-store #content table.cart img,
.kl-store table.cart img,
.kl-store-page #content table.cart img,
.kl-store-page table.cart img {
    height: auto;
    width: 42px;
    box-shadow: none;
}
.kl-store #content table.cart td.actions,
.kl-store table.cart td.actions,
.kl-store-page #content table.cart td.actions,
.kl-store-page table.cart td.actions {
    text-align: right;
}

.kl-store #content table.cart td.actions .input-text,
.kl-store table.cart td.actions .input-text,
.kl-store-page #content table.cart td.actions .input-text,
.kl-store-page table.cart td.actions .input-text {
    width: 150px;
    margin-right: 5px;
    padding: 5px 10px;
    font-size: 0.8rem;
}

.kl-store #content table.cart td.actions .coupon,
.kl-store table.cart td.actions .coupon,
.kl-store-page #content table.cart td.actions .coupon,
.kl-store-page table.cart td.actions .coupon {
    float: left;
}
.kl-store table.shop_table td {
    border-top: 1px solid rgba(221, 221, 221, 0.64);
    padding: 6px 12px;
    vertical-align: middle;
}
.kl-store .cart-collaterals .cart_totals table td,
.kl-store .cart-collaterals .cart_totals table th {
    border-top: 1px solid #ebe9eb;
}

.kl-store .cart-collaterals .cart_totals table tr:first-child td,
.kl-store .cart-collaterals .cart_totals table tr:first-child th {
    border-top: 0;
}
.kl-store-page #respond input#submit.alt,
.kl-store-page a.button.alt,
.kl-store-page button.button.alt,
.kl-store-page input.button.alt {
    background: #000;
    font-weight: 600;
}

.kl-store #content table.cart td.actions .coupon label,
.kl-store table.cart td.actions .coupon label,
.kl-store-page #content table.cart td.actions .coupon label,
.kl-store-page table.cart td.actions .coupon label {
    display: none;
}

.kl-store .cart-collaterals .shipping_calculator:after,
.kl-store .cart-collaterals .shipping_calculator:before,
.kl-store .cart-collaterals:after,
.kl-store .cart-collaterals:before,
.kl-store form .form-row:after,
.kl-store form .form-row:before,
.kl-store ul.cart_list li:after,
.kl-store ul.cart_list li:before,
.kl-store ul.product_list_widget li:after,
.kl-store ul.product_list_widget li:before,
.kl-store-page .cart-collaterals .shipping_calculator:after,
.kl-store-page .cart-collaterals .shipping_calculator:before,
.kl-store-page .cart-collaterals:after,
.kl-store-page .cart-collaterals:before,
.kl-store-page form .form-row:after,
.kl-store-page form .form-row:before,
.kl-store-page ul.cart_list li:after,
.kl-store-page ul.cart_list li:before,
.kl-store-page ul.product_list_widget li:after,
.kl-store-page ul.product_list_widget li:before {
    content: ' ';
    display: table;
}

.kl-store .cart-collaterals,
.kl-store-page .cart-collaterals {
    width: 100%;
}
.kl-store .cart-collaterals .related,
.kl-store-page .cart-collaterals .related {
    width: 30.75%;
    float: left;
}

.kl-store .cart-collaterals .cross-sells,
.kl-store-page .cart-collaterals .cross-sells {
    width: 48%;
    float: left;
}
.kl-store .cart-collaterals .cross-sells ul.products,
.kl-store-page .cart-collaterals .cross-sells ul.products {
    float: none;
}
.kl-store .cart-collaterals .cross-sells ul.products li,
.kl-store-page .cart-collaterals .cross-sells ul.products li {
    width: 48%;
}
.kl-store .cart-collaterals .shipping_calculator,
.kl-store-page .cart-collaterals .shipping_calculator {
    width: 48%;
    clear: right;
    float: right;
}

.kl-store .cart-collaterals .shipping_calculator:after,
.kl-store form .form-row-wide,
.kl-store form .form-row:after,
.kl-store ul.cart_list li:after,
.kl-store ul.product_list_widget li:after,
.kl-store-page .cart-collaterals .shipping_calculator:after,
.kl-store-page form .form-row-wide,
.kl-store-page form .form-row:after,
.kl-store-page ul.cart_list li:after,
.kl-store-page ul.product_list_widget li:after {
    clear: both;
}

.kl-store .cart-collaterals .shipping_calculator .col2-set .col-1,
.kl-store .cart-collaterals .shipping_calculator .col2-set .col-2,
.kl-store-page .cart-collaterals .shipping_calculator .col2-set .col-1,
.kl-store-page .cart-collaterals .shipping_calculator .col2-set .col-2 {
    width: 47%;
}

.kl-store .cart-collaterals .cart_totals,
.kl-store-page .cart-collaterals .cart_totals {
    float: right;
    width: 48%;
}
.kl-store ul.cart_list li img,
.kl-store ul.product_list_widget li img,
.kl-store-page ul.cart_list li img,
.kl-store-page ul.product_list_widget li img {
    float: right;
    height: auto;
}
.kl-store form .form-row label,
.kl-store-page form .form-row label {
    display: block;
}
.kl-store form .form-row label.checkbox,
.kl-store-page form .form-row label.checkbox {
    display: inline;
}
.kl-store form .form-row select,
.kl-store-page form .form-row select {
    width: 100%;
}
.kl-store form .form-row .input-text,
.kl-store-page form .form-row .input-text {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 8px;
}
.kl-store form .form-row-first,
.kl-store form .form-row-last,
.kl-store-page form .form-row-first,
.kl-store-page form .form-row-last {
    float: left;
    width: 47%;
    overflow: visible;
}
.kl-store #payment #place_order,
.kl-store form .form-row-last,
.kl-store-page #payment #place_order,
.kl-store-page form .form-row-last {
    float: right;
}
.kl-store #payment .form-row select,
.kl-store-page #payment .form-row select {
    width: auto;
}
.kl-store #payment .terms,
.kl-store-page #payment .terms {
    text-align: right;
    padding: 0 1em;
}

/* Kallyas-store overide styles */

/* Products in listings */
.kl-store-page .left_sidebar ul.products li.product:nth-child(3n + 1) {
    clear: both;
}
.kl-store-page ul.products {
    list-style: none;
    margin: 0 -15px;
    padding: 0;
}
.kl-store-page ul.products li.product {
    margin: 0 0 50px;
    padding: 0 15px;
    width: 25%;
}
/* When with sidebar */
.kl-store-page .left_sidebar ul.products li.product,
.kl-store-page .right_sidebar ul.products li.product {
    width: 33.3333%;
    float: left;
}

.kl-store-page #content div.product div.images,
.kl-store-page div.product div.images {
    width: 100%;
}

/* custom buttons */
.btn-flat,
.kl-store-page a.button,
.kl-store-page button.button,
.kl-store-page button.button.alt,
.kl-store-page input.button,
.kl-store-page input#button,
.kl-store-page #review_form #submit {
    background: #106ee9;
    font-size: 13px !important;
    padding: 10px 20px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    text-shadow: none;
    border-radius: 3px;
    box-shadow: none;
    position: relative;
    border: 0;
    line-height: 1;
    cursor: pointer;
}

.btn-flat:hover,
.kl-store-page a.button:hover,
.kl-store-page button.button:hover,
.kl-store-page button.button.alt:hover,
.kl-store-page input.button:hover,
.kl-store-page input#button:hover,
.kl-store-page #review_form #submit:hover {
    background: #343434;
    color: #fff !important;
}

/* Alternative Button */
.kl-store-page #respond input#submit.alt,
.kl-store-page a.button.alt,
.kl-store-page button.button.alt,
.kl-store-page input.button.alt {
    font-weight: 600;
}
.kl-store-page #respond input#submit.alt:hover,
.kl-store-page a.button.alt:hover,
.kl-store-page button.button.alt:hover,
.kl-store-page input.button.alt:hover {
    background: #232323;
}

.product-list-item,
.kl-store-page ul.products li.product .product-list-item {
    display: block;
    position: relative;
    background: #ffffff;
    -webkit-transition: box-shadow 0.15s ease-out;
    transition: box-shadow 0.15s ease-out;
}

/*.product-list-item:before,
.kl-store-page ul.products li.product .product-list-item:before {content:" "; position:absolute; left:0; top:0; z-index: 1; width:100%; height: 100%; pointer-events: none; -webkit-transition: all 0.2s ease-out; transition: all 0.2s ease-out;}*/

/* Product in listing - Image */
.product-list-item > a,
.kl-store-page ul.products li.product .product-list-item > a {
    display: block;
    position: relative;
}
.product-list-item .kw-prodimage,
.kl-store-page ul.products li.product .product-list-item .kw-prodimage {
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 0;
    display: block;
}
.product-list-item .kw-prodimage-img,
.kl-store-page ul.products li.product .product-list-item .kw-prodimage-img {
    margin-bottom: 0;
    width: auto;
    margin: 0 auto;
    vertical-align: middle;
    opacity: 1;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    -webkit-backface-visibility: hidden;
}

.product-list-item .kw-prodimage-img:not(:only-child),
.kl-store-page
    ul.products
    li.product
    .product-list-item
    .kw-prodimage-img:not(:only-child) {
    position: relative;
    z-index: 1;
}
.product-list-item .kw-prodimage-img-secondary,
.kl-store-page
    ul.products
    li.product
    .product-list-item
    .kw-prodimage-img-secondary {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    -webkit-transition: opacity 0.4s;
    transition: opacity 0.4s;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-backface-visibility: hidden;
}
.product-list-item:hover .kw-prodimage-img-secondary,
.kl-store-page
    ul.products
    li.product
    .product-list-item:hover
    .kw-prodimage-img-secondary {
    opacity: 1;
    z-index: 1;
}
.product-list-item:hover .kw-prodimage-img:not(:only-child),
.kl-store-page
    ul.products
    li.product
    .product-list-item:hover
    .kw-prodimage-img:not(:only-child) {
    opacity: 0;
}

.product-list-item .kw-prodimage-img[data-src],
.kl-store-page
    ul.products
    li.product
    .product-list-item
    .kw-prodimage-img[data-src] {
    opacity: 0;
}
/* Product in listing - Details */
.product-list-item .kw-details,
.kl-store-page ul.products li.product .product-list-item .kw-details {
    padding: 25px 30px 60px;
    position: relative;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    background: #fff;
}
.product-list-item .kw-details-title,
.kl-store-page ul.products li.product .product-list-item .kw-details-title {
    font-size: 16px;
    line-height: 1.5;
    margin: 0 0 10px;
    padding: 0;
    font-weight: 300;
    min-height: 40px;
}
.product-list-item .kw-details-title,
.kl-store-page ul.products li.product .product-list-item .kw-details-title,
.product-list-item .kw-details-title a,
.kl-store-page ul.products li.product .product-list-item .kw-details-title a {
    color: #2f2f2f;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

/* Product in listing - Actions */
.product-list-item .kw-actions,
.kl-store-page ul.products li.product .product-list-item .kw-actions {
    position: absolute;
    right: 10px;
    bottom: 0;
    width: auto;
}
.product-list-item .kw-actions a,
.kl-store-page ul.products li.product .product-list-item .kw-actions a {
    width: 40px;
    height: 50px;
    display: block;
    float: left;
    line-height: 37px;
    text-align: center;
    margin-left: 10px;
}

.product-list-item .kw-actions .shopping-cart .svg-cart-icon {
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.product-list-item .kw-actions .shopping-cart:hover .svg-cart-icon {
    fill: #106ee9;
}

.product-list-item .kw-actions a .cart-icon,
.product-list-item .kw-actions a .more-icon {
    vertical-align: middle;
    font-size: 25px;
}

/* Product in listing - Price */
.product-list-item .price,
.product-list-item .price ins,
.kl-store-page ul.products li.product .product-list-item .price,
.kl-store-page ul.products li.product .product-list-item .price ins {
    color: #282828;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 0;
    text-decoration: none;
}
.product-list-item .price del span,
.kl-store-page ul.products li.product .product-list-item .price del {
    font-weight: normal;
    display: inline-block;
}
.product-list-item .price del span,
.kl-store-page ul.products li.product .product-list-item .price del span {
    position: relative;
    color: #000;
    margin-right: 5px;
    text-decoration: line-through;
}
.product-list-item .price del,
.product-list-item .price ins,
.kl-store-page ul.products li.product .product-list-item .price del,
.kl-store-page ul.products li.product .product-list-item .price ins {
    font-size: 90%;
}

.product-list-item .price del {
    margin-right: 5px;
}

/* Product in listing - Rating */
.product-list-item .star-rating,
.product_list_widget .star-rating {
    overflow: hidden;
    position: absolute;
    left: 30px;
    bottom: 22px;
    height: 1em;
    line-height: 1;
    font-size: 13px;
    width: 72px;
    color: #afafaf;
    font-family: 'Font Awesome 5 Free';
}
.product-list-item .star-rating::before,
.product_list_widget .star-rating::before {
    content: '\f005\f005\f005\f005\f005';
    color: #e0e0e0;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
}
.product-list-item .star-rating span,
.product_list_widget .star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em;
}
.product-list-item .star-rating span::before,
.product_list_widget .star-rating span::before {
    content: '\f005\f005\f005\f005\f005';
    top: 0;
    position: absolute;
    left: 0;
}

/* Product in listing - Hover item */
.product-list-item:hover,
.kl-store-page ul.products li.product .product-list-item:hover {
    box-shadow: 8px 15px 40px rgba(0, 0, 0, 0.15);
}
.product-list-item .kw-details-title {
    font-family: 'Montserrat', 'OpenSans', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;
}
.product-list-item:hover .kw-details-title,
.kl-store-page
    ul.products
    li.product
    .product-list-item:hover
    .kw-details-title {
    color: #106ee9;
}

/* PRODUCT CATEGORIES WIDGET */
.kl-store.widget_product_categories {
}
.kl-store.widget_product_categories .product-categories {
    list-style: none;
    margin: 0;
    padding: 0;
}
.kl-store.widget_product_categories .cat-item {
    margin: 10px 0;
}
.kl-store.widget_product_categories .cat-item a {
    font-weight: 400;
    font-size: 14px;
    color: #484848 !important;
    padding: 10px 0;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
.kl-store.widget_product_categories .cat-item a:hover {
    color: #111;
}
.kl-store.widget_product_categories .cat-item a:before {
    content: '';
    display: inline-block;
    margin-right: 12px;
    width: 8px;
    height: 2px;
    background: #e1e1e1;
    vertical-align: middle;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
.kl-store.widget_product_categories .cat-item.current-cat > a {
    font-weight: 600;
    color: #181818;
}
.kl-store.widget_product_categories .cat-item.current-cat > a:before {
    background: #bbb;
}
.kl-store.widget_product_categories .cat-item .count {
    opacity: 0.5;
}
.kl-store.widget_product_categories .children {
    list-style: none;
    margin: 0;
    padding: 0 0 0 20px;
}

/* Price Slider Widget */
.kl-store.widget_price_filter .price_label {
    font-size: 14px;
}

.kl-store.widget_price_filter .ui-slider .ui-slider-handle {
    -webkit-transition: background-color 0.2s ease-out;
    transition: background-color 0.2s ease-out;
    background-color: #c8c8c8;
    width: 10px;
    height: 10px;
    top: 2px;
}
.kl-store.widget_price_filter:hover .ui-slider .ui-slider-handle {
    background-color: #898989;
}
.kl-store.widget_price_filter .ui-slider .ui-slider-range {
    background-color: #c8c8c8;
}
.kl-store.widget_price_filter .price_slider_wrapper .ui-widget-content {
    background-color: #9d9d9d;
    margin-bottom: 30px;
    margin-top: 30px;
}
.kl-store.widget_price_filter .ui-slider-horizontal {
    height: 3px;
    margin-top: 25px;
}

/* Top rated produts */
.kl-store.widget ul.product_list_widget {
    list-style: none;
    padding: 0;
}
.kl-store.widget ul.product_list_widget li {
    margin-bottom: 30px;
    padding: 0;
    position: relative;
}
.kl-store.widget ul.product_list_widget li a {
    font-size: 0.9rem;
    line-height: 1.4;
    margin-bottom: 10px;
}
.kl-store.widget ul.product_list_widget li a span {
    font-weight: 400;
}
.kl-store.widget ul.product_list_widget li img {
    width: 58px;
    float: right;
}
.kl-store.widget ul.product_list_widget li .amount {
    font-weight: 300;
    display: block;
}
.kl-store.widget ul.product_list_widget li .star-rating {
    color: #106ee9;
    font-size: 10px;
    margin-bottom: 10px;
    margin-top: 5px;
    position: relative;
    left: 0;
    bottom: 0;
}

.kl-store.widget:last-child {
    margin-bottom: 0;
}

/* Subcategories page */
.kl-store-page ul.products li.product-category h3 {
    margin: 0 20px;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.4;
    padding-bottom: 20px;
}
.kl-store-page ul.products li.product-category h3 mark {
    background: none;
    font-weight: 400;
}

/* Cart List */
.kl-store ul.cart_list li a {
    font-size: 14px;
}

/* Shopping cart widget */
.kl-store .widget_shopping_cart .total,
.kl-store.widget_shopping_cart .total {
    font-size: 16px;
    border-top-style: solid;
    padding-top: 15px;
}

/* Category */
.kl-store-page .page-title {
    color: #343434;
}

/* Top side of category listing */
.kl-store-page .kl-store-ordering select {
    height: 34px;
    font-size: 11px;
    font-weight: 600;
    outline: none;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    -webkit-appearance: none;
    background-image: url(../images/select-arrow.svg);
    background-repeat: no-repeat;
    background-size: 6px 12px;
    background-position-y: center;
    background-position-x: calc(100% - 10px);
    text-transform: uppercase;
    padding-left: 10px;
    color: #909090;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.kl-store-page .kl-store-ordering select:hover,
.kl-store-page .kl-store-ordering select:focus {
    border: 1px solid #bababa;
    color: #787878;
}
.kl-store-page .kl-store-result-count {
    margin: 10px 0;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    color: #838383;
    float: left;
}
.kl-store-page .kl-store-ordering,
.kl-store-page .kl-store-result-count {
    margin: 0 0 20px;
}
.kl-store-page .kl-store-ordering {
    float: right;
}

/* Forms */
.kl-store-page input.input-text,
.kl-store-page textarea.input-text,
.kl-store-page form .form-row select,
/* Overrides for forced styles from WC */
.kl-store-cart table.cart td.actions .coupon .input-text {
    padding: 7px 10px;
    box-shadow: inset 2px 2px 0 0px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    -webkit-transition: box-shadow 0.25s ease-in-out;
    transition: box-shadow 0.25s ease-in-out;
    width: 50px;
}

.kl-store-page input.input-text:hover,
.kl-store-page textarea.input-text:hover,
.kl-store-page form .form-row select:hover,
.kl-store-page input.input-text:focus,
.kl-store-page textarea.input-text:focus,
.kl-store-page form .form-row select:focus {
    border: 1px solid #bababa;
    color: #787878;
}

.kl-store-page form .form-row select {
    height: 34px;
}

.kl-store-page input.input-text,
.kl-store-page textarea.input-text,
.kl-store-page .login .input-text {
    border: 1px solid #d8d8d8;
}

.kl-store-page form .form-row.kl-store-invalid input.input-text,
.kl-store-page form .form-row.kl-store-invalid select {
    box-shadow: inset 3px 0 0 #e2401c;
}

.kl-store-page .kl-store form .form-row label {
    font-weight: 400;
}

/* Messages / Notices */
.kl-store-page .kl-store-error,
.kl-store-page .kl-store-error:before,
.kl-store-page .kl-store-info,
.kl-store-page .kl-store-info:before,
.kl-store-page .kl-store-message,
.kl-store-page .kl-store-message:before {
    color: #fff;
    line-height: 30px;
    border-top: 0;
}
.kl-store-page .kl-store-error,
.kl-store-page .kl-store-info,
.kl-store-page .kl-store-message {
    border-left: 0.618em solid rgba(0, 0, 0, 0.15);
}
.kl-store-page .kl-store-error a,
.kl-store-page .kl-store-info a,
.kl-store-page .kl-store-message a {
    color: #fff;
    margin-left: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.kl-store-page .kl-store-error a:hover,
.kl-store-page .kl-store-info a:hover,
.kl-store-page .kl-store-message a:hover {
    border-color: rgba(255, 255, 255, 0.4);
}
.kl-store-page .kl-store-info {
    background-color: #3d9cd2;
}
.kl-store-page .kl-store-error {
    background-color: #e2401c;
}
.kl-store-page .kl-store-message {
    background-color: #0f834d;
}
.kl-store-page .kl-store-message .button.wc-forward {
    background: none;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.kl-store-page .kl-store-message .button.wc-forward:hover {
    background: none;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.8);
}

/*** Shopping Cart */
.kl-store-page table.shop_table {
    border: 0;
    border-radius: 0;
    background-color: #fff;
    min-width: 600px;
    width: 100%;
}
.kl-store-page table.shop_table thead {
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 3px;
}
.kl-store-page table.shop_table thead th {
    background-color: #ccc;
    color: #454545;
    font-weight: 600;
}
.kl-store-page table.shop_table thead th:first-child {
    border-radius: 3px 0 0 0;
}
.kl-store-page table.shop_table thead th:last-child {
    border-radius: 0 3px 0 0;
}
.kl-store-page table.shop_table thead th,
.kl-store-page table.shop_table td {
    padding: 15px;
}

.kl-store-cart table.cart .product-remove {
    width: 60px;
    padding: 0;
    text-align: center;
}
.kl-store-cart table.cart .product-remove .remove {
    display: inline-block;
    margin: 0 auto;
}
.kl-store-cart table.cart .product-thumbnail {
    width: 52px;
    padding: 0;
}
.kl-store-cart table.cart .product-thumbnail img {
    width: 42px;
    box-shadow: none;
}

.kl-store-cart table.cart td.actions .coupon .input-text {
    width: auto;
    height: auto;
    line-height: normal;
}

.shipping-calculator-button {
    background-color: #d4d4d4;
    padding: 6px 10px;
    border-radius: 2px;
    line-height: 1;
    margin-top: 10px;
    display: inline-block;
}
.shipping-calculator-button:hover {
    background-color: #ccc;
}

/*** Cart totals */
.kl-store-page .cart-collaterals .cart_totals {
    width: 50%;
}
.kl-store-page .cart-collaterals .cart_totals > h2 {
    text-transform: uppercase;
    color: #888;
}
.kl-store-page .cart-collaterals .cart_totals table {
    width: 100%;
}

.kl-store-page .cart-collaterals .cart_totals .order-total {
    background-color: #eee;
    font-size: 16px;
}

.kl-store-page .cart-collaterals .cart_totals table th {
    text-transform: uppercase;
    font-weight: 600;
}
.kl-store-page .cart-collaterals .cart_totals table td,
.kl-store-page .cart-collaterals .cart_totals table th {
    padding: 10px;
}

.kl-store-page
    .cart-collaterals
    ul.products
    li.product
    .product-list-item
    .kw-prodimage {
    line-height: 180px;
    height: 180px;
}

.kl-store-page .cart-collaterals .cross-sells {
    width: 50%;
}
.kl-store-page .cart-collaterals .cross-sells > h2 {
    font-size: 22px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}

.kl-store-page .wc-proceed-to-checkout {
    display: inline-block;
}

/*** Checkout */
.kl-store-page .kl-store-shipping-fields > h3 {
    margin-top: 10px;
}
.kl-store-page .kl-store-shipping-fields > h3 label {
    font-weight: 400;
    display: inline-block;
    font-size: 20px;
    margin-right: 10px;
}

.kl-store-page .kl-store-checkout-review-order .product-name {
    width: 35%;
}

.kl-store table.shop_table tbody th,
.kl-store table.shop_table tfoot td,
.kl-store table.shop_table tfoot th {
    font-weight: 600;
}

.kl-store-page table.shop_table .order-total {
    background-color: #eee;
    font-size: 16px;
}
.kl-store-page table.kl-store-checkout-review-order-table {
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
}
.payment_methods {
    list-style: none;
    padding: 0;
}

/*** ACCOUNT Page */
.kl-store-page table.my_account_orders th.order-total,
.kl-store-page table.my_account_orders td.order-total {
    background-color: #ccc;
    font-size: 13px;
}
.kl-store-page table.my_account_orders td.order-total {
    background-color: transparent;
}

.kl-store-page .addresses .title h3 {
    float: left;
    margin-top: 0;
    line-height: 1.3;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 20px;
}
.kl-store-page .addresses .title .edit,
.kl-store-page ul.digital-downloads li .count {
    float: right;
    padding: 7px 10px 6px;
    background-color: #dedede;
    border-radius: 2px;
    text-transform: uppercase;
    font-weight: 600;
    color: #676767;
    font-size: 12px;
    line-height: 1;
    margin-top: 5px;
}
.kl-store-page .addresses .title .edit:hover,
.kl-store-page ul.digital-downloads li .count:hover {
    background-color: #ccc;
}

.kl-store-page .kl-store > form .form-row {
    padding-left: 0;
    padding-right: 0;
}
.kl-store-page .kl-store > form fieldset {
    clear: both;
    margin-top: 30px;
    margin-bottom: 20px;
}
.kl-store-page .kl-store > form legend {
    clear: both;
    padding-bottom: 10px;
}

.kl-store-page .myaccount_user {
    font-size: 14px;
    line-height: 1.7;
}
.kl-store-page .myaccount_user a {
    text-decoration: underline;
}

.kl-store-page .myaccount_user ~ h2,
.kl-store-page .order-info ~ h2,
.kl-store-page .order-info ~ header h2,
.kl-store-page .kl-store > h2 {
    text-transform: uppercase;
    font-size: 20px;
}

.kl-store form.checkout_coupon,
.kl-store form.login,
.kl-store form.register {
    border: 1px solid #e9e9e9;
    padding: 10px 20px;
}
.kl-store form.login .form-row,
.kl-store form.register .form-row {
    margin-bottom: 10px;
}
.kl-store form.login .form-row .button + label,
.kl-store form.register .form-row .button + label {
    margin-left: 10px;
}

/*** PRODUCT PAGE */
.kl-store-page .product-page {
    margin-bottom: 80px;
}
.kl-store-page div.product .product_title {
    font-size: 34px;
    line-height: 1.4;
    font-weight: normal;
}
.kl-store-page div.product .tabbable {
    margin-bottom: 100px;
}

/* Prod page - main image */
.kl-store-page .single_product_main_image .images {
    border: 1px solid #eaeaea;
    background: #fff;
    width: 100%;
    padding-bottom: 1px;
    position: relative;
}
.kl-store-page .single_product_main_image .images .kl-store-main-image {
    width: 100%;
    text-align: center;
    display: block;
}

/* Prod page - thumbnails */
.kl-store-page div.product div.images div.thumbnails {
    padding: 10px 0 0 10px;
    margin: 0 10px;
    overflow: hidden;
}
.kl-store-page div.product div.images div.thumbnails img {
    border: 1px solid #eaeaea;
    padding: 2px;
}
.kl-store-page div.product div.images div.thumbnails img:hover {
    border: 1px solid #eee;
}

/* Prod page - summary (right side) */
.kl-store-page div.product div.summary {
    float: none !important;
    width: 100% !important;
}

/* Prod page - price */
.kl-store-page.single-product div.product p.price,
.kl-store-page.single-product div.product span.price {
    font-size: 24px;
    color: #282828;
}
.kl-store-page.single-product div.product p.price .amount,
.kl-store-page.single-product div.product span.price .amount {
    margin: 0 5px;
}
.kl-store-page.single-product div.product p.price del,
.kl-store-page.single-product div.product span.price del {
    opacity: 0.8;
    text-decoration: none;
    display: inline-block;
    margin-right: 10px;
    display: inline-block;
}
.kl-store-page.single-product div.product p.price del .amount,
.kl-store-page.single-product div.product span.price del .amount {
    text-decoration: line-through;
}
.kl-store-page.single-product div.product p.price ins,
.kl-store-page.single-product div.product span.price ins {
    text-decoration: none;
    font-weight: 600;
    display: inline-block;
}
.kl-store-page.single-product div.product p.price del:before,
.kl-store-page.single-product div.product span.price del:before,
.kl-store-page.single-product div.product p.price ins:before,
.kl-store-page.single-product div.product span.price ins:before {
    text-decoration: none;
    font-size: 10px;
    display: block;
    line-height: 1.4;
    margin-left: 5px;
    font-weight: 700;
}
.kl-store-page.single-product div.product p.price del:before,
.kl-store-page.single-product div.product span.price del:before {
    content: attr(data-was);
    color: #888;
}
.kl-store-page.single-product div.product p.price del + ins:before,
.kl-store-page.single-product div.product span.price del + ins:before {
    content: attr(data-now);
    color: #106ee9;
}

/* Prod page - description */
.kl-store-page.single-product div.product .kw-details-desc {
    margin-bottom: 30px;
}

/* Prod page - simple add to cart */
.kl-store-page div.product form.cart div.quantity {
    margin-right: 10px;
}
.kl-store-page div.product form.cart div.quantity .qty {
    height: 33px;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
}

/* Prod page - Variations (add to cart form) */
.kl-store-page div.product form.cart .variations td.label {
    display: table-cell;
    padding: 0;
    font-size: 100%;
    text-align: start;
    vertical-align: top;
    padding-top: 10px;
}
.kl-store-page div.product form.cart .variations label {
    display: inline-block;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin-right: 20px;
}
.kl-store-page div.product form.cart .variations select {
    height: 34px;
    font-size: 11px;
    font-weight: 600;
    outline: none;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    background-repeat: no-repeat;
    background-size: 6px 12px;
    background-position-y: center;
    background-position-x: calc(100% - 10px);
    text-transform: uppercase;
    padding-left: 10px;
    color: #909090;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.kl-store-page div.product form.cart .variations select:hover,
.kl-store-page div.product form.cart .variations select:focus {
    border: 1px solid #bababa;
    color: #787878;
}
.kl-store-page div.product form.cart .reset_variations {
    font-size: 11px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.8);
    display: block !important;
    margin-top: 5px;
}
.kl-store-page div.product form.cart .single_variation_wrap {
    border-left: 3px solid rgba(0, 0, 0, 0.1);
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 20px;
}
.kl-store-page div.product form.cart .single_variation_wrap:before,
.kl-store-page div.product form.cart .single_variation_wrap:after {
    display: table;
    content: ' ';
}
.kl-store-page div.product form.cart .single_variation_wrap:after {
    clear: both;
}
.kl-store-page div.product form.cart .single_variation_wrap .single_variation {
    margin-bottom: 15px;
}
.kl-store-page
    div.product
    form.cart
    .single_variation_wrap
    .single_variation
    .price {
    font-size: 32px;
    color: #444;
    line-height: 1;
}
.kl-store-page
    div.product
    form.cart
    .single_variation_wrap
    .single_variation
    .price:before {
    content: '';
    width: 20px;
    height: 1px;
    background: #ccc;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    margin-left: -20px;
}
.kl-store-page div.product form.cart .single_variation_wrap .variations_button {
}
.kl-store-page
    div.product
    form.cart
    .single_variation_wrap
    .variations_button:before,
.kl-store-page
    div.product
    form.cart
    .single_variation_wrap
    .variations_button:after {
    display: table;
    content: ' ';
}
.kl-store-page
    div.product
    form.cart
    .single_variation_wrap
    .variations_button:after {
    clear: both;
}
.kl-store-page
    div.product
    form.cart
    .single_variation_wrap
    .variations_button
    div.quantity {
    margin-right: 10px;
    float: left;
}
.kl-store-page
    div.product
    form.cart
    .single_variation_wrap
    .variations_button
    div.quantity
    .qty {
    height: 33px;
    width: 50px;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
}

.variations {
    margin-bottom: 15px;
}

/* Prod page - product meta */
.kl-store-page .product_meta {
    margin-bottom: 10px;
}
.kl-store-page .product_meta > span {
    display: block;
}
.kl-store-page .product_meta span a {
    font-weight: normal;
}

/* Product page - rating */
.kl-store-page .kl-store-product-rating .star-rating {
    margin: 5px 5px 10px;
    float: left;
    color: #106ee9;
}
.kl-store-page #comments .star-rating {
    margin-bottom: 10px;
    float: right;
}

/* kl-store Tabs - reviews / comments */
.kl-store-page #reviews #comments ol.commentlist li .comment-text {
    margin-left: 0;
    border: 0;
}
.kl-store-page #reviews #comments ol.commentlist li img.avatar {
    margin: 10px;
}

/* kl-store Tabs - Tab nav */
.kl-store-page div.product .kl-store-tabs {
    margin-bottom: 50px;
}
.kl-store-page div.product .kl-store-tabs ul.tabs {
    margin: 0;
    padding: 0;
    margin-bottom: -1px;
    border-bottom: none;
}
.kl-store-page div.product .kl-store-tabs ul.tabs:before {
    border: 0;
}
.kl-store-page div.product .kl-store-tabs ul.tabs li {
    margin: 0;
    box-shadow: none;
    background: none;
    padding: 0;
    border: none;
    float: left;
    display: block;
}
.kl-store-page div.product .kl-store-tabs ul.tabs li:before {
    display: none;
}
.kl-store-page div.product .kl-store-tabs ul.tabs li:after {
    display: none;
}
.kl-store-page div.product .kl-store-tabs ul.tabs li a {
    font-weight: normal;
    padding: 15px 20px;
    line-height: 18px;
    color: #343434;
    display: block;
    text-transform: uppercase;
}
.kl-store-page div.product .kl-store-tabs ul.tabs li.active a {
    background: #fff;
    border: 1px solid #ececec;
    border-bottom: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

/* Product page tabs */
.product .nav {
    margin-bottom: -1px;
}
.product .nav > li {
    display: inline-block;
}
.product .nav > li > a.active {
    background-color: #fff;
    border-radius: 4px 4px 0 0;
    border: 1px solid #e4e4e4;
    border-bottom: 0;
}
.product .nav > li > a.active:hover,
.product .nav > li > a.active:focus {
    background-color: #fff !important;
}
.product .tab-content {
    background-color: #fff;
    border-radius: 0px 4px 4px 4px;
    border: 1px solid #e4e4e4;
    padding: 20px;
}
.product .tab-content h2 {
    margin-top: 0;
}

/* kl-store Tabs - panels */
.kl-store-page div.product .kl-store-tabs .panel {
    width: 100%;
    padding: 10px 15px 15px;
    border: 1px solid #ececec;
    background: #fff;
}
.kl-store-page div.product .kl-store-tabs h2,
.upsells.products h2,
.related.products h2 {
    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 25px;
}

div.product p.stock,
.site-content div.product p.stock {
    font-size: 11px;
    color: #535353;
}
.variation .variation-color {
    display: inline-block;
}
.shop_table .cart-subtotal,
.shop_table .shipping {
    border-top: 1px solid rgba(221, 221, 221, 0.64);
}
.kl-store table.shop_table tfoot th {
    padding-left: 10px;
}
.kl-store-checkout #payment {
    background: #ebe9eb;
    border-radius: 5px;
    padding: 20px;
}
.kl-store-checkout #payment div.payment_box {
    position: relative;
    width: 96%;
    padding: 1em 2%;
    margin: 1em 0;
    font-size: 0.92em;
    border-radius: 2px;
    line-height: 1.5;
    background-color: #dfdcde;
    color: #515151;
}
.kl-store-checkout #payment div.payment_box p {
    margin-bottom: 0;
}
.payment_method_paypal img {
    height: 60px;
    margin-left: 15px;
    margin-right: 15px;
}

/* BADGES */
.hg_badge_container {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
}
.product-page .hg_badge_container {
    left: 35px;
}
.hg_badge_container .hg_badge_sale:not(:only-child) {
    margin-bottom: -1px;
}
.hg_badge_container .hg_badge_sale:not(:only-child):after {
    display: none;
}
.hg_badge_container .hg_badge_new:not(:only-child):before {
    content: '&';
}
.hg_badge_sale,
.hg_badge_new {
    color: #ffffff;
    font-size: 12px;
    line-height: 1;
    padding: 8px 12px 7px;
    position: relative;
    display: block;
    margin-bottom: 11px;
    border-radius: 2px;
    text-align: center;
}
.hg_badge_sale {
    background: #c51212;
}
.hg_badge_sale:after,
.hg_badge_new:after {
    position: absolute;
    bottom: -3px;
    content: '';
    width: 10px;
    height: 10px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-radius: 2px;
}
.hg_badge_sale:after {
    z-index: 1;
    background-color: #106ee9;
    left: 5px;
}
.hg_badge_new {
    background-color: #12a1c5;
}
.hg_badge_new:after {
    background-color: #12a1c5;
    z-index: 1;
    left: 5px;
}

/* ORDERING FORM */
.zn_woo_cat_page {
    position: relative;
}
.kl-store_ordering {
    float: right;
    margin-bottom: 20px;
    position: absolute;
    top: 0;
    right: 0;
}

/* register form */
form .form-row input.input-text,
form .form-row textarea {
    height: auto;
    min-height: 30px;
}

#payment ul.payment_methods li label {
    display: inline-block;
}

.popupaddcart {
    z-index: 1000;
    width: 250px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #cecece;
    border: 3px solid #999;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    color: #333;
    position: fixed;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -125px;
}

.zn_loop_row {
    clear: both;
    height: 1px;
}

/* Reviews */
.kl-store #reviews h3 {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 600;
}
.kl-store #reviews #comments ol.commentlist {
    padding: 0;
}
.kl-store #reviews #comments ol.commentlist li img.avatar,
.kl-store-page #reviews #comments ol.commentlist li img.avatar {
    width: 70px;
    position: relative;
    margin-right: 15px;
    float: left;
}

/* WOOCOOMERCE PRODUCT SUBCATEGORY */
.zn_product_subcategory {
    text-align: center;
}
.price-range button {
    background: #106ee9;
    font-size: 13px !important;
    padding: 6px 20px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    text-shadow: none;
    border-radius: 3px;
    box-shadow: none;
    position: relative;
    border: 0;
    display: inline-block;
}
.price-range button:hover {
    background-color: #a41a1b;
}
.price-range {
    position: relative;
}
.price-range-slider {
    height: 4px;
    background: #e7e7e7;
    border-radius: 4px;
    margin-bottom: 30px;
}
.price-range-slider .ui-slider-range {
    background: #e5e5e7;
    border-radius: 4px;
}
.price-range-slider .ui-slider-handle {
    border-radius: 9px;
    background-color: #ff525e;
    width: 17px;
    height: 17px;
    margin-top: -6px;
    outline: 0;
    cursor: pointer;
}
.price-range-slider .ui-slider-handle:active {
    outline: 0;
}
.price-range .price-result {
    border: 0;
    background: 0 0;
    max-width: 70px;
    font-size: 13px;
    color: #535353;
    outline: 0;
    height: 32px;
}
.price-range:hover .price-range-slider {
    background: #e7e7e7;
}
.price-range:hover .price-range-slider .ui-slider-range {
    background: #c1c1c1;
}
.ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.ui-slider {
    position: relative;
    text-align: left;
}
.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 1;
    width: 1.2em;
    height: 1.2em;
}
.ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: 0.7em;
    display: block;
    border: 0;
    background-position: 0 0;
}
.price-range .filter {
    position: absolute;
    right: 20px;
}
.price-range span {
    color: #535353;
    display: inline-block;
    font-size: 0.9rem;
}
.pr-result {
    display: inline-block;
    float: right;
    margin-top: 5px;
}

/* Messages / Notices */
.kl-store-page .kl-store-error,
.kl-store-page .kl-store-error:before,
.kl-store-page .kl-store-info,
.kl-store-page .kl-store-info:before,
.kl-store-page .kl-store-message,
.kl-store-page .kl-store-message:before {
    color: #fff;
    line-height: 30px;
    border-top: 0;
}
.kl-store-page .kl-store-error,
.kl-store-page .kl-store-info,
.kl-store-page .kl-store-message {
    border-left: 0.618em solid rgba(0, 0, 0, 0.15);
}
.kl-store-page .kl-store-error a,
.kl-store-page .kl-store-info a,
.kl-store-page .kl-store-message a {
    color: #fff;
    margin-left: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.kl-store-page .kl-store-error a:hover,
.kl-store-page .kl-store-info a:hover,
.kl-store-page .kl-store-message a:hover {
    border-color: rgba(255, 255, 255, 0.4);
}
.kl-store-page .kl-store-info {
    background-color: #3d9cd2;
    margin-bottom: 20px;
    padding: 10px 20px;
}
.kl-store-page .kl-store-error {
    background-color: #e2401c;
}
.kl-store-page .kl-store-message {
    background-color: #0f834d;
}
.kl-store-page .kl-store-message .button.wc-forward {
    background: none;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.kl-store-page .kl-store-message .button.wc-forward:hover {
    background: none;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.8);
}

/* Magnific Popup CSS */
.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8;
    filter: alpha(opacity=80);
}
.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
}
.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
}
.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.mfp-align-top .mfp-container:before {
    display: none;
}
.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
}
.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
}
.mfp-ajax-cur {
    cursor: progress;
}
.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}
.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}
.mfp-auto-cursor .mfp-content {
    cursor: auto;
}
.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.mfp-loading.mfp-figure {
    display: none;
}
.mfp-hide {
    display: none !important;
}
.mfp-preloader {
    color: #ccc;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
}
.mfp-preloader a {
    color: #ccc;
}
.mfp-preloader a:hover {
    color: #fff;
}
.mfp-s-ready .mfp-preloader {
    display: none;
}
.mfp-s-error .mfp-content {
    display: none;
}
button.mfp-close,
button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    -webkit-box-shadow: none;
    box-shadow: none;
}
button::-moz-focus-inner {
    padding: 0;
    border: 0;
}
.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    filter: alpha(opacity=65);
    padding: 0 0 18px 10px;
    color: #fff;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
}
.mfp-close:hover,
.mfp-close:focus {
    opacity: 1;
    filter: alpha(opacity=100);
}
.mfp-close-btn-in .mfp-close {
    color: #333;
}
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    color: #fff;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
}
.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #ccc;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
}
.mfp-arrow {
    position: absolute;
    opacity: 0.65;
    filter: alpha(opacity=65);
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mfp-arrow:active {
    margin-top: -54px;
}
.mfp-arrow:hover,
.mfp-arrow:focus {
    opacity: 1;
    filter: alpha(opacity=100);
}
.mfp-arrow:before,
.mfp-arrow:after,
.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent;
}
.mfp-arrow:after,
.mfp-arrow .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
}
.mfp-arrow:before,
.mfp-arrow .mfp-b {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7;
}
.mfp-arrow-left {
    left: 0;
}
.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a {
    border-right: 17px solid #fff;
    margin-left: 31px;
}
.mfp-arrow-left:before,
.mfp-arrow-left .mfp-b {
    margin-left: 25px;
    border-right: 27px solid #3f3f3f;
}
.mfp-arrow-right {
    right: 0;
}
.mfp-arrow-right:after,
.mfp-arrow-right .mfp-a {
    border-left: 17px solid #fff;
    margin-left: 39px;
}
.mfp-arrow-right:before,
.mfp-arrow-right .mfp-b {
    border-left: 27px solid #3f3f3f;
}
.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
    top: -40px;
}
.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
}
/* Main image in popup */
img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    padding: 40px 0 40px;
    margin: 0 auto;
}
/* The shadow behind the image */
.mfp-figure {
    line-height: 0;
}
.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444;
}
.mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px;
}
.mfp-figure figure {
    margin: 0;
}
.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
}
.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #f3f3f3;
    word-wrap: break-word;
    padding-right: 36px;
}
.mfp-image-holder .mfp-content {
    max-width: 100%;
}
.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
}
/* Fade Effect for opening */
.mfp-fade.mfp-bg {
    background: #333;
    opacity: 0;
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.94;
}
.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}
/* Zoom effect */
.mfp-zoom-in .mfp-with-anim {
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
}
.mfp-zoom-in.mfp-bg {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.mfp-zoom-in.mfp-ready .mfp-with-anim {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.8;
}
.mfp-zoom-in.mfp-removing .mfp-with-anim {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0;
}
@media screen and (max-width: 800px) and (orientation: landscape),
    screen and (max-height: 300px) {
    /** * Remove all paddings around the image on small screen */
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0;
    }
    .mfp-img-mobile img.mfp-img {
        padding: 0;
    }
    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0;
    }
    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px;
    }
    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
    }
    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0;
    }
    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px;
    }
    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        text-align: center;
        padding: 0;
    }
}
@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(0.75);
        -ms-transform: scale(0.75);
        transform: scale(0.75);
    }
    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        -ms-transform-origin: 0;
        transform-origin: 0;
    }
    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        -ms-transform-origin: 100%;
        transform-origin: 100%;
    }
    .mfp-container {
        padding-left: 6px;
        padding-right: 6px;
    }
}

/* REVOLUTION SLIDER */
.tp-caption.more_details a {
    color: #fff;
}
.tp-caption.project_subtitle {
    font-size: 18px;
    font-weight: 700;
    text-shadow: 1px 1px 0 white;
    color: #888;
}
/*.rev_slider_wrapper.fullscreen-container {height: 100vh !important;}*/

/* Rev slider v5 with Kallyas v4+ */
.tp-bullets.kallyas-default.vertical {
    direction: ltr;
    height: auto !important;
    left: 99% !important;
}
.tp-bullets.kallyas-default.vertical:before {
    content: none;
}
.tp-bullets.kallyas-default.vertical .tp-bullet {
    width: 30px;
    height: 6px;
    position: relative;
    top: auto !important;
    right: 0;
    left: auto;
    background-color: transparent;
    -webkit-transition: width 0.15s ease-out, height 0.15s ease-out;
    transition: width 0.15s ease-out, height 0.15s ease-out;
    cursor: pointer;
}
.tp-bullets.kallyas-default.vertical .tp-bullet:before,
.tp-bullets.kallyas-default.vertical .tp-bullet:after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    -webkit-transition: background-color 0.15s ease-out;
    transition: background-color 0.15s ease-out;
}
.tp-bullets.kallyas-default.vertical .tp-bullet:before {
    top: 3px;
    background-color: rgba(0, 0, 0, 0.1);
}
.tp-bullets.kallyas-default.vertical .tp-bullet:after {
    top: 4px;
    background-color: rgba(255, 255, 255, 0.7);
}
.tp-bullets.kallyas-default.vertical .tp-bullet.selected {
    background-color: transparent;
}
.tp-bullets.kallyas-default.vertical .tp-bullet:hover,
.tp-bullets.kallyas-default.vertical .tp-bullet.selected {
    width: 40px;
}
.tp-bullets.kallyas-default.vertical .tp-bullet.selected:hover:before,
.tp-bullets.kallyas-default.vertical .tp-bullet.selected:hover:after,
.tp-bullets.kallyas-default.vertical .tp-bullet.selected:before,
.tp-bullets.kallyas-default.vertical .tp-bullet.selected:after {
    background-color: #106ee9;
}
.rev_slider_wrapper:hover .tp-bullets.kallyas-default.vertical .tp-bullet {
    width: 50px;
}
.rev_slider_wrapper:hover .tp-bullets.kallyas-default.vertical .tp-bullet:hover,
.rev_slider_wrapper:hover
    .tp-bullets.kallyas-default.vertical
    .tp-bullet.selected {
    width: 70px;
}

.rev_slider,
.rev_slider_wrapper {
    height: 780px;
}

/* Arrows */
.tparrows.kallyas-default {
    background-color: transparent;
}
.tparrows.kallyas-default:before {
    font-size: 24px;
}
/* A radial lighting background to simulate a gradient over a color */
.tp-caption.light-faded-radial {
    background-color: transparent !important;
    background-image: -webkit-radial-gradient(
            43% 56%,
            circle,
            rgba(255, 255, 255, 0.3),
            transparent 70%
        ),
        radial-gradient(
            circle at 65.72% 51.72%,
            rgba(255, 255, 255, 0.3),
            transparent 100%
        ),
        radial-gradient(circle at 50% 50%, transparent, transparent 50%);
    background-image: radial-gradient(
            circle at 43% 56%,
            rgba(255, 255, 255, 0.3),
            transparent 70%
        ),
        radial-gradient(
            circle at 65.72% 51.72%,
            rgba(255, 255, 255, 0.3),
            transparent 50%
        ),
        radial-gradient(circle at 50% 50%, transparent, transparent 100%);
}
/* Gradient Blue revolution slider background */
.rev-gradient-blue .tp-bgimg {
    background: #066ed9;
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0%, #066ed9),
        color-stop(100%, #0da2be)
    ) !important;
    background: -webkit-linear-gradient(
        left,
        #066ed9 0%,
        #0da2be 100%
    ) !important;
    background: -webkit-linear-gradient(
        left,
        #066ed9 0%,
        #0da2be 100%
    ) !important;
    background: linear-gradient(to right, #066ed9 0%, #0da2be 100%) !important;
}
/* Dash list helper for rev slider */
.rev-list-dash:before {
    content: '';
    width: 12px;
    height: 3px;
    background: #106ee9;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}

.tp-caption.Fashion-BigDisplay,
.Fashion-BigDisplay {
    color: rgba(0, 0, 0, 1);
    font-size: 60px;
    line-height: 60px;
    font-weight: 900;
    font-style: normal;
    font-family: Raleway;
    padding: 0px 0px 0px 0px;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
    border-style: none;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
    letter-spacing: 2px;
}

.tp-caption.kl-shopslider-1,
.kl-shopslider-1 {
    color: rgba(207, 207, 207, 1);
    font-size: 54px;
    line-height: 60px;
    font-weight: 300;
    font-style: normal;
    font-family: Open Sans;
    padding: 0px 0px 0px 0px;
    text-decoration: none;
    text-align: left;
    background-color: transparent;
    border-color: transparent;
    border-style: none;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
}
.tp-caption.kl-shop-btn,
.kl-shop-btn {
    color: rgba(212, 38, 39, 1);
    font-size: 16px;
    line-height: 17px;
    font-weight: 500;
    font-style: normal;
    font-family: Open Sans;
    padding: 12px 35px 12px 35px;
    text-decoration: none;
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(212, 38, 39, 1);
    border-style: solid;
    border-width: 2px;
    border-radius: 3px 3px 3px 3px;
}
.tp-caption.kl-red-label,
.kl-red-label {
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    font-style: normal;
    font-family: Open Sans;
    padding: 5px 50px 5px 10px;
    text-decoration: none;
    text-align: left;
    background-color: rgba(212, 38, 39, 1);
    border-color: transparent;
    border-style: none;
    border-width: 0px;
    border-radius: 3px 3px 3px 3px;
}

/* Bubble Box */
.bubble-box {
    max-width: 305px;
    position: fixed;
    bottom: 50px;
    left: -100%;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.bubble-box.bb--anim-show {
    left: 50px;
}
.bubble-box .bb--inner {
    padding: 25px;
}
.bubble-box p:last-child {
    margin-bottom: 0;
}
.bubble-box .bb--close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    line-height: 1;
    cursor: pointer;
}
.bubble-box .bb--close:hover {
    color: #fff;
}
.bubble-box.bb--anim-show.bb--anim-hide {
    left: -100%;
}

/* Notification Style */
.notification-box {
    max-width: 305px;
    border-radius: 7px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.15);
}
.notification-box a {
    color: rgba(113, 189, 116, 0.75);
}
.notification-box .bb--close {
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
}

/* Ad box */
.ads-box {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    text-align: center;
    color: #4f5152;
    max-width: 190px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.18);
    font-size: 13px;
    line-height: 1.5;
}
.ads-box .pp-adimg {
    border-radius: 2px;
    margin-bottom: 10px;
}
.ads-box .bb--close {
    right: 6px;
    top: 5px;
}
.ads-box .bb--close:hover {
    color: #000;
}

/* Kallyas Popup Box */
.kl-pp-box {
    width: 400px;
    margin: 0 auto;
    position: relative;
}
.kl-pp-box .kl-pp-inner {
    border-radius: 5px;
    background-color: #fff;
}
.kl-pp-box[data-ppbox-timeout] .dontshow {
    font-size: 12px;
    color: #fff;
    margin-top: 5px;
    display: inline-block;
}

/* Newsletter box */
.kl-newsletter-box {
    width: 755px;
    height: 540px;
    margin: 0 auto;
}
.kl-newsletter-box .kl-pp-inner {
    position: relative;
}
.nlbox--inner {
    padding: 80px 60px 40px;
    margin-left: 315px;
}
.nlbox--hugediscount {
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    margin: 20px 0;
    padding: 0;
    max-height: 200px;
}
.nlbox--hugediscount > span {
    display: inline-block;
    vertical-align: top;
}
.nlbox--preview {
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 315px;
    height: 100%;
    border-radius: 5px 0 0 5px;
}
.nlbox-logo {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    min-height: 60px;
}
.kl-newsletter-box input[type='email'] {
    width: calc(100% - 100px);
    height: 40px;
}
.kl-newsletter-box input,
.kl-newsletter-box input:focus {
    border: 1px solid #ced4da !important;
}
.kl-newsletter-box form .btn {
    width: 40%;
}

/* Portfolio Full Width */
/* Project list */
ul.thumbs {
    list-style-type: none;
    -webkit-backface-visibility: hidden;
    margin: 0;
    padding: 0;
}
ul.thumbs .project-item {
    font-size: 13px;
    text-align: center;
    vertical-align: top;
    display: block;
    float: left;
}
ul.thumbs .inner-item {
    padding: 0 15px;
    margin-bottom: 2%;
}
ul.thumbs[data-columns='1'] .project-item {
    width: 100%;
    margin-bottom: 0;
}
ul.thumbs[data-columns='2'] .project-item {
    width: 50%;
    width: -webkit-calc(100% / 2);
    width: calc(100% / 2);
    margin-bottom: 0;
}
ul.thumbs[data-columns='3'] .project-item {
    width: 33.3333%;
    margin-bottom: 0;
}
ul.thumbs[data-columns='4'] .project-item {
    width: 25%;
    width: -webkit-calc(100% / 4);
    width: calc(100% / 4);
    margin-bottom: 0;
}
ul.thumbs[data-columns='5'] .project-item {
    width: 20%;
    width: -webkit-calc(100% / 5);
    width: calc(100% / 5);
    margin-bottom: 0;
}
ul.thumbs[data-columns='6'] .project-item {
    width: 16.66666666666667%;
    width: -webkit-calc(100% / 6);
    width: calc(100% / 6);
    margin-bottom: 1.8%;
}

@media (max-width: 419px) {
    ul.thumbs .project-item {
        width: 100% !important;
    }
}
@media (min-width: 420px) and (max-width: 580px) {
    ul.thumbs[data-columns='2'] .project-item,
    ul.thumbs[data-columns='3'] .project-item,
    ul.thumbs[data-columns='4'] .project-item,
    ul.thumbs[data-columns='5'] .project-item,
    ul.thumbs[data-columns='6'] .project-item {
        width: 100%;
    }
}
@media (min-width: 580px) and (max-width: 767px) {
    ul.thumbs[data-columns='2'] .project-item,
    ul.thumbs[data-columns='3'] .project-item,
    ul.thumbs[data-columns='4'] .project-item,
    ul.thumbs[data-columns='5'] .project-item,
    ul.thumbs[data-columns='6'] .project-item {
        width: 50%;
        width: -webkit-calc(100% / 2);
        width: calc(100% / 2);
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    ul.thumbs[data-columns='3'] .project-item,
    ul.thumbs[data-columns='4'] .project-item,
    ul.thumbs[data-columns='5'] .project-item,
    ul.thumbs[data-columns='6'] .project-item {
        width: 33.3333%;
        width: -webkit-calc(100% / 3);
        width: calc(100% / 3);
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    ul.thumbs[data-columns='4'] .project-item,
    ul.thumbs[data-columns='5'] .project-item,
    ul.thumbs[data-columns='6'] .project-item {
        width: 33.333%;
        width: -webkit-calc(100% / 3);
        width: calc(100% / 3);
    }
}

ul.thumbs li {
}
ul.thumbs li [data-role='title'] {
    font-size: 15px;
    font-weight: 600;
    color: #686b78;
    margin: 0 0 10px;
    line-height: 1;
    text-transform: uppercase;
}
ul.thumbs li .prj-info {
    margin-top: 20px;
}
ul.thumbs li .img-placeholder {
    position: relative;
}
ul.thumbs .overlay {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}
ul.thumbs .overlay-inner {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
}
ul.thumbs .overlay a {
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    padding: 15px 30px;
    border: 1px solid #fff;
    border-radius: 2px;
    text-decoration: none;
    line-height: 1;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
}
ul.thumbs .overlay a:hover {
    border-color: transparent;
    background: #fe6060;
}
ul.thumbs .overlay a:nth-child(1) {
    top: -50px;
}
ul.thumbs .overlay a:nth-child(2) {
    top: 50px;
}
ul.thumbs li:hover .overlay {
    opacity: 1;
    visibility: visible;
}
ul.thumbs li:hover .overlay a {
    top: 0;
}
ul.thumbs li .appreciate {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background: #dddddd;
    text-decoration: none;
}
ul.thumbs li .appreciate::before {
    font-size: 18px;
    line-height: 39px;
    text-align: center;
    color: #fff;
}
ul.thumbs li .appreciate:hover {
    background: #fe6060;
}
/* project list - style2 */
ul.thumbs.style2 .inner-item {
    position: relative;
    overflow: hidden;
}
ul.thumbs.style2 .prj-info {
    margin: 0;
    position: absolute;
    width: 100%;
    top: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    background: rgba(255, 255, 255, 0.95);
    padding: 25px;
    text-align: left;
}
ul.thumbs.style2 .prj-info::before {
    content: '';
    position: absolute;
    left: 30px;
    bottom: 100%;
    border: 6px solid transparent;
    border-bottom-color: rgba(255, 255, 255, 0.95);
}
ul.thumbs.style2 .prj-info > div {
    float: left;
    width: -webkit-calc(100% - 40px);
    width: calc(100% - 40px);
}
ul.thumbs.style2 [data-role='title'] {
    text-transform: none;
    color: #242731;
    font-size: 16px;
}
ul.thumbs.style2 [data-role='cat'] {
    color: #888;
    font-size: 13px;
    font-weight: 600;
    margin: 0;
}
ul.thumbs.style2 li:hover .prj-info {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 1;
    visibility: visible;
}
ul.thumbs.style2 .appreciate {
    background: none;
    float: right;
    height: auto;
    color: #888;
    text-align: center;
    line-height: 1;
}
ul.thumbs.style2 .appreciate::before,
ul.thumbs.style2 .appreciate:hover::before {
    color: #106ee9;
    line-height: 1;
    font-size: 18px;
}
ul.thumbs.style2 .appreciate::after {
    font-size: 13px;
    line-height: 1;
    text-align: center;
    display: block;
    vertical-align: bottom;
    margin-top: 5px;
}
ul.thumbs.style2 .appreciate:hover::before {
    color: #565656;
}
ul.thumbs.style2 .appreciate:hover {
    background: none;
}
ul.thumbs.style2 .overlay {
    background: rgba(54, 54, 54, 0.85);
}
ul.thumbs.style2 .overlay a {
    color: #fff;
    padding: 10px 15px;
    top: 0;
}
ul.thumbs.style2 .overlay a:hover {
    background: #106ee9;
    border-color: transparent;
}
ul.thumbs.style2 .overlay a:nth-child(1) {
    top: -50px;
}
ul.thumbs.style2 .overlay a:nth-child(2) {
    top: -55px;
    -webkit-transition-delay: 0.05s !important;
    transition-delay: 0.05s !important;
}
ul.thumbs.style2 li:hover .overlay a {
    top: 0;
}
ul.thumbs .overlay-inner {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    margin-top: -50px;
}
ul.project-list.style2 li:hover .prj-info {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 1;
    visibility: visible;
}
ul.project-list.style2 .prj-info {
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
.heart-count {
    display: block;
    font-size: 16px;
    margin-top: 5px;
    font-family: 'Open Sans';
}

.full-portfolio {
    background-color: #1a1c25;
    padding: 40px 0;
}
.full-portfolio h3 {
    color: #fff;
    text-transform: uppercase;
}
.full-portfolio .hg-portfolio-sortable #portfolio-nav {
    margin: 0;
    float: right;
}
.full-portfolio .hg-portfolio-sortable #portfolio-nav li a {
    background: none;
    color: #fff;
    font-size: 12px;
}
.full-portfolio .hg-portfolio-sortable #portfolio-nav li a:hover,
.full-portfolio .hg-portfolio-sortable #portfolio-nav li.current a {
    background: none;
    color: #106ee9;
}

.fullwidthportfolio.portfolio-items {
    width: 100%;
    padding: 0;
}
.fullwidthportfolio.portfolio-items > .row {
    margin-left: 0;
    margin-right: 0;
}
.fullwidthportfolio.portfolio-items > .row > div {
    float: none;
    padding: 0;
}
.fullwidthportfolio ul.thumbs {
    margin: 0;
}
.fullwidthportfolio ul.thumbs .project-item {
    margin-bottom: 0;
}
.fullwidthportfolio ul.thumbs .inner-item {
    padding: 0;
    margin-bottom: 0;
}
.fullwidthportfolio ul.thumbs li {
    -webkit-transition-property: -webkit-transform, opacity, box-shadow;
    transition-property: transform, opacity, box-shadow;
}
.fullwidthportfolio ul.thumbs li:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    z-index: 3;
}
.project-item .overlay a {
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
.bg-dark-blue {
    background: #1a1c25 !important;
}

/* FreeWall Gallery */
.photo-wall .isotope-item,
.photo-wall .grid-sizer {
    width: 380px;
    width: -webkit-calc(100% / 5);
    width: calc(100% / 5);
}
.photo-wall .isotope-item {
    float: left;
    position: relative;
    padding-bottom: 380px;
    padding-bottom: -webkit-calc(100% / 5);
    padding-bottom: calc(100% / 5);
    overflow: hidden;
}
.photo-wall .isotope-item.width2 {
    width: 760px;
    width: -webkit-calc((100% / 5) * 2);
    width: calc((100% / 5) * 2);
}
.photo-wall .isotope-item.height2 {
    padding-bottom: 760px;
    padding-bottom: -webkit-calc((100% / 5) * 2);
    padding-bottom: calc((100% / 5) * 2);
}
.photo-wall .pw-item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
.photo-wall .pw-item::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fe6060;
    opacity: 0;
    visibility: hidden;
}
.photo-wall .isotope-item:hover .pw-item {
    -webkit-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
}
.photo-wall .isotope-item:hover .pw-item::after {
    opacity: 0.5;
    visibility: visible;
}
.photo-wall .pw-details {
    position: absolute;
    bottom: -200px;
    left: 5%;
    color: #fff;
    width: 100%;
    padding-left: 0;
    opacity: 0;
    visibility: hidden;
    padding-bottom: 40px;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
.photo-wall .isotope-item:hover .pw-details {
    bottom: 0;
    opacity: 1;
    visibility: visible;
}
.photo-wall .pw-details [data-role='title'] {
    font-size: 18px;
    font-weight: 600;
    color: inherit;
    margin-bottom: 0;
    letter-spacing: 0;
}
.photo-wall .pw-details [data-role='category'] {
    font-size: 12px;
    font-weight: 600;
    opacity: 0.7;
    color: inherit;
    margin-bottom: 0;
}
.photo-wall .pw-details i {
    float: left;
}
.photo-wall .pw-details h5 {
    margin-top: 0;
}
/* end FreeWall Gallery */

.g-recaptcha {
    overflow: hidden;
    display: inline-block;
    padding-left: 15px;
    margin-bottom: 15px;
}

/* One Page */
.section-video-icons .left-side {
    background-color: rgba(61, 61, 61, 0.7) !important;
    padding-right: 50px !important;
}
.section-video-icons .right-side {
    background-color: rgba(205, 33, 34, 0.7) !important;
    padding-left: 50px !important;
}
.section-video-icons img {
    width: 100%;
}

.custom-position3 {
    padding-top: 15%;
    padding-right: 15%;
    padding-bottom: 13%;
    padding-left: 10%;
    background-color: rgba(132, 105, 16, 0.7) !important;
}

.mtop-50 {
    margin-top: 50px;
}
.ptop-30 {
    padding-top: 30px;
}
.lh-24 {
    line-height: 24px !important;
}
.fa-facebook-f.color {
    color: #3b5998;
}
.fa-youtube.color {
    color: #cd201f;
}
.fa-twitter.color {
    color: #55acee;
}
.fa-pinterest.color {
    color: #cc2127;
}

.fa-facebook-f.color:hover,
.fa-youtube.color:hover,
.fa-twitter.color:hover,
.fa-pinterest.color:hover {
    color: #4c4c4c;
}
.custom-height .caroufredsel_wrapper,
.custom-height .recentwork_carousel__img {
    height: 500px !important;
}
.footer_nav {
    margin-top: 0 !important;
}
.kl-slideshow .kl-contentmaps__panel {
    top: 150px;
}
/* Social Icons Element */
.elm-social-icons {
    margin-left: 0;
    padding: 0;
    display: inline-block;
    list-style: none;
}
.elm-social-icons li {
    display: inline-block;
    margin: 5px;
    margin-left: 3px;
    margin-right: 3px;
}
.elm-social-icons .elm-sc-icon {
    display: inline-block;
    color: #fff;
    width: auto;
    height: auto;
    padding: 23px;
    position: relative;
    text-align: center;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    font-size: 14px;
    font-weight: normal;
    opacity: 1;
}
.elm-social-icons .elm-sc-icon:before {
    position: absolute;
    -webkit-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    -webkit-backface-visibility: hidden;
    top: 50%;
    left: 50%;
}
.elm-social-icons.sc--normal .elm-sc-icon {
    background-color: #111;
}
.elm-social-icons.sc--normal .elm-sc-icon:hover {
    background-color: #106ee9;
}
/* Colored social icons */
.elm-social-icons.sc--colored .elm-sc-icon:hover {
    background-color: #111;
}
/* Colored social icons */
.elm-social-icons.sc--colored_hov .elm-sc-icon {
    background-color: #111;
}
.elm-social-icons.sc--colored_hov .elm-sc-icon:hover {
    background-color: rgba(0, 0, 0, 0.05);
}
/* Social icons - clean style */
.elm-social-icons.sc--clean .elm-sc-icon {
    background: none !important;
}
.elm-social-icons.sc--clean .elm-sc-icon:hover {
    background: none !important;
    box-shadow: none;
}
/* Shape */
.elm-social-icons.sh--rounded .elm-sc-icon {
    border-radius: 3px;
}
.elm-social-icons.sh--circle .elm-sc-icon {
    border-radius: 50%;
}
.elm-social-icons.sh--special1 .elm-sc-icon:before {
    top: 75%;
    left: 75%;
}
.elm-socialicons {
    margin-bottom: 35px;
}

.fa-facebook-f.bg-icon {
    background: #3b5998;
}
.fa-twitter.bg-icon {
    background: #55acee;
}
.fa-dribbble.bg-icon {
    background: #ea4c89;
}
.fa-pinterest.bg-icon {
    background: #cc2127;
}
.fa-linkedin.bg-icon {
    background: #0077b5;
}

#footer[data-footer-style='2'] .newsletter-signup .nl-email {
    width: calc(99% - 130px);
}
#footer[data-footer-style='2'] .newsletter-signup {
    margin-bottom: 30px;
}
.chaser .main-menu li a {
    background: transparent;
}
.nav > li > a:focus,
.nav > li > a:hover {
    background: none !important;
}

/* LOGIN / REGISTER POPUPS */
.kl-fw-input {
    width: 100%;
}
.loginbox-popup .kl-fancy-form {
    margin-bottom: 15px;
}
.loginbox-popup .kl-fancy-form .form-control {
    padding: 26px 15px 6px 15px;
}
.auth-popup-panel {
    padding: 45px;
}
.auth-popup-title {
    font-size: 20px !important;
    font-weight: 400;
    color: #4a4a4a;
    margin-bottom: 20px;
    line-height: 1.9;
    text-shadow: none;
}
.auth-popup-createacc {
    margin-right: 8px;
    background: none !important;
}
.auth-popup-createacc + .auth-popup-sep {
    margin-right: 8px;
    border-left: 1px solid #ccc;
}
.auth-popup-links {
    margin-top: 20px;
    color: #4a4a4a;
    font-size: 10px;
    font-weight: 600;
}
.auth-popup-link {
    font-size: 13px;
    font-weight: 400;
    color: #898989;
}
.auth-popup-link:hover {
    color: #585858;
}
.auth-popup-remember {
    margin: 0 0 20px;
    display: block;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 11px;
    text-shadow: none;
}
.auth-popup-remember-chb {
    margin: 0;
}
.mfp-bg.mfp-fade.mfp-bg-lighter {
    background: #000;
}
.mfp-bg.mfp-fade.mfp-bg-lighter.mfp-ready {
    opacity: 0.75;
}
.auth-popup .mfp-close {
    color: #828282;
}
.auth-popup {
    width: 420px;
    margin: 0 auto;
    border: 1px solid #fff;
    background: #f9f9f9;
    box-shadow: 0 0 20px #000;
    border-radius: 2px;
    position: relative;
}
.register-popup {
    width: 420px;
}

/* FANCYFORMS */
.kl-fancy-form {
    position: relative;
    margin-bottom: 20px;
}
.kl-fancy-form-label {
    color: #969696;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    top: 6px;
    left: 16px;
    cursor: text;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.kl-fancy-form-input:focus + .kl-fancy-form-label,
.kl-fancy-form-textarea:focus + .kl-fancy-form-label {
    color: #c0c0c0;
}
.kl-fancy-form .form-control {
    padding: 26px 16px 6px 9px;
    height: auto;
}
/* Selectbox fix for Chrome/Safari on OSX */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .kl-fancy-form-select {
        height: 52px;
        -webkit-appearance: none;
        box-shadow: inset 1px 2px 0 rgba(0, 0, 0, 0.06);
        background-image: url(../images/select-arrow.svg);
        background-repeat: no-repeat;
        background-size: 6px 12px;
        background-position: calc(100% - 10px) center;
        background-position-y: center;
        background-position-x: calc(100% - 10px);
    }
}
.kl-fancy-form-input::-webkit-input-placeholder {
    color: #bababa;
}
.kl-fancy-form-input:focus::-webkit-input-placeholder {
    color: #d7d7d7;
}
/* Firefox < 19 */
.kl-fancy-form-input:-moz-placeholder {
    color: #bababa;
}
.kl-fancy-form-input:focus:-moz-placeholder {
    color: #d7d7d7;
}
/* Firefox > 19 */
.kl-fancy-form-input::-moz-placeholder {
    color: #bababa;
}
.kl-fancy-form-input:focus::-moz-placeholder {
    color: #d7d7d7;
}
/* Internet Explorer 10 */
.kl-fancy-form-input:-ms-input-placeholder {
    color: #bababa;
}
.kl-fancy-form-input:focus:-ms-input-placeholder {
    color: #d7d7d7;
}

.reset-list,
.reset-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.inline-list {
    display: inline-block;
}
.inline-list--items > li {
    display: inline-block;
}

.fancy-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
.fancy-list li::before {
    content: '';
    display: inline-block;
    margin-right: 12px;
    width: 8px;
    height: 2px;
    background: #e1e1e1;
    vertical-align: middle;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
.fancy-list li:hover::before {
    width: 12px;
    margin-right: 8px;
    background: #106ee9;
}

/* Contact form pop-up element (Get a quote) */
.contact-popup {
    width: 650px;
    margin: 0 auto;
    border: 1px solid #fff;
    background: #f9f9f9;
    box-shadow: 0 0 20px #000;
    border-radius: 2px;
    position: relative;
}
.contact-popup-panel .contactForm {
    padding: 50px;
}
.contact-popup-title {
    font-size: 22px !important;
}

/* Niche Agency demo updates */
.ls__laptop-mask {
    margin-bottom: 20px;
}
.agency .ls__arrow:before,
.agency .ls__arrow:after {
    background-color: #989898;
}

.agency .ls__arrow:hover:before,
.agency .ls__arrow:hover:after {
    background-color: rgb(93, 93, 93);
}

.hover-scale {
    display: inline-block;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    transition: -webkit-transform 0.1s ease-out;
    transition: transform 0.1s ease-out;
}
.hover-scale:hover {
    -webkit-transform: scale(1.4, 1.4);
    -ms-transform: scale(1.4, 1.4);
    transform: scale(1.4, 1.4);
}
.kl-icon.ico-klmid {
    vertical-align: middle;
}
.kl-icon.ico-size-xxl {
    font-size: 32px;
}
.stg-alignleft .kl-icon {
    float: left;
    margin-right: 25px;
    font-size: 28px;
    color: #fff;
}

.testimonials-partners--dark .ts-pt-testimonials__text.gray-bg {
    background: rgba(165, 165, 165, 0.2);
}

.cms-icon-section {
    overflow: hidden;
    position: relative;
}
.cms-icon-container {
    position: absolute;
    top: 0;
    left: 50%;
    text-align: center;
    margin-left: -200px;
}
.cms-icon-container i {
    position: absolute;
    font-size: 400px;
    line-height: 400px;
    vertical-align: middle;
    height: 400px;
    top: 10px;
    color: rgba(0, 0, 0, 0.05);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    opacity: 0;
    transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}
.cms-icon-container .kl-icon.icon-github-circled {
    font-size: 520px;
    line-height: 520px;
    height: 520px;
    top: -50px;
    margin-left: -40px;
}
.cms-icon-container .kl-icon.hovered {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}
.colored-area {
    background: linear-gradient(135deg, #360384, #da0b68) !important;
    background-image: linear-gradient(
        135deg,
        rgb(54, 3, 132),
        rgb(218, 11, 104)
    ) !important;
    background-repeat: repeat;
    background-position: 0% 0%;
    background-clip: border-box;
    background-origin: padding-box;
    background-size: auto auto;
}

.socialbox {
    padding: 70px 0;
    background: #efefef;
    color: #bfbfbf;
    display: block;
    margin: 0 -15px;
    border-style: solid;
    text-decoration: none;
    border-color: #dbdbdb;
    border-width: 1px 0 1px 1px;
    text-align: center;
}
.socialbox:hover {
    text-decoration: none;
}
.socialbox .kl-icon {
    font-size: 44px;
    display: inline-block;
}
.socialbox .fan-count {
    opacity: 0;
    visibility: hidden;
    display: block;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    font-weight: 800;
    margin: 0;
    line-height: 1.3;
    height: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
}

.socialbox .fan-count > span {
    display: block;
    font-size: 30px;
    font-weight: 400;
    color: #fff;
}

.socialbox:hover .kl-icon,
.touch .socialbox:active .kl-icon {
    color: #fff;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
}
.socialbox:hover .fan-count,
.socialbox:active .fan-count {
    opacity: 1;
    visibility: visible;
}
.socialbox,
.socialbox .kl-icon,
.socialbox .fan-count {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.socialbox.sb-twitter:hover {
    background: #00bdec;
}
.socialbox .fa-twitter {
    color: #00bdec;
}
.socialbox.sb-facebook:hover {
    background: #3b5998;
}
.socialbox .fa-facebook-f {
    color: #3b5998;
}
.socialbox.sb-dribbble:hover {
    background: #ef5b92;
}
.socialbox .fa-dribbble {
    color: #ef5b92;
}
.socialbox.sb-envato:hover {
    background: #7fb841;
}
.socialbox .icon-envato {
    color: #7fb841;
}
.socialbox.sb-vimeo:hover {
    background: #01557a;
}
.socialbox.sb-google-plus:hover {
    background: #d94a39;
}
.socialbox.sb-linkedin:hover {
    background: #1b92bd;
}
.socialbox.sb-behance:hover {
    background: #026afa;
}

.socialbox.sb-pinterest:hover {
    background: #cb2027;
}
.socialbox.sb-instagram:hover {
    background: #517fa4;
}
.socialbox.sb-youtube:hover {
    background: #d20800;
}
.socialbox.sb-skype:hover {
    background: #18b7f1;
}
.socialbox.sb-flickr:hover {
    background: #ea2698;
}
.socialbox.sb-yelp:hover {
    background: #af0606;
}
.socialbox.sb-lastfm:hover {
    background: #d2013a;
}

.el-devimages {
    position: relative;
}
.el-devimages--product .el-devimages__laptop,
.el-devimages--product .el-devimages__smartphone {
    transition: -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
}

.fade-animation:hover .el-devimages__smartphone {
    -webkit-transform: translateX(20px) translateY(5px);
    -ms-transform: translateX(20px) translateY(5px);
    transform: translateX(20px) translateY(5px);
}
.fade-animation:hover .el-devimages__laptop {
    -webkit-transform: translateX(-40px) translateY(-5px);
    -ms-transform: translateX(-40px) translateY(-5px);
    transform: translateX(-40px) translateY(-5px);
}

/* Eos Homepage */
.poppins {
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
}
.custom-p {
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
    font-size: 1rem;
    color: #868686;
}
.find-out .kl-icon {
    font-size: 32px;
    margin-right: 12px;
}
.find-out span {
    vertical-align: middle;
    display: inline-block;
}
.find-out .text {
    font-weight: 700;
}

/* Parallax Effects in static content - product presentation alternative */
.parallax-effects {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    list-style: none;
}
.parallax-effects .layer {
    width: 100%;
    height: 100%;
}
.parallax-effects .layer-element {
    position: absolute;
}
#page_wrapper .parallax-effects .slide-image {
    width: 110%;
    height: 110%;
    left: -5%;
    top: -5%;
}
.pos-vertical-middle {
    position: relative;
    margin: auto;
    left: 0;
    top: 50%;
    right: 0;
    z-index: 1;
}
.pos-vertical-middle > .container {
    top: 50%;
    position: absolute;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.rotating {
    display: inline-block;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateX(0) rotateY(0) rotateZ(0);
    -moz-transform: rotateX(0) rotateY(0) rotateZ(0);
    -ms-transform: rotateX(0) rotateY(0) rotateZ(0);
    -o-transform: rotateX(0) rotateY(0) rotateZ(0);
    transform: rotateX(0) rotateY(0) rotateZ(0);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform-origin-x: 50%;
}

.rotating.flip {
    position: relative;
}

.rotating .front,
.rotating .back {
    left: 0;
    top: 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
}
.rotating .front {
    position: absolute;
    display: inline-block;
    -webkit-transform: translate3d(0, 0, 1px);
    -moz-transform: translate3d(0, 0, 1px);
    -ms-transform: translate3d(0, 0, 1px);
    -o-transform: translate3d(0, 0, 1px);
    transform: translate3d(0, 0, 1px);
}
.rotating.flip .front {
    z-index: 1;
}
.rotating .back {
    display: block;
    opacity: 0;
}
.rotating.spin {
    -webkit-transform: rotate(360deg) scale(0);
    -moz-transform: rotate(360deg) scale(0);
    -ms-transform: rotate(360deg) scale(0);
    -o-transform: rotate(360deg) scale(0);
    transform: rotate(360deg) scale(0);
}
.rotating.flip .back {
    z-index: 2;
    display: block;
    opacity: 1;
    -webkit-transform: rotateY(180deg) translate3d(0, 0, 0);
    -moz-transform: rotateY(180deg) translate3d(0, 0, 0);
    -ms-transform: rotateY(180deg) translate3d(0, 0, 0);
    -o-transform: rotateY(180deg) translate3d(0, 0, 0);
    transform: rotateY(180deg) translate3d(0, 0, 0);
}

.rotating.flip.up .back {
    -webkit-transform: rotateX(180deg) translate3d(0, 0, 0);
    -moz-transform: rotateX(180deg) translate3d(0, 0, 0);
    -ms-transform: rotateX(180deg) translate3d(0, 0, 0);
    -o-transform: rotateX(180deg) translate3d(0, 0, 0);
    transform: rotateX(180deg) translate3d(0, 0, 0);
}
.rotating.flip.cube .front {
    -webkit-transform: translate3d(0, 0, 100px) scale(0.9, 0.9);
    -moz-transform: translate3d(0, 0, 100px) scale(0.85, 0.85);
    -ms-transform: translate3d(0, 0, 100px) scale(0.85, 0.85);
    -o-transform: translate3d(0, 0, 100px) scale(0.85, 0.85);
    transform: translate3d(0, 0, 100px) scale(0.85, 0.85);
}

.rotating.flip.cube .back {
    -webkit-transform: rotateY(180deg) translate3d(0, 0, 100px) scale(0.9, 0.9);
    -moz-transform: rotateY(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
    -ms-transform: rotateY(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
    -o-transform: rotateY(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
    transform: rotateY(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
}

.rotating.flip.cube.up .back {
    -webkit-transform: rotateX(180deg) translate3d(0, 0, 100px) scale(0.9, 0.9);
    -moz-transform: rotateX(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
    -ms-transform: rotateX(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
    -o-transform: rotateX(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
    transform: rotateX(180deg) translate3d(0, 0, 100px) scale(0.85, 0.85);
}

/* Team Boxes Carousel element*/
.team-carousel {
    margin-left: -15px;
    margin-right: -15px;
}

/* Team Member Block */
.team-member {
    margin: 0 15px 3px;
    padding: 0 0 15px;
    margin-bottom: 40px;
    -webkit-backface-visibility: hidden;
}
.team-boxes__carousel-wrapper {
    position: relative;
}
.team-boxes__carousel-wrapper .team-carousel .team-member {
    margin: 0 15px 3px;
}
.team-member .mmb-title {
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 18px;
    padding: 0 15px;
}
.team-member .mmb-position {
    color: #2a83ed;
    margin-bottom: 20px;
    font-size: 12px;
    padding: 0 15px;
}
.team-member .mmb-desc {
    color: #888;
    padding: 0 15px;
}
.team-member .separator {
    background: #e8e8e8;
    height: 1px;
    width: 100%;
    margin: 0 auto 20px;
    display: block;
}
.team-member.text-center .separator {
    width: 80%;
}
.team-member .mmb-social-list {
    overflow: hidden;
    margin: 0 auto;
    display: inline-block;
}
.team-member .mmb-social-list li {
    display: inline-block;
    margin: 0 5px;
}
.team-member .mmb-social-list a {
    color: #bababb;
    font-size: 17px;
    text-decoration: none;
}
.team-member:hover .mmb-social-list a,
.team-member .mmb-social-list a:hover {
    color: #828282;
}
.team-member.tm-hover:hover {
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.team-boxes__carousel-wrapper .hgSlickNav {
    position: absolute;
    top: -30px;
    right: 30px;
}
.team-boxes__carousel-wrapper .hgSlickNav-arr,
.team-boxes__carousel-wrapper .hgSlickNav-arr svg {
    width: 25px;
}
/* end Team Boxes Carousel element*/

/* Static content - Immersive video */
.kl-slideshow.immersive-video {
    background-color: #ececec;
}
.cd-overflow-hidden {
    /* this class is added to the body while the video is playing - used on desktop version only */
    height: 100vh;
}

.cd-immersive-video {
    position: relative;
    height: 100vh;
    width: 100%;
    padding: 3em 5%;
    margin: 0 auto;
}
.cd-loader {
    display: none;
}

.cd-immersive-video .product-intro {
    text-align: center;
    margin-bottom: 3em;
    -webkit-animation: cd-item-move-up 0.5s 0.3s;
    animation: cd-item-move-up 0.5s 0.3s;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
}
.cd-immersive-video .product-intro h1 {
    font-size: 2.4rem;
    line-height: 1.2;
    color: #151515;
    margin-bottom: 0.4em;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    text-transform: uppercase;
}
.cd-immersive-video .product-intro p {
    line-height: 1.4;
    margin: 1em 0 1.4em;
    font-family: 'OpenSans', Helvetica, Arial, sans-serif;
    line-height: 22px;
    margin-bottom: 30px;
}

.cd-btn {
    display: inline-block;
    color: #ffffff;
    background: #2d60d5;
    padding: 1em 2em;
    box-shadow: 0 2px 10px rgba(45, 96, 213, 0.2);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}
.cd-btn:hover {
    box-shadow: 0 6px 30px rgba(45, 96, 213, 0.3);
    color: #fff;
}
.cd-immersive-video .product-preview {
    position: relative;
    width: 340px;
    max-width: 90%;
    margin: 0 auto;
    -webkit-animation: cd-item-move-up 0.5s 0.5s;
    animation: cd-item-move-up 0.5s 0.5s;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
    overflow: hidden;
}
.cd-immersive-video .product-preview .device-frame {
    position: relative;
    z-index: 1;
}
.cd-immersive-video .product-preview .device-frame img {
    display: block;
    width: 100%;
}
.cd-immersive-video .product-preview .product-image,
.cd-immersive-video .product-preview .product-video {
    position: absolute;
    top: 9.5%;
    left: 6%;
    width: 88%;
    height: 81%;
    box-shadow: 0 0 0 1px #000;
}

.cd-immersive-video .product-preview .product-image {
    z-index: 2;
    overflow: hidden;
    background: #000;
}
.cd-immersive-video .product-preview .product-image img {
    display: block;
    width: 100%;
}
.cd-immersive-video .product-preview .product-video {
    display: none;
    z-index: 3;
}
.no-cssanimations .cd-immersive-video .product-preview .product-video {
    visibility: hidden;
}

@-webkit-keyframes "cd-item-move-up" {
    0% {
        opacity: 0;
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes "cd-item-move-up" {
    0% {
        opacity: 0;
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@-webkit-keyframes "cd-item-slide-in" {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
@keyframes "cd-item-slide-in" {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
@-webkit-keyframes "cd-loading" {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }
    100% {
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(360deg);
        transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
}
@keyframes "cd-loading" {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }
    100% {
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(360deg);
        transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
}

/* Slick slider default styles */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/* Slider */
.slick-loading .slick-list {
    background: #fff url('../images/ajax-loader.gif') center center no-repeat;
}

/* Arrows */
.slick--showOnMouseover .hgSlickNav {
    opacity: 0;
    -webkit-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
}
.slick--showOnMouseover:hover .hgSlickNav {
    opacity: 1;
}
.hgSlickNav-arr,
.hgSlickNav-arr svg {
    width: 18px;
    height: 18px;
    cursor: pointer;
}
.hgSlickNav-arr polyline {
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
    stroke: #141414;
}
.hgSlickNav-arr:hover polyline {
    stroke-width: 35px;
}
.hgSlickNav-arr:active polyline {
    stroke-width: 50px;
}
.hgSlickNav-arr.hgSlickNav-prev {
    text-align: left;
}
.hgSlickNav-arr.hgSlickNav-next {
    text-align: right;
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}
.slick-dots li {
    display: inline-block;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
.slick-dots .slickBtn {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button,
.hgSlickNav--light .slick-dots li button {
    background-color: #fff;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    padding: 5px;
    opacity: 0.5;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    margin-right: 5px;
}
.hgSlickNav--light polyline {
    stroke: #ececec;
}
.hgSlickNav--light .slick-dots li.slick-active button {
    opacity: 0.8;
}
/* end Slick slider default styles */

/**** Slider element with Slick Slider styles ****/

/* Screenshot-box element */
.screenshot-box {
    background: #fff;
    position: relative;
    border-radius: 3px;
    border: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.screenshot-box .left-side h3.title {
    color: #106ee9;
    line-height: 1.4;
    font-size: 1.4rem;
}
.screenshot-box .left-side ul.features li:first-child {
    margin-top: 0;
}
.screenshot-box .left-side ul.features li h4 {
    font-size: 1rem;
    line-height: 1.3;
    color: #323232;
    margin: 0 0 5px;
    text-transform: uppercase;
    font-weight: 600;
}
.screenshot-box .left-side ul.features li h4 + p {
    font-size: 0.9rem;
    line-height: 1.5;
}
.screenshot-box .left-side {
    padding: 10px 0 25px 30px;
}
.screenshot-box .left-side ul.features {
    margin: 25px 0;
    padding: 0;
    list-style: none;
}
.screenshot-box .left-side ul.features li {
    padding-left: 50px;
    background: url(../images/ok3.png) no-repeat left center;
    margin-top: 20px;
}
.screenshot-box .left-side > .btn {
    margin-left: 50px;
}

/* Slick slider resets */
.slick-dots li button:before {
    display: none;
}
.screenshot-slider .js-slick {
    margin-bottom: 0;
}
.screenshot-slider .slick-dots {
    bottom: 0;
}
.slick-slide {
    outline: none;
}

/* Screenshot slider */
.screenshot-box .screenshot-slider {
    position: relative;
    background-color: #dcdee7;
    padding: 36px 0 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-top: -10px;
}
.screenshot-box .screenshot-slider:before {
    content: '';
    position: absolute;
    top: 15px;
    margin-top: 0;
    left: 30px;
    display: block;
    background: #ababab;
    border-radius: 50%;
    width: 7px;
    height: 7px;
    box-shadow: -12px 0 0 #ababab, 12px 0 0 #ababab;
}
.screenshot-box .screenshot-slider img {
    width: 100%;
    height: 100%;
}

.screenshot-slider .screenshotBox-slickNav li {
    display: inline-block;
    width: auto;
    height: auto;
    margin: 5px;
}
.screenshot-slider .screenshotBox-slickNav .slickBtn {
    width: 35px;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.5);
    display: inline-block;
    margin: 0;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
    border-radius: 0;
    padding: 0;
}
.screenshot-slider .screenshotBox-slickNav .slick-active .slickBtn {
    background-color: #fff;
}
.screenshot-slider .screenshotBox-slickNav {
    position: absolute;
    bottom: 20px;
    text-align: center;
    left: 0;
    right: 0;
    z-index: 10;
}
/* end Screenshot-box element */

/* Partners carousel element */
.ts-pt-partners__title {
    width: 22%;
    float: left;
    font-size: 18px;
    color: #fff;
    opacity: 0.6;
    position: relative;
    line-height: 1.2;
}
.ts-pt-partners__title:after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 50px;
    height: 3px;
    background: #fff;
}
.ts-pt-partners__carousel-wrapper {
    width: 100%;
}
.ts-pt-partners--y-title .ts-pt-partners__carousel-wrapper {
    width: 78%;
    float: left;
}
.ts-pt-partners__carousel-item {
    float: left;
    height: 60px;
    line-height: 60px;
    vertical-align: middle;
    text-align: center;
}
.ts-pt-partners__carousel.non-slick {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.ts-pt-partners__carousel.non-slick .ts-pt-partners__carousel-item {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.ts-pt-partners__img {
    max-height: 60px;
    margin: 0 auto;
    width: auto;
}
.slick-initialized .ts-pt-partners__img {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: opacity 0.3s ease 0s, -webkit-transform 0.2s ease 0s;
    transition: opacity 0.3s ease 0s, -webkit-transform 0.2s ease 0s;
    transition: opacity 0.3s ease 0s, transform 0.2s ease 0s;
    transition: opacity 0.3s ease 0s, transform 0.2s ease 0s,
        -webkit-transform 0.2s ease 0s;
}
.slickSlider--activated .slick-active .ts-pt-partners__img {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    -webkit-transition: opacity 0.6s ease 0.1s, -webkit-transform 0.6s ease 0s;
    transition: opacity 0.6s ease 0.1s, -webkit-transform 0.6s ease 0s;
    transition: opacity 0.6s ease 0.1s, transform 0.6s ease 0s;
    transition: opacity 0.6s ease 0.1s, transform 0.6s ease 0s,
        -webkit-transform 0.6s ease 0s;
}
.slickSlider--activated .slick-active ~ .slick-active .ts-pt-partners__img {
    -webkit-transition-delay: 0.2s, 0.1s;
    transition-delay: 0.2s, 0.1s;
}
.slickSlider--activated
    .slick-active
    ~ .slick-active
    ~ .slick-active
    .ts-pt-partners__img {
    -webkit-transition-delay: 0.3s, 0.2s;
    transition-delay: 0.3s, 0.2s;
}
.slickSlider--activated
    .slick-active
    ~ .slick-active
    ~ .slick-active
    ~ .slick-active
    .ts-pt-partners__img {
    -webkit-transition-delay: 0.4s, 0.3s;
    transition-delay: 0.4s, 0.3s;
}
/* Dark version */
.testimonials-partners--dark .testimonials-partners__separator {
    background: #181818;
}
.testimonials-partners--dark .ts-pt-testimonials__item {
    color: #181818;
}
.testimonials-partners--dark .ts-pt-testimonials__text {
    background: rgba(255, 255, 255, 0.2);
}
.testimonials-partners--dark
    .ts-pt-testimonials__item--normal
    .ts-pt-testimonials__text:after {
    border-top-color: rgba(255, 255, 255, 0.2);
}
.testimonials-partners--dark
    .ts-pt-testimonials__item--reversed
    .ts-pt-testimonials__text:after {
    border-bottom-color: rgba(255, 255, 255, 0.2);
}
.testimonials-partners--dark .ts-pt-partners__title {
    color: #181818;
}
.testimonials-partners--dark .ts-pt-partners__title:after {
    background: #181818;
}
.testimonials-partners--dark .ts-pt-partners__title.no-border:after {
    background: none;
}
/* end Partners carousel element */

/* Testimonials carousel quoter-style style element */
.testimonial_slider__carousel-wrapper {
    margin: 0 180px 20px;
    position: relative;
}
.testimonial_slider__carousel-wrapper::before,
.testimonial_slider__carousel-wrapper::after {
    position: absolute;
    top: 50px;
    font-size: 120px;
    font-family: 'Trebuchet MS';
    color: #e9eaea;
    line-height: 0;
}
.testimonial_slider__carousel-wrapper::before {
    left: -50px;
    content: '\201C';
}
.testimonial_slider__carousel-wrapper::after {
    right: -50px;
    content: '\201D';
}
.testimonial_slider__carousel-wrapper .testimonialbox {
    font-size: 16px;
    color: #595959;
    line-height: 1.8;
    text-align: center;
}
.testimonial_slider__carousel-wrapper .thead img {
    margin: 0 auto 20px;
    width: auto;
}

.testimonial_slider__carousel-wrapper .testimonialbox .who {
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1;
}
.testimonial_slider__carousel-wrapper .testimonialbox .who strong {
    display: block;
    font-size: 1.2rem;
    color: #fe6060;
    line-height: 1.6;
    margin-bottom: 5px;
}
.testimonial_slider__carousel-wrapper .thead {
    margin-bottom: 20px;
}

.testimonial_slider__carousel-wrapper .testimonialbox.biggertext .tcontent {
    font-size: 16px;
}
/*no quotes*/
.testimonial_slider__carousel-wrapper.stg-no-quotes::before,
.testimonial_slider__carousel-wrapper.stg-no-quotes::after {
    display: none;
}
.testimonial_slider__carousel-wrapper.stl-light .testimonialbox .who {
    color: #acacac;
}
.testimonial_slider__carousel-wrapper.stl-light .testimonialbox .tcontent {
    color: #e7e7e7;
}

.testimonial_slider__carousel-wrapper .testimonialSlider-slickNav {
    position: absolute;
    bottom: -20px;
    text-align: center;
    left: 0;
    right: 0;
    z-index: 10;
}
.testimonial_slider__carousel-wrapper .testimonialSlider-slickNav li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.testimonial_slider__carousel-wrapper .testimonialSlider-slickNav li .slickBtn {
    display: inline-block;
    margin: 0 3px;
    background: #e5e5e5;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    box-shadow: none;
    padding: 3px;
}
.testimonial_slider__carousel-wrapper
    .testimonialSlider-slickNav
    li
    .slickBtn:hover {
    background-color: #ccc;
}
.testimonial_slider__carousel-wrapper
    .testimonialSlider-slickNav
    li.slick-active
    .slickBtn {
    background-color: #fe6060;
    box-shadow: none;
}

.testimonial_slider__carousel-wrapper.slim-margin {
    margin: 70px 100px;
}
/* end Testimonials carousel quoter-style style element */

/* Testimonials carousel fader style element */
.testimonials_fader__carousel-wrapper {
    margin-bottom: 40px;
}
.testimonial_fader__carousel {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
}
.testimonials_fader__carousel-wrapper .has-left-border {
    border-left: 1px solid #e2e2e2;
}
.testimonial_fader__carousel .testimonials_fader__carousel-item {
    position: relative;
    padding: 0 0 20px 100px;
}
.testimonial_fader__carousel .testimonials_fader__carousel-item:after {
    content: '\201C';
    position: absolute;
    top: -10px;
    left: 30px;
    font-size: 150px;
    color: #e2e2e2;
    line-height: 1;
    font-family: tahoma;
}
.testimonials_fader__carousel-wrapper blockquote {
    margin: 0;
    padding: 0;
    border: 0;
    font: italic 20px/1.4 Georgia, Arial, sans-serif;
    overflow: hidden;
}
.testimonials_fader__carousel-wrapper blockquote + h6 {
    font-weight: normal;
    font-size: 11px;
    font-style: italic;
}
.testimonials_fader__carousel-wrapper .testimonial-author--photo {
    width: 30px;
    height: 30px;
}
.testimonials_fader__carousel-wrapper .testimonial-author h6 {
    float: left;
    margin: 5px 0;
}
/* end Testimonials carousel fader style element */

/* Simple Testimonials carousel element */
.testimonial_simple__carousel blockquote {
    background: #fff;
    margin: 0 10px 10px 10px;
    padding: 25px;
    color: #898989;
    font-size: 14px;
    border-left: none;
    line-height: 2;
    font-style: italic;
    position: relative;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
}
.testimonial_simple__carousel blockquote:after,
.testimonial_simple__carousel blockquote:before {
    content: '';
    position: absolute;
    bottom: -14px;
    left: 14px;
    border: 7px solid transparent;
    border-top-color: #fff;
}
.testimonial_simple__carousel blockquote:before {
    bottom: -16px;
    border-top-color: #d9d9d9;
}
.testimonial-author h5 {
    display: inline-block;
    margin: 12px 0;
    font-size: 13px;
    font-weight: 500;
}
.testimonials-author blockquote + h5 {
    display: inline-block;
    margin: 12px 0;
    font-size: 13px;
    font-weight: 500;
}
.testimonial-author {
    margin-top: 15px;
}
.testimonial-author--photo {
    margin-right: 10px;
    float: left;
    border-radius: 50%;
    overflow: hidden;
    width: 40px;
    height: 40px;
}
.testimonial-author--photo img {
    width: 100%;
    height: auto;
    display: block;
}
/* end Simple Testimonials carousel element */

/* Portfolio carousel element */
.pt-content-title {
    font-size: 32px;
    line-height: 1.4;
    margin-bottom: 20px;
    font-weight: 300;
}
.pt-content-title.small {
    font-size: 1.5rem;
}
.ptcarousel--frames-classic {
    position: relative;
    border: 4px solid #fff;
    box-shadow: 0 0 0 2px #ddd, 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
}
.ptcarousel--frames-classic:after {
    content: '';
    position: absolute;
    left: -34px;
    top: 50%;
    margin-top: -15px;
    border: 15px solid transparent;
    border-right: 15px solid #ddd;
    z-index: 1;
}
.ptcarousel--frames-classic:before {
    content: '';
    position: absolute;
    left: -30px;
    top: 50%;
    margin-top: -13px;
    border: 13px solid transparent;
    border-right: 13px solid #fff;
    z-index: 2;
}
.ptcarousel--frames-modern {
    position: relative;
    padding-top: 30px;
    padding-bottom: 0;
    border-radius: 4px 4px 2px 2px;
    background-color: #dcdee7;
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 16px 10px -11px rgba(0, 0, 0, 0.12);
}
.ptcarousel--frames-modern:before {
    content: '';
    position: absolute;
    top: 12px;
    margin-top: 0;
    left: 25px;
    display: block;
    background: #ababab;
    border-radius: 30px;
    width: 7px;
    height: 7px;
    box-shadow: -12px 0 0 #ababab, 12px 0 0 #ababab;
}
.ptcarousel--frames-minimal {
    position: relative;
}
.ptcarousel ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
}
.ptcarousel li {
    float: left;
}
.ptcarousel .controls > a {
    position: absolute;
    left: 15px;
    top: 50%;
    z-index: 11;
    background: #000;
    display: inline-block !important;
    margin: 0;
    margin-top: -12px;
    width: 30px;
    height: 31px;
    text-align: center;
    border-radius: 2px;
    opacity: 0;
}

.itemLinks {
    margin-top: 20px;
}
.itemLinks span a {
    background: #eee;
    padding: 7px 10px;
    line-height: 1;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #888;
    border-radius: 2px;
    display: inline-block;
    margin-right: 15px;
}
.itemLinks span a:hover {
    background: #106ee9;
    color: #fff;
}

.hg_general__carousel .hgSlickNav-arr {
    position: absolute;
    left: 15px;
    top: 50%;
    z-index: 11;
    background: rgba(0, 0, 0, 0.2);
    margin: 0;
    margin-top: -25px;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 2px;
    opacity: 0;
}
.hg_general__carousel .hgSlickNav-arr svg {
    display: block;
    margin: 0 auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: relative;
}
.hg_general__carousel .hgSlickNav-arr polyline {
    stroke: #fff;
}
.hg_general__carousel .hgSlickNav-arr.hgSlickNav-prev {
    text-align: left;
}
.hg_general__carousel .hgSlickNav-arr.hgSlickNav-next {
    left: auto;
    right: 15px;
}
.hg_general__carousel:hover .hgSlickNav-arr {
    opacity: 1;
}
/* end Portfolio carousel element */

/* Partners carousel simple element */
.partners_carousel h5.title {
    font-weight: 500;
    margin-top: 0;
    text-shadow: 1px 1px 0 #fff;
}
.partners_carousel__simple .partners_carousel-item {
    float: left;
    text-align: center;
    margin: 0;
    padding: 0 0 0 10px;
    width: 250px;
}
.partners_carousel__simple .partners_carousel-item a {
    display: block;
    padding: 10px 0;
    margin: 0 5px;
    min-height: 46px;
    line-height: 46px;
    border: 4px solid #f9f9f9;
    border-radius: 3px;
    background: #ffffff;
}
.partners_carousel__simple .partners_carousel-img {
    opacity: 0.8;
    display: inline-block;
}
.partners_carousel__simple .partners_carousel-item a:hover {
    background: #f9f9f9;
    border: 4px solid #eee;
}
.partners_carousel__simple
    .partners_carousel-item
    a:hover
    .partners_carousel-img {
    opacity: 1;
}
/* end Partners carousel simple element */

/* Image boxes carousel element */
.image-boxes__carousel-wrapper {
    border-bottom: solid 1px #ddd;
}
.image-boxes__carousel-wrapper.no-border {
    border: none;
}
.image-boxes__carousel-wrapper .image-boxes__carousel .inner-slide a {
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
.image-boxes__carousel-wrapper .inner-slide {
    color: #fff;
    color: rgba(255, 255, 255, 0.3);
    font-size: 15px;
    font-weight: 700;
    text-decoration: none;
    display: block;
}
.image-boxes__carousel-wrapper .inner-slide:hover {
    color: #fff;
}
.image-boxes__carousel-wrapper .inner-slide:hover a {
    color: #106ee9;
}
.image-boxes__carousel-wrapper .inner-slide:hover .btn-fullcolor {
    color: #fff;
}

.image-boxes__carousel-wrapper .inner-slide .imgprev {
    background: #242731;
}
.image-boxes__carousel-wrapper .inner-slide:hover .imgprev img {
    opacity: 0.6;
}

.image-boxes__carousel-wrapper .inner-slide .details {
    display: block;
    padding: 30px 0 0;
    border-left: 1px solid #eeeeee;
    min-height: 105px;
}
.image-boxes__carousel-wrapper .inner-slide .details {
    text-align: center;
}

.image-boxes__carousel-wrapper .inner-slide [data-role='title'] {
    font-size: 16px;
    margin: 0;
    font-weight: 600;
}
.image-boxes__carousel-wrapper .inner-slide [data-role='category'] {
    font-size: 13px;
    margin-top: 0;
    margin-bottom: 0;
    color: #ceced0;
}

.image-boxes__carousel-wrapper .slick-dots {
    position: absolute;
    bottom: 0;
    text-align: center;
    left: 0;
    right: 0;
    z-index: 10;
}
.image-boxes__carousel-wrapper .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.image-boxes__carousel-wrapper .slick-dots li .slickBtn {
    display: inline-block;
    margin: 0 3px;
    background: #e5e5e5;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    box-shadow: none;
    padding: 3px;
}
.image-boxes__carousel-wrapper .slick-dots li .slickBtn:hover {
    background-color: #ccc;
}
.image-boxes__carousel-wrapper .slick-dots li.slick-active .slickBtn {
    background-color: #fe6060;
    box-shadow: none;
}
.no-touch .image-boxes__carousel-wrapper .inner-slide .imgprev::after {
    background: transparent;
    border-bottom: 0;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.no-touch .image-boxes__carousel-wrapper .inner-slide:hover .imgprev::after {
    background: rgba(255, 255, 255, 0.1);
    border-bottom: 8px solid #106ee9;
}

/* Hoverlay style */
.image-boxes__carousel-wrapper.stl-hoverlay .inner-slide {
    position: relative;
    margin: 0;
    color: #fff;
    cursor: pointer;
}
.image-boxes__carousel-wrapper.stl-hoverlay .inner-slide .imgprev {
    margin: 0;
}
.image-boxes__carousel-wrapper.stl-hoverlay .inner-slide .imgprev img {
    width: 100%;
    display: block;
}
.image-boxes__carousel-wrapper.stl-hoverlay .inner-slide .imgprev::after {
    display: none;
}
.image-boxes__carousel-wrapper.stl-hoverlay .inner-slide .details {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fe6060;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    border-left: none;
}
.image-boxes__carousel-wrapper.stl-hoverlay .inner-slide:hover .details {
    opacity: 1;
    visibility: visible;
}
.image-boxes__carousel-wrapper.stl-hoverlay .dt-inner {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
}
.image-boxes__carousel-wrapper.stl-hoverlay .inner-slide [data-role='title'] {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin: 10px 0;
}

/* Full-width style */
.image-boxes__carousel-wrapper.full-width,
.image-boxes__carousel-wrapper.full-width .inner-slide .details {
    border: none;
}
.image-boxes__carousel-wrapper.full-width .inner-slide {
    margin: 0 12px;
}
.image-boxes__carousel-wrapper.full-width .inner-slide:hover .imgprev img {
    opacity: 1;
}
/* end Image boxes carousel element */

/* Fancy Carousel element */
.fancy-carousel__wrapper .hgSlickNav {
    position: absolute;
    top: 50%;
    width: 100%;
}
.fancy-carousel__wrapper .hgSlickNav-arr,
.fancy-carousel__wrapper .hgSlickNav-arr svg {
    width: 30px;
    height: 30px;
}
.fancy-carousel__wrapper .hgSlickNav-arr polyline {
    stroke: #fff;
}
.fancy-carousel__wrapper .hgSlickNav-prev {
    float: left;
    margin-left: 25px;
}
.fancy-carousel__wrapper .hgSlickNav-next {
    float: right;
    margin-right: 25px;
}
/* end Fancy Carousel element */

/* Showroom carousel styles */
.shcar__pagination {
    height: 20px;
    line-height: 20px;
    display: inline-block;
}
.shcar__pagination li button {
    background-color: rgba(255, 255, 255, 0.6);
}
.shcar__pagination li.slick-active button {
    background-color: #fff;
}
.shcar__nav_pag {
    position: absolute;
    top: 0;
    left: 30px;
    padding: 0;
}
.shcar__nav_pag .hgSlickNav-arr polyline {
    stroke: #fff;
}

/* Restaurant menu carousel */
.restaurant-menu {
    list-style: none;
    margin: 0;
    padding: 0;
}
.restaurant-menu li {
    font-size: 17px;
    overflow: hidden;
}
.restaurant-menu-title {
    text-align: center;
    color: #ddd;
}
.restaurant-menu-item {
    float: left;
}
.restaurant-menu-price {
    float: right;
}
.menu-carousel .hgSlickNav {
    text-align: center;
    margin-top: 50px;
}
.menu-carousel .hgSlickNav-arr.hgSlickNav-prev {
    margin-right: 20px;
}
